// configuration
import React from "react";

// Third party packages
import { Rate } from "antd";

// components
import { Pagination } from "@mui/material";
import { RatingsHeader } from "./RatingsHeader";

export const AllRatingsPageForLawyer = () => {
    // varible declation
    // hook variables

    // js variables
    const demo = [1, 2, 3, 4];

    return (
        <div>
            <RatingsHeader />

            {/* // Number of reviews */}
            <p className="font-poppins text-[26px] leading-[48px] font-normal text-[#707276] text-left mt-[62px]">Reviews (135)</p>

            <div className="w-full grid grid-cols-1 gap-y-[72px]">
                {demo.map(p => {
                    return (
                        <div className="flex gap-x-[37px]">
                            <div>
                                <img
                                    className="w-[147px] h-[147px] rounded-full"
                                    src="https://scontent.frjh5-1.fna.fbcdn.net/v/t39.30808-6/310814492_3383940818533755_1197676094327108122_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=09cbfe&_nc_eui2=AeGA9lfn6WujkVz6fYFMVoFwjDMuNZrRHNWMMy41mtEc1bicZO-AvSF9FXCNTfRYimndHxJlLdYlNYLMqh4qcFXK&_nc_ohc=BwNn2Tk3roEAX_nvcBl&tn=5Af7VWSQfe-WPdJS&_nc_ht=scontent.frjh5-1.fna&oh=00_AfA4QzKzl6N7cmmtkB_Xot3va6sPjw7uglJbrdMSCjzMIg&oe=63A92D9C"
                                    alt=""
                                />
                            </div>
                            <div className="flex-1 flex flex-col gap-y-[32px] border-t-[1.5px] border-[#F4F4F5] pt-[27px]">
                                <div className="w-full flex justify-between">
                                    <Rate disabled value={4} />
                                    <button className="w-[78px] h-[28px] bg-[#E41272] flex justify-center items-center text-white rounded-[4px]">
                                        Edit
                                    </button>
                                </div>
                                <div>
                                    <p className="text-left text-[18px] text-[#18181B] font-PlusJakartaSans">
                                        You made it so simple. My new site is so much faster and easier to work with than my old site. I just choose
                                        the page, make the changes.
                                    </p>
                                    <div className="flex flex-col gap-y-[16px] items-start mt-[27px]">
                                        <h3 className="text-[#18181B] font-PlusJakartaSans text-[24px] leading-[22px] font-bold">Kristin Watson</h3>
                                        <p className="text-[#71717A] text-[22px] font-normal font-PlusJakartaSans">March 14, 2021</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>

            {/* pagination */}
            <div className="flex justify-around items-center my-12">
                <p className="leading-6 font-medium">Showing 8 from 160 data</p>
                <Pagination className="bg-[#E3E4EB] px-[1px] py-[3px] rounded-md text-white" count={20} shape="rounded" />
            </div>
        </div>
    );
};

// configuration
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// Third party package
import { IoClose } from "react-icons/io5";

// Components
import { getDateFromCreatedAt } from "../../../../../../helper/utilities/getDateFromCreatedAt";
import CancelBtn from "./UpdateOrderStatusBtn/CancelBtn";
import DeliveredBtn from "./UpdateOrderStatusBtn/DeliveredBtn";

export const OnGoingOrderBy = ({ data }) => {
  const [deliveredSuccess, setDeliveredSuccess] = useState(false);
  const [cancelledSuccess, setCancelledSuccess] = useState(false);
  const { firstName, lastName } = data?.singleOrder?.billingInfo || {};

  const navigate = useNavigate();

  return (
    <div className="bg-white shadow-[2px_2px_10px_2px_rgba(0,0,0,0.12)] rounded-[12px] py-6 mt-5">
      <div className="px-6 pb-2 w-full flex flex-col items-start text-[#000000] font-SourceCodePro gap-y-[8px]">
        <div className="w-full flex justify-between items-center">
          <p className="text-[32px] font-medium capitalize">
            Order by user #{(firstName || "") + " " + (lastName || "")}
          </p>
          <div onClick={() => navigate(-1)}>
            <IoClose className="border border-gray-500 text-3xl rounded-full hover:bg-gray-500 hover:text-white cursor-pointer duration-300" />
          </div>
        </div>
        <p className="text-[#666666] text-[24px] font-normal font-SourceCodePro">
          Order Id #{data?.singleOrder?.orderId}
        </p>
        <p className="text-[#666666] text-[24px] font-normal font-SourceCodePro">
          {getDateFromCreatedAt(data?.singleOrder?.createdAt)}
        </p>
      </div>
      <div className="px-6 mt-4 w-full flex justify-between items-center">
        <DeliveredBtn {...{ data, setDeliveredSuccess, cancelledSuccess }} />
        <CancelBtn {...{ data, setCancelledSuccess, deliveredSuccess }} />
      </div>
    </div>
  );
};

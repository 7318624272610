import React from "react";
import { useNavigate } from "react-router-dom";

export const ModifyCaseHistoryProfileCard = () => {
    const navigate = useNavigate();
    return (
        <div>
            <div className=" bg-[linear-gradient(180deg,#FFCCE8_0%,#D5F2FF_100%)] max-w-[385px] rounded-[8px] px-[35px] py-[31px]">
                <div className="flex gap-x-[24px]">
                    <img className="w-[85px] h-[85px] rounded-full" src="https://i.ibb.co/f4Vxd2k/Ellipse-18.png" alt="profile" />
                    <div className="flex flex-col gap-y-[18px]">
                        <h1 className=" text-[24px] leading-[29px] font-medium">Jhon William</h1>
                        <p className=" text-[20px] leading-[24px] font-medium">Age: 26</p>
                        <button
                            className="w-[135px] h-[37px] mt-[20px] flex justify-center items-center bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] text-white rounded-[4px]"
                            onClick={() => navigate("/lawyer/singleCases")}
                        >
                            Modify Review
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

import { createSlice } from "@reduxjs/toolkit";

const initialState = { action: "Suspend", searchTerm: "", for: null, selectedId: "" };

const restrictSuspendUserSlice = createSlice({
    name: "restrictSuspend",
    initialState,
    reducers: {
        setAction: (state = initialState, action) => {
            state.action = action.payload;
        },
        setRestrictSuspendSearchTerm: (state = initialState, action) => {
            state.searchTerm = action.payload;
        },
        setFor: (state = initialState, action) => {
            state.for = action.payload;
        },
        setSelectedId: (state = initialState, action) => {
            state.selectedId = action.payload;
        },
        resetRestrictInfo: (state = initialState, action) => {
            state.searchTerm = "";
            state.for = null;
            state.selectedId = "";
        },
    },
});

export const { setAction, setRestrictSuspendSearchTerm, setFor, setSelectedId, resetRestrictInfo } = restrictSuspendUserSlice.actions;
export default restrictSuspendUserSlice.reducer;

import React, { useState } from "react";
import { RecentApprovedUserForLawyer } from "../../Lawyer/View__Registration__Request/RecentApprovedUserForLawyer";
import { RecentRegReqForLawyer } from "../../Lawyer/View__Registration__Request/RecentRegReqForLawyer";
import { RecentRejectedUserForLawyer } from "../../Lawyer/View__Registration__Request/RecentRejectedUserForLawyer";
import { ReSubmitForLawyer } from "../../Lawyer/View__Registration__Request/ReSubmitForLawyer";
/* card user img */
/* components */
const ViewRegistrationRequestForKazi = () => {
  const [type, setType] = useState("");
  return (
    <div className="max-w-[900px] mx-auto">
      {(type === "registration" || !type) && (
        <RecentRegReqForLawyer type={type} setType={setType} role="kazi" />
      )}
      {(type === "resubmit" || !type) && (
        <ReSubmitForLawyer type={type} setType={setType} role="kazi" />
      )}
      {(type === "approved" || !type) && (
        <RecentApprovedUserForLawyer
          type={type}
          setType={setType}
          role="kazi"
        />
      )}
      {(type === "rejected" || !type) && (
        <RecentRejectedUserForLawyer
          type={type}
          setType={setType}
          role="kazi"
        />
      )}
    </div>
  );
};

export default ViewRegistrationRequestForKazi;

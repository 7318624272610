// normal import
import React, { useState } from "react";
// Third party packages
import Pagination from "@mui/material/Pagination";
// Components
import SearchManyUsers from "../../../Shared/components/SearchUsers/SearchManyUsers";
import AssignKaziCard from "../../../Shared/Cards/Assign_Kazi_Card/AssignKaziCard";
import AssignKaziToUserCard from "../../../Shared/Cards/Assign_Kazi_Card/AssignKaziToUserCard";

const AssignKazi = () => {
  const [userPage, setUserPage] = useState(1);
  const [kaziPage, setKaziPage] = useState(1);
  const [userObj, setUser] = useState();
  const [kaziObj, setKazi] = useState();
  const [selectedKazi, setSelectedKazi] = useState("");

  return (
    <div className="px-8">
      {/* search input  */}
      <div className="mb-8">
        <SearchManyUsers setUser={setKazi} page={kaziPage} role="kazi" />
      </div>

      <section className="grid grid-cols-2 gap-[29px]">
        {kaziObj?.data?.users?.map((kazi) => (
          <AssignKaziCard
            key={kazi._id}
            kazi={kazi}
            selectedKazi={selectedKazi}
            setSelectedKazi={setSelectedKazi}
          />
        ))}
      </section>
      <div className="flex justify-between items-center my-[62px] ">
        <p className="text-[#3E4954] leading-6 font-medium">
          Showing {kaziObj?.data?.users?.length} from {kaziObj?.data?.total}{" "}
          data
        </p>
        <Pagination
          className="bg-[#E3E4EB] px-[1px] py-[3px] rounded-md text-white"
          count={Math.ceil(kaziObj?.data?.total / 8) || 1}
          onChange={(event, pageNumber) => setKaziPage(pageNumber)}
          shape="rounded"
        />
      </div>
      {/* --- Assign Lawyer To User --- */}
      <h1 className="text-[40px] text-left leading-[48px] font-semibold mb-10">
        Assign Kazi To User:
      </h1>
      <div className="mb-8">
        <SearchManyUsers setUser={setUser} page={userPage} role="member" />
      </div>
      <section className="grid grid-cols-2 gap-[29px]">
        {userObj?.data?.users?.map((item) => (
          <AssignKaziToUserCard
            key={item._id}
            user={item}
            selectedKazi={selectedKazi}
          />
        ))}
      </section>
      <div className="flex justify-between items-center my-[62px] ">
        <p className="text-[#3E4954] leading-6 font-medium">
          Showing {userObj?.data?.users?.length} from {userObj?.data?.total}{" "}
          data
        </p>
        <Pagination
          className="bg-[#E3E4EB] px-[1px] py-[3px] rounded-md text-white"
          count={Math.ceil(userObj?.data?.total / 8) || 1}
          onChange={(event, pageNumber) => setUserPage(pageNumber)}
          shape="rounded"
        />
      </div>
    </div>
  );
};

export default AssignKazi;

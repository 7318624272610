import getCookie from "../../../helper/cookies/getCookie";
import { apiSlice } from "../../api/apiSlice";

export const userPlansApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllPlans: builder.query({
      query: () => ({
        url: `/matrimony-package`,
      }),
    }),
    attachPlanToUser: builder.mutation({
      query: ({ user, plan, renderWith }) => ({
        url: `/admin/action/upgradePackage?_user=${user}&_package=${plan}&_duration=${renderWith}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
    }),
  }),
});

export const { useGetAllPlansQuery,useAttachPlanToUserMutation } = userPlansApi;

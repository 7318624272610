import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";

// Third party libraries
import Select from "react-select";
import makeAnimated from "react-select/animated";

// Components
import { useGetAllProductsQuery } from "../../../../Redux/features/Shop/shopApi";
import { Modal } from "../../../Shared/Modal/Modal";

const animatedComponents = makeAnimated();

export const SelectProductsModal = ({
  modalControll,
  setSelectedProducts,
  selectedProducts,
}) => {
  const [option, setOption] = useState([]);
  const { data, isLoading } = useGetAllProductsQuery({
    page: "",
    limit: "",
    keyword: "",
  });
  const { products } = data || {};

  useEffect(() => {
    const selectionValue = products?.map((item) => {
      return {
        label: item.name || "Not Added",
        value: item,
      };
    });

    setOption(selectionValue);
  }, [products]);

  const handleSelectedOptions = (values) => {
    setSelectedProducts(values);
  };
  return (
    <Modal modalControll={modalControll}>
      <div className="flex justify-between">
        <h3 className="text-[26px] font-Inter text-left font-semibold">
          Choose Some Products
        </h3>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#E41272",
            fontWeight: 700,
            "&:hover": {
              backgroundColor: "#f00c74",
            },
          }}
          onClick={modalControll}
        >
          {selectedProducts?.length === 0 ? "Close" : "Apply"}
        </Button>
      </div>
      <div>
        <Select
          closeMenuOnSelect={false}
          isLoading={isLoading}
          components={animatedComponents}
          isMulti
          value={selectedProducts}
          options={option}
          onChange={handleSelectedOptions}
        />
      </div>
    </Modal>
  );
};

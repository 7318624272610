// Configuration
import React from "react";

// Third party packages
import Chart from "react-apexcharts";

const ManageUsageByCategory = () => {
  return (
    <section className="bg-white mx-8 rounded-[40px] mb-14">
      <h1 className="text-[#22242C] text-[32px] text-left leading-10 font-bold ml-9 py-9">
        Usage By Category
      </h1>
      <div className="px-9 pb-[68px]">
        <Chart
          type="donut"
          width={344}
          height={344}
          series={[27, 11, 22, 15, 25]}
          options={{
            labels: ["Users", "Agents", "Kazis", "Lawyers", "Others"],
            colors: ["#6160DC", "#22242C", "#FF4A55", "#FFB74A", "#54C5EB"],
            dataLabels: {
              enabled: false,
            },
            legend: {
              show: true,
              fontSize: "18px",
              height: "50px",
              fontWeight: 500,
              labels: {
                colors: "#22242C",
              },
            },
          }}
        />
      </div>
    </section>
  );
};

export default ManageUsageByCategory;

import React from "react";

// Third party package
import { useNavigate } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Modal } from "antd";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useDeleteCourseMutation } from "../../../../../../Redux/features/Course/courseApi";

const LeftSideHeader = ({ course }) => {
  const { name, description, image } = course || {};
  const navigate = useNavigate();

  const [deleteCourse, { data: response, error }] = useDeleteCourseMutation();

  // mui three dot
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { confirm } = Modal;
  const showDeleteCourseConfirm = (id) => {
    confirm({
      title: "Do you Want to delete this course?",
      content: (
        <p>
          Deleting a course will permanently remove all associated content and
          cannot be undone. Are you sure you want to proceed with the deletion?
        </p>
      ),
      icon: <ExclamationCircleFilled />,
      okButtonProps: {
        style: {
          backgroundColor: "red",
        },
      },
      onOk() {
        deleteCourse(id);
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    if (response) {
      toast.success("Course Section Deleted");
      navigate(-1);
    }
    if (error) {
      toast.error(
        <p>
          Failed to delete section. <br /> Please login or Try again later
        </p>
      );
    }
  }, [response, error, navigate]);

  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="text-[#354895] text-[24px] lg:text-[34px] font-bold font-outfit">
          {name}
        </h1>
        <div>
          <BsThreeDotsVertical
            id="long-button"
            aria-controls={open ? "long-button" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            className="hover:text-black cursor-pointer duration-500 hover:bg-gray-300 bg-gray-200 rounded-full p-1 w-6 h-6"
          />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={() => navigate(-1)}>Back</MenuItem>
            <MenuItem onClick={() => showDeleteCourseConfirm(course?._id)}>
              Delete Course
            </MenuItem>
          </Menu>
        </div>
      </div>
      <p className="text-[20px] text-gray-500 font-syne mt-[14px]">
        {description}
      </p>
      <img
        src={image}
        alt="CourseImage"
        className="mt-[18px] w-full rounded-[6px] max-w-[966px] max-h-[621px]"
        loading="lazy"
      />
    </div>
  );
};
export default LeftSideHeader;

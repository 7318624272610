import React from "react";
import { NavLink } from "react-router-dom";

const CourseBundleNav = ({ children }) => {
  const routes = [
    {
      id: 1,
      path: "addBundle",
      name: "Add Bundle",
    },
    {
      id: 2,
      path: "modifyBundle",
      name: "Modify Bundle",
    },
    {
      id: 3,
      path: "bundleUsers",
      name: "View Bundle Users",
    },
    {
      id: 4,
      path: "notifyToUpgrade",
      name: "Notify to Upgrade",
    },
    {
      id: 5,
      path: "notifyToPay",
      name: "Notify to pay",
    },
  ];
  return (
    <div>
      <h1 className="text-2xl font-bold">Course Bundle</h1>
      <div className="max-w-[1200px]">
        <div className="pt-10 mb-10 px-5">
          <ul className="grid grid-cols-4 gap-[41px] font-poppins font-normal text-base text-[#000000]">
            {routes.map((route) => (
              <NavLink
                key={route.id}
                className={({ isActive }) =>
                  `w-full  text-center py-[5px] rounded-[50px] border border-[#E41272] cursor-pointer text-[15px] font-semibold ${
                    isActive
                      ? " text-white bg-[#E41272]"
                      : "text-[#000000] bg-[#FFFFFF]"
                  }`
                }
                to={route.path}
              >
                {route.name}
              </NavLink>
            ))}
          </ul>
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default CourseBundleNav;

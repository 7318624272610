import React from 'react';
import CurrencyNav from './currencyNav/CurrencyNav';
import { Outlet } from 'react-router-dom';

const Currency = () => {
    return (
        <CurrencyNav>
            <Outlet />
        </CurrencyNav>
    );
}

export default Currency;

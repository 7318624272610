/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useEffect } from "react";
import { useAttachPlanToUserMutation } from "../../../Redux/features/usersPlan/userPlansApi";
import { toast } from "react-hot-toast";

const PackageForm = ({ setShowPopup, pack,user, setSelectedPack }) => {
  const [selectedOption, setSelectedOption] = useState("30");
    
    const [addPlanToUser,{data, isLoading, error}] = useAttachPlanToUserMutation()

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // Redux api call


  useEffect(() => {
    if (data) {
      setShowPopup(false)
      toast.success("User upgraded successfully");
    }
    if (error) {
      toast.error("Something went wrong! Please try again.")
    }
  },[data,error])
  return (
    <>
      <div
        style={{ zIndex: 999 }}
        onClick={() => setShowPopup(false)}
        className="fixed w-full h-full top-0 left-0 inset-0  bg-black/40 cursor-pointer"
      ></div>
      <div
        style={{ zIndex: 9999 }}
        className="rounded-xl sm:max-w-full bg-white w-[400px] space-y-4 p-6 fixed top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2"
      >
              <p className="text-3xl text-[#E41272] font-semibold">{pack?.title}</p>
              <div className="w-full h-[2px] bg-gray-200 "></div>
        <h3 className="text-xl font-semibold text-left pl-12">Add Plan for:</h3>
        <div className="grid grid-cols-2 gap-y-6">
          <div className="ml-4">
            <label className="inline-flex">
              <input
                type="radio"
                className="form-radio h-5 w-5 text-blue-600"
                value="30"
                checked={selectedOption === "30"}
                onChange={handleOptionChange}
              />
              <span className="ml-2">1 Month</span>
            </label>
          </div>
          <div className="ml-4">
            <label className="inline-flex">
              <input
                type="radio"
                className="form-radio h-5 w-5 text-blue-600"
                value="90"
                checked={selectedOption === "90"}
                onChange={handleOptionChange}
              />
              <span className="ml-2">3 Month</span>
            </label>
          </div>
          <div className="ml-4">
            <label className="inline-flex">
              <input
                type="radio"
                className="form-radio h-5 w-5 text-blue-600"
                value="180"
                checked={selectedOption === "180"}
                onChange={handleOptionChange}
              />
              <span className="ml-2">6 Month</span>
            </label>
          </div>
          <div className="ml-4 flex justify-start pl-[35px]">
            <label className="inline-flex ">
              <input
                type="radio"
                className="form-radio h-5 w-5 text-blue-600"
                value="365"
                checked={selectedOption === "365"}
                onChange={handleOptionChange}
              />
              <span className="ml-2">1 Year</span>
            </label>
          </div>
        </div>

        <button
          onClick={() =>
            addPlanToUser({
              plan: pack?._id,
              user: user?._id,
              renderWith: selectedOption,
            })
          }
          className="w-[102px] mt-60 h-[32px] border-[1px] border-[#E41272] hover:bg-[#E41272] hover:text-white rounded-[37px] text-[#E41272] text-[14px] font-Montserrat font-bold uppercase"
        >
          {isLoading?"Loading":"Submit"}
        </button>
      </div>
    </>
  );
};

export default PackageForm;

// configuration
import React, { useEffect, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

// Third party packages
import { Select } from "antd";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import { toast, Toaster } from "react-hot-toast";

// Components
import Error from "../../../Shared/components/accordion/UI/Error/Error";
import { RichTextEditor } from "../../../Shared/components/TextEditor/RichTextEditor";
import {
  useAddNewProductsMutation,
  useGetAllCategoriesQuery,
} from "../../../../Redux/features/Shop/shopApi";
import { useGetReferPointQuery } from "../../../../Redux/features/referral/referralApi";

export const AddProductForm = () => {
  const [priceInPoint, setPriceInPoint] = useState(0);
  const [shippingChargeInPoint, setShippingChargeInPoint] = useState(0);
  const [categories, setCategories] = useState("");
  const [description, setDescription] = useState(null);
  const [customError, setCustomError] = useState([
    {
      categoryError: "",
    },
    { descriptionError: "" },
  ]);
  const [productPhotos, setProductPhotos] = useState(null);

  const navigate = useNavigate();

  const { data } = useGetReferPointQuery();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const { data: categoryResponse } = useGetAllCategoriesQuery("shop");
  const [
    addNewProducts,
    { data: productResponse, isLoading: addProductLoading, error },
  ] = useAddNewProductsMutation();

  const categoryOptions = categoryResponse
    ? categoryResponse?.category?.map((category) => {
        return {
          value: category?.name,
          label: category?.name,
        };
      })
    : [];

  // function declarations
  const onDrop = useCallback(
    (acceptedFiles) => {
      setProductPhotos(acceptedFiles);
    },
    [setProductPhotos]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  // function declarations
  const onSubmit = async (data) => {
    if (categories === "") {
      setCustomError[0].categoryError("Category is required");
      return;
    }
    if (description === null) {
      setCustomError[1].descriptionError("Description is required");
      return;
    }
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("size", data.size);
    formData.append("color", data.color);
    formData.append("brand", data.brand);
    formData.append("stock", data.stock);
    formData.append("price", Number(data.price));
    formData.append("shiping", Number(data.shiping));
    formData.append("discount", Number(data.discount));
    formData.append("description", description);
    formData.append("category", categories);
    formData.append("priceInPoint", Number(priceInPoint));
    formData.append("shippingChargeInPoint", Number(shippingChargeInPoint));
    for (let i = 0; i < productPhotos.length; i++) {
      formData.append("photos", productPhotos[i]);
    }
    await addNewProducts(formData);
  };

  useEffect(() => {
    if (productResponse) {
      toast.success("Product Added Successfully");
      reset();
      navigate(-1);
    }
    if (error) {
      toast.error(
        <p>
          Something went wrong. <br /> Please try again later.
        </p>
      );
    }
  }, [productResponse, error, reset, navigate]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col items-start w-full gap-y-[40px]"
    >
      {/* Product Name */}
      <div className="w-full flex flex-col">
        <label
          htmlFor="name"
          className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]"
        >
          Product Name
        </label>
        <input
          {...register("name", {
            required: true,
          })}
          className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-19px px-[12px]"
          type="text"
          placeholder="Product Name"
        />
      </div>

      {/* product category */}
      <div className="w-full flex flex-col">
        <label
          htmlFor="name"
          className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]"
        >
          Product Category
        </label>
        <Select
          placeholder="Select Categories"
          bordered={false}
          className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-19px px-[12px] text-start flex items-center"
          //   options={options}
          options={categoryOptions}
          onSelect={(value) => setCategories(value)}
          onChange={() => setCustomError("")}
        />
        {customError[0]?.categoryError && (
          <Error message={customError[0]?.categoryError} />
        )}
      </div>

      {/* product size */}
      <div className="w-full flex flex-col">
        <label
          htmlFor="size"
          className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]"
        >
          Product Size
        </label>
        <input
          {...register("size")}
          className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-19px px-[12px]"
          type="text"
          placeholder="Size"
        />
      </div>

      {/* product color */}
      <div className="w-full flex flex-col">
        <label
          htmlFor="color"
          className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]"
        >
          Product Color
        </label>
        <input
          {...register("color")}
          className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-19px px-[12px]"
          type="text"
          placeholder="Color"
        />
      </div>

      {/* brand name */}
      <div className="w-full flex flex-col">
        <label
          htmlFor="brand"
          className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]"
        >
          Brand Name
        </label>
        <input
          {...register("brand")}
          className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-19px px-[12px]"
          type="text"
          placeholder="Brand Name"
        />
      </div>

      {/* product price */}
      <div className="w-full flex flex-col">
        <label
          htmlFor="name"
          className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]"
        >
          Product Price
        </label>
        <input
          {...register("price", {
            required: "Price is required",
            pattern: {
              value: /^[0-9]*$/,
              message: "Input must be a number",
            },
          })}
          className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-19px px-[12px]"
          type="number"
          placeholder="Price"
          onChange={(e) =>
            setPriceInPoint(e.target.value / data?.point[0]?.ammountPerPoint)
          }
        />
        {errors?.price && <Error message={errors.price.message} />}
      </div>

      {/* productPrice In Point */}
      <div className="w-full flex flex-col">
        <label
          htmlFor="priceInPoint"
          className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]"
        >
          Product Price in point
        </label>
        <input
          {...register("priceInPoint", {
            required: "priceInPoint is required",
            pattern: {
              value: /^[0-9]*$/,
              message: "Input must be a number",
            },
          })}
          className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-19px px-[12px]"
          type="number"
          value={priceInPoint}
          placeholder="Price In point"
          onChange={(e) => setPriceInPoint(e.target.value)}
        />
        {errors?.priceInPoint && (
          <Error message={errors.priceInPoint.message} />
        )}
      </div>

      {/* product stock */}
      <div className="w-full flex flex-col">
        <label
          htmlFor="name"
          className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]"
        >
          Product in Stock
        </label>
        <input
          {...register("stock", {
            required: "Stock is required",
            pattern: {
              value: /^[0-9]*$/,
              message: "Input must be a number",
            },
          })}
          className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-19px px-[12px]"
          type="text"
          placeholder="Stock"
        />
        {errors?.stock && <Error message={errors.stock.message} />}
      </div>

      {/* shipping charge */}
      <div className="w-full flex flex-col">
        <label
          htmlFor="name"
          className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]"
        >
          Shipping Charge
        </label>
        <input
          {...register("shiping", {
            required: "Shipping charge is required",
            pattern: {
              value: /^[0-9]*$/,
              message: "Input must be a number",
            },
          })}
          className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-19px px-[12px]"
          type="text"
          placeholder="Charge"
          onChange={(e) =>
            setShippingChargeInPoint(
              e.target.value / data?.point[0]?.ammountPerPoint
            )
          }
        />
        {errors?.shiping && <Error message={errors.shiping.message} />}
      </div>

      {/* shipping charge in point */}
      <div className="w-full flex flex-col">
        <label
          htmlFor="name"
          className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]"
        >
          Shipping Charge in point
        </label>
        <input
          {...register("shippingChargeInPoint", {
            required: "Shipping charge is required",
            pattern: {
              value: /^[0-9]*$/,
              message: "Input must be a number",
            },
          })}
          className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-19px px-[12px]"
          type="text"
          placeholder="Shipping Charge In Point"
          value={shippingChargeInPoint}
          onChange={(e) => setShippingChargeInPoint(e.target.value)}
        />
        {errors?.shiping && <Error message={errors.shiping.message} />}
      </div>

      {/* discount prices */}
      <div className="w-full flex flex-col">
        <label
          htmlFor="name"
          className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]"
        >
          DISCOUNT IN PERCENT (%)
        </label>
        <input
          {...register("discount", {
            pattern: {
              value: /^[0-9]*$/,
              message: "Input must be a number",
            },
          })}
          className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-19px px-[12px]"
          type="text"
          placeholder="Type Discount number"
        />
        {errors?.discount && <Error message={errors.discount.message} />}
      </div>

      {/* products photo */}
      <div className="w-full flex flex-col">
        <label
          htmlFor="name"
          className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]"
        >
          Products Photo
        </label>
        <div
          {...getRootProps()}
          className="bg-white rounded-[3.6px] h-[174px] shadow-[2px_2px_10px_rgba(0,0,0,0.1)] cursor-pointer"
        >
          <input type="file" accept=".png" {...getInputProps()} />
          {isDragActive ? (
            <div className="h-full w-full flex justify-center items-center text-[13px] text-green-400">
              Drop the files here ...
            </div>
          ) : productPhotos?.length > 0 ? (
            <div className="w-full h-full flex flex-col items-center justify-center  text-[22px] text-green-400">
              <p>
                Total {productPhotos?.length} <br /> Photos
              </p>
            </div>
          ) : (
            <div className="w-full h-full flex flex-col items-center justify-center  text-[13px] text-[#707276]">
              <p>
                Drop files here to add <br /> attachments
              </p>
              <p className="mt-[18px]">
                or <span className="text-[#E41272]">Browse</span>
              </p>
            </div>
          )}
        </div>
      </div>

      {/* product description */}
      <div className="w-full flex flex-col">
        <label
          htmlFor="description"
          className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]"
        >
          Description
        </label>
        <div className="min-h-[250px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-19px px-[12px] pt-[10px]">
          <RichTextEditor {...{ setDescription }} />
        </div>
      </div>

      <input
        type="submit"
        value={addProductLoading ? "Loading..." : "Submit"}
        className="bg-[#E41272] text-white w-[129px] h-[47px] rounded-[3px] border-[1px] border-[#3D66D7] mt-[18px] cursor-pointer"
      />
      <Toaster />
    </form>
  );
};

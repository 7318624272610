// Configuration
import React from "react";

// Third party package
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import EditQuizContainer from "../EditQuizContainer/EditQuizContainer";
import { Toaster } from "react-hot-toast";

const EditQuizModule = ({ section }) => {
  const { Panel } = Collapse;
  return (
    <div className="px-6">
      {/* Show all module */}
      <div className="mb-4">
        <Collapse
          bordered={false}
          defaultActiveKey={["1"]}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          collapsible="icon"
        >
          {section?.modules?.map((module) => (
            <Panel
              header={
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-xl font-medium">{module?.name}</p>
                  </div>
                </div>
              }
              key={module?._id}
            >
              <EditQuizContainer {...{ module }} />
            </Panel>
          ))}
        </Collapse>
      </div>
      <Toaster />
    </div>
  );
};

export default EditQuizModule;

// Configuration
import React from "react";

// Third party packages
import Chart from "react-apexcharts";

// components
import { BsThreeDots } from "react-icons/bs";

const ManageResourceMemory = () => {
  return (
    <section className="bg-white mx-8 rounded-xl pb-[62px] mb-14">
      <div className="flex justify-between items-center px-12 pt-[50px] mb-14">
        <h1 className="text-[#333333] text-[32px] leading-[48px] font-bold">
          Memory
        </h1>{" "}
        <BsThreeDots className="text-[#98A4B5] text-xl" />
      </div>
      <div className="flex flex-col items-center gap-16">
        <Chart
          type="donut"
          width={425}
          height={425}
          series={[5, 1, 4]}
          options={{
            labels: ["Free", "System", "Used"],
            colors: ["#DDDDDD", "#FD6270", "#01A2FD"],
            dataLabels: {
              enabled: false,
            },
            legend: {
              show: false,
            },
          }}
        />
        <div className="flex items-center gap-[86px]">
          <div>
            <p className="text-[#98A4B5] text-2xl leading-8 font-normal mb-2">
              System
            </p>
            <p className="text-2xl leading-8 font-medium">1 GB</p>
          </div>
          <div>
            <p className="text-[#98A4B5] text-2xl leading-8 font-normal mb-2">
              Used
            </p>
            <p className="text-2xl leading-8 font-medium">4 GB</p>
          </div>
          <div>
            <p className="text-[#98A4B5] text-2xl leading-8 font-normal mb-2">
              Free
            </p>
            <p className="text-2xl leading-8 font-medium">5 GB</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ManageResourceMemory;

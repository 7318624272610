// normal import
import React, { useState } from "react";

// Third Party Package
import SearchManyUsers from "../../../../Shared/components/SearchUsers/SearchManyUsers";

// components
import { RestrictSuspendHeaderButton } from "./RestrictSuspendHeaderButton";
import { RestrictSuspendOptions } from "./RestrictSuspendOptions";
import { RestrictSuspendProfileContainer } from "./RestrictSuspendProfileContainer";

export const RestrictSuspendUserProfile = () => {
    // hook variable declaration
    const [searchQuery, setSearchQuery] = useState([]);
    const [userObj, setUser] = useState()
    const [page,setPage] = useState(1)
    const [suspend, setSuspend] = useState({suspend:true, restrict:false, suspendFor:7});

    // function declaration

    return (
        <div>
            <div>
            <SearchManyUsers role="member" setUser={setUser} page={page} />
            </div>
            <div className="mt-[39px]">
                <RestrictSuspendProfileContainer userObj={userObj} searchQuery={searchQuery} setSearchQuery={setSearchQuery} setPage={setPage} setUser={setUser} page={page} />
                <RestrictSuspendHeaderButton setSuspend={setSuspend} />
                <RestrictSuspendOptions suspend={suspend} searchQuery={searchQuery} />
            </div>
        </div>
    );
};

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    products: null,
};

const costCalculator = createSlice({
    name: "costCalculator",
    initialState,
    reducers: {
        setInitialCostCalculator: (state = initialState, action) => {
            state.data = action.payload;
        },
        updateProductQuantity: (state = initialState, action) => {
            const { price, id, quantity } = action.payload || {};

            return state.data.filter(item => {
                if (item._id === id) {
                    return {
                        _id: id,
                        name: item.name,
                        price: item.price,
                        subTotal: price * quantity,
                    };
                } else {
                    return item;
                }
            });
        },
    },
});

export const { setInitialCostCalculator, updateProductQuantity } = costCalculator.actions;
export default costCalculator.reducer;

import React from "react";

// Components
import CourseDetailsTab from "./CourseDetailsTab/CourseDetailsTab";
import LeftSideHeader from "./LeftSideHeader/LeftSideHeader";

const SingleCourseLeft = ({ course }) => {
  return (
    <div className="relative">
      <LeftSideHeader course={course} />
      <div>
        <CourseDetailsTab course={course} />
      </div>
    </div>
  );
};

export default SingleCourseLeft;

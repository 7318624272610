// Configuration
import React, { useEffect } from "react";

// Third party package
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";

// Components
import {
  useCreateSectionMutation,
  useUpdateSectionMutation,
} from "../../../../../../Redux/features/Course/courseApi";
import Error from "../../../../../Shared/components/accordion/UI/Error/Error";

const AddSectionForm = ({
  id,
  setSectionFormVisible,
  setSectionEditFormVisible,
  sectionForEdit,
  formType,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const [createSection, { data: sectionResponse, isLoading, error }] =
    useCreateSectionMutation();

  const [
    updateSection,
    {
      data: sectionEditResponse,
      isLoading: sectionEditLoading,
      error: sectionEditError,
    },
  ] = useUpdateSectionMutation();

  const onSubmit = async (data) => {
    if (formType === "editForm") {
      data.name = data.name || sectionForEdit?.name;
      data.description = data.description || sectionForEdit?.description;
      data.module = Number(data.module) || sectionForEdit?.module;
      await updateSection({ id: sectionForEdit?._id, data: data });
    } else {
      data.module = Number(data.module);
      data.course = id;
      await createSection(data);
    }
  };

  useEffect(() => {
    if (sectionResponse) {
      toast.success("Section Created Successfully");
      reset();
      setSectionFormVisible(false);
    }
    if (sectionEditResponse) {
      toast.success("Section Edited Successfully");
      reset();
      setSectionEditFormVisible(false);
    }
    if (error) {
      toast.error(error?.data?.message);
      reset();
      setSectionFormVisible(false);
    }
    if (sectionEditError) {
      toast.error("Something went wrong. Please login or try again later");
      reset();
      setSectionEditFormVisible(false);
    }
  }, [
    sectionResponse,
    error,
    reset,
    setSectionFormVisible,
    sectionEditError,
    sectionEditResponse,
    setSectionEditFormVisible,
  ]);

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col items-start w-full gap-y-5"
      >
        {/* Section Name */}
        <div className="w-full flex flex-col">
          <label className="text-left mb-1 text-[12px] font-bold text-[#707276] ml-[5px]">
            Section Name
          </label>
          <input
            {...register(
              "name",
              formType !== "editForm" && {
                required: "Section Name is required",
              }
            )}
            className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-4 px-[12px]"
            type="text"
            placeholder="Section Name"
          />
          {errors?.name && <Error message={errors?.name?.message} />}
        </div>
        {/* section description */}
        <div className="w-full flex flex-col">
          <label className="text-left mb-1 text-[12px] font-bold text-[#707276] ml-[5px]">
            Description
          </label>
          <textarea
            {...register(
              "description",
              formType !== "editForm" && {
                required: "Description is required",
              }
            )}
            className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-2 px-[12px]"
            type="text"
            placeholder="Description"
          />
          {errors?.description && (
            <Error message={errors?.description?.message} />
          )}
        </div>
        {/* Number of module */}
        <div className="w-full flex flex-col">
          <label className="text-left mb-1 text-[12px] font-bold text-[#707276] ml-[5px]">
            Number of Module
          </label>
          <input
            {...register(
              "module",
              formType !== "editForm"
                ? {
                    required: "Number of Module is required",
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Input must be a number",
                    },
                  }
                : {
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Input must be a number",
                    },
                  }
            )}
            className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-4 px-[12px]"
            type="number"
            placeholder="Number of Module"
          />
          {errors?.module && <Error message={errors?.module?.message} />}
        </div>

        <input
          type="submit"
          value={isLoading || sectionEditLoading ? "Loading..." : "Submit"}
          className="py-3 px-10 rounded-2xl bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] text-white font-medium text-lg cursor-pointer hover:bg-[linear-gradient(166deg,rgb(148,45,217)_0%,rgb(242,40,118)_100%)]"
        />
      </form>
    </div>
  );
};

export default AddSectionForm;

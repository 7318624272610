import { createSlice } from "@reduxjs/toolkit";

const initialState = { searchTerm: "" };

const userFilter = createSlice({
    name: "registrationRequest",
    initialState,
    reducers: {
        setSearchTerm: (state = initialState, action) => {
            state.searchTerm = action.payload;
        },
    },
});

export const { setSearchTerm } = userFilter.actions;
export default userFilter.reducer;

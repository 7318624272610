// Configuration
import React, { useState } from "react";

// Third party package
import { Collapse, Modal } from "antd";
import { Toaster } from "react-hot-toast";
import { CaretRightOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";

// Components
import { useGetSingleCourseQuery } from "../../../../../Redux/features/Course/courseApi";
import AddSectionForm from "./AddSectionForm/AddSectionForm";
import AddModuleContainer from "../AddModuleContainer/AddModuleContainer";
import { LineWaveLoader } from "../../../../Shared/Loader/LineWaveLoader";
import ErrorMessage from "../../../../Shared/components/ErrorMessage/ErrorMessage";
import useDocumentTitle from "../../../../../helper/utilities/useDocumentTitle";

const AddSectionPage = () => {
  const [sectionFormVisible, setSectionFormVisible] = useState(false);
  const { id } = useParams();
  const { data: singleCourse, isLoading, error } = useGetSingleCourseQuery(id);

  const handleSectionFormCancel = () => {
    setSectionFormVisible(false);
  };

  // Page Title
  useDocumentTitle("Admin | Add Course Content");

  const { Panel } = Collapse;

  let content;
  if (isLoading) {
    content = (
      <div className="min-h-[350px]">
        <LineWaveLoader />
      </div>
    );
  } else if (error) {
    content = <ErrorMessage />;
  } else if (!error && singleCourse) {
    content = (
      <>
        <div>
          <h1 className="text-[#32353B] text-2xl font-Inter font-semibold text-left">
            Add Section To Course - {singleCourse?.course?.name}
          </h1>
          <hr className="mt-2 mb-4" />
        </div>
        {/* View all section */}
        <div className="my-4">
          <Collapse
            bordered={false}
            defaultActiveKey={["1"]}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            {singleCourse?.course?.sections?.map((section) => (
              <Panel
                header={
                  <div>
                    <p className="text-xl font-medium">{section?.name}</p>
                    <p className="">{section?.description}</p>
                  </div>
                }
                key={section?._id}
              >
                <AddModuleContainer {...{ section }} />
              </Panel>
            ))}
          </Collapse>
        </div>

        {/* Add section button */}
        <div>
          <button
            onClick={() => setSectionFormVisible(true)}
            className="py-3 px-10 rounded-2xl bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] text-white font-medium text-lg"
          >
            Add Section
          </button>
        </div>
      </>
    );
  }

  return (
    <div className="text-left px-6 mb-20">
      {content}

      {/* Add section form modal */}
      <Modal
        title={<p className="text-xl">Add Section</p>}
        open={sectionFormVisible}
        onCancel={handleSectionFormCancel}
        footer={null}
      >
        <AddSectionForm {...{ id, setSectionFormVisible }} />
      </Modal>

      <Toaster />
    </div>
  );
};

export default AddSectionPage;

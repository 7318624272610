import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: [],
};

const shopPackage = createSlice({
  name: "shopPackage",
  initialState,
  reducers: {
    addOrRemoveProducts: (state, action) => {
      state?.products?.find((item) => item?._id === action?.payload?._id)
        ? (state.products = state?.products?.filter(
            (item) => item?._id !== action?.payload?._id
          ))
        : (state.products = [
            ...state?.products,
            { ...action.payload, quantity: 1 },
          ]);
    },
    incrementQuantity: (state, action) => {
      state?.products?.forEach((item) => {
        if (item._id === action.payload) {
          item.quantity += 1;
        }
      });
    },
    decrementQuantity: (state, action) => {
      state?.products?.forEach((item) => {
        if (item._id === action.payload) {
          item.quantity -= 1;
        }
      });
    },
    resetShopBundleStore: (state) => {
      state.products = [];
    },
  },
});

export const {
  addOrRemoveProducts,
  incrementQuantity,
  decrementQuantity,
  resetShopBundleStore,
} = shopPackage.actions;
export default shopPackage.reducer;

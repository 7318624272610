import { Pagination } from "@mui/material";
import FolderOffIcon from "@mui/icons-material/FolderOff";
import React, { useState, useEffect } from "react";
import { Toaster } from "react-hot-toast";
/* Certificate Images */
import useDocumentTitle from "../../../../helper/utilities/useDocumentTitle";
import { useGetUserCertificatesQuery } from "../../../../Redux/features/Certificates/certificatedApi";
/* components */
import SelectUserCard from "../../../Shared/Cards/SelectUserCard/SelectUserCard";
import SearchManyUsers from "../../../Shared/components/SearchUsers/SearchManyUsers";
import CertificateSelectButton from "./CertificateSelectButton";
import MarriageCertificates from "./MarriageCertificates";
import { OvalLoader } from "../../../Shared/Loader/OvalLoader";
import ErrorMessage from "../../../Shared/components/ErrorMessage/ErrorMessage";

const MarraigeCertificateVerification = () => {
  const [selected, setSelected] = useState();
  const [userObj, setUser] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const { data, isLoading, error } = useGetUserCertificatesQuery(
    selectedUser?._id || ""
  );

  const [page, setPage] = useState(1);
  // page title
  useDocumentTitle("Admin | Marriage Certificate Verification");

  useEffect(() => {
    if (data) {
      if (data?.data?.marriage?.length !== 0) {
        setSelected(data?.data?.marriage[0]);
      } else {
        setSelected();
      }
    }
  }, [data, selectedUser]);

  return (
    <div className="bg-[#FAFBFF]">
      <h1 className="pt-[74px] pb-[38px] text-center font-Nunito font-bold text-[40px] text-[#1D252D]">
        Marriage Certificate Verification
      </h1>

      {/* User Search */}
      <SearchManyUsers setUser={setUser} page={page} role="member" />
      {/* Select user card */}
      {userObj?.data && (
        <SelectUserCard
          users={userObj?.data?.users}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        ></SelectUserCard>
      )}

      {/* Pagination */}
      <div className="flex justify-around items-center my-12">
        <p className="leading-6 font-medium">
          Showing {userObj?.data?.users?.length} from {userObj?.data?.total}
          data
        </p>
        <Pagination
          className="bg-[#E3E4EB] px-[1px] py-[3px] rounded-md text-white"
          onChange={(event, value) => {
            setPage(value);
          }}
          count={Math.ceil(userObj?.data?.total / 8) || 1}
          shape="rounded"
        />
      </div>
      {selected && (
        <h1 className="pt-[74px] pb-[38px] text-center font-Nunito font-bold text-[40px] text-[#1D252D]">
          Marriage Certificates
        </h1>
      )}
      {selected && (
        <div className="grid grid-cols-6">
          {data?.data?.marriage?.map((item, index) => (
            <CertificateSelectButton
              {...{ item, index, selected, setSelected }}
            />
          ))}
        </div>
      )}

      {/* Certificate Show case */}
      {selected && !isLoading && <MarriageCertificates {...{ selected }} />}
      {!isLoading && data?.data?.marriage?.length === 0 && (
        <div className="my-20">
          <FolderOffIcon style={{ fontSize: "100px", color: "gray" }} />
          <p className="font-semibold text-2xl text-center">
            This user doesn't have any marriage certificate
          </p>
        </div>
      )}
      {isLoading && (
        <div className="h-52">
          <OvalLoader title="Please wait !" />
        </div>
      )}
      {error && selectedUser && (
        <div>
          <ErrorMessage />
        </div>
      )}

      <Toaster />
    </div>
  );
};

export default MarraigeCertificateVerification;

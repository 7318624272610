import React, { useState } from "react";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";

import ProductCard from "./ProductCard";

import ProductShowCase from "./ProductShowCase";

const SelectProductButton = () => {
  const [showDrop, setShowDrop] = useState(false);
  const products = useSelector((state) => state?.shopPackage?.products);
  const getTotalPrice = () => {
    let total = 0
    products?.forEach(item => {
      total = total+(item.price*item.quantity)
    })
    return total;
  }
  
  

  return (
    <div className="relative mb-4">
      <label className="text-left block mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]">
        Select Products
      </label>
      <p className="my-2 font-semibold">Total Price: BDT {getTotalPrice()}</p>
      <div
        id="myElement"
        onClick={() => setShowDrop(true)}
        className=" relative overflow-y-auto h-[300px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-2 px-[12px]"
      >
        {products?.map((item) => (
          <ProductCard {...{ item, key: item._id, useCase: "cancel" }} />
        ))}
        {!showDrop && (
          <div className="bg-gray-200 shadow-inner text-white rounded-lg overflow-hidden my-1 mx-auto h-32">
            <AddIcon style={{ fontSize: "120px" }} />
          </div>
        )}
      </div>
      {showDrop && <ProductShowCase {...{ setShowDrop }} />}
    </div>
  );
};

export default SelectProductButton;

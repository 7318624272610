import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useRef } from "react";

export const RichTextEditor = ({ setDescription }) => {
  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      return editorRef.current.getContent();
    }
  };

  useEffect(() => {
    setDescription(log());
  }, [setDescription]);

  return (
    <Editor
      onInit={(evt, editor) => (editorRef.current = editor)}
      initialValue="<p>Type your content...</p>"
      init={{
        apiKey: "iawwa9qy94heygjskdt3fnlatersbqdyb7stwf660xvo7g11",
        height: 500,
        menubar: false,
        plugins: [
          "autolink",
          "advlist",
          "lists",
          "link",
          "image",
          "charmap",
          "print",
          "preview",
          "anchor",
          "searchreplace",
          "visualblocks fullscreen",
          "insertdatetime",
          "media table paste help wordcount",
          "textcolor",
          "colorpicker",
          "fontselect",
          "fontsize",
        ],
        toolbar:
          "undo redo | formatselect | " +
          "bold italic underline " +
          " fontfamily fontsize fontselect fontsizeselect | " +
          "numlist bullist | " +
          "forecolor backcolor " +
          "removeformat |" +
          "outdent indent " +
          "alignleft aligncenter alignright alignjustify" +
          " help",
        lists_indent_on_tab: false,
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        extended_valid_elements: "font[face|size|color|style]",
      }}
      onChange={(e) => setDescription(e.target.getContent())}
    />
  );
};

// Configuration
import React from "react";
import { Toaster } from "react-hot-toast";

// Components
import useDocumentTitle from "../../../../helper/utilities/useDocumentTitle";
import AddCourseForm from "./AddCourseForm/AddCourseForm";

const AddCourse = () => {
  // Page Title
  useDocumentTitle("Admin | Add Course");
  return (
    <div>
      <div className="px-5 mx-auto">
        <div className="w-full h-auto shadow-[2px_2px_10px_2px_rgba(0,0,0,0.12)] bg-white rounded-[12px] px-[22px] py-[29px] mb-8">
          <h1 className="text-[#32353B] text-[28px] font-poppins font-semibold text-left">
            Add New Course
          </h1>
          <div className="w-full h-[1px] bg-[#E9ECF2] mt-[22px] mb-[30px]"></div>
          <AddCourseForm />
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default AddCourse;

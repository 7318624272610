import getCookie from "../../../helper/cookies/getCookie";
import { apiSlice } from "../../api/apiSlice";

export const packagesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCoursePackages: builder.query({
      query: () => ({
        url: "/package/course/admin/get-all-packages",
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
    }),
    getShopPackages: builder.query({
      query: () => ({
        url: "/package/shop/admin/get-all-packages",
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
    }),
    getProfessionalPackages: builder.query({
      query: (role) => ({
        url: `/package/professional/get-all?role=${role}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
    }),
    updateCoursePackage: builder.mutation({
      query: ({ id, data }) => ({
        url: `/package/course/update-package/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
    }),
    updateShopPackage: builder.mutation({
      query: ({ id, data }) => ({
        url: `/package/shop/update-package/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
    }),
    updateProfessionalPackage: builder.mutation({
      query: ({ packageId, data }) => ({
        url: `/package/professional/update/${packageId}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
    }),

    // shop bundle
    getAllShopBundles: builder.query({
      query: () => ({
        url: "/bundle/shop/get-all",
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
    }),
    createShopBundle: builder.mutation({
      query: (data) => ({
        url: `/bundle/shop/create`,
        method: "POST",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
    }),
    updateShopBundle: builder.mutation({
      query: ({data, id}) => ({
        url: `/bundle/shop/update/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
    }),

    // course bundle
    getAllCourseBundles: builder.query({
      query: () => ({
        url: "/bundle/course/get-all",
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
    }),
    createCourseBundle: builder.mutation({
      query: (data) => ({
        url: `/bundle/course/create`,
        method: "POST",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
    }),
    updateCourseBundle: builder.mutation({
      query: ({id,data}) => ({
        url: `/bundle/course/update/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
    }),
  }),
});

export const {
  useGetCoursePackagesQuery,
  useUpdateCoursePackageMutation,
  useGetShopPackagesQuery,
  useUpdateShopPackageMutation,
  useGetProfessionalPackagesQuery,
  useUpdateProfessionalPackageMutation,
  useCreateShopBundleMutation,
  useGetAllShopBundlesQuery,
  useGetAllCourseBundlesQuery,
  useCreateCourseBundleMutation,
  useUpdateCourseBundleMutation
} = packagesApi;

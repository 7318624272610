// configuration
import React from "react";

// components
import { DeliveredOrderBillingContainer } from "./BillingDetailes/DeliveredOrderBillingContainer";
import { DeliveredBillingSummary } from "./BillingSummary/DeliveredBillingSummary";
import { DeliveredDetailesOrderContainer } from "./OrderInfoContainer/DeliveredDetailesOrderContainer";

export const DeleveredOrderMark = () => {
    return (
        <div className="mt-[70px] px-[45px]">
            <div className="w-full flex justify-between">
                <p className="text-[#272727] text-[32px] font-semibold font-Inter text-left">Delivered Order</p>
            </div>
            <DeliveredDetailesOrderContainer />
            <DeliveredOrderBillingContainer />
            <DeliveredBillingSummary />
        </div>
    );
};

import React from 'react';

const ModifyMemberShipPackage = () => {
    return (
        <div>
            Modify MemberShip Pacakge
        </div>
    );
}

export default ModifyMemberShipPackage;

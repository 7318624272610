import getCookie from "../../../helper/cookies/getCookie";
import { apiSlice } from "../../api/apiSlice";

export const walletApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllWallet: builder.query({
      query: ({ page, limit }) => ({
        url: `/wallet/get-all-wallet?_page=${page}&_limit=${limit}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
    }),
    getSingleWallet: builder.query({
      query: (id) => ({
        url: `/wallet/get-wallet-by-id/${id}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
    }),

    getWithdrawRequests: builder.query({
      query: ({ page, limit }) => ({
        url: `/refer/admin/withdraw-requests?_page=${page}&_limit=${limit}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      providesTags: ["wallet"],
    }),

    getAcceptedWithdrawRequests: builder.query({
      query: ({ page, limit }) => ({
        url: `/refer/admin/accepted-withdraw-requests?_page=${page}&_limit=${limit}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      providesTags: ["wallet"],
    }),

    acceptWithdrawRequest: builder.mutation({
      query: (id) => ({
        url: `/refer/withdraw-request/accept/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      invalidatesTags: ["wallet"],
    }),
  }),
});

export const {
  useGetAllWalletQuery,
  useGetSingleWalletQuery,
  useGetWithdrawRequestsQuery,
  useAcceptWithdrawRequestMutation,
  useGetAcceptedWithdrawRequestsQuery,
} = walletApi;

// Configuration
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Third party packages
import { useForm } from "react-hook-form";
import { toast, Toaster } from "react-hot-toast";

// Components
import { useRegisterUserMutation } from "../../../../Redux/features/User/userApi";
import useDocumentTitle from "../../../../helper/utilities/useDocumentTitle";
import AddForm from "../../../Shared/components/AddForm/AddForm";

const AddUser = () => {
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [hometown, setHometown] = useState("");

  const navigate = useNavigate();
  const url = new URL(window.location.href);
  const pathname = url.pathname;
  const parts = pathname.split("/");
  const lastPart = parts[parts.length - 1]; // last part of the path
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // api
  const [registerUser, { data: response, error, isLoading }] =
    useRegisterUserMutation();

  // form submit
  const onSubmit = async (data) => {
    if (!dateOfBirth) {
      toast.error("Date of Birth is required");
      return;
    } else {
      data.dateOfBirth = dateOfBirth;
    }
    if (!hometown) {
      toast.error("Hometown is required");
      return;
    } else {
      data.hometown = hometown;
    }

    data = { ...data, role: "member", profile: "mattrimonyAccess" };
    await registerUser(data);
  };

  useEffect(() => {
    if (lastPart === "user") {
      navigate("/user/addUser");
    }
  }, [lastPart, navigate]);

  useEffect(() => {
    if (response) {
      toast.success(response?.message);
      setDateOfBirth("");
      setHometown("");
      reset();
    }
    if (error) {
      toast.error(error?.data?.message);
    }
  }, [response, error, reset]);

  // page title
  useDocumentTitle("Admin | Add User");

  return (
    <div className="font-Inter text-sm">
      {/* Add User Form */}
      <div className="w-[523px] mx-auto mt-10 px-[21px] py-[31px] rounded-[10px] shadow text-left bg-[#FFFFFF]">
        <AddForm
          {...{
            register,
            handleSubmit,
            onSubmit,
            errors,
            isLoading,
            setDateOfBirth,
            setHometown,
            dateOfBirth,
            role: "member",
          }}
        />
      </div>
      <Toaster />
    </div>
  );
};

export default AddUser;

import { AddNewProduct } from "../../Admin__Portion/Components/Shop/AddProductsForm/AddNewProduct";
import { CostCalculator } from "../../Admin__Portion/Components/Shop/CostCalculator/CostCalculator";
import { EditProduct } from "../../Admin__Portion/Components/Shop/EditProduct/EditProduct";
import { DeleveredOrderMark } from "../../Admin__Portion/Components/Shop/OrderTrackAndManage/DeliveredOrder/DeleveredOrderMark";
import { OnGoingOrderMark } from "../../Admin__Portion/Components/Shop/OrderTrackAndManage/OnGoingOrder/OnGoingOrderMark";
import { ProductListingAndTrendyProducts } from "../../Admin__Portion/Components/Shop/ProductListing&TrendyProducts/ProductListingAndTrendyProducts";
import { Shop } from "../../Admin__Portion/Components/Shop/Shop";
import ShopCategories from "../../Admin__Portion/Components/Shop/ShopCategories/ShopCategories";
import ShopOrders from "../../Admin__Portion/Components/Shop/ShopOrders/ShopOrders";
import SingleProductDetails from "../../Admin__Portion/Components/Shop/SingleProductDetails/SingleProductDetails";

const shopRoutes = [
  {
    id: 1,
    path: "/shop",
    element: <Shop />,
    nestedRoutes: [
      {
        id: 1,
        path: "listing&TrendyProducts",
        element: <ProductListingAndTrendyProducts />,
      },
      {
        id: 2,
        path: "addANewProduct",
        element: <AddNewProduct />,
      },
      {
        id: 3,
        path: "productEdit/:id",
        element: <EditProduct />,
      },
      {
        id: 4,
        path: "categories",
        element: <ShopCategories />,
      },
      {
        id: 5,
        path: "orders",
        element: <ShopOrders />,
      },
      {
        id: 6,
        path: "orderId/:id",
        element: <OnGoingOrderMark />,
      },
      {
        id: 7,
        path: "deliveredOrderId/:id",
        element: <DeleveredOrderMark />,
      },
      {
        id: 8,
        path: "costCalculator",
        element: <CostCalculator />,
      },
      {
        id: 9,
        path: "product/:id",
        element: <SingleProductDetails />,
      },
    ],
  },
];

export default shopRoutes;

import React from "react";

export const ConversationCard = ({ conversation,handleClick }) => {
    

    return (
        <div onClick={()=>handleClick(conversation?._id)}
            className={`w-full h-[134px] py-[20px] flex items-start px-[30px] ${
                conversation.id === 2 && "bg-gradient-to-r from-[#E41272] to-[#A72CC7]"
            }`}
        >
            <div>
                <img className="max-w-[58px] max-h-[58px] rounded-full" src={conversation.profilePhoto} alt="profile" />
            </div>
            <div className="flex flex-col w-full ml-[34px]">
                <div className="flex justify-between items-start w-full">
                    <p className="text-left text-[#000000] font-poppins font-medium text-[16px]">{conversation.firstName}</p>
                    <div className="text-[#898989] text-[14px] font-normal font-poppins tracking-[-0.24px]">2m ago</div>
                </div>
                <p className="mt-[10px] text-[14px] font-normal font-poppins text-[#000000] text-left">{conversation.message}</p>
            </div>
        </div>
    );
};

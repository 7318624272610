// Configuration
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

/* Third party package */
import { AiOutlineWarning } from "react-icons/ai";
import { MdOutlineRecommend } from "react-icons/md";

// Components
import { OvalLoader } from "../../../../Shared/Loader/OvalLoader";
import { useGetAllCasesQuery } from "../../../../../Redux/features/Services/servicesApi";
import SingleCaseCard from "../../../../Shared/Cards/Lawyer/ModifyCaseStudyCard/SingleCaseCard";

const SingleUserCases = ({ role }) => {
  const { id } = useParams();

  /* Used to navigate to case study page */
  const navigate = useNavigate();

  //   Redux api
  const { data, isLoading, error } = useGetAllCasesQuery({
    id: id,
    page: 1,
    limit: 4,
  });

  let content;
  if (isLoading) {
    content = (
      <div className="w-full flex gap-y-[20px]">
        <OvalLoader />
      </div>
    );
  } else if (error) {
    content = (
      <div className="flex flex-col items-center justify-center mt-[100px]">
        <AiOutlineWarning className="text-[42px] text-gray-400" />
        <p className="mt-[10px] text-[16px] font-Inter font-medium text-gray-500">
          Something Went Wrong! <br /> Please login or try again later!
        </p>
      </div>
    );
  } else if (!error && data?.serviceStudies?.length === 0) {
    content = (
      <div className="flex flex-col items-center justify-center mt-[100px]">
        <MdOutlineRecommend className="text-[42px] text-gray-400" />
        <p className="mt-[10px] text-[16px] font-Inter font-medium text-gray-500">
          No Case Found!
        </p>
      </div>
    );
  } else if (!error && data?.serviceStudies?.length > 0) {
    content = (
      <section className="grid grid-cols-1 gap-y-[30px] mt-16">
        {data?.serviceStudies?.map((singleCase) => (
          <SingleCaseCard key={singleCase._id} {...{ singleCase, role }} />
        ))}
      </section>
    );
  }

  return (
    <div>
      {/* Back to ModifyCaseStudy */}
      <div className="flex justify-start font-poppins font-semibold text-xl text-[#000000] mt-[117px] mb-[37px] ml-[95px]">
        <button
          onClick={() => navigate(-1)}
          style={{ boxShadow: "2px 2px 10px 2px rgba(0, 0, 0, 0.12)" }}
          className="py-3 px-[45px] rounded-2xl bg-[#FFFFFF]"
        >
          Back To List
        </button>
      </div>
      {/* All cases */}
      <div className="ml-[95px]">{content}</div>
    </div>
  );
};

export default SingleUserCases;

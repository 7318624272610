// Configuration
import React from "react";

// Third party packages
import Pagination from "@mui/material/Pagination";

// components
import { useNavigate, useParams } from "react-router-dom";
import { useGetAllReviewsQuery, useGetSingleServiceByIdQuery } from "../../../../../Redux/features/Services/servicesApi";
import ServiceReviewsCard from "../../../Cards/Lawyer/Service__Reviews_Card/ServiceReviewsCard";
import { TBFaceError } from "../../../components/UI/TBFaceError";
import { LineWaveLoader } from "../../../Loader/LineWaveLoader";
import ServiceOwnerInfo from "./LawyerInfoExtra";
import ServiceInfoExtra from "./ServiceInfoExtra";

const SingleServicePage = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { data, isLoading: serviceLoading, isError } = useGetSingleServiceByIdQuery(id);
    const {
        data: reviewsData,
        isLoading,
        isError: reviewsError,
    } = useGetAllReviewsQuery({
        id: id,
        page: 1,
        limit: 5,
    });
    const { service } = data || {};
    const { reviews } = reviewsData || {};

    let reviewsContent;
    if (isLoading) {
        reviewsContent = (
            <div className="h-[400px] w-full flex justify-center items-center">
                <LineWaveLoader />
            </div>
        );
    } else if (!isLoading && reviewsError) {
        reviewsContent = (
            <div className="h-[400px] w-full flex justify-center items-center text-[26px] text-gray-400 font-Inter">
                Something went wrong! Please try again later
            </div>
        );
    } else if (!isLoading && reviews?.length === 0) {
        reviewsContent = (
            <div className="h-[400px] w-full flex justify-center items-center text-[26px] text-gray-400 font-Inter">No Reviews Found!</div>
        );
    } else if (!isLoading && reviews?.length > 0) {
        reviewsContent = reviews?.map(review => {
            return <ServiceReviewsCard key={review?._id} {...{ review }} />;
        });
    }

    let serviceContent;
    if (serviceLoading) {
        serviceContent = <LineWaveLoader />;
    } else if (!serviceLoading && service) {
        serviceContent = (
            <div className="text-left px-8">
                <div className="flex justify-between mb-14">
                    <button
                        className="text-xl leading-[30px] font-semibold text-center w-[209px] py-3 rounded-2xl"
                        style={{ boxShadow: "3px 3px 10px 3px rgba(0, 0, 0, 0.12)" }}
                        onClick={() => navigate(-1)}
                    >
                        Back To List
                    </button>
                </div>
                {/* ----- Lawyer Info ----- */}
                <ServiceOwnerInfo {...{ service }} />
                <hr className="border-1 border-black mt-8 mb-[54px]" />
                {/* ----- Service Info ----- */}
                <ServiceInfoExtra {...{ service }} />
                {/* ----- Reviews ----- */}
                <div>
                    <h2 className="text-[#707276] text-[27px] leading-[48px] font-semibold mb-16">Reviews ({reviews?.length})</h2>
                    {reviewsContent}
                    <div className="flex justify-between items-center my-12">
                        <p className="leading-6 font-medium">Showing 8 from 160 data</p>
                        <Pagination className="bg-[#E3E4EB] px-[1px] py-[3px] rounded-md text-white" count={20} shape="rounded" />
                    </div>
                </div>
            </div>
        );
    } else if (!serviceLoading && isError) {
        serviceContent = <TBFaceError />;
    }

    return serviceContent;
};

export default SingleServicePage;

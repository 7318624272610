import React from "react";
import { Outlet } from "react-router-dom";

const ViewEditProfileContainer = () => {
  return (
    <div>
      <h1 className="text-[40px] leading-[55px] font-bold text-[#1D252D] text-center mb-12">
        View/Edit User Profile
      </h1>
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default ViewEditProfileContainer;

// Configuration
import React, { useState, useEffect } from "react";

// React Icon
import { HiOutlineBars3BottomRight } from "react-icons/hi2";

// Third party packages
import { TbLogin } from "react-icons/tb";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { decodeToken } from "react-jwt";

// components
import { BiUserVoice } from "react-icons/bi";
import { FaRegUser } from "react-icons/fa";
import { FiBook, FiLayers, FiUsers } from "react-icons/fi";
import { HiOutlineBadgeCheck, HiOutlineShoppingBag } from "react-icons/hi";
import { IoColorPaletteSharp } from "react-icons/io5";
import { MdMarkChatRead, MdSupport } from "react-icons/md";
import { RiDashboardLine, RiTable2, RiUserHeartLine } from "react-icons/ri";
import AttractionsIcon from "@mui/icons-material/Attractions";
import removeCookie from "../../helper/cookies/removeCookie";
import { isLoggedIn } from "../../helper/hooks/loginPersestency/isLoggedIn";
import useDocumentTitle from "../../helper/utilities/useDocumentTitle";
import getCookie from "../../helper/cookies/getCookie";

export const Dashboard = () => {
  const navigate = useNavigate();
  const [drawer, setDrawer] = useState(true);

  // page title
  useDocumentTitle("Admin | Dashboard");

  const logged = isLoggedIn();
  const url = new URL(window.location.href);
  const pathname = url.pathname;
  const parts = pathname.split("/");
  const lastPart = parts[parts.length - 1];

  useEffect(() => {
    if (lastPart === "lawyer") {
      navigate("/lawyer/addLawyer");
    }
  }, [lastPart, navigate]);

  //js variables
  const token = getCookie("adminToken");
  const myDecodeToken = decodeToken(token);
  const { role } = myDecodeToken;

  let dashboardLinks = [];
  if (role === "vendor") {
    dashboardLinks = [
      {
        id: 1,
        linkTo: "/shop",
        linkText: "Shop",
        linkIcon: <HiOutlineShoppingBag className="ml-9 text-[18px]" />,
      },
      {
        id: 2,
        linkTo: `${logged ? "/" : "/login"}`,
        linkText: `${logged ? "Logout" : "Login"}`,
        linkIcon: <TbLogin className="ml-9 text-[18px] rotate-180" />,
      },
    ];
  } else {
    dashboardLinks = [
      {
        id: 1,
        linkTo: "/",
        linkText: "Dashboard",
        linkIcon: <RiDashboardLine className="ml-9 text-[18px]" />,
      },
      {
        id: 2,
        linkTo: "/user",
        linkText: "User",
        linkIcon: <FaRegUser className="ml-9 text-[18px]" />,
      },
      {
        id: 3,
        linkTo: "/lawyer",
        linkText: "Lawyer",
        linkIcon: <FiLayers className="ml-9 text-[18px]" />,
      },
      {
        id: 4,
        linkTo: "/agent",
        linkText: "Agent",
        linkIcon: <BiUserVoice className="ml-9 text-[18px]" />,
      },
      {
        id: 5,
        linkTo: "/kazi",
        linkText: "Kazi",
        linkIcon: <RiUserHeartLine className="ml-9 text-[18px]" />,
      },
      {
        id: 6,
        linkTo: "/chatMessage",
        linkText: "Chat/Message",
        linkIcon: <MdMarkChatRead className="ml-9 text-[18px]" />,
      },
      {
        id: 7,
        linkTo: "/courses",
        linkText: "Courses",
        linkIcon: <FiBook className="ml-9 text-[18px] rotate-180" />,
      },
      {
        id: 8,
        linkTo: "/packages",
        linkText: "Packages",
        linkIcon: <RiTable2 className="ml-9 text-[18px]" />,
      },
      {
        id: 9,
        linkTo: "/badges",
        linkText: "Badges",
        linkIcon: <HiOutlineBadgeCheck className="ml-9 text-[18px]" />,
      },
      {
        id: 10,
        linkTo: "/support",
        linkText: "Support",
        linkIcon: <MdSupport className="ml-9 text-[18px]" />,
      },
      {
        id: 11,
        linkTo: "/employee",
        linkText: "Employee",
        linkIcon: <FiUsers className="ml-9 text-[18px]" />,
      },
      {
        id: 12,
        linkTo: "/sponsor",
        linkText: "Sponsor",
        linkIcon: (
          <IoColorPaletteSharp className="ml-9 text-[18px] rotate-180" />
        ),
      },
      {
        id: 13,
        linkTo: "/currency",
        linkText: "Currency",
        linkIcon: <AttractionsIcon className="ml-9 text-[18px]" />,
      },
      {
        id: 14,
        linkTo: `${logged ? "/" : "/login"}`,
        linkText: `${logged ? "Logout" : "Login"}`,
        linkIcon: <TbLogin className="ml-9 text-[18px] rotate-180" />,
      },
    ];
  }

  const logoutHandler = (button) => {
    if (button === "Logout") {
      removeCookie("adminToken");
      window.location.reload();
    }
  };

  return (
    <div className="bg-[#FAFBFF] max-w-[1400px] mx-auto">
      <div className="flex w-full transition-all duration-500 ease-in-out relative">
        <div className="flex h-screen sticky top-0">
          <div
            style={{ boxShadow: "2px 2px 10px 2px rgba(0, 0, 0, 0.12)" }}
            className={`relative min-h-screen  overflow-y-auto bg-[#FFFFFF] transition-all duration-500 ease-in-out ${
              drawer ? "md:w-28 lg:w-[350px]" : "w-[350px]"
            }`}
            id="myElement"
          >
            <div
              onClick={() => setDrawer(!drawer)}
              className="lg:hidden md:block absolute top-0 right-3 text-[#757575] text-2xl"
            >
              <HiOutlineBars3BottomRight />
            </div>
            <div className="lg:w-[203px] lg:px-0 w-full px-3 mx-auto pt-3 pb-3">
              {dashboardLinks.map((link) => (
                <NavLink
                  key={link.id}
                  to={link.linkTo}
                  className={`flex items-center gap-2 justify-start text-[#757575] py-[11px] rounded-lg mb-[8px] ${
                    link.linkText === "Logout" && "bg-white"
                  }`}
                  style={({ isActive }) =>
                    isActive
                      ? {
                          backgroundImage: `${
                            link.linkText !== "Logout" &&
                            "linear-gradient(83.26deg, #E41272 17.33%, #C94964 98.93%)"
                          }`,
                          color: `${
                            link.linkText === "Logout" ? "#757575" : "#FFFFFF"
                          }`,
                          marginBottom: "22px",
                          boxShadow: `${
                            link.linkText !== "Logout"
                              ? "0px 12px 28px rgba(19, 98, 252, 0.37)"
                              : "2px 2px 10px 2px rgba(0, 0, 0, 0.12)"
                          }`,
                        }
                      : {
                          boxShadow: "2px 2px 10px 2px rgba(0, 0, 0, 0.12)",
                        }
                  }
                  onClick={() => logoutHandler(link.linkText)}
                >
                  <div
                    className={`transition-all duration-500 ease-in-out ${
                      drawer ? "-ml-1" : "ml-0"
                    }`}
                  >
                    {link.linkIcon}
                  </div>
                  <p
                    className={`transition-all lg:hidden duration-500 ease-in-out text-lg font-semibold leading-7 ${
                      drawer
                        ? "-translate-x-20 opacity-0"
                        : "-translate-x-0 opacity-1"
                    }`}
                  >
                    {link.linkText}
                  </p>
                  <p
                    className={`transition-all lg:block hidden duration-500 ease-in-out text-base font-semibold leading-7`}
                  >
                    {link.linkText}
                  </p>
                </NavLink>
              ))}
            </div>
          </div>
        </div>
        <div className="w-full text-center h-auto flex-1">
          <div className="w-full overflow-x-hidden">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

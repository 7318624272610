// configuration, ex: react-router
import React from "react";

export const RecentRejectedUserCard = ({ user }) => {
    // JS variables
    const { firstName, lastName, profilePhoto } = user || {};
    let name;
    name = firstName + " " + lastName;
    if (name.length > 13) {
        name = name.slice(0, 13) + " ...";
    }
    return (
        <div>
            <div className=" bg-[#BB243F] rounded-[8px] px-[35px] py-[31px]">
                <div className="flex gap-x-[24px]">
                    <img className="w-[85px] h-[85px] rounded-full" src={profilePhoto} alt="profile" />
                    <div className="flex flex-col gap-y-[18px]">
                        <h1 className="text-[#FFFFFF] text-[24px] leading-[29px] font-medium">{name}</h1>
                        <p className="text-[#FFFFFF] text-[20px] leading-[24px] font-medium">Age: 26</p>
                        <button className="w-[135px] h-[37px] mt-[20px] flex justify-center items-center bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] text-white rounded-[4px]">
                            View Request
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Configuration
import React from "react";
import { useParams } from "react-router-dom";
import { useGetTicketByIDQuery } from "../../../../../Redux/features/SupportTicket/supportTicketApi";
import ErrorMessage from "../../../../Shared/components/ErrorMessage/ErrorMessage";
import { LineWaveLoader } from "../../../../Shared/Loader/LineWaveLoader";
import SingleSupportTicketDetailsHeader from "./SingleSupportTicketDetailsHeader/SingleSupportTicketDetailsHeader";
import SingleSupportTicketDetailsBody from "./SingleSupportTicketDetailsBody/SingleSupportTicketDetailsBody";
import SingleSupportTicketDetailsFooter from "./SingleSupportTicketDetailsFooter/SingleSupportTicketDetailsFooter";

const SingleSupportTicketDetails = () => {
  const { id } = useParams();
  const { data, isLoading, isError } = useGetTicketByIDQuery(id);
  const { ticket } = data || {};

  let content;
  if (isLoading) {
    content = (
      <div className="h-[250px] w-full flex justify-center items-center">
        <LineWaveLoader />
      </div>
    );
  } else if (!isLoading && isError) {
    content = (
      <div className="h-[250px] w-full flex justify-center items-center">
        <ErrorMessage />
      </div>
    );
  } else if (!isLoading && ticket) {
    content = (
      <div className="px-6 pb-16">
        <SingleSupportTicketDetailsHeader ticket={ticket} />
        <SingleSupportTicketDetailsBody ticket={ticket} />
        <SingleSupportTicketDetailsFooter />
      </div>
    );
  }
  return content;
};

export default SingleSupportTicketDetails;

export const lawyerCategory = [
    {
        value: "Criminal Law",
        label: "Criminal Law",
    },
    {
        value: "Family Law",
        label: "Family Law",
    },
    {
        value: "Personal Injury Law",
        label: "Personal Injury Law",
    },
    {
        value: "Commercial Law",
        label: "Commercial Law",
    },
];

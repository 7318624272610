import React, { useState } from 'react';
/* React zoom icons for header img */
import { RiZoomInFill, RiZoomOutFill } from "react-icons/ri";

const HeaderImg = ({ selectCertificate }) => {

    const [zoom, setZoom] = useState(false);

    return (
        <div className='pb-1'>
            <div className='relative overflow-hidden'>
                <img className={`rounded-xl h-[463px] w-full transition-all duration-500 ease-in-out ${zoom ? 'scale-150' : 'scale-100'}`} src={selectCertificate?.photo} alt="certificate" />
                <div className='flex gap-[35px] absolute right-9 bottom-9'>
                    <RiZoomInFill onClick={() => setZoom(true)} className='text-[30] text-[#E41272]' />
                    <RiZoomOutFill onClick={() => setZoom(false)} className='text-[30] text-[#E41272]' />
                </div>
            </div>
            <p className='pt-[22px] text-center text-2xl text-[#000000]'>Certificate 1</p>
        </div>
    );
};

export default HeaderImg;
import React from "react";
import { NavLink } from "react-router-dom";

export const AgentNav = ({ children }) => {
    const routes = [
        {
            id: 1,
            path: "addAgent",
            name: "Add Agent",
        },
        {
            id: 2,
            path: "registrationRequest",
            name: "View Registration Request",
        },
        {
            id: 3,
            path: "allAgents",
            name: "View All Agent",
        },
        {
            id: 4,
            path: "assignAgent",
            name: "Assign Agent",
        },
        {
            id: 5,
            path: "modifyDealStatus",
            name: "Modify Deal Status",
        },
        {
            id: 6,
            path: "reviewRatingsForAgent",
            name: "Modify Review & Ratings",
        },
        {
            id: 7,
            path: "agentsProfiles",
            name: "Modify Agent Profile",
        },
        {
            id: 8,
            path: "dealHistory",
            name: "Modify Deal History",
        },
        {
            id: 9,
            path: "agentServices",
            name: "View Agent Services",
        },
        {
            id: 10,
            path: "clientChat",
            name: "View Agent-Client Chat",
        },
        {
            id: 11,
            path: "documentsVerification",
            name: "Documents Verification",
        },
        {
            id: 12,
            path: "payment",
            name: "Payment",
        },
    ];
    return (
        <div className="px-5 mx-auto min-h-screen">
            <div className="pt-10 mb-10">
                <ul className="grid grid-cols-3 gap-[41px] font-poppins font-normal text-base text-[#000000]">
                    {routes.map(route => (
                        <NavLink
                            key={route.id}
                            className={({ isActive }) =>
                                `w-full  text-center py-[5px] rounded cursor-pointer text-[15px] font-semibold ${
                                    isActive ? " text-white bg-[#E41272]" : "text-[#000000] bg-[#FFFFFF]"
                                }`
                            }
                            to={route.path}
                        >
                            {route.name}
                        </NavLink>
                    ))}
                </ul>
            </div>
            <div>{children}</div>
        </div>
    );
};

// Configuration
import React, { useEffect, useState } from "react";

// Third party packages
import { Button, Modal } from "antd";
import { BiEditAlt } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-hot-toast";

// Components
import { useUpdateCategoryMutation } from "../../../../../Redux/features/Shop/shopApi";
import { useUpdateCourseTypeMutation } from "../../../../../Redux/features/Course/courseApi";

const EditCategoriesModal = ({
  editCategoriesOpen,
  handleEditCategoriesCancel,
  setEditCategoriesOpen,
  selectedCategory,
  title: dynamicTitle,
}) => {
  const [editCategoryName, setEditCategoryName] = useState();

  const [updateCategory, { data: response, isLoading, error }] =
    useUpdateCategoryMutation();

  const [
    updateCourseType,
    { data: typeResponse, isLoading: typeLoading, error: typeError },
  ] = useUpdateCourseTypeMutation();

  const title = (
    <div className="flex items-center gap-1">
      <BiEditAlt className="text-2xl" />
      <p className="text-3xl leading-6 font-Inter font-normal">
        Edit {dynamicTitle === "courseTypes" && "Type"}{" "}
        {dynamicTitle === "courseCategory" && "Category"}
        {dynamicTitle === "shopCategory" && "Category"}
      </p>
    </div>
  );

  const handleEditCategoriesOk = async () => {
    if (dynamicTitle === "courseTypes") {
      await updateCourseType({
        id: selectedCategory.id,
        data: { name: editCategoryName || selectedCategory?.name },
      });
    } else {
      await updateCategory({
        id: selectedCategory.id,
        data: { name: editCategoryName || selectedCategory?.name },
      });
    }
  };

  useEffect(() => {
    if (response) {
      toast.success("Category name edited successfully");
      setEditCategoryName("");
      setEditCategoriesOpen(false);
    }
    if (typeResponse) {
      toast.success("Type name edited successfully");
      setEditCategoryName("");
      setEditCategoriesOpen(false);
    }
    if (error || typeError) {
      toast.error(
        <p>
          Something went wrong <br /> Please try again later
        </p>
      );
      setEditCategoriesOpen("");
      setEditCategoriesOpen(false);
    }
  }, [response, error, setEditCategoriesOpen, typeError, typeResponse]);

  return (
    <Modal
      open={editCategoriesOpen}
      closeIcon={<RxCross2 className="text-[#202020] mx-auto" />}
      title={title}
      onOk={handleEditCategoriesOk}
      onCancel={handleEditCategoriesCancel}
      footer={[
        <Button
          key="back"
          onClick={handleEditCategoriesCancel}
          className="text-[#E41272] h-12 px-6 text-xl font-bold rounded-[40px]"
        >
          Dismiss
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isLoading || typeLoading}
          onClick={handleEditCategoriesOk}
          className="bg-[#E41272] h-12 px-6 text-xl text-white font-bold rounded-[40px]"
        >
          Confirm
        </Button>,
      ]}
    >
      <p className="text-2xl leading-6 font-Inter my-8">
        Rename the {dynamicTitle === "courseTypes" && "type"}{" "}
        {dynamicTitle === "courseCategory" && "category"}
        {dynamicTitle === "shopCategory" && "category"}
      </p>
      <textarea
        name="editedCategoryName"
        value={editCategoryName}
        id="editedCategoryName"
        placeholder={selectedCategory?.name}
        rows="4"
        className="border border-[#EEF6FF] rounded w-full px-4 py-3 text-xl leading-5 font-Inter mb-6 focus:outline-none"
        style={{ boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.12)" }}
        onChange={(e) => setEditCategoryName(e.target.value)}
      />
    </Modal>
  );
};

export default EditCategoriesModal;

// configuration
import React from "react";
import { useNavigate } from "react-router-dom";

// Third party package
import { IoClose } from "react-icons/io5";

// Components
import useDocumentTitle from "../../../../helper/utilities/useDocumentTitle";
import { AddProductForm } from "./AddProductForm";

export const AddNewProduct = () => {
  const navigate = useNavigate();

  // page title
  useDocumentTitle("Admin | Add Product");
  return (
    <div className="px-5 mx-auto">
      <div className="w-full h-auto shadow-[2px_2px_10px_2px_rgba(0,0,0,0.12)] bg-white rounded-[12px] px-[22px] py-[29px]">
        <div className="flex justify-between items-center">
          <h1 className="text-[#32353B] text-[28px] font-poppins font-semibold text-left">
            Add New Products
          </h1>
          <div onClick={() => navigate(-1)}>
            <IoClose className="border border-gray-500 text-3xl rounded-full hover:bg-gray-500 hover:text-white cursor-pointer duration-300" />
          </div>
        </div>
        <div className="w-full h-[1px] bg-[#E9ECF2] mt-[22px] mb-[30px]"></div>
        <AddProductForm />
      </div>
    </div>
  );
};

// configuration,
import React from "react";

// Components
import { ageCalculator } from "../../../../helper/utilities/ageCalculator";

export const SendForReSubmitCard = ({ user }) => {
  const age = ageCalculator(user?.dateOfBirth);
  return (
    <div>
      <div className=" bg-[#DF933B] rounded-[8px] px-[35px] py-[31px]">
        <div className="flex gap-x-[24px]">
          <img
            className="w-[85px] h-[85px] rounded-full"
            src={user?.profilePhoto}
            alt="profile"
          />
          <div className="flex flex-col items-start gap-y-[18px]">
            <h1 className="text-[#FFFFFF] text-[24px] leading-[29px] font-medium">
              {(user?.firstName ? user?.firstName : "") +
                " " +
                (user?.lastName ? user?.lastName : "")}
            </h1>
            <p className="text-[#FFFFFF] text-[20px] leading-[24px] font-medium">
              Age: {user?.age ? user?.age : age ? age : ""}
            </p>
            <button className="w-[135px] h-[37px] mt-[20px] flex justify-center items-center bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] text-white rounded-[4px]">
              View Request
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

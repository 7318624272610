import React from "react";
import { Outlet } from "react-router-dom";
import CoursePackageNav from "../PackagesNav/CoursePackageNav";

const CoursePackage = () => {
  return (
      <CoursePackageNav>
          <Outlet />
    </CoursePackageNav>
  );
};

export default CoursePackage;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Toaster, toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";

import { resetCourseBundleStore } from "../../../../../Redux/features/Packages/coursePackageSlice";
import ImageUploader1 from "../../../../Shared/components/InmageUploader/ImageUploader1";
import { useUpdateCourseBundleMutation } from "../../../../../Redux/features/Packages/packagesApi";
import SelectCourse from "../AddCoursePackage/SelectCourse";
import EditableInput from "../../partials/EditableInput";
import EditableTextArea from "../../partials/EditableTextArea";

const ModifyBundle = ({ setEditMode, selectedBundle }) => {
  const [editedBundle, setEditedBundle] = useState({});
  const [image, setImage] = useState(selectedBundle.image);
  // const { editedBundle, handleSubmit, reset } = useForm();
  const courses = useSelector((state) => state?.coursePackage?.courses) || [];
  const dispatch = useDispatch();
  // Redux api
  const [updateCourseBundle, { data, isLoading, error }] =
    useUpdateCourseBundleMutation();

  const handleChange = (e) => {
    console.log(e.target.value);
    let newEditedBundle = { ...editedBundle };
    newEditedBundle[e.target.name] = e.target.value;
    setEditedBundle(newEditedBundle);
    console.log(newEditedBundle)
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = { ...editedBundle, courses: courses.map((item) => item._id) };
    delete data.createdAt;
    delete data.updatedAt;
    delete data._id;
    data.image = image;
    console.log({ id: selectedBundle._id, data });

    updateCourseBundle({ id: selectedBundle._id, data });
  };

  useEffect(() => {
    if (selectedBundle) {
      setEditedBundle(selectedBundle);
    }
  }, [selectedBundle]);

  useEffect(() => {
    if (data) {
      setImage(null);
      dispatch(resetCourseBundleStore());
      setEditedBundle({});
      toast.success("Bundle Updated successfully");
    }
    if (error) {
      toast.error("Failed! Please try again");
    }
  }, [data, error]);
  return (
    <div className="min-h-[200vh]">
      <div className="px-5 mx-auto">
        <div className="w-full h-auto shadow-[2px_2px_10px_2px_rgba(0,0,0,0.12)] bg-white rounded-[12px] px-[22px] py-[29px] mb-8">
          <div className="relative flex items-center justify-between">
            <h1 className="text-[#32353B] text-[28px] font-poppins font-semibold text-left">
              Update Course bundle
            </h1>
            <span
              onClick={() => setEditMode(false)}
              className="cursor-pointer left-2 text-[#e41272] top-2"
            >
              <CancelIcon />
            </span>
          </div>
          <div className="w-full h-[1px] bg-[#E9ECF2] mt-[22px] mb-[30px]"></div>
          <div>
            <form onSubmit={handleSubmit}>
              <EditableInput
                {...{
                  handleChange,
                  editedBundle,
                  required: true,
                  title: "Bundle Name",
                  name: "name",
                  type: "text",
                }}
              />
              <EditableTextArea
                {...{
                  handleChange,
                  editedBundle,
                  required: true,
                  title: "Description",
                  name: "description",
                }}
              />
              <div className="mb-3">
                <ImageUploader1
                  {...{ image, setImage, title: "Upload Cover Image" }}
                />
              </div>
              <SelectCourse />
              <EditableInput
                {...{
                  handleChange,
                  editedBundle,
                  required: true,
                  title: "price",
                  name: "price",
                  type: "number",
                }}
              />
              <EditableInput
                {...{
                  handleChange,
                  editedBundle,
                  required: true,
                  title: "Price In Point",
                  name: "priceInPoint",
                  type: "number",
                }}
              />
              <EditableInput
                {...{
                  handleChange,
                  editedBundle,
                  required: true,
                  title: "Skill Level",
                  name: "skillLevel",
                  type: "text",
                }}
              />
              <EditableInput
                {...{
                  handleChange,
                  editedBundle,
                  required: true,
                  title: "Why This Course",
                  name: "whyThisCourse",
                  type: "text",
                }}
              />
              <EditableInput
                {...{
                  handleChange,
                  editedBundle,
                  required: true,
                  title: "What is in This course",
                  name: "whatIsInThisCourse",
                  type: "text",
                }}
              />
              <EditableInput
                {...{
                  handleChange,
                  editedBundle,
                  required: true,
                  title: "What is required for this courses",
                  name: "whatRequiredForTheCourse",
                  type: "text",
                }}
              />
              <EditableInput
                {...{
                  handleChange,
                  editedBundle,
                  required: true,
                  title: "what need to know for the course",
                  name: "whatNeedToKnowForTheCourse",
                  type: "text",
                }}
              />
              <EditableInput
                {...{
                  handleChange,
                  editedBundle,
                  required: true,
                  title: "Who can do this courses",
                  name: "forWhomIsThisCourse",
                  type: "text",
                }}
              />
              <EditableInput
                {...{
                  handleChange,
                  editedBundle,
                  required: true,
                  title: "Is Certificate available",
                  name: "willCertificateBeAvailable",
                  type: "text",
                }}
              />
              <input
                type="submit"
                value={isLoading ? "Loading..." : "submit"}
                className="bg-[#E41272] text-white w-[129px] h-[47px] rounded-[3px] border-[1px] border-[#3D66D7] mt-[18px] cursor-pointer font-medium hover:bg-opacity-80"
              />
            </form>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default ModifyBundle;

import React, { useState } from "react";
import useDocumentTitle from "../../../../helper/utilities/useDocumentTitle";

/* components */
import UserPlanHeader from "../UserPlanHeader";
import AddBadgeTable from "./AddBadgeTable/AddBadgeTable";
import { BadgeTableModal } from "./BadgeTableModal";

const AddBadge = () => {
  const [user, setUser] = useState(null);
  const [badgeModalOpen, setBadgeModalOpen] = useState(false);

  const modalControll = () => {
    setBadgeModalOpen(!badgeModalOpen);
  };

  // page title
  useDocumentTitle("Admin | Add Badge");
  return (
    <div className="bg-[#FAFBFF]">
      <h1 className="pt-[74px] pb-[38px] text-center font-Nunito font-bold text-[40px] text-[#1D252D]">
        Add Badge
      </h1>

      {/* User Search */}
      <UserPlanHeader setUser={setUser} user={user} role="member" />

      <div className="font-inter font-semibold text-base text-[#FFFFFF] mb-[90px] pt-[20px]">
        <button
          className={`px-5 py-2 rounded  ${
            user
              ? "bg-gradient-to-b from-[#DF298C] to-[#B02BBE]"
              : "bg-slate-200 text-gray-500 cursor-not-allowed"
          }`}
          onClick={modalControll}
          disabled={!user}
        >
          Add Badge
        </button>
      </div>

      {/* Add badge table */}
      <AddBadgeTable />
      {badgeModalOpen && (
        <BadgeTableModal
          modalControll={modalControll}
          userId={user._id}
          setUser={setUser}
        />
      )}
    </div>
  );
};

export default AddBadge;

import React, { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useChangeStatusOfCertificatesMutation } from "../../../../../Redux/features/Certificates/certificatedApi";

const VerificationButton = ({ selected, selectedPhoto }) => {
  const [changeStatusOfCertificates, { data, isLoading, error }] =
    useChangeStatusOfCertificatesMutation();

  const submitStatus = (status) => {
    changeStatusOfCertificates({
      id: selected?._id,
      photo: selectedPhoto?._id,
      status: status,
    });
  };
  useEffect(() => {
    if (data) {
      toast.success(data?.message);
    }
    if (error) toast.error(error?.data?.message);
  }, [error, data]);

  return (
    <div>
      {isLoading && (
        <div className="flex justify-center items-center mb-12 gap-8 font-inter font-semibold text-[24px] text-white pt-[55px] drop-shadow-md">
          <div className="bg-gradient-to-b from-[#DF298C] to-[#B02BBE] px-5 py-1 rounded approve-lawyer">
            <button>Loading...</button>
          </div>
        </div>
      )}
      {!isLoading && (
        <div className="flex justify-center items-center mb-12 gap-8 font-inter font-semibold text-[24px] text-white pt-[55px] drop-shadow-md">
          <div
            onClick={() => submitStatus("approved")}
            className="bg-gradient-to-b from-[#DF298C] to-[#B02BBE] px-5 py-1 rounded approve-lawyer"
          >
            <button>Approve</button>
          </div>
          <div
            onClick={() => submitStatus("askedToResubmit")}
            className="bg-gradient-to-b from-[#DF298C] to-[#B02BBE] px-5 py-1 rounded approve-lawyer"
          >
            <button className="">Ask To Resubmit</button>
          </div>
          <div
            onClick={() => submitStatus("rejected")}
            className="bg-gradient-to-b from-[#DF298C] to-[#B02BBE] px-5 py-1 rounded approve-lawyer"
          >
            <button className="">Reject</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default VerificationButton;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Offers from "./Offers";

export default function PlanCard({
  plan,
  condition,
  setShowPopup,
  setPack,
}) {
  const [price, setPrice] = useState(0);
  const [priceFor, serPriceFor] = useState("");
  const handlePop = () => {
    setPack(plan);
    setShowPopup(true);
  };

  const renderWith = useSelector((state) => state?.plans?.renderWith);
  let planTitle;
  let monthlyPrice;
  let yearlyPrice;
  if (plan) {
    planTitle = plan.title || "";
    monthlyPrice = plan.priceMonth || 0;
    yearlyPrice = plan.priceYear || 0;
  }

  useEffect(() => {
    if (renderWith === "monthly") {
      setPrice(monthlyPrice);
      serPriceFor("monthly");
    }
    if (renderWith === "yearly") {
      setPrice(yearlyPrice);
      serPriceFor("yearly");
    }
  }, [renderWith, monthlyPrice, yearlyPrice]);
  return (
    <div className="relative mx-auto">
      <div className="rounded-lg overflow-hidden relative h-[820px] w-[420px] shadow-2xl">
        <svg
          className="relative -top-[9px]"
          width="380"
          height="129"
          viewBox="0 0 437 129"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.15"
            d="M354.495 101.685C383.643 149.537 413.87 80.2525 436 1L14.9514 1.00007C-39.8693 71.9703 82.2289 181.162 116.453 98.1964C129.406 66.794 149.461 57.0075 227.107 116.639C281.625 158.508 280.995 -18.9771 354.495 101.685Z"
            fill="url(#paint0_linear_475_4585)"
            stroke="black"
          />
          <defs>
            <linearGradient
              id="paint0_linear_475_4585"
              x1="408.526"
              y1="117.451"
              x2="40.1507"
              y2="-15.6624"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#E41272" />
              <stop offset="1" stop-color="#611F9E" />
            </linearGradient>
          </defs>
        </svg>
        <div className="absolute h-full top-0 left-[50px] mb-4 z-[100]">
          <div className="relative pt-16 h-full">
            <div className="flex">
              <div className="w-[72px] h-[72px] rounded bg-[#ECEBFF] flex justify-center items-center">
                <div
                  style={{
                    borderRadius: "50px",
                    borderRightColor: "#E41272",
                    borderTopColor: "#F664BC",
                    borderBottomColor: "#E41272",
                    borderLeftColor: "#F664BC",
                    borderWidth: "18px",
                    borderStyle: "solid",
                    height: "0px",
                    width: "0px",
                    transform: "rotate(137deg)",
                  }}
                ></div>
              </div>
              <div className="ml-4">
                <p className="text-2xl font-medium">
                  <small>{planTitle} plan</small>
                </p>
                <p className="text-[#E41272] text-xl font-semibold">
                  {planTitle}
                </p>
              </div>
            </div>
            <div className="mt-8 mb-4 ">
              <p>
                {" "}
                <span className="text-4xl font-bold text-[#E41272]">
                  ৳{price}
                </span>{" "}
                <span> /{priceFor}</span>
              </p>
              <p className="mt-4 text-xl text-[#E41272]">What's included</p>
            </div>
            <div>
              <Offers {...{ plan }} />
            </div>
            <div className="flex justify-center absolute bottom-8 text-white mt-4">
              <button
                onClick={
                  () => handlePop()
                }
                className="px-10 py-4 rounded-2xl bg-[background: linear-gradient(180deg, #E22989 0%, #A72CC7 100%)] z-0  text-lg font-semibold w-[300px]"
                style={{
                  background:
                    "linear-gradient(180deg, #E41272 0%, #942DD9 100%)",
                }}
              >
                <div className="text-white font-bold">
                  Add Plan to user
                </div>
              </button>
            </div>
          </div>
        </div>
        <div className="absolute -bottom-[9px]">
          <svg
            width="380"
            height="147"
            viewBox="0 0 433 147"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.15"
              d="M126.503 27.902C85.8399 -40.8626 13.601 62.0474 1 146H427.71C451.245 71.2545 371.986 -54.3172 334.837 27.9018C319.149 62.6236 288.356 114.855 249.998 27.9018C212.849 -56.3102 202.821 156.963 126.503 27.902Z"
              fill="url(#paint0_linear_475_4683)"
              stroke="black"
            />
            <defs>
              <linearGradient
                id="paint0_linear_475_4683"
                x1="29.1808"
                y1="29.5"
                x2="404.814"
                y2="168.671"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#E41272" />
                <stop offset="1" stop-color="#611F9E" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  );
}

import React from "react";
// import { ageCalculator } from "../../../../helper/utilities/ageCalculator";
import { Link } from "react-router-dom";

const UserWalletCard = ({ item,useCase }) => {
  const { profilePhoto, firstName, lastName } = item||{}
  return (
    <div>
      <div className="h-[150px] bg-payment-card rounded-[8px] px-[35px] py-[31px]">
        <div className="flex items-center gap-x-[24px]">
          <img
            className="w-[85px] h-[85px] rounded-full"
            src={profilePhoto}
            alt="profile"
          />
          <div className="flex flex-col justify-between h-full gap-y-[10px]">
            <h1 className="text-[#FFFFFF] text-[24px] leading-[29px] font-medium">{`${firstName} ${lastName}`}</h1>
            {/* <p className="text-[#FFFFFF] text-[20px] leading-[24px] font-medium">
              Age: {ageCalculator(dateOfBirth)}
            </p> */}
            {!useCase && <Link to={`/currency/wallet/overview?user=${item._id}`}>
              <button
                className={`w-[135px] h-[37px] mt-[20px] flex justify-center items-center  rounded-[4px] bg-[#d5316a] text-white`}
              >
                View Wallet
              </button>
            </Link>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserWalletCard;

import React from "react";

const WalletCalculation = ({ data }) => {
  return (
    <div className="">
      <div className="flex items-center justify-center">
        <div className="mb-4 p-4 shadow-lg w-1/2 rounded text-white bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)]">
          <p className="font-semibold text-lg">Total Deposit till now (TK)</p>
          <h1 className="text-6xl my-4">{data?.data?.totalDeposit}</h1>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-6 ">
        <div className="text-white bg-[rgb(242,40,118)] p-3 shadow-lg rounded">
          <p className="font-semibold text-lg">
            Current Total Point Of all Users
          </p>
          <h1 className="text-6xl my-4">{data?.data?.totalPoint}</h1>
        </div>
        <div className="text-white bg-[rgb(242,40,118)]  p-3 shadow-lg rounded">
          <p className="font-semibold text-lg">
            Total Point spent till now by users
          </p>
          <h1 className="text-6xl my-4">{data?.data?.totalPointSpent}</h1>
        </div>
        <div className="text-white bg-[rgb(242,40,118)]  p-3 shadow-lg rounded">
          <p className="font-semibold text-lg">
            Total point purchased by users
          </p>
          <h1 className="text-6xl my-4">{data?.data?.totalPointPurchased}</h1>
        </div>
      </div>
    </div>
  );
};

export default WalletCalculation;

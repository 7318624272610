import React from "react";
import { ageCalculator } from "../../../../helper/utilities/ageCalculator";
// import "./Payment.css";
const UserCard = ({ user, searchQuery, setSearchQuery }) => {
  const handleClick = () => {
    if (searchQuery.filter((item) => item._id === user._id)?.length) {
      setSearchQuery(searchQuery.filter((item) => item._id !== user._id));
    } else {
      setSearchQuery([...searchQuery, { _id: user._id }]);
    }
  };

  const { profilePhoto, firstName, lastName, dateOfBirth } = user || {};
  return (
    <div>
      <div className="h-[222px] bg-payment-card rounded-[8px] px-[35px] py-[31px]">
        <div className="flex gap-x-[24px]">
          <img
            className="w-[85px] h-[85px] rounded-full"
            src={profilePhoto}
            alt="profile"
          />
          <div className="flex flex-col gap-y-[18px]">
            <h1 className="text-[#FFFFFF] text-[24px] leading-[29px] font-medium">{`${firstName} ${lastName}`}</h1>
            <p className="text-[#FFFFFF] text-[20px] leading-[24px] font-medium">
              Age: {ageCalculator(dateOfBirth)}
            </p>
            <button
              onClick={handleClick}
              className={`w-[135px] h-[37px] mt-[20px] flex justify-center items-center  rounded-[4px] ${
                searchQuery.filter((item) => item._id === user._id)?.length
                  ? "bg-[#d5316a] text-white"
                  : "bg-white text-black"
              }`}
            >
              {searchQuery.filter((item) => item._id === user._id)?.length
                ? "Selected"
                : "Select"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserCard;
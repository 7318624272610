import getCookie from "../../../../helper/cookies/getCookie";
import { apiSlice } from "../../../api/apiSlice";
export const restrictSuspendApi = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllMembersForSuspend: builder.query({
            query: ({ page, searchTerm }) => ({
                url: `admin/member/unsuspended?_role=member&_limit=4&_page=${page}&_name=${searchTerm}`,
                headers: {
                    authorization: `Bearer ${getCookie("adminToken")}`,
                },
            }),
        }),
        suspendUser: builder.mutation({
            query: (data) => ({
                url: `/admin/action/suspend`,
                method: "POST",
                headers: {
                    authorization: `Bearer ${getCookie("adminToken")}`,
                },
                body: data,
            }),
        }),
    }),
});

export const { useGetAllMembersForSuspendQuery, useSuspendUserMutation } = restrictSuspendApi;

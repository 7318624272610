import React from "react";
/* msg sender & user img */
import { MessageLeft } from "./MessageLeft";
import { MessageRight } from "./MessageRight";

export const AllChattingListsWithLawyer = ({ message,user,receiver }) => {

    return (
        <div className="bg-[#FFFFFF] font-Poppins font-normal rounded-[20px] pb-[35px] w-[848px]">
            {/* ---------- Header Start ---------- */}
            <div className="flex justify-between bg-[#FEFEFE] shadow-[0px_12px_23px_rgba(62, 73, 84, 0.04)] rounded-[20px] mb-5">
                <div style={{ boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)" }} className="flex justify-between py-[15px] w-full">
                    {/* {lawyerData.map(lawyer => ( */}
                        <div key={receiver?._id} className="flex items-center gap-[37px]">
                            <img className="rounded-[64px] w-12 ml-[15px]" src={receiver?.profilePhoto} alt="Profile Pic" />
                            <div>
                                <h1 className="font-medium text-[20px] text-[#000000]">{receiver?.firstName +" "+ receiver?.lastName}</h1>
                                {/* <p className="mt-[1px] font-normal text-left text-[16px] text-[#999999]">Last seen {lawyer.time}</p> */}
                            </div>
                        </div>
                    {/* ))} */}
                    <div className="flex items-center font-medium text-base mr-[21px]">
                        <button
                            style={{
                                boxShadow: "-2px -2px 10px 2px rgba(0, 0, 0, 0.12), 2px 2px 10px 2px rgba(0, 0, 0, 0.12)",
                            }}
                            className="font-medium text-base text-[#000000] bg-[#FFFFFF] rounded-[22px] px-[30px] py-3"
                        >
                            View Mode
                        </button>
                    </div>
                </div>
            </div>
            {/* ---------- Header End ---------- */}

            {/* ------------ All Message ------------- */}
            <div className="max-h-[700px] overflow-y-auto">
                {message?.map(item => {
                    if (item?.fromSelf) {
                        return <MessageRight key={item.id} message={item} user={user} />;
                    } else if (item?.role === "receiver") {
                        return <MessageLeft key={item.id} message={item} receiver={receiver} />;
                    }
                })}
            </div>
            <div
                style={{
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.12), -2px -2px 10px 2px rgba(0, 0, 0, 0.12)",
                }}
                className="flex items-center justify-center rounded-[22px] py-[22px] mt-[38px] mx-[15px] cursor-pointer"
            >
                <button className="font-medium text-base rounded-[20px]">
                    <p>View Mode</p>
                </button>
            </div>
        </div>
    );
};

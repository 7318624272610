// Configuration
import React from "react";

// Third party package
import ReactApexChart from "react-apexcharts";

const ServerStatusChart = () => {
  return (
    <div className="flex flex-col items-center rounded-xl w-[410px] bg-white">
      <div className="w-full p-6">
        <div>
          <h1 className="text-left text-xl leading-8 font-medium font-poppins">
            Server Status
          </h1>
        </div>
        <div className="flex justify-start mt-3">
          <button className="px-2 py-1 text-white text-sm leading-[18px] font-medium font-poppins bg-[#E41272] rounded-md">
            Active
          </button>
        </div>
      </div>
      <ReactApexChart
        options={{
          chart: {
            type: "area",
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          stroke: {
            curve: "smooth",
            width: 2,
          },
          dataLabels: {
            enabled: false,
          },
          colors: ["#E41272"],
          grid: {
            show: false,
          },
          xaxis: {
            categories: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
            labels: {
              show: false,
            },
          },
          yaxis: {
            labels: {
              show: false,
            },
          },
        }}
        series={[
          {
            name: "Server Call",
            data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 139, 213, 180],
            type: "area",
          },
        ]}
        type="area"
        height={200}
        width={390}
      />
      <div className="flex gap-10">
        <div>
          <p className="text-sm leading-[18px] font-poppins text-[#98A4B5] mb-3">
            Hosting Site
          </p>
          <p className="text-[#E41272] text-sm leading-[18px] font-poppins">
            Shongshari.com
          </p>
        </div>
        <div>
          <p className="text-sm leading-[18px] font-poppins text-[#98A4B5] mb-3">
            Storage
          </p>
          <p className="text-[#333333] text-sm leading-[18px] font-medium">
            10 GB
          </p>
        </div>
      </div>
    </div>
  );
};

export default ServerStatusChart;

// Configuration
import React, { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";

// Components
import { useGetProfileDetailsQuery } from "../../../../../Redux/features/User/userApi";
import ProfileAccordion from "../../../../Shared/ProfileAccordion/ProfileAccordion";

const ModifyKaziSingleProfileDetails = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetProfileDetailsQuery(id);

  // Scroll to top
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <div>
        <Outlet />
      </div>
      <ProfileAccordion {...{ data, isLoading, edit: true }}></ProfileAccordion>
    </div>
  );
};

export default ModifyKaziSingleProfileDetails;

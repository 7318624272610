// Configuration
import React, { useEffect, useState } from "react";

// Third party package
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";

// Components
import useDocumentTitle from "../../../../helper/utilities/useDocumentTitle";
import { setInitialCostCalculator } from "../../../../Redux/features/costCalculator/costCalculatorSlice";
import { CalculationTable } from "./CalculationTable";
import { SelectProductsModal } from "./SelectProductsModal";

export const CostCalculator = () => {
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const dispatch = useDispatch();

    const modalControll = () => {
        setModalOpen(!modalOpen);
    };

    useEffect(() => {
        const updatedProduct = selectedProducts?.map(item => {
            const { value } = item || {};
            const data = {
                _id: value?._id,
                name: value?.name,
                price: value?.price,
                subTotal: value?.price,
            };
            return data;
        });

        dispatch(setInitialCostCalculator(updatedProduct));
    }, [selectedProducts, dispatch]);

    // page title
    useDocumentTitle("Admin | Cost Calculator");

    return (
        <div className="w-full h-auto px-[30px] mb-[60px]">
            <div className="flex justify-between">
                <div className="w-full flex flex-col items-start">
                    <h3 className="text-[28px] font-OpenSans font-semibold">Cost Calculator</h3>
                    <p className="text-[14px] text-gray-500 tracking-wider">Calculate your product costs</p>
                </div>
                {selectedProducts?.length > 0 && (
                    <Button variant="outlined" onClick={modalControll}>
                        Select Products
                    </Button>
                )}
            </div>
            {selectedProducts?.length === 0 && (
                <div className="h-[300px] w-full flex justify-center items-center">
                    <Button variant="outlined" onClick={modalControll}>
                        Select Products
                    </Button>
                </div>
            )}

            {selectedProducts?.length > 0 && (
                <div className="mt-[60px] w-full">
                    <CalculationTable selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} />
                </div>
            )}
            {modalOpen && (
                <SelectProductsModal modalControll={modalControll} setSelectedProducts={setSelectedProducts} selectedProducts={selectedProducts} />
            )}
        </div>
    );
};

import Referral from "../../Admin__Portion/Components/ShongnshariCurrency/Referral";
import Currency from "../../Admin__Portion/Components/ShongnshariCurrency/currency";
import ReferBonus from "../../Admin__Portion/Components/ShongnshariCurrency/referral/ReferBonus/ReferBonus";
import ReferUser from "../../Admin__Portion/Components/ShongnshariCurrency/referral/referUser/ReferUser";
import Wallet from "../../Admin__Portion/Components/ShongnshariCurrency/wallet";
import WalletContainer from "../../Admin__Portion/Components/ShongnshariCurrency/wallet/WalletContainer";
import WithdrawRequestPage from "../../Admin__Portion/Components/ShongnshariCurrency/wallet/withDrawReq/WithdrawRequestPage";


const currencyRoutes = [
  {
    id: 1,
    path: "/currency",
    element: <Currency />,
    nestedRoutes: [
      {
        id: 1,
        path: "referral",
        element: <Referral />,
        nestedRoutes: [
          {
            id: 1,
            path: "refer-bonus",
            element: <ReferBonus />
          },
          {
            id: 2,
            path: "refer-user",
            element: <ReferUser />
          },
        ],
      },
      {
        id: 2,
        path: "wallet",
        element: <Wallet />,
        nestedRoutes: [
          {
            id: 1,
            path: "overview",
            element: <WalletContainer/>
          },
          {
            id: 1,
            path: "withdraw-request",
            element: <WithdrawRequestPage />
          },
        ],
      },
    ],
  },
];

export default currencyRoutes;

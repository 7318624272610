// configuration
import React, { useEffect, useState } from "react";

// Third party packages
import Pagination from "@mui/material/Pagination";
// components
import { ModifyLawyerServicesCard } from "../../Cards/Lawyer/Modify__Lawyer__Services__Profile__Card/ModifyLawyerServicesCard";
import SearchManyUsers from "../SearchUsers/SearchManyUsers";
import { useSearchParams } from "react-router-dom";
import AllServices from "../../../Components/Lawyer/ModifyCaseStudy/AllServices/AllServices";

export const ServiceUserProfiles = ({ role }) => {
    const [limit, setLimit] = useState(4);
    const [page, setPage] = useState(1);
    const [userObj, setUser] = useState();

    const [searchParams] = useSearchParams();
    const propsUserId = searchParams.get("user");

    const { data } = userObj || {};
    const paginationPage = Math.ceil(data?.total / 4);

    useEffect(() => {
        setLimit(4);
    }, []);

    if (searchParams.get("user")) {
      return <AllServices {...{ propsUserId }} />;
    }

    return (
        <div>
            <div>
                <SearchManyUsers setUser={setUser} role={role} page={page} limit={limit} />
            </div>
            <div className="grid grid-cols-2 gap-[29px] mt-[65px]">
                {userObj?.data?.users?.map(p => {
                    return <ModifyLawyerServicesCard key={p._id} user={p} role={role} />;
                })}
            </div>
            {paginationPage > 1 && (
                <div className="mt-[40px] flex justify-end pb-[100px]">
                    <Pagination count={paginationPage} variant="outlined" color="secondary" onChange={(event, pageNumber) => setPage(pageNumber)} />
                </div>
            )}
        </div>
    );
};

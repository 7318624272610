import AddCourse from "../../Admin__Portion/Components/Courses/AddCourse/AddCourse";
import AddCourseQuiz from "../../Admin__Portion/Components/Courses/AddCourseQuiz/AddCourseQuiz";
import AddQuizPage from "../../Admin__Portion/Components/Courses/AddCourseQuiz/AddQuizPage/AddQuizPage";
import AddCourseSection from "../../Admin__Portion/Components/Courses/AddCourseSection/AddCourseSection";
import AddSectionPage from "../../Admin__Portion/Components/Courses/AddCourseSection/AddSectionPage/AddSectionPage";
import { Courses } from "../../Admin__Portion/Components/Courses/Courses";
import EditCoursePage from "../../Admin__Portion/Components/Courses/EditCoursePage/EditCoursePage";
import EditSingleCourse from "../../Admin__Portion/Components/Courses/EditCoursePage/EditSingleCourse/EditSingleCourse";
import ModifyCourseReview from "../../Admin__Portion/Components/Courses/ModifyCourseReview/ModifyCourseReview";
import ViewCourseReview from "../../Admin__Portion/Components/Courses/ModifyCourseReview/ViewCourseReview/ViewCourseReview";
import SingleCoursePage from "../../Admin__Portion/Components/Courses/SingleCoursePage/SingleCoursePage";
import ViewCourse from "../../Admin__Portion/Components/Courses/ViewCourse/ViewCourse";
import CategoriesAndTypes from "../../Admin__Portion/Shared/components/CategoriesAndTypes/CategoriesAndTypes";

const courseRoutes = [
  {
    id: 1,
    path: "/courses",
    element: <Courses />,
    nestedRoutes: [
      {
        id: 1,
        path: "addCourse",
        element: <AddCourse />,
      },
      {
        id: 2,
        path: "viewCourse",
        element: <ViewCourse />,
      },
      {
        id: 3,
        path: "editCourse",
        element: <EditCoursePage />,
      },
      {
        id: 4,
        path: "categories",
        element: <CategoriesAndTypes {...{ title: "courseCategory" }} />,
      },
      {
        id: 5,
        path: "types",
        element: <CategoriesAndTypes {...{ title: "courseTypes" }} />,
      },
      {
        id: 6,
        path: "quiz",
        element: <AddCourseQuiz />,
      },
      {
        id: 7,
        path: "modifyReview",
        element: <ModifyCourseReview />,
      },
      {
        id: 8,
        path: "addCourseSection",
        element: <AddCourseSection />,
      },
      {
        id: 10,
        path: "addCourseSection/:id",
        element: <AddSectionPage />,
      },
      {
        id: 11,
        path: "course/:id",
        element: <SingleCoursePage />,
      },
      {
        id: 12,
        path: "editCourse/:id",
        element: <EditSingleCourse />,
      },
      {
        id: 13,
        path: "modifyCourseReview/:id",
        element: <ViewCourseReview />,
      },
      {
        id: 13,
        path: "quiz/:id",
        element: <AddQuizPage />,
      },
    ],
  },
];

export default courseRoutes;

// configuration
import React, { useEffect, useState } from "react";

// Third party packages
import { toast } from "react-hot-toast";
import { AiOutlineSave } from "react-icons/ai";

// Components
import { useUpdateSingleProductMutation } from "../../../../Redux/features/Shop/shopApi";
import { RichTextEditor } from "../../../Shared/components/TextEditor/RichTextEditor";

export const EditDescription = ({ description, id }) => {
  const [editedDescription, setDescription] = useState();

  const [updateSingleProduct, { data: response, isLoading, error }] =
    useUpdateSingleProductMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await updateSingleProduct({
      id: id,
      data: { description: editedDescription || description },
    });
  };

  useEffect(() => {
    if (response) {
      toast.success("Description updated successfully");
      setDescription("");
    }
    if (error) {
      toast.error(
        <p>
          Something went wrong <br /> Please login or, try again later
        </p>
      );
    }
  }, [response, error]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-[93px] w-full mr-[45px] h-[622px] p-[34px] bg-white shadow-[2px_2px_10px_2px_rgba(0,0,0,0.12)] rounded-[12px]">
        <h1 className="text-[#000000] text-[32px] font-bold font-Inter text-left mb-4">
          Edit Description
        </h1>
        <RichTextEditor {...{ setDescription }} />
      </div>
      <div className="mt-[29px]">
        <button
          type="submit"
          className="flex items-center justify-center min-w-[118px] px-4 h-[48px] bg-[linear-gradient(90deg,#E41272_0%,_#A72CC7_100%)] rounded-[100px] gap-x-[10px] text-white tracking-[1.25px] font-Inter font-semibold text-[16px]"
        >
          {isLoading ? "Loading..." : "SAVE"}{" "}
          <AiOutlineSave className="text-[16px]" />
        </button>
      </div>
    </form>
  );
};

// Configuration
import React, { useState } from "react";

// third party package
import { Toaster } from "react-hot-toast";
import { AiOutlineArrowRight } from "react-icons/ai";
import useDocumentTitle from "../../../../helper/utilities/useDocumentTitle";
import { useGetAllCourseTypesQuery } from "../../../../Redux/features/Course/courseApi";

// Components
import { useGetAllCategoriesQuery } from "../../../../Redux/features/Shop/shopApi";
import DeleteCategories from "../../../Components/Shop/ShopCategories/DeleteCategories/DeleteCategories";
import EditCategories from "../../../Components/Shop/ShopCategories/EditCategories/EditCategories";
import AddCategoriesModal from "../../Cards/Shop/ShopCategories/AddCategoriesModal";

const CategoriesAndTypes = ({ title }) => {
  const [categoryName, setCategoryName] = useState("");
  const [addCategoriesOpen, setAddCategoriesOpen] = useState(false);

  // category query & page title decision
  let type = "";
  let pageTitle;
  if (title === "courseCategory") {
    type = "course";
    pageTitle = "Category";
  } else if (title === "shopCategory") {
    type = "shop";
    pageTitle = "Category";
  }
  if (title === "courseTypes") {
    pageTitle = "Types";
  }

  // Page Title
  useDocumentTitle(`Admin | ${pageTitle}`);

  const {
    data: allCategories,
    isLoading: categoriesDataLoading,
    error: categoriesDataError,
  } = useGetAllCategoriesQuery(type);

  const {
    data: allCourseTypes,
    isLoading: courseTypesLoading,
    error: courseTypesError,
  } = useGetAllCourseTypesQuery();

  const showAddCategoriesModal = () => {
    setAddCategoriesOpen(true);
  };

  const handleAddCategoriesCancel = () => {
    setAddCategoriesOpen(false);
  };

  return (
    <div className="px-6">
      <div className="w-full pt-12 pb-[72px] flex justify-center items-center">
        <button
          className="text-white h-[80px] w-[317px] rounded-[100px] text-[24px] font-bold font-Inter tracking-[1.25px] flex items-center gap-x-[13px] justify-center uppercase"
          style={{
            backgroundImage: "linear-gradient(90deg, #E41272 0%, #A72CC7 100%)",
          }}
          onClick={() => showAddCategoriesModal()}
        >
          Add {title === "courseTypes" && "Types"}{" "}
          {title === "courseCategory" && "Category"}
          {title === "shopCategory" && "Category"}
          <AiOutlineArrowRight className="text-[24px]" />
        </button>
      </div>
      {/* Add categories or types modal */}
      <AddCategoriesModal
        {...{
          addCategoriesOpen,
          handleAddCategoriesCancel,
          setCategoryName,
          categoryName,
          title,
          setAddCategoriesOpen,
        }}
      />

      {/* Edit categories */}
      <EditCategories
        {...{
          allCategories,
          allCourseTypes,
          categoriesDataError,
          courseTypesError,
          categoriesDataLoading,
          courseTypesLoading,
          title,
        }}
      />

      {/* Delete Categories */}
      <DeleteCategories
        {...{
          allCategories,
          allCourseTypes,
          categoriesDataError,
          courseTypesError,
          categoriesDataLoading,
          courseTypesLoading,
          title,
        }}
      />

      <Toaster />
    </div>
  );
};

export default CategoriesAndTypes;

// Configuration
import React, { useState } from "react";

// Third party package
import { Button } from "@mui/material";
import { pink } from "@mui/material/colors";
import { Collapse, Modal } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";

// Components
import AddContentForm from "./AddContentForm/AddContentForm";

const AddContentContainer = ({ module }) => {
  const [contentFormVisible, setContentFormVisible] = useState(false);

  const handleContentFormCancel = () => {
    setContentFormVisible(false);
  };

  const { Panel } = Collapse;
  return (
    <div className="px-6">
      {/* show all content */}
      <div className="mb-4">
        <Collapse
          bordered={false}
          defaultActiveKey={["1"]}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
        >
          {module?.contents?.map((content) => (
            <Panel
              header={
                <div>
                  <p className="text-xl font-medium">{content?.name}</p>
                </div>
              }
              key={content?._id}
            >
              <a
                className="px-6 text-blue-500 text-base font-Inter"
                target={"blank"}
                href={content?.video}
              >
                {content?.video}{" "}
                <span className="text-black text-sm font-Inter font-medium ml-1">
                  [{content?.duration} min]
                </span>
              </a>
            </Panel>
          ))}
        </Collapse>
      </div>
      {/* Add content button */}
      <div>
        <Button
          onClick={() => setContentFormVisible(true)}
          sx={{
            color: pink[500],
            borderColor: pink[500],
          }}
          variant="outlined"
        >
          Add Content
        </Button>
      </div>

      {/* Add content form modal */}
      <Modal
        title={<p className="text-xl">Add Content</p>}
        open={contentFormVisible}
        onCancel={handleContentFormCancel}
        footer={null}
      >
        <AddContentForm {...{ id: module?._id, setContentFormVisible }} />
      </Modal>
    </div>
  );
};

export default AddContentContainer;

// Configuration
import React from "react";
import { Outlet } from "react-router-dom";

// Components
import useDocumentTitle from "../../../helper/utilities/useDocumentTitle";
import CoursesNav from "./CoursesNav/CoursesNav";

export const Courses = () => {
  useDocumentTitle("Admin | Courses");
  return (
    <CoursesNav>
      <Outlet />
    </CoursesNav>
  );
};

import { Badge } from "../../Admin__Portion/Components/Badge/Badge";

const badgeRoutes = [
    {
        id: 1,
        path: "/badges",
        element: <Badge />,
    },
];

export default badgeRoutes;

// configuration
import { Route } from "react-router-dom";
import { ProductListingAndTrendyProducts } from "../../Admin__Portion/Components/Shop/ProductListing&TrendyProducts/ProductListingAndTrendyProducts";

// components
import RequireAuth from "../../helper/hooks/loginPersestency/RequireAuth";
import shopRoutes from "../Shop/shopRoutes";

export const renderShopRoutes = () => {
    return shopRoutes?.map((route, i) => {
        return (
            <Route key={i} path={route.path} element={<RequireAuth>{route.element}</RequireAuth>}>
                <Route index element={<ProductListingAndTrendyProducts />} />;
                {route?.nestedRoutes?.map((route, i) => {
                    return <Route key={i} path={route.path} index={i === 1} element={<RequireAuth>{route.element}</RequireAuth>}></Route>;
                })}
            </Route>
        );
    });
};

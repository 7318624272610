import React from "react";

// Third party packages
import { Outlet } from "react-router";
import useDocumentTitle from "../../../helper/utilities/useDocumentTitle";

// components
import { AgentNav } from "./AgentNav/AgentNav";

export const Agent = () => {
    useDocumentTitle("Admin | Agent");
    return (
        <AgentNav>
            <Outlet />
        </AgentNav>
    );
};

export default Agent;

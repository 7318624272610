import React, { useEffect, useState } from "react";

// Components
import ProductImagesCard from "./ProductImagesCard";

const SingleProductImages = ({ product }) => {
  const [selectedImage, setSelectedImage] = useState("");
  const { photos } = product || {};

  useEffect(() => {
    setSelectedImage(photos[0]?.url);
  }, [photos]);

  return (
    <div>
      <div className="flex gap-6">
        <div>
          <ProductImagesCard {...{ setSelectedImage, selectedImage, photos }} />
        </div>
        <div>
          <img
            className="w-[862px] h-[644px] rounded-md"
            src={selectedImage}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default SingleProductImages;

import React from "react";
import { Navigate, useLocation } from "react-router";
import { isLoggedIn } from "./isLoggedIn";

const RequireAuth = ({ children }) => {
    const logged = isLoggedIn();
    let location = useLocation();

    if (logged) {
        return children;
    } else {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
};

export default RequireAuth;

// configuration
import React from "react";

export const DeliveredOrderInfoAndMark = () => {
    return (
        <>
            <div className="mt-[45px] px-[30px]">
                <h1 className="text-[40px] font-SourceCodePro font-semibold text-[#000000] text-left">Beautiful new wedding dress for women</h1>
                <h3 className="mt-[8px] text-[32px] font-semibold font-SourceCodePro text-[#E41272] text-left">
                    BDT <span>5000</span>
                </h3>
            </div>
            <div className="h-[1px] bg-[#67868F] w-full mt-[49px]"></div>
        </>
    );
};

import React from "react";
import bundle from "../../../../assets/images/bundle.png";
import { useDispatch } from "react-redux";
import { storeCourseOfBundle } from "../../../../Redux/features/Packages/coursePackageSlice";

const BundleCards = ({ item, setSelectedBundle, setEditMode }) => {
  const dispatch  = useDispatch()
  const handleSetEdit = () => {
    setSelectedBundle(item);
    setEditMode(true);
    dispatch(storeCourseOfBundle(item.courses))
  };
  return (
    <div className="w-full relative shadow hover:shadow-xl md:w-[220px] mx-auto cursor-pointer overflow-hidden bg-gray-100 rounded">
      <div
        className="bg-slate-100 w-full overflow-hidden  h-[220px] flex justify-center items-center"
        //   onClick={() => navigate(`/bundle/${_id}`)}
      >
        <img src={item?.image} className="h-full w-full" alt="productImage" />
      </div>
      <span className="absolute -top-3 -right-8 w-[150px] rotate-45">
        <img className="w-full" src={bundle} alt="" />
      </span>

      <section className="flex flex-col items-start gap-y-[5px]">
        <div
          className="flex justify-between p-[5px] font-outfit w-full"
          // onClick={() => navigate(`/bundle/${_id}`)}
        >
          <h3 className="font-medium">{item?.name}</h3>
          <p className="font-medium flex items-center">
            {/* <TbCurrencyTaka /> */}
            {item?.price}
          </p>
        </div>
        <div className="flex justify-between bg-black">
          <button
            onClick={handleSetEdit}
            className={`px-4 text-[14px] py-2  duration-300 hover:text-black font-bold text-white bg-yellow-600 ring-primary`}
          >
            Edit
          </button>
          <button
            className={`px-4 text-[14px] py-2  duration-300 hover:text-black font-bold text-white bg-red-600 ring-primary`}
          >
            Delete
          </button>
        </div>
      </section>
    </div>
  );
};

export default BundleCards;

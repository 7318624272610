import React from "react";

// Third party packages
import { Outlet } from "react-router";
import useDocumentTitle from "../../../helper/utilities/useDocumentTitle";

// components
import LawyerNav from "./LawyerNav/LawyerNav";

export const Lawyer = () => {
    useDocumentTitle("Admin | Lawyer");
    return (
        <LawyerNav>
            <Outlet />
        </LawyerNav>
    );
};

// Configuration
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

// Third party packages
import { Toaster, toast } from "react-hot-toast";

// Components
import { useCreateCourseMutation } from "../../../../../Redux/features/Course/courseApi";
import AddCourseInputField from "../AddCourseInputField/AddCourseInputField";
import Error from "../../../../Shared/components/accordion/UI/Error/Error";

const AddCourseForm = () => {
  const [category, setCategory] = useState("");
  const [type, setType] = useState("");
  const [skillLevel, setSkillLevel] = useState("");
  const [courseDetails, setCourseDetails] = useState(null);
  const [whatIsInThisCourse, setWhatIsInThisCourse] = useState(null);
  const [image, setImage] = useState("");
  const [instructorImage, setInstructorImage] = useState("");
  const [instructorImageExtra, setInstructorImageExtra] = useState([]);
  const [customError, setCustomError] = useState([
    {
      categoryError: "",
    },
    {
      typeError: "",
    },
    {
      skillLevelError: "",
    },
    {
      whatIsInThisCourseError: "",
    },
    {
      courseDetailsError: "",
    },
    {
      courseBannerError: "",
    },
    {
      instructorImageError: "",
    },
  ]);
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm();

  // Redux api
  const [createCourse, { data: courseResponse, isLoading, error }] =
    useCreateCourseMutation();

  const onSubmit = async (data) => {
    if (!category) {
      setCustomError((prevState) => [
        {
          categoryError: "Category is required",
        },
      ]);
      return;
    }
    if (!type) {
      setCustomError((prevState) => [
        {
          typeError: "Type is required",
        },
      ]);
      return;
    }
    if (!skillLevel) {
      setCustomError((prevState) => [
        {
          skillLevelError: "Skill Level is required",
        },
      ]);
      return;
    }
    if (!whatIsInThisCourse) {
      setCustomError((prevState) => [
        {
          whatIsInThisCourseError: "What in this course field is required",
        },
      ]);
      return;
    }
    if (!courseDetails) {
      setCustomError((prevState) => [
        {
          courseDetailsError: "Course Details is required",
        },
      ]);
      return;
    }
    if (!image) {
      setCustomError((prevState) => [
        { courseBannerError: "Course Banner is required" },
      ]);
    }
    if (!instructorImage) {
      setCustomError((prevState) => [
        { instructorImageError: "Instructor image is required" },
      ]);
    }
    data.price = Number(data.price);
    data.oldPrice = Number(data.oldPrice);

    data.instructor.map((ins) => delete ins.image);
    data.instructor.map(
      (ins, index) => (ins.image = instructorImageExtra[index])
    );

    const newObject = Object.create(data);
    newObject.name = data.nameInstructor;
    newObject.designation = data.designationInstructor;
    newObject.image = instructorImage;
    newObject.bio = data.bioInstructor;

    data.instructor.push(newObject);
    delete data.nameInstructor;
    delete data.designationInstructor;
    delete data.bioInstructor;

    data = {
      ...data,
      image,
      category,
      type,
      skillLevel,
      whatIsInThisCourse,
      courseDetails,
    };

    await createCourse(data);
  };

  useEffect(() => {
    if (courseResponse) {
      toast.success("Course Created Successfully");
      setImage("");
      setInstructorImage("");
      setInstructorImageExtra([]);
      setCategory("");
      setType("");
      setSkillLevel("");
      reset();
    }
    if (error) {
      toast.error("Something Went Wrong. Please try again later.");
    }
    if (courseDetails !== "") {
      setCustomError((prevState) => [
        {
          courseDetailsError: "",
        },
      ]);
    }
    if (whatIsInThisCourse !== "") {
      setCustomError((prevState) => [
        {
          whatIsInThisCourseError: "",
        },
      ]);
    }
    if (image !== "") {
      setCustomError((prevState) => [
        {
          courseBannerError: "",
        },
      ]);
    }
    if (instructorImage !== "") {
      setCustomError((prevState) => [
        {
          instructorImageError: "",
        },
      ]);
    }
  }, [
    courseResponse,
    error,
    reset,
    courseDetails,
    whatIsInThisCourse,
    image,
    instructorImage,
  ]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <AddCourseInputField
          {...{
            register,
            errors,
            control,
            setCategory,
            setType,
            setSkillLevel,
            setCourseDetails,
            setWhatIsInThisCourse,
            customError,
            setCustomError,
            image,
            setImage,
            instructorImageExtra,
            setInstructorImageExtra,
            instructorImage,
            setInstructorImage,
            formType: "addForm",
          }}
        />
        <div className="max-w-xs">
          {customError[0].categoryError && (
            <Error message={customError[0].categoryError} />
          )}
          {customError[0]?.typeError && (
            <Error message={customError[0]?.typeError} />
          )}
          {customError[0]?.skillLevelError && (
            <Error message={customError[0]?.skillLevelError} />
          )}
          {customError[0]?.whatIsInThisCourseError && (
            <Error message={customError[0]?.whatIsInThisCourseError} />
          )}
          {customError[0]?.courseDetailsError && (
            <Error message={customError[0]?.courseDetailsError} />
          )}
          {customError[0]?.courseBannerError && (
            <Error message={customError[0]?.courseBannerError} />
          )}
          {customError[0]?.instructorImageError && (
            <Error message={customError[0]?.instructorImageError} />
          )}
        </div>
        <input
          type="submit"
          value={isLoading ? "Loading..." : "Submit"}
          className="bg-[#E41272] text-white w-[129px] h-[47px] rounded-[3px] border-[1px] border-[#3D66D7] mt-[18px] cursor-pointer font-medium hover:bg-opacity-80"
        />
      </form>
      <Toaster />
    </div>
  );
};

export default AddCourseForm;

import React from "react";
import { Link } from "react-router-dom";

export const ModifyKaziProfileCard = ({ kazi }) => {
  return (
    <>
      <div className="bg-[linear-gradient(110.19deg,#FB5A95_0%,#3494F9_0.01%,#3A3F8B_97.27%)] rounded-[8px] pl-[35px] py-[31px] text-white">
        <div className="flex gap-x-[24px]">
          <img
            className="w-[85px] h-[85px] rounded-full"
            src={kazi?.profilePhoto}
            alt="profile"
          />
          <div className="flex flex-col justify-start items-start gap-y-[18px]">
            <h1 className="text-[24px] leading-[29px] font-medium">
              {(kazi?.firstName ? kazi?.firstName : "") +
                " " +
                (kazi?.lastName ? kazi?.lastName : "")}
            </h1>
            <p className=" text-[20px] leading-[24px] font-medium">Age: 26</p>
            <Link
              to={`profile/${kazi?._id}`}
              className="w-[135px] h-[37px] mt-[20px] flex justify-center items-center bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] text-white rounded-[4px]"
            >
              Modify Profile
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

// configuration
import React, { useState } from "react";
import { useParams } from "react-router-dom";

// Third party package
import { Toaster } from "react-hot-toast";

// components
import { EditDescription } from "./EditDescription";
import { EditHeaderButton } from "./EditHeaderButton";
import { EditPrice } from "./EditPrice";
import { FullEditForm } from "./FullEditForm";
import { ProductImagesContainer } from "./ProductImagesContainer";
import { ProductNewPhotoUploadContainer } from "./ProductNewPhotoUploadContainer";
import { useGetSingleProductQuery } from "../../../../Redux/features/Shop/shopApi";
import { OvalLoader } from "../../../Shared/Loader/OvalLoader";
import useDocumentTitle from "../../../../helper/utilities/useDocumentTitle";

export const EditProduct = () => {
  const [isProductDeleted, setIsProductDeleted] = useState(false);
  const { id } = useParams();
  const { data: productInfo, isLoading } = useGetSingleProductQuery(id);
  const {
    _id,
    name,
    category,
    size,
    shiping,
    discount,
    price,
    description,
    stock,
    photos,
  } = productInfo?.product || {};

  useDocumentTitle("Admin | Product Edit");

  return (
    <div className="px-[45px] mt-[78px] mb-[236px]">
      {isProductDeleted ? (
        <div className="pt-5">
          <p className="text-gray-500 text-3xl capitalize">
            This product has been deleted.
          </p>
        </div>
      ) : isLoading ? (
        <OvalLoader title={"Loading..."} />
      ) : (
        <div>
          <EditHeaderButton {...{ _id, setIsProductDeleted, stock }} />
          <ProductImagesContainer {...{ _id, photos }} />
          <ProductNewPhotoUploadContainer {...{ _id }} />
          <EditPrice {...{ price, id }} />
          <EditDescription {...{ description, id }} />
          <FullEditForm
            {...{ name, category, size, shiping, discount, stock, id }}
          />
        </div>
      )}
      <Toaster />
    </div>
  );
};

// Configuration
import React, { useEffect, useState } from "react";

// Third party package
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";

// Components
import {
  useCreateQuizQuestionMutation,
  useUpdateQuizQuestionsMutation,
} from "../../../../../../Redux/features/Course/courseApi";
import Error from "../../../../../Shared/components/accordion/UI/Error/Error";

const AddQuestionForm = ({
  id,
  setQuestionFormVisible,
  contentForEdit,
  setQuestionEditFormVisible,
  formType,
}) => {
  const [options, setOptions] = useState([
    contentForEdit?.options ? contentForEdit?.options[0] : "",
    contentForEdit?.options ? contentForEdit?.options[1] : "",
    contentForEdit?.options ? contentForEdit?.options[2] : "",
    contentForEdit?.options ? contentForEdit?.options[3] : "",
  ]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const [createQuizQuestion, { data: questionResponse, isLoading, error }] =
    useCreateQuizQuestionMutation();
  const [
    updateQuizQuestion,
    {
      data: questionEditResponse,
      isLoading: questionEditLoading,
      error: questionEditError,
    },
  ] = useUpdateQuizQuestionsMutation();

  // handle option function
  const handleOptionChange = (index, value) => {
    const updatedOptions = [...options];
    updatedOptions[index] = value;
    setOptions(updatedOptions);
  };

  // Check valid options
  const isOptionValid = options.every((option) => Boolean(option));

  const onSubmit = async (data) => {
    if (formType === "editForm") {
      data.question = data.question || contentForEdit?.question;
      data.answer = data.answer || contentForEdit?.answer;
      data.options = options;
      await updateQuizQuestion({ id: contentForEdit?._id, data: data });
    } else {
      data.options = options;
      data.quiz = id;
      await createQuizQuestion(data);
    }
  };

  useEffect(() => {
    if (questionResponse) {
      toast.success("Question Created Successfully");
      reset();
      setOptions(["", "", "", ""]);
      setQuestionFormVisible(false);
    }
    if (questionEditResponse) {
      toast.success("Question Edited Successfully");
      reset();
      setOptions(["", "", "", ""]);
      setQuestionEditFormVisible(false);
    }
    if (error) {
      toast.error("Something went wrong. Please login or try again later");
      reset();
      setQuestionFormVisible(false);
    }
    if (questionEditError) {
      toast.error("Something went wrong. Please login or try again later");
      reset();
      setQuestionEditFormVisible(false);
    }
  }, [
    questionResponse,
    error,
    reset,
    setQuestionFormVisible,
    setQuestionEditFormVisible,
    questionEditResponse,
    questionEditError,
  ]);

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col items-start w-full gap-y-5"
      >
        {/* Question */}
        <div className="w-full flex flex-col">
          <label className="text-left mb-1 text-[12px] font-bold text-[#707276] ml-[5px]">
            Question
          </label>
          <input
            {...register(
              "question",
              formType !== "editForm" && {
                required: "Question is required",
              }
            )}
            className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-4 px-[12px]"
            type="text"
            placeholder="Question"
          />
          {errors?.question && <Error message={errors?.question?.message} />}
        </div>

        {/* Options */}
        {options.map((option, index) => (
          <div className="w-full flex flex-col">
            <label className="text-left mb-1 text-[12px] font-bold text-[#707276] ml-[5px]">
              Option {index + 1}
            </label>
            <input
              type="text"
              value={option}
              className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-4 px-[12px]"
              onChange={(event) =>
                handleOptionChange(index, event.target.value)
              }
              placeholder={`Option ${index + 1}`}
              required={formType !== "editForm"}
            />
          </div>
        ))}

        {/* Answer */}
        <div className="w-full flex flex-col">
          <label className="text-left mb-1 text-[12px] font-bold text-[#707276] ml-[5px]">
            Answer
          </label>
          <select
            {...register(
              "answer",
              formType !== "editForm" && {
                required: "Answer is required",
              }
            )}
            className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-4 px-[12px]"
            type="text"
          >
            <option disabled selected value="">
              Select Answer
            </option>
            {isOptionValid ? (
              options.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))
            ) : (
              <option disabled value="">
                Please, Provide all options.
              </option>
            )}
          </select>
          {errors?.answer && <Error message={errors?.answer?.message} />}
        </div>

        <input
          type="submit"
          value={isLoading || questionEditLoading ? "Loading..." : "Submit"}
          className="py-2 px-8 rounded-md font-Inter bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] text-white font-medium text-lg cursor-pointer hover:bg-[linear-gradient(166deg,rgb(148,45,217)_0%,rgb(242,40,118)_100%)]"
        />
      </form>
    </div>
  );
};

export default AddQuestionForm;

// Configuration
import React, { useState, useEffect } from "react";

// Third party package
import { Button } from "@mui/material";
import { pink } from "@mui/material/colors";
import { Collapse, Modal } from "antd";
import { CaretRightOutlined, ExclamationCircleFilled } from "@ant-design/icons";

// Components
import AddModuleForm from "../../AddCourseSection/AddModuleContainer/AddModuleForm/AddModuleForm";
import EditCourseContent from "../EditCourseContent/EditCourseContent";
import { useDeleteCourseModuleMutation } from "../../../../../Redux/features/Course/courseApi";
import { toast } from "react-hot-toast";

const EditCourseModule = ({ section }) => {
  const [moduleEditFormVisible, setModuleEditFormVisible] = useState(false);
  const [moduleForEdit, setModuleForEdit] = useState(null);

  const [deleteCourseModule, { data: response, error }] =
    useDeleteCourseModuleMutation();

  const handleModuleEditFormCancel = () => {
    setModuleEditFormVisible(false);
  };

  const handleEditModule = (module) => {
    setModuleEditFormVisible(true);
    setModuleForEdit(module);
  };

  const { confirm } = Modal;
  const showDeleteModuleConfirm = (id) => {
    confirm({
      title: "Do you Want to delete this module?",
      icon: <ExclamationCircleFilled />,
      okButtonProps: {
        style: {
          backgroundColor: "red",
        },
      },
      onOk() {
        deleteCourseModule(id);
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    if (response) {
      toast.success("Course Module Deleted");
    }
    if (error) {
      toast.error(
        <p>
          Failed to delete module. <br /> Please login or Try again later
        </p>
      );
    }
  }, [response, error]);

  const { Panel } = Collapse;
  return (
    <div className="px-6">
      {/* Show all module */}
      <div className="mb-4">
        <Collapse
          bordered={false}
          defaultActiveKey={["1"]}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          collapsible="icon"
        >
          {section?.modules?.map((module) => (
            <Panel
              header={
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-xl font-medium">{module?.name}</p>
                    <p className="">{module?.description}</p>
                  </div>
                  <div className="flex gap-x-2">
                    <Button
                      onClick={() => handleEditModule(module)}
                      sx={{
                        color: pink[500],
                        borderColor: pink[500],
                      }}
                      variant="outlined"
                      className="whitespace-nowrap"
                    >
                      Edit Module
                    </Button>
                    <Button
                      onClick={() => showDeleteModuleConfirm(module?._id)}
                      sx={{
                        color: pink[900],
                        borderColor: pink[900],
                      }}
                      variant="outlined"
                      className="whitespace-nowrap"
                    >
                      Delete Module
                    </Button>
                  </div>
                </div>
              }
              key={module?._id}
            >
              <EditCourseContent {...{ module }} />
            </Panel>
          ))}
        </Collapse>
      </div>

      {/* Edit module form modal */}
      <Modal
        title={<p className="text-xl">Edit Module</p>}
        open={moduleEditFormVisible}
        onCancel={handleModuleEditFormCancel}
        footer={null}
      >
        <AddModuleForm
          {...{
            moduleForEdit,
            setModuleEditFormVisible,
            formType: "editForm",
          }}
        />
      </Modal>
    </div>
  );
};

export default EditCourseModule;

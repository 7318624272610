import React, { useState, useEffect } from "react";

import HeaderImg from "../MarraigeCertificateVerification/HeaderImg/HeaderImg";
import ImgNumberIndicator from "../MarraigeCertificateVerification/ImgNumberIndicator/ImgNumberIndicator";
import VerificationButton from "../MarraigeCertificateVerification/VerificationButton/VerificationButton";

const MarriageCertificates = ({ selected }) => {
  const [selectedPhoto, setSelectedPhoto] = useState();

  useEffect(() => {
    if (selected?.certificates?.length !== 0) {
      setSelectedPhoto(selected?.certificates[0]);
    }
  }, [selected]);
  return (
    <div>
      <div className="font-poppins font-medium pt-[66px]">
        {/* Header Img */}
        {selectedPhoto && <HeaderImg {...{ selectedPhoto }} />}

        {/* Img number indicator */}
        {selected?.certificates?.length !== 0 && (
          <ImgNumberIndicator
            {...{
              photos: selected?.certificates,
              selectedPhoto,
              setSelectedPhoto,
            }}
          ></ImgNumberIndicator>
        )}
        {/* <div>No Certificate found</div> */}

        {/* Certificate banner images */}
        {selected?.certificates?.length !==0 && <div className="grid grid-cols-3 gap-[25px] w-full pb-[55px] font-poppins font-medium text-[18px] text-[#000000]">
          {selected?.certificates?.map((certificate, i) => (
            <div
              onClick={() => setSelectedPhoto(certificate)}
              key={certificate._id}
              className="h-[137px] w-full my-8"
            >
              <img
                className={`h-full w-full rounded-xl  ${
                  certificate?._id === selectedPhoto?._id
                    ? "border-2 border-[#E41272]"
                    : "border-0"
                }`}
                src={certificate?.photo}
                alt="certificate"
              />
              <p className="pt-[10px]">Certificate {i + 1}</p>
            </div>
          ))}
        </div>}
      </div>
      {/* verification buttons */}
      {selectedPhoto && <VerificationButton {...{ selected, selectedPhoto }} />}
    </div>
  );
};

export default MarriageCertificates;

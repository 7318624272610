// Configuration
import React from "react";

const AssignLawyerAllCard = ({ lawyer,selectedLawyer,setSelectedLawyer }) => {
  
  return (
    <div
      className="py-8 rounded-lg bg-white h-[222px] box-border"
      style={
        selectedLawyer === lawyer._id
          ? {
              backgroundImage:
                "linear-gradient(180deg, #E81CAF 0%, #FEE487 100%)",
              boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.12)",
              border: "8px solid",
              borderImageSlice: "1",
              borderImageSource:
                "linear-gradient(180deg, #E41272 0%, #A72CC7 100%)",
            }
          : {
              boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.12)",
            }
      }
    >
      <div className="flex justify-start items-center gap-6 px-9 mb-9">
        <img
          className="w-[85px] h-[85px] rounded-full"
          src={lawyer?.profilePhoto}
          alt=""
        />
        <div className={`${(selectedLawyer === lawyer._id) ? "text-white" : "text-black"} text-left`}>
          <h3 className="text-2xl leading-[29px] font-medium mb-4">
            {`${lawyer.firstName} ${lawyer.lastName}`}
          </h3>
          <p className="text-xl leading-6">Age : {lawyer.age}</p>
        </div>
      </div>
      <div className="flex justify-center items-center gap-6">
        {(selectedLawyer === lawyer._id) ? (
          <button
            className="text-base text-white leading-5 font-medium w-[169px] py-[9px] rounded-sm"
            style={{
              backgroundImage:
                "linear-gradient(90deg, #E22989 7.1%, #A72CC7 93.49%)",
            }}
            onClick={() => setSelectedLawyer("")}
          >
            Selected
          </button>
        ) : (
          <button
            className="text-base text-white leading-5 font-medium w-[169px] py-[9px] rounded-sm"
            style={{
              backgroundImage:
                "linear-gradient(90deg, #E22989 7.1%, #A72CC7 93.49%)",
            }}
            onClick={() => setSelectedLawyer(lawyer._id)}
          >
            Select
          </button>
        )}
      </div>
    </div>
  );
};

export default AssignLawyerAllCard;

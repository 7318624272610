// normal import
import React from "react";
import liveLinkGenerator from "../../../../helper/utilities/liveLinkGenerator";

const RestrictSuspendUserCard = ({ user }) => {
  return (
    <div>
      <div
        key={user.id}
        style={{
          background:
            "linear-gradient(97.77deg, #D52C79 21.61%, #973ED3 86.06%)",
        }}
        className="pt-2 pl-6 pr-9 pb-2 mb-6 rounded-lg font-SourceCodePro font-semibold text-base w-[717px]"
      >
        <div className="text-left text-sm pt-4">
          <div className="flex">
            <p className="font-normal text-white text-[13px]">
              End Date:{" "}
              {`${new Date(
                user?.suspendExpTime || user?.restrictionExpTime
              ).getDate()}/${
                new Date(
                  user?.suspendExpTime || user?.restrictionExpTime
                ).getMonth() + 1
              }/${new Date(
                user?.suspendExpTime || user?.restrictionExpTime
              ).getFullYear()}`}
            </p>
            <h1 className="font-semibold text-[12px] text-white ml-[420px] mt-[10px]">
              Action
            </h1>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex items-center gap-[6px] mt-[20px] pb-[30px]">
            <img
              // src="https://i.ibb.co/26QkDXq/image-3.png"
              src={
                user?.profilePhoto
                  ? liveLinkGenerator(user?.profilePhoto)
                  : "https://i.ibb.co/26QkDXq/image-3.png"
              }
              alt=""
              className="rounded-full h-6 w-6"
            ></img>
            <p className="font-normal text-sm text-white">
              {user.firstName} {user.lastName}
            </p>
          </div>
          <div className="flex items-center gap-[15px]">
            <div>
              <button className="bg-[#E41272] rounded py-[5px] px-[22px] text-sm text-[#FFFFFF]">
                {`${user?.suspended ? "Suspended" : "Restricted"}`}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RestrictSuspendUserCard;

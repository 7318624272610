// Configuration
import React from "react";

// Third party package
import { Collapse } from "antd";

// Components
import Modules from "./Modules";

// Third party packages
const { Panel } = Collapse;

const CourseOutline = ({ sections }) => {
  return (
    <div className="my-4">
      <Collapse accordion style={{ width: "100%" }}>
        {sections?.map((milestone) => {
          return (
            <Panel header={milestone.name} key={milestone?._id}>
              <div className="w-full flex flex-col">
                <Modules modules={milestone?.modules} />
              </div>
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
};
export default CourseOutline;

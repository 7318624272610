import React, { useState } from "react";
import WithdrawContainer from "./withdrawContainer";
import { Tabs } from "antd";
import {
  useGetAcceptedWithdrawRequestsQuery,
  useGetWithdrawRequestsQuery,
} from "../../../../../Redux/features/wallet/walletApi";

const WithdrawRequestPage = () => {
  const [page, setPage] = useState(1);
  const [acceptedPage, setAcceptedPage] = useState(1);
  const { data, isLoading } = useGetWithdrawRequestsQuery({
    page: page,
    limit: 20,
  });
  const { data: acceptedData, isLoading: acceptedLoading } =
    useGetAcceptedWithdrawRequestsQuery({ page: acceptedPage , limit: 20 });
  const items = [
    {
      key: "1",
      label: "Requests",
      children: (
        <WithdrawContainer
          {...{
            data,
            isLoading,
            setPage,
            title: "Withdraw Requests",
            useCase: "unaccepted",
          }}
        />
      ),
    },
    {
      key: "2",
      label: "Accepted Requests",
      children: (
        <WithdrawContainer
          {...{
            useCase: "accepted",
            data: acceptedData,
            isLoading: acceptedLoading,
            setPage: setAcceptedPage,
            title: "Accepted Withdraw Requests",
          }}
        />
      ),
    },
  ];
  return (
    <div className="px-4">
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};

export default WithdrawRequestPage;

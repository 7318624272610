// configuration
import React from "react";

export const DeliveredOrderShippingDetailes = () => {
    return (
        <div className="shipping__Container mt-[59px]">
            <p className="text-[#000000] text-[16px] font-bold font-OpenSans text-left">Shipping Address</p>
            <div className="flex flex-col items-start mt-[24px] gap-y-[20px]">
                <p className="text-[#4F4F4F] text-[15px] font-semibold font-OpenSans">Monir Hossain</p>
                <p className="text-[#4F4F4F] text-[15px] font-semibold font-OpenSans">raj021159@gmail.com</p>
                <div className="text-left">
                    <p className="text-[12px] text-[#828282] font-normal font-OpenSans">Street Address</p>
                    <p className="text-[#666666] text-[14px] font-normal font-SourceCodePro">Road number 20#4, Dhaka 1200, Dhaka</p>
                </div>
                <div className="w-full grid grid-cols-2 mt-[36px]">
                    <div className="w-full flex flex-col items-start">
                        <p className="text-[#828282] font-normal font-OpenSans text-[12px]">State/Province</p>
                        <p className="text-[15px] font-semibold font-OpenSans text-[#4F4F4F]">Gulshan</p>
                    </div>
                    <div className="w-full flex flex-col items-start">
                        <p className="text-[#828282] font-normal font-OpenSans text-[12px]">City</p>
                        <p className="text-[15px] font-semibold font-OpenSans text-[#4F4F4F]">Dhaka</p>
                    </div>
                </div>
                <div className="w-full grid grid-cols-2 mt-[36px]">
                    <div className="w-full flex flex-col items-start">
                        <p className="text-[#828282] font-normal font-OpenSans text-[12px]">Zip/Postal Code</p>
                        <p className="text-[15px] font-semibold font-OpenSans text-[#4F4F4F]">1200</p>
                    </div>
                    <div className="w-full flex flex-col items-start">
                        <p className="text-[#828282] font-normal font-OpenSans text-[12px]">Phone</p>
                        <p className="text-[15px] font-semibold font-OpenSans text-[#4F4F4F]">+880 1956306002</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Configuration
import React, { useState } from "react";

// Third party packages
import Pagination from "@mui/material/Pagination";
import SearchManyUsers from "../../../Shared/components/SearchUsers/SearchManyUsers";
import AssignAgentAllCard from "../../../Shared/Cards/Assign_Agent_Card/AssignAgentAllCard";
import AssignAgentToUserCard from "../../../Shared/Cards/Assign_Agent_Card/AssignAgentToUserCard";

const AssignAgent = () => {
  const [userPage, setUserPage] = useState(1);
  const [agentPage, setAgentPage] = useState(1);
  const [userObj, setUser] = useState();
  const [agentObj, setAgent] = useState();
  const [selectedAgent, setSelectedAgent] = useState("");

  return (
    <div className="px-8">
      <div className="mb-8">
        <SearchManyUsers setUser={setAgent} page={agentPage} role="agent" />
      </div>
      <section className="grid grid-cols-2 gap-[29px]">
        {agentObj?.data?.users?.map((agent) => (
          <AssignAgentAllCard  key={agent._id} agent={agent} selectedAgent={selectedAgent} setSelectedAgent={setSelectedAgent} />
        ))}
      </section>
      <div className="flex justify-between items-center my-[62px] ">
        <p className="text-[#3E4954] leading-6 font-medium">
          Showing {agentObj?.data?.users?.length} from {agentObj?.data?.total}{" "}
          data
        </p>
        <Pagination
          className="bg-[#E3E4EB] px-[1px] py-[3px] rounded-md text-white"
          count={Math.ceil(agentObj?.data?.total / 8) || 1}
          onChange={(event, pageNumber) => setAgentPage(pageNumber)}
          shape="rounded"
        />
      </div>
      {/* --- Assign Lawyer To User --- */}
      <h1 className="text-[40px] text-left leading-[48px] font-semibold mb-10">
        Assign Agent To User:
      </h1>
      <div className="mb-8">
        <SearchManyUsers setUser={setUser} page={userPage} role="member" />
      </div>
      <section className="grid grid-cols-2 gap-[29px]">
        {userObj?.data?.users?.map((user) => (
          <AssignAgentToUserCard key={user._id} user={user} selectedAgent={selectedAgent}/>
        ))}
      </section>
      <div className="flex justify-between items-center my-[62px] ">
        <p className="text-[#3E4954] leading-6 font-medium">
          Showing {userObj?.data?.users?.length} from {userObj?.data?.total}{" "}
          data
        </p>
        <Pagination
          className="bg-[#E3E4EB] px-[1px] py-[3px] rounded-md text-white"
          count={Math.ceil(userObj?.data?.total / 8) || 1}
          onChange={(event, pageNumber) => setUserPage(pageNumber)}
          shape="rounded"
        />
      </div>
    </div>
  );
};

export default AssignAgent;

// configuration
import React from "react";

export const DeliveredOrderDetailes = () => {
    return (
        <div className="mt-[35px] px-[22px]">
            <div>
                <h2 className="text-[#707276] text-[16px] font-OpenSans font-bold text-left uppercase">description</h2>
                <p className="text-[16px] font-normal font-OpenSans text-left text-[#000000] mt-[12px]">
                    Lorem ipsum dolor sit amet consectetur. Lobortis massa sapien cursus ut sed quisque adipiscing. Tellus vitae porta id amet amet
                    hendrerit eu tincidunt magnis. Sapien et turpis pretium justo consequat commodo. Porta ullamcorper viverra sit nisi. Nunc
                    pellentesque nisi nunc porttitor ipsum elementum cursus felis orci. Suspendisse non nisi et donec aliquam turpis congue semper
                    nunc. At platea in amet nisl mi eu massa neque tempus. Dolor sagittis pellentesque eu urna quam quam quam eu. Consequat amet
                    scelerisque ac laoreet neque adipiscing hendrerit sit libero. Dolor tortor pretium et sed.
                </p>
            </div>
            <div className="mt-[55px] w-full flex flex-col items-start gap-y-[32px] text-left">
                <div>
                    <h4 className="text-[#707276] font-bold text-[16px] font-OpenSans uppercase">Category</h4>
                    <p className="mt-[10px] text-[16px] text-[#000000] font-OpenSans font-normal">Listing</p>
                </div>
                <div>
                    <h4 className="text-[#707276] font-bold text-[16px] font-OpenSans uppercase">Shipping</h4>
                    <p className="mt-[10px] text-[16px] text-[#000000] font-OpenSans font-normal">100 BDT</p>
                </div>
                <div>
                    <h4 className="text-[#707276] font-bold text-[16px] font-OpenSans uppercase">Colors</h4>
                    <p className="mt-[10px] text-[16px] text-[#000000] font-OpenSans font-normal">Black</p>
                </div>
                <div>
                    <h4 className="text-[#707276] font-bold text-[16px] font-OpenSans uppercase">Size Style</h4>
                    <p className="mt-[10px] text-[16px] text-[#000000] font-OpenSans font-normal">CM</p>
                </div>
            </div>
        </div>
    );
};

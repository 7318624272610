import React from "react";

const PackageCard = () => {
  return (
    <div className="text-left w-96">
      <div className="rounded-lg bg-white p-6 shadow-md">
        <h2 className="mb-4 text-4xl font-semibold font-Inter text-gray-600">
          Gold Pack
        </h2>
        <h3 className="text-xl font-Inter text-gray-600 mb-4">
          <span className="text-6xl font-semibold font-playFair">99</span>
          $/month
        </h3>
        <ul className="list-disc pl-6 text-lg font-Inter text-gray-500">
          <li className="mb-2">Unlimited Chat Request</li>
          <li className="mb-2">Unlimited Swaps</li>
          <li className="mb-2">Unlimited Message</li>
          <li className="mb-2">Video Chat: 30 Minutes a Day</li>
          <li className="mb-2">Timeline Post</li>
          <li className="mb-2">Course Discount: 25%</li>
          <li className="mb-2">Upload Marriage Certificate</li>
          <li className="mb-2">Free Kazi Contact</li>
        </ul>
        <button className="mt-6 rounded bg-pink-500 px-4 py-2 font-medium text-white hover:bg-pink-600 text-lg font-Inter">
          Buy Pack
        </button>
      </div>
    </div>
  );
};

export default PackageCard;

import { Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { Oval } from "react-loader-spinner";
import {
  useAddBadgeToMemberMutation,
  useGetAllBadgesQuery,
} from "../../../../Redux/features/Badge/badgeApi";
import CustomAlert from "../../../Shared/components/UI/CustomAlert";
import { Modal } from "../../../Shared/Modal/Modal";

export const BadgeTableModal = ({ modalControll, userId, setUser }) => {
  const [selectedBadge, setSelectedBadge] = useState(null);
  const [open, setOpen] = useState(false);

  // Redux Api Call
  const { data: badges, isLoading, isError } = useGetAllBadgesQuery();
  const [addBadgeToMember, { isSuccess, isLoading: badgeSubmitLoader }] =
    useAddBadgeToMemberMutation();

  // JS Variable declaration
  const loaderArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  let content;
  if (isLoading) {
    content = (
      <div className="w-full grid grid-cols-5 gap-[10px]">
        {loaderArr?.map((item) => {
          return (
            <div
              key={item}
              class="w-[85px] h-[85px] bg-gray-200 rounded-full animate-pulse"
            ></div>
          );
        })}
      </div>
    );
  } else if (isError) {
    content = (
      <div className="h-[205px] w-full flex justify-center items-center">
        <p>Something went wrong!</p>
      </div>
    );
  } else if (!isLoading && badges?.data?.total === 0) {
    content = (
      <div className="h-[205px] w-full flex justify-center items-center">
        <p>No badges found!</p>
      </div>
    );
  } else if (!isLoading && badges?.data?.batch?.length > 0) {
    content = (
      <div className="w-full grid grid-cols-5 gap-[15px]">
        {badges?.data?.batch?.map((badge) => {
          const { _id, icon, name } = badge || {};
          return (
            <div
              key={_id}
              className="w-[80px] flex flex-col items-center cursor-pointer justify-center"
              onClick={() => setSelectedBadge(badge)}
            >
              <img
                src={icon}
                alt="badge"
                className={`w-[65px] h-[65px] rounded-full ${
                  _id === selectedBadge?._id && "border-[3px] border-green-400"
                }`}
                onClick={() => setSelectedBadge(badge)}
              />
              <h3 onClick={() => setSelectedBadge(badge)}>{name}</h3>
            </div>
          );
        })}
      </div>
    );
  }

  const submitBadge = () => {
    addBadgeToMember({
      id: userId,
      badgeId: selectedBadge._id,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      // modalControll();

      setOpen(true);
      setTimeout(() => {
        modalControll();
        setUser(null);
      }, 3000);
    }
  }, [isSuccess, modalControll, setUser]);
  return (
    <>
      <Modal modalControll={modalControll}>
        <div className="w-full flex justify-between items-center">
          <h3 className="text-semibold text-[#32353B] text-[28px] font-poppins text-left">
            Badges
          </h3>
          <RxCross2
            className="text-[25px] cursor-pointer"
            onClick={modalControll}
          />
        </div>
        <div>{content}</div>
        <Divider />
        <div className="w-full flex justify-end">
          <button
            className={`w-[158px] h-[55px] rounded-[96px] text-white bg-[linear-gradient(90deg,_#CC1A7A_0%,_#A72CC7_95.57%)] text-[24px] font-bold gap-x-[5px] font-Inter flex justify-center items-center ${
              isLoading ? "cursor-wait" : "cursor-pointer"
            }`}
            onClick={submitBadge}
          >
            {badgeSubmitLoader ? (
              <>
                <p>Saving</p>
                <Oval
                  height={30}
                  width={30}
                  color="#FFFFFF"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#808080"
                  strokeWidth={4}
                  strokeWidthSecondary={4}
                />
              </>
            ) : (
              "Add"
            )}
          </button>
        </div>
      </Modal>
      {open && (
        <CustomAlert
          {...{ open, setOpen, message: "Badge added successfully" }}
        />
      )}
    </>
  );
};

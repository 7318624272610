import React from "react";
import { AiOutlineWarning } from "react-icons/ai";

const ErrorMessage = () => {
  return (
    <div className="w-full flex justify-center items-center min-h-[300px]">
      <div className="flex flex-col items-center justify-center mt-[100px]">
        <AiOutlineWarning className="text-[42px] text-gray-400" />
        <p className="mt-[10px] text-[16px] text-center font-Inter font-medium text-gray-500">
          An Error occurred! <br /> Please login or try again later!
        </p>
      </div>
    </div>
  );
};

export default ErrorMessage;

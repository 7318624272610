import React, { useState } from "react";
import { AllRatingsPageForEdit } from "../../../Shared/components/LawyerAgentKaziServices/ModifyReviewRatings/AllRatingsPageForEdit";
import { AllServicesOfReviewsRatings } from "../../../Shared/components/LawyerAgentKaziServices/ModifyReviewRatings/AllServicesOfReviewsRatings";
import UserPlanHeader from "../../User/UserPlanHeader";

/* Components */

const ModifyReviewAndRatingsForAgent = () => {
    // hook variable declaration
    const [user, setUser] = useState(null);
    const [serviceID, setServiceID] = useState("");
    return (
        <div>
            <div className="max-w-[500px] mx-auto">
                <UserPlanHeader setUser={setUser} user={user} role="agent" />
            </div>
            {user && <AllServicesOfReviewsRatings userID={user?._id} setServiceID={setServiceID} setUser={setUser} />}
            {serviceID && <AllRatingsPageForEdit serviceID={serviceID} />}
        </div>
    );
};

export default ModifyReviewAndRatingsForAgent;

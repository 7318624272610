import React, { useState } from "react";
import { AiOutlineWarning } from "react-icons/ai";

// Components
import { FiEdit } from "react-icons/fi";
import { MdOutlineRecommend } from "react-icons/md";
import DeleteCategoriesModal from "../../../../Shared/Cards/Shop/ShopCategories/DeleteCategoriesModal";
import { OvalLoader } from "../../../../Shared/Loader/OvalLoader";

const DeleteCategories = ({
  allCategories,
  allCourseTypes,
  categoriesDataError,
  courseTypesError,
  categoriesDataLoading,
  courseTypesLoading,
  title,
}) => {
  const [deleteCategoriesOpen, setDeleteCategoriesOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();

  const showDeleteCategoriesModal = (id) => {
    setDeleteCategoriesOpen(true);
    setSelectedCategory(id);
  };
  const handleDeleteCategoriesCancel = () => {
    setDeleteCategoriesOpen(false);
  };

  let content;
  if (categoriesDataLoading || courseTypesLoading) {
    content = <OvalLoader title={"Loading..."} />;
  } else if (categoriesDataError || courseTypesError) {
    content = (
      <div className="flex flex-col items-center justify-center mt-[100px]">
        <AiOutlineWarning className="text-[42px] text-gray-400" />
        <p className="mt-[10px] text-[16px] font-Inter font-medium text-gray-500">
          An Error occurred! <br /> Please login or try again later!
        </p>
      </div>
    );
  } else if (title !== "courseTypes" && allCategories?.category?.length === 0) {
    content = (
      <div className="flex flex-col items-center justify-center mt-[100px]">
        <MdOutlineRecommend className="text-[42px] text-gray-400" />
        <p className="mt-[10px] text-[16px] font-Inter font-medium text-gray-500">
          No Category Found!
        </p>
      </div>
    );
  } else if (title === "courseTypes" && allCourseTypes?.types?.length === 0) {
    content = (
      <div className="flex flex-col items-center justify-center mt-[100px]">
        <MdOutlineRecommend className="text-[42px] text-gray-400" />
        <p className="mt-[10px] text-[16px] font-Inter font-medium text-gray-500">
          No Types Found!
        </p>
      </div>
    );
  } else if (title !== "courseTypes" && allCategories?.category?.length > 0) {
    content = (
      <div className="flex items-center gap-5 flex-wrap">
        {allCategories?.category?.map((category) => (
          <div key={category?._id}>
            <button
              className="flex items-center justify-center gap-2 text-xs leading-4 font-bold font-Inter text-[#A72CC7] py-4 px-6 border border-[#A72CC7] rounded-tr-full rounded-br-full min-w-[160px]"
              onClick={() => showDeleteCategoriesModal(category?._id)}
            >
              <p>{category?.name}</p> <FiEdit />
            </button>
          </div>
        ))}
      </div>
    );
  } else if (title === "courseTypes" && allCourseTypes?.types?.length > 0) {
    content = (
      <div className="flex items-center gap-5 flex-wrap">
        {allCourseTypes?.types?.map((type) => (
          <div key={type?._id}>
            <button
              className="flex items-center justify-center gap-2 text-xs leading-4 font-bold font-Inter text-[#A72CC7] py-4 px-6 border border-[#A72CC7] rounded-tr-full rounded-br-full min-w-[160px]"
              onClick={() => showDeleteCategoriesModal(type?._id)}
            >
              <p>{type?.name}</p> <FiEdit />
            </button>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="mb-32">
      <h2 className="text-start text-[#272727] text-3xl leading-8 font-semibold font-playFair mb-10">
        Delete {title === "courseTypes" && "Types"}{" "}
        {title === "courseCategory" && "Categories"}
        {title === "shopCategory" && "Categories"}
      </h2>
      <div>{content}</div>
      {/* Edit categories modal */}
      <DeleteCategoriesModal
        {...{
          deleteCategoriesOpen,
          handleDeleteCategoriesCancel,
          setDeleteCategoriesOpen,
          selectedCategory,
          title,
        }}
      />
    </div>
  );
};

export default DeleteCategories;

// Configuration
import React from "react";

// Components
import useDocumentTitle from "../../../../helper/utilities/useDocumentTitle";
import DeliveredOrder from "./DeliveredOrder/DeliveredOrder";
import OngoingOrder from "./OngoingOrder/OngoingOrder";
import OrderHistory from "./OrderHistory/OrderHistory";

const ShopOrders = () => {
  // Page Title
  useDocumentTitle("Admin | Order");
  return (
    <div>
      {/* Ongoing orders */}
      <OngoingOrder />

      {/* Delivered orders */}
      <DeliveredOrder />

      {/* Order history */}
      <OrderHistory />
    </div>
  );
};

export default ShopOrders;

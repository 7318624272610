// configuration
import React from "react";

export const DeliveredOrderBy = () => {
    return (
        <>
            <div className="px-[24px] py-[7px] w-full flex flex-col items-start text-[#000000] font-SourceCodePro gap-y-[8px]">
                <p className="text-[32px] font-medium">Order by user #jhon658</p>
                <p className="text-[#666666] text-[24px] font-normal font-SourceCodePro">10-06-2023</p>
            </div>
            <div className="w-full flex justify-start mt-[40px] px-[24px] mb-[17px]">
                <button className="uppercase rounded-[100px] w-[342px] h-[80px]  text-white bg-[linear-gradient(137.27deg,_#33C04A_19.41%,_#0E881A_65.49%)] shadow-[0px_4px_4px_rgba(14,53,191,0.25)] text-[24px] font-semibold font-Inter">
                    Delivered
                </button>
            </div>
            <div className="mt-[8px]">
                <img
                    src="https://images.pexels.com/photos/7435900/pexels-photo-7435900.jpeg?auto=compress&cs=tinysrgb&w=1600"
                    alt="productImage"
                    className="w-full h-[485px]"
                />
            </div>
        </>
    );
};

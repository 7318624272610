import React from "react";
import { NavLink } from "react-router-dom";

const LawyerNav = ({ children }) => {
  const routes = [
    {
      id: 1,
      path: "addLawyer",
      name: "Add Lawyer",
    },
    {
      id: 2,
      path: "registrationRequest",
      name: "View Registration Request",
    },
    {
      id: 3,
      path: "allLawyers",
      name: "View All Lawyers",
    },
    {
      id: 4,
      path: "assignLawyer",
      name: "Assign Lawyer",
    },
    {
      id: 5,
      path: "modifyCaseStudy",
      name: "Modify Case Study",
    },
    {
      id: 6,
      path: "reviewRatings",
      name: "Modify Review & Ratings",
    },
    {
      id: 7,
      path: "lawyerProfile",
      name: "Modify Lawyer Profile",
    },
    {
      id: 8,
      path: "caseHistory",
      name: "Modify Case History",
    },
    {
      id: 9,
      path: "lawyerServices",
      name: "View Lawyer Services",
    },
    {
      id: 10,
      path: "clientChat",
      name: "View Lawyer-Client Chat",
    },
    {
      id: 11,
      path: "documentsVerification",
      name: "Documents Verification",
    },
    {
      id: 12,
      path: "payment",
      name: "Payment",
    },
  ];

  return (
    <div className="px-5 mx-auto min-h-screen">
      <div className="pt-10 mb-10">
        <ul className="grid grid-cols-3 gap-[41px] font-poppins font-normal text-base text-[#000000]">
          {routes.map((route) => (
            <NavLink
              key={route.id}
              className={({ isActive }) =>
                `w-full  text-center py-[5px] rounded cursor-pointer text-[15px] font-semibold ${
                  isActive
                    ? " text-white bg-[#E41272]"
                    : "text-[#000000] bg-[#FFFFFF]"
                }`
              }
              to={route.path}
            >
              {route.name}
            </NavLink>
          ))}
        </ul>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default LawyerNav;

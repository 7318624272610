// configuration
import React, { useState } from "react";

// Third party packages
import Pagination from "@mui/material/Pagination";
import { AiOutlineWarning } from "react-icons/ai";
import { MdOutlineRecommend } from "react-icons/md";

// Components
import { useGetUsersActivityQuery } from "../../../../Redux/features/User/userApi";

const ActivitiesTable = ({ selectedId: id }) => {
  const [page, setPage] = useState(1);
  const { data, isLoading, error } = useGetUsersActivityQuery({
    id,
    limit: 10,
    page: page,
  });

  const getDate = (string) => {
    const date = new Date(Date.parse(string));
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  let content;
  if (id === "") {
    content = (
      <div className="flex flex-col items-center justify-center mt-10">
        <MdOutlineRecommend className="text-[42px] text-gray-400" />
        <p className="mt-[10px] text-[16px] font-Inter font-medium text-gray-500 mb-10 capitalize">
          Please select a user to see his activity.
        </p>
      </div>
    );
  } else if (isLoading) {
    content = (
      <div className="w-full px-10 py-20">
        <div className="bg-white shadow rounded-md p-4 max-w-sm w-full mx-auto">
          <div className="animate-pulse flex space-x-4">
            <div className="flex-1 space-y-6 py-1">
              <div className="space-y-3">
                <div className="grid grid-cols-3 gap-4">
                  <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                  <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </div>
                <div className="h-2 bg-slate-200 rounded"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (id !== "" && error) {
    content = (
      <div className="flex flex-col items-center justify-center mt-[100px]">
        <AiOutlineWarning className="text-[42px] text-gray-400" />
        <p className="mt-[10px] text-[16px] font-Inter font-medium text-gray-500 mb-10">
          An Error occurred! <br /> Please login or try again later!
        </p>
      </div>
    );
  } else if (!error && data?.data?.activities?.length === 0) {
    content = (
      <div className="flex flex-col items-center justify-center mt-[100px]">
        <MdOutlineRecommend className="text-[42px] text-gray-400" />
        <p className="mt-[10px] text-[16px] font-Inter font-medium text-gray-500 mb-10">
          No Activities Found!
        </p>
      </div>
    );
  } else if (!error && data?.data?.activities?.length > 0) {
    content = (
      <div>
        {data?.data?.activities?.map((act) => (
          <div key={act?._id} className="grid grid-cols-12">
            <div className="flex justify-start items-start gap-8 col-span-9 border-b py-2">
              <p className="w-52 text-left">{act?._id}</p>
              <p className="text-left">{act?.activity}</p>
            </div>
            <div className="col-span-1"></div>
            <p className="col-span-2 text-right border-b">
              {getDate(act?.updatedAt)}
            </p>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div>
      <div className="bg-[#D9D9D9] px-3">
        <h2 className="py-9 text-2xl leading-6 font-semibold">Activity</h2>
        <div className="grid grid-cols-12 text-[#6E7C87]">
          <div className="flex justify-start items-center gap-8 col-span-9 border-b py-2">
            <p className="w-52 text-left">ID</p>
            <p className="text-left">Action</p>
          </div>
          <div className="col-span-1"></div>
          <p className="col-span-2 text-right border-b">Date</p>
        </div>
        <div>{content}</div>
      </div>
      <div className="my-12">
        {!error && data?.data?.activities?.length > 0 && (
          <div className="flex justify-around items-center">
            <p className="leading-6 font-medium">
              Showing {data?.data?.activities?.length} from {data?.data?.total}{" "}
              data
            </p>
            <Pagination
              className="bg-[#E3E4EB] px-[1px] py-[3px] rounded-md text-white"
              onChange={(event, value) => {
                setPage(value);
              }}
              count={Math.ceil(data?.data?.total / 10) || 1}
              shape="rounded"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ActivitiesTable;

import React from 'react'
import { ageCalculator } from '../../../../helper/utilities/ageCalculator'

export default function RecentUserCard({ user }) {
    const {firstName, dateOfBirth,_id, profilePhoto} = user?.user || {}
    return (
        <div className='py-6 text-white rounded-xl bg-[#1B6B67]' key={_id}>
            {/* <p className='flex justify-center mb-1 text-xl font-normal'>priority for {user.priorityFor}</p> */}
            <div className='mb-6 px-10 flex items-center'>
                <div className='mr-4'>
                    <img src={profilePhoto} className='w-[85px] h-[85px] rounded-full' alt="" />
                </div>
                <div>
                    <h1 className='text-2xl font-medium'>{firstName}</h1>
                    <p className='text-xl font-normal'>Age:{ageCalculator(dateOfBirth)}</p>
                </div>
            </div>
            <div className='flex justify-center'>
                <button className='px-6 py-1 rounded bg-[#E16B28] text-white' >cancel</button>
            </div>
        </div>
    )
}

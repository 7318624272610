// Configuration
import React from "react";
import { useNavigate } from "react-router-dom";

// Third party package
import { BsCircleFill } from "react-icons/bs";

// Components
import liveLinkGenerator from "../../../../../helper/utilities/liveLinkGenerator";

const SingleCaseCard = ({ singleCase, role }) => {
  let { details } = singleCase || {};
  details = details?.length > 144 ? details.slice(0, 144) + "..." : details;

  const navigate = useNavigate();

  // decision about dynamic route
  let dynamicRoute = "";
  if (role === "lawyer") {
    dynamicRoute = `/lawyer/editCase/${singleCase?._id}`;
  }
  if (role === "agent") {
    dynamicRoute = `/agent/editCase/${singleCase?._id}`;
  }
  if (role === "kazi") {
    dynamicRoute = `/kazi/editCase/${singleCase?._id}`;
  }

  return (
    <div
      style={{
        background:
          "linear-gradient(180deg, #EEE6FF 0%, #FFDAE3 0.01%, #F4E6FF 5.73%, #FFF2E6 100%)",
      }}
      className="pt-6 pl-6 pr-9 pb-2 mb-6 rounded-xl font-SourceCodePro font-semibold text-base w-[617px] mx-auto"
    >
      <img
        src={liveLinkGenerator(singleCase?.certificate)}
        className="w-full h-[258px] rounded-[12px]"
        alt="case"
      />
      <div className="text-left text-sm">
        <h1 className="font-medium text-lg text-[#000000] my-4">
          {singleCase?.name}
        </h1>
        <p className="font-normal text-[#666666]">{details}</p>
        <h5 className="text-[#000000] my-4">{singleCase?.period}</h5>
      </div>
      <div className="flex justify-between">
        <div className="flex items-center gap-[6px]">
          <BsCircleFill className="text-base text-[#DEDEDE]" />
          <p className="font-normal text-sm text-[#666666]">
            {singleCase?.court}
          </p>
        </div>
        <div className="flex items-center gap-[15px]">
          <button
            className="bg-[#E41272] rounded py-[5px] px-[22px] text-sm text-[#FFFFFF]"
            onClick={() => navigate(dynamicRoute)}
          >
            Edit
          </button>
        </div>
      </div>
    </div>
  );
};

export default SingleCaseCard;

import getCookie from "../../../helper/cookies/getCookie";
import { apiSlice } from "../../api/apiSlice";

export const chatApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllMessage: builder.query({
      query(body) {
        return {
          url: `/inbox/messages?from=${body.from}&to=${body.to}`,
          headers: {
            authorization: `Bearer ${getCookie("token")}`,
          },
        };
      },
    }),
  }),
});

export const { useGetAllMessageQuery } = chatApi;

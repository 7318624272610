import React from "react";

const SingleSupportTicketDetailsBody = ({ ticket }) => {
  const { replies, message, images: requestor } = ticket || {};

  let content;
  if (replies?.length === 0) {
    content = (
      <div className="flex items-start pt-6">
        <img
          className="w-[40px] h-[40px] rounded-full mr-5"
          src={requestor?.profilePhoto}
          alt="profile"
        />
        <div>
          <p className="bg-gray-100 p-[10px] rounded-[4px] text-left">
            {message}
          </p>
          <p className="text-gray-400 text-[12px] text-left pl-3">11:30 PM</p>
        </div>
      </div>
    );
  } else if (replies?.length > 0) {
    content = (
      <div className="mt-[20px]">
        <div>
          <div className="flex items-start pt-3 pb-1">
            <img
              className="w-[40px] h-[40px] rounded-full mr-5"
              src={requestor?.profilePhoto}
              alt="profile"
            />
            <div>
              <p className="bg-gray-100 p-[10px] rounded-[4px] text-left">
                {message}
              </p>
              <p className="text-gray-400 text-[12px] text-left pl-3">
                11:30 PM
              </p>
            </div>
          </div>
        </div>
        {replies?.map((item) => {
          const { message, role, _id } = item || {};
          if (!role.includes("admin")) {
            return (
              <div key={_id} className="flex items-start py-1">
                <img
                  className="w-[40px] h-[40px] rounded-full mr-5"
                  src={requestor?.profilePhoto}
                  alt="profile"
                />
                <div>
                  <p className="bg-gray-100 p-[10px] rounded-[4px]">
                    {message}
                  </p>
                  <span className="text-gray-400 text-[12px]">11:30 PM</span>
                </div>
              </div>
            );
          } else if (role.includes("admin")) {
            return (
              <div
                key={_id}
                className="flex justify-end items-start gap-x-[20px] py-1"
              >
                <div>
                  <p className="bg-slate-50 p-[10px] rounded-[4px]">
                    {message}
                  </p>
                  <p className="text-gray-400 text-[12px] text-right pr-3">
                    11:30 PM (Admin)
                  </p>
                </div>
                <img
                  className="w-[40px] h-[40px] rounded-full"
                  src="https://png.pngtree.com/png-clipart/20220719/original/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_8385663.png"
                  alt="profile"
                />
              </div>
            );
          } else {
            return false;
          }
        })}
      </div>
    );
  }
  return (
    <div className="min-h-[500px] mt-[20px] bg-white shadow-[rgba(0,0,0,0.05)_0px_6px_24px_0px,_rgba(0,0,0,0.08)_0px_0px_0px_1px] w-full overflow-y-auto px-[12px] rounded-[8px]">
      {content}
    </div>
  );
};

export default SingleSupportTicketDetailsBody;

import React from "react";
import { useNavigate } from "react-router-dom";

export const BackToList = () => {
    const navigate = useNavigate();
    return (
        <div>
            <div className="lawyer-certificate w-48  mt-2 rounded-xl mb-12">
                <p className="pt-[10px] pb-[10px] cursor-pointer" onClick={() => navigate(-1)}>
                    Back To List
                </p>
            </div>
        </div>
    );
};

import React from "react";
import { Link } from "react-router-dom";

// Components
import { ageCalculator } from "../../../../helper/utilities/ageCalculator";

export const ViewAllKaziCard = ({ kazi }) => {
  const age = ageCalculator(kazi?.dateOfBirth);
  return (
    <div className="py-10 rounded-lg bg-[linear-gradient(81.5deg,#FFF6B9_0.62%,#F6486D_91.79%)] shadow-[2px_2px_10px_rgba(0,0,0,0.12)]">
      <div className="flex justify-start items-center gap-6 px-9 mb-9">
        <img
          className="w-[85px] h-[85px] rounded-full"
          src={kazi?.profilePhoto}
          alt="profile"
        />
        <div className="text-left">
          <h3 className="text-2xl leading-[29px] font-medium mb-4">
            {(kazi?.firstName ? kazi?.firstName : "") +
              " " +
              (kazi?.lastName ? kazi?.lastName : "")}
          </h3>
          <p className="text-xl leading-6">
            Age : {kazi?.age ? kazi?.age : age ? age : ""}
          </p>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <Link
          to={`profile/${kazi?._id}`}
          className="text-base text-white leading-5 font-medium w-[135px] py-[9px] rounded-sm mr-2"
          style={{
            backgroundImage:
              "linear-gradient(180deg, #E22989 0%, #A72CC7 100%)",
          }}
        >
          View Profile
        </Link>
      </div>
    </div>
  );
};

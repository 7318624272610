// Configuration
import React from "react";

// Third party packages
import { AiOutlinePlayCircle } from "react-icons/ai";

const Contents = ({ contents }) => {
  return (
    <div>
      {contents?.map((content) => {
        return (
          <div
            className={`flex items-center py-[4px] gap-x-[6px] hover:bg-gray-100 px-[6px] cursor-pointer rounded-[4px]}`}
            key={content?._id}
          >
            <AiOutlinePlayCircle className="text-[20px]" />
            <div className="flex flex-col font-outfit">
              <h4 className="text-[14px]">{content?.name}</h4>
              <span className="text-[11px] leading-3">{content?.video}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default Contents;

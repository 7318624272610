import { createSlice } from "@reduxjs/toolkit";

const initialState = { user: "", gender: "", isLifeTime: false, expiryDate: "" };

const priorityUserSlice = createSlice({
    name: "registrationRequest",
    initialState,
    reducers: {
        setUserInfo: (state = initialState, action) => {
            state.user = action.payload.user;
            state.gender = action.payload.gender;
        },
        setLifeTimeMarked: (state = initialState, action) => {
            state.isLifeTime = action.payload;
        },
        setExpiryDate: (state = initialState, action) => {
            state.expiryDate = action.payload.date;
        },
        setResetMarkPriority: (state = initialState, action) => {
            state.user = "";
            state.gender = "";
            state.isLifeTime = false;
            state.expiryDate = "";
        },
    },
});

export const { setUserInfo, setLifeTimeMarked, setExpiryDate, setResetMarkPriority } = priorityUserSlice.actions;
export default priorityUserSlice.reducer;

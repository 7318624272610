// configuration
import React, { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-hot-toast";

// Third party packagees
import { AiOutlineSave } from "react-icons/ai";
import { RxCrossCircled } from "react-icons/rx";
import {
  useDeleteSingleProductImageMutation,
  useUpdatePrimaryImageMutation,
} from "../../../../Redux/features/Shop/shopApi";

export const ProductImagesContainer = ({ _id, photos }) => {
  const [selectedImage, setSelectedImage] = useState(photos[0]);

  const [
    deleteSingleProductImage,
    {
      data: deletePhotoResponse,
      isLoading: deletePhotoLoading,
      error: deletePhotoError,
    },
  ] = useDeleteSingleProductImageMutation();
  const [
    updatePrimaryImage,
    {
      data: primaryImageResponse,
      isLoading: primaryImageLoading,
      error: primaryImageError,
    },
  ] = useUpdatePrimaryImageMutation();

  const handlePhotoRemove = async () => {
    await deleteSingleProductImage({
      id: _id,
      data: { photoId: selectedImage?._id },
    });
  };
  const handlePrimaryImage = async () => {
    await updatePrimaryImage({
      id: _id,
      data: { photoId: selectedImage?._id },
    });
  };

  useEffect(() => {
    if (deletePhotoResponse) {
      toast.success("Photo Removed Successfully");
      setSelectedImage(photos[0]);
    }
    if (deletePhotoError) {
      toast.error("Something went wrong. Please try again later");
    }
    if (primaryImageResponse) {
      toast.success("Photo Successfully Saved as Primary Image");
      setSelectedImage(photos[0]);
    }
    if (primaryImageError) {
      toast.error("Something went wrong. Please try again later");
    }
  }, [
    deletePhotoError,
    deletePhotoResponse,
    photos,
    primaryImageError,
    primaryImageResponse,
  ]);

  return (
    <div className="mt-[40px]">
      {selectedImage !== "" && (
        <img
          className="w-full h-[595px] rounded-[4px]"
          src={selectedImage?.url}
          alt=""
        />
      )}

      <div className="mt-[53px]">
        <h1 className="text-[#000000] font-bold font-Inter text-[32px] text-left">
          Select Primary Image
        </h1>
        <div className="mt-[41px] flex items-center gap-x-[16px]">
          {photos?.map((photo) => {
            return (
              <img
                src={photo?.url}
                key={photo?._id}
                className={`w-[127px] h-[84px] rounded-[4px] cursor-pointer ${
                  photo?._id === selectedImage?._id &&
                  "border-[2px] border-[#E41272]"
                }`}
                onClick={() => setSelectedImage(photo)}
                alt="product"
              />
            );
          })}
        </div>
        <div className="mt-[27px] w-full flex justify-start gap-x-[8px]">
          <button
            onClick={() => handlePrimaryImage()}
            className="flex items-center justify-center w-[118px] h-[48px] bg-[linear-gradient(90deg,#E41272_0%,_#A72CC7_100%)] rounded-[100px] gap-x-[10px] text-white tracking-[1.25px] font-Inter font-semibold text-[16px]"
          >
            {primaryImageLoading ? (
              "Loading..."
            ) : (
              <>
                SAVE <AiOutlineSave className="text-[16px]" />
              </>
            )}
          </button>
          <button
            onClick={() => handlePhotoRemove()}
            className="flex items-center justify-center w-[146px] h-[48px] bg-[linear-gradient(90deg,#E41272_0%,_#A72CC7_100%)] rounded-[100px] gap-x-[10px] text-white tracking-[1.25px] font-Inter font-semibold text-[16px]"
          >
            {deletePhotoLoading ? (
              "Loading..."
            ) : (
              <>
                REMOVE <RxCrossCircled className="text-[16px]" />
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

// configuration
import React, { useEffect, useState } from "react";

// Third party packages
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

// Components
import { useRegisterUserMutation } from "../../../../Redux/features/User/userApi";

// Components
import AddForm from "../../../Shared/components/AddForm/AddForm";

const AddLawyer = () => {
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [hometown, setHometown] = useState("");

  const navigate = useNavigate();
  const url = new URL(window.location.href);
  const pathname = url.pathname;
  const parts = pathname.split("/");
  const lastPart = parts[parts.length - 1];
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // Redux api
  const [registerUser, { data: response, error, isLoading }] =
    useRegisterUserMutation();

  // form submit
  const onSubmit = async (data) => {
    if (!dateOfBirth) {
      toast.error("Date of Birth is required");
      return;
    } else {
      data.dateOfBirth = dateOfBirth;
    }
    if (!hometown) {
      toast.error("Hometown is required");
      return;
    } else {
      data.hometown = hometown;
    }

    data = {
      ...data,
      designation: "lawyer",
      role: "lawyer",
      profile: "lawyerAccess",
    };
    await registerUser(data);
  };
  useEffect(() => {
    if (lastPart === "lawyer") {
      navigate("/lawyer/addLawyer");
    }
  }, [lastPart, navigate]);

  useEffect(() => {
    if (response) {
      toast.success(response?.message);
      setDateOfBirth("");
      setHometown("");
      reset();
    }
    if (error) {
      toast.error(error?.data?.message);
    }
  }, [response, error, reset]);

  return (
    <div className="font-Inter text-sm">
      {/* Add Lawyer Form */}
      <div className="w-[523px] text-left mx-auto mt-10 px-[21px] py-[31px] rounded-[10px] shadow bg-[#FFFFFF]">
        <AddForm
          {...{
            register,
            handleSubmit,
            onSubmit,
            errors,
            isLoading,
            setHometown,
            setDateOfBirth,
            dateOfBirth,
          }}
        />
      </div>
      <Toaster />
    </div>
  );
};

export default AddLawyer;

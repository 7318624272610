// normal import
import React, { useState } from "react";

// configuration
import { useNavigate } from "react-router-dom";

// Third Party Packages
import { Input } from "antd";
import { AiOutlineWarning } from "react-icons/ai";
import { FaUsersSlash } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";

// components
import { Pagination } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useGetAllRegistrationRequestsQuery } from "../../../../../Redux/features/User/registrationRequestApi";
import { setSearchTerm } from "../../../../../Redux/features/User/userFilter/userFilter";
import { RegistrationRequestCard } from "../../../../Shared/Cards/Registration__Request__Card/RegistrationRequestCard";
import { RegistrationRequestLoader } from "../../../../Shared/Loader/User/RegistrationRequestLoader";

export const RegistrationRequestAdmin = () => {
    const [customSearchTerm, setCustomSearchTerm] = useState("");
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const searchTerm = useSelector(state => state?.filter?.searchTerm);

    const { data: registration, isLoading, error } = useGetAllRegistrationRequestsQuery({ page, searchTerm,limit:8, role:"member"});

    // pagination
    const count = Math.ceil(registration?.data?.total / 8);

    let content;
    if (isLoading) {
        content = (
            <div className="grid grid-cols-2 gap-8 gap-y-[40px] mt-[24px]">
                <RegistrationRequestLoader />
                <RegistrationRequestLoader />
                <RegistrationRequestLoader />
                <RegistrationRequestLoader />
            </div>
        );
    } else if (!isLoading && registration?.data?.users.length === 0) {
        content = (
            <div className="flex flex-col items-center justify-center mt-[10%]">
                <FaUsersSlash className="text-[48px] text-gray-400" />
                <p className="mt-[10px] text-[22px] font-Inter font-medium text-gray-500">No User Request Found!</p>
            </div>
        );
    } else if (!isLoading && registration) {
        content = (
            <div className="mt-[24px] grid grid-cols-2 gap-8  gap-y-[40px] ml-[-20px]">
                {registration?.data?.users?.map(user => (
                    <RegistrationRequestCard key={user._id} user={user} />
                ))}
            </div>
        );
    } else if (!isLoading && error) {
        content = (
            <div className="flex flex-col items-center justify-center mt-[10%]">
                <AiOutlineWarning className="text-[48px] text-gray-400" />
                <p className="mt-[10px] text-[22px] font-Inter font-medium text-gray-500">server Error</p>
            </div>
        );
    }

    // function declaration
    const startApiFilterHandler = () => {
        dispatch(setSearchTerm(customSearchTerm));
        setCustomSearchTerm("");
    };

    return (
        <div>
            {/* Registration Request */}
            <div>
                <h1 className="text-[#333333] text-[32px] leading-[44px] font-semibold font-Nunito ml-[-508px]">Registration Request</h1>
                {/* back button  */}
                <div className="flex justify-start font-poppins font-semibold text-xl text-[#000000] mt-[48px] mb-[39px]">
                    <button
                        onClick={() => navigate(-1)}
                        style={{ boxShadow: "2px 2px 10px 2px rgba(0, 0, 0, 0.12)" }}
                        className="py-3 px-[45px] rounded-2xl bg-[#FFFFFF]"
                    >
                        Back To Main Pages
                    </button>
                </div>
                {/* search input  */}
                <Input
                    className="max-w-lg mx-auto border-2 border-[#FFE8F3] flex py-2 px-4 mb-[72px]"
                    style={{ boxShadow: "0px 4px 4px rgba(62, 73, 84, 0.04)" }}
                    placeholder="Search user name"
                    value={customSearchTerm}
                    onChange={e => setCustomSearchTerm(e.target.value)}
                    prefix={<FiSearch className="text-2xl mr-4 hover:text-[#E31371]" onClick={startApiFilterHandler} />}
                />

                <div>{content}</div>
                <div className="flex justify-between items-center mt-14 mb-20">
                    <p className="text-[#3E4954] leading-6 font-medium">Showing 8 from {registration?.data?.total} data</p>
                    <Pagination
                        className="bg-[#E3E4EB] px-[1px] py-[3px] rounded-md text-white"
                        count={count}
                        shape="rounded"
                        onChange={(e, p) => setPage(p)}
                    />
                </div>
            </div>
        </div>
    );
};

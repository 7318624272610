import React, { useState, useEffect } from "react";

// Third party package
import { Collapse, Modal } from "antd";
import { CaretRightOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { Button } from "@mui/material";
import { pink } from "@mui/material/colors";

// Components
import AddSectionForm from "../../AddCourseSection/AddSectionPage/AddSectionForm/AddSectionForm";
import EditCourseModule from "../EditCourseModule/EditCourseModule";
import { useDeleteCourseSectionMutation } from "../../../../../Redux/features/Course/courseApi";
import { toast } from "react-hot-toast";

const EditCourseSection = ({ course }) => {
  const [sectionEditFormVisible, setSectionEditFormVisible] = useState(false);
  const [sectionForEdit, setSectionForEdit] = useState("");

  const [deleteCourseSection, { data: response, error }] =
    useDeleteCourseSectionMutation();

  const handleSectionEditFormCancel = () => {
    setSectionEditFormVisible(false);
  };

  const handleEditSection = (section) => {
    setSectionEditFormVisible(true);
    setSectionForEdit(section);
  };

  const { confirm } = Modal;
  const showDeleteSectionConfirm = (id) => {
    confirm({
      title: "Do you Want to delete this section?",
      icon: <ExclamationCircleFilled />,
      okButtonProps: {
        style: {
          backgroundColor: "red",
        },
      },
      onOk() {
        deleteCourseSection(id);
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    if (response) {
      toast.success("Course Section Deleted");
    }
    if (error) {
      toast.error(
        <p>
          Failed to delete section. <br /> Please login or Try again later
        </p>
      );
    }
  }, [response, error]);

  const { Panel } = Collapse;
  return (
    <div>
      <div className="my-4 text-left">
        <Collapse
          bordered={false}
          defaultActiveKey={["1"]}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          collapsible="icon"
        >
          {course?.sections?.map((section) => (
            <Panel
              header={
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-xl font-medium">{section?.name}</p>
                    <p className="">{section?.description}</p>
                  </div>
                  <div className="flex gap-x-2">
                    <Button
                      onClick={() => handleEditSection(section)}
                      sx={{
                        color: pink[500],
                        borderColor: pink[500],
                      }}
                      variant="outlined"
                      className="whitespace-nowrap"
                    >
                      Edit Section
                    </Button>
                    <Button
                      onClick={() => showDeleteSectionConfirm(section?._id)}
                      sx={{
                        color: pink[900],
                        borderColor: pink[900],
                      }}
                      variant="outlined"
                      className="whitespace-nowrap"
                    >
                      Delete Section
                    </Button>
                  </div>
                </div>
              }
              key={section?._id}
            >
              <EditCourseModule {...{ section }} />
            </Panel>
          ))}
        </Collapse>
      </div>
      <Modal
        title={<p className="text-xl">Edit Section</p>}
        open={sectionEditFormVisible}
        onCancel={handleSectionEditFormCancel}
        footer={null}
      >
        <AddSectionForm
          {...{
            sectionForEdit,
            setSectionEditFormVisible,
            formType: "editForm",
          }}
        />
      </Modal>
    </div>
  );
};

export default EditCourseSection;

// configuration
import React, { useState } from "react";

// Third party packages
import { Pagination } from "@mui/material";

// components
import SearchManyUsers from "../SearchUsers/SearchManyUsers";
import { DocumentVerificationProfileCard } from "../../Cards/Lawyer/DocumentVerificationProfile/DocumentVerificationProfileCard";
import { useSearchParams } from "react-router-dom";
import  DocumentVerification  from "./documentVerification";

export const DocumentsVerificationUserProfiles = ({role}) => {
  const [userObj, setUser] = useState({});
  const [page, setPage] = useState(1);
  const [searchParams] = useSearchParams();
  if (searchParams.get("id")) {
    return (
      <DocumentVerification {...{ propsUserId: searchParams.get("id") }} />
    );
  }
    return (
      <div>
        <SearchManyUsers setUser={setUser} page={page} role={role} />
        <div className="grid grid-cols-2 gap-[29px] mt-[65px] min-h-[500px]">
          {userObj?.data?.users?.map((item) => {
            return (
              <DocumentVerificationProfileCard
                key={item._id}
                user={item}
                role={role}
              />
            );
          })}
        </div>
        <div className="flex justify-around items-center my-12">
          <p className="leading-6 font-medium">
            Showing {userObj?.data?.users?.length} from {userObj?.data?.total}{" "}
            data
          </p>
          <Pagination
            className="bg-[#E3E4EB] px-[1px] py-[3px] rounded-md text-white"
            onChange={(event, value) => {
              setPage(value);
            }}
            count={Math.ceil(userObj?.data?.total / 8) || 1}
            shape="rounded"
          />
        </div>
      </div>
    );
};

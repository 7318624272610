import { Pagination } from "@mui/material";
import React from "react";
import { AiOutlineWarning } from "react-icons/ai";
import { FaUsersSlash } from "react-icons/fa";
import useDocumentTitle from "../../../../../helper/utilities/useDocumentTitle";
// import { RestrictSuspendUserProfileCard } from "../../../../Shared/Cards/RestrictSuspendUserCard/RestrictSuspendUserProfileCard";
import { SendForPaymentCard } from "../../../../Shared/Cards/Send_For_Payment_Card/SendForPaymentCard";

export const RestrictSuspendProfileContainer = ({ userObj, setPage, setUser, page, searchQuery, setSearchQuery }) => {
    // hook variable declaration
    const loaderArr = [1, 2, 3, 4];

    // page title
    useDocumentTitle("Admin | Restrict Suspend");

    // js variable declaration
    if (userObj?.isLoading) {
        return (
            <div className="mt-[24px] grid grid-cols-2 gap-4 ml-[30px]">
                {loaderArr.map(p => (
                    <div key={p} class="h-[222px] w-[385px] bg-[linear-gradient(180deg,#9054DC_0%,#642DAA_100%)] rounded-[8px] px-[35px] py-[31px]">
                        <div class="flex items-center gap-x-[24px]">
                            <div class="w-[85px] h-[85px] rounded-full bg-gray-200 animate-pulse"></div>
                            <div class="flex flex-col gap-y-[20px]">
                                <div class="h-[14px] w-[160px] rounded-[4px] bg-gray-200 animate-pulse"></div>
                                <div class="h-[14px] w-[120px] rounded-[4px] bg-gray-200 animate-pulse"></div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    } else if (userObj?.error) {
        return (
            <div className="flex flex-col items-center justify-center mt-[10%]">
                <AiOutlineWarning className="text-[48px] text-gray-400" />
                <p className="mt-[10px] text-[22px] font-Inter font-medium text-gray-500">server Error</p>
            </div>
        );
    } else if (userObj?.data?.users?.length === 0) {
        return (
            <div className="flex flex-col items-center justify-center mt-[10%]">
                <FaUsersSlash className="text-[48px] text-gray-400" />
                <p className="mt-[10px] text-[22px] font-Inter font-medium text-gray-500">No User Found!</p>
            </div>
        );
    } else {
        return (
            <div>
                <div className="mt-[24px] grid grid-cols-2 gap-x-[29px] gap-y-[40px] ml-[30px]">
                    {userObj?.data?.users?.map(item => (
                        <SendForPaymentCard searchQuery={searchQuery} setSearchQuery={setSearchQuery} user={item}></SendForPaymentCard>
                    ))}
                </div>
                <div className="flex justify-between items-center my-[62px] ">
                    <p className="text-[#3E4954] leading-6 font-medium">
                        Showing {userObj?.data?.users?.length} from {userObj?.data?.total} data
                    </p>
                    {userObj?.data && (
                        <Pagination
                            className="bg-[#E3E4EB] px-[1px] py-[3px] rounded-md text-white"
                            page={page}
                            onChange={(event, value) => {
                                setPage(value);
                                setUser(null);
                            }}
                            count={Math.ceil(userObj?.data?.total / 8)}
                            shape="rounded"
                        />
                    )}
                </div>
            </div>
        );
    }
};

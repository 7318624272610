import React from "react";

/* Third party package */
import { useNavigate, useSearchParams } from "react-router-dom";

/* Components */
import AllServicesCard from "../../../../Shared/Cards/Lawyer/ModifyCaseStudyCard/AllServicesCard/AllServicesCard";

const AllServices = ({ propsUserId }) => {
  const [searchParams] = useSearchParams();
  const userID = propsUserId || searchParams.get("user");

  /* Used to navigate to case study page */
  const navigate = useNavigate();

  return (
    <div>
      {/* Back to ModifyCaseStudy */}
      <div className="flex justify-start font-poppins font-semibold text-xl text-[#000000] mt-[117px] mb-[37px] ml-[95px]">
        <button
          onClick={() => navigate(-1)}
          style={{ boxShadow: "2px 2px 10px 2px rgba(0, 0, 0, 0.12)" }}
          className="py-3 px-[45px] rounded-2xl bg-[#FFFFFF]"
        >
          Back To List
        </button>
      </div>
      {/* All services */}
      <div className="ml-[95px]">
        <AllServicesCard userID={userID}></AllServicesCard>
      </div>
    </div>
  );
};

export default AllServices;

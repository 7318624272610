// configuration
import React from "react";

// Third party packages
import { Pagination } from "@mui/material";
import { FiSearch } from "react-icons/fi";
import { LawyerClientChatCard } from "../../../Shared/Cards/Lawyer/Lawyer__Client__Chat__Card/LawyerClientChatCard";
import { useGetAllProfilesByRoleQuery } from "../../../../Redux/features/User/userApi";

export const LawyerClientChat = () => {
  // demo array
  const { data } = useGetAllProfilesByRoleQuery();

  return (
    <div>
      <div className="flex mx-auto items-center w-[492px] h-[52px] bg-[#FFFFFF] border border-[#FFE8F3] py-[14px] px-[21px] text-base rounded-lg gap-6">
        <FiSearch className="text-[#353751] text-2xl" />
        <input
          className="font-poppins font-normal text-[#AAAAAA] focus: outline-none h-full w-full"
          type="search"
          name="searchUser"
          id=""
          placeholder="Search user name"
        />
      </div>
      <div className="grid grid-cols-2 gap-[29px] mt-[65px]">
        {data?.data?.users?.map((p) => {
          return <LawyerClientChatCard key={p._id} user={p} />;
        })}
      </div>
      <div className="flex justify-around items-center my-12">
        <p className="leading-6 font-medium">Showing 8 from 160 data</p>
        <Pagination
          className="bg-[#E3E4EB] px-[1px] py-[3px] rounded-md text-white"
          count={20}
          shape="rounded"
        />
      </div>
    </div>
  );
};

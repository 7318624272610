import { Select } from "antd";
import React from "react";
import { useState } from "react";
import SupportTicketTable from "./SupportTicketTable/SupportTicketTable";

const SupportTicket = () => {
  const [status, setStatus] = useState("");
  return (
    <div>
      <div className="ml-6 mt-[40px] border-[1px] border-gray-200 rounded-[4px] h-auto mb-20">
        <div className="w-full flex justify-between items-center px-3">
          <h4 className="py-[10px] text-[20px] font-semibold font-Inter">
            All Tickets
          </h4>
          <Select
            defaultValue=""
            style={{
              width: 120,
            }}
            onChange={(value) => setStatus(value)}
            options={[
              {
                value: "",
                label: "All",
              },
              {
                value: "closed",
                label: "Closed",
              },
              {
                value: "open",
                label: "Opened",
              },
            ]}
          />
        </div>
        <div className="overflow-auto">
          <SupportTicketTable status={status} />
        </div>
      </div>
    </div>
  );
};

export default SupportTicket;

import React from 'react';
import ReferralNav from './currencyNav/ReferralNav';
import { Outlet } from 'react-router-dom';

const Referral = () => {
    return (
        <ReferralNav>
            <Outlet />
        </ReferralNav>
    );
}

export default Referral;

// configuration
import React, { useState } from "react";

// Third party packages
import Pagination from "@mui/material/Pagination";
import { AiOutlineWarning } from "react-icons/ai";
import { MdOutlineRecommend } from "react-icons/md";

// components
import UserActivitiesCard from "../../../Shared/Cards/User__Activities__Card/UserActivitiesCard";
import ActivitiesTable from "./ActivitiesTable";
import { ApprovedUserLoader } from "../../../Shared/Loader/User/ApprovedUserLoader";
import useDocumentTitle from "../../../../helper/utilities/useDocumentTitle";
import SearchManyUsers from "../../../Shared/components/SearchUsers/SearchManyUsers";

const ViewUserActivities = () => {
  const [userObj, setUser] = useState({});
  const [page, setPage] = useState(1);
  const [selectedId, setSelectedId] = useState("");

  // page title
  useDocumentTitle("Admin | View User Activities");

  const { isLoading, error } = userObj;

  let content;
  if (isLoading) {
    content = (
      <div className="w-full flex gap-y-[20px]">
        <ApprovedUserLoader />
        <ApprovedUserLoader />
      </div>
    );
  } else if (error) {
    content = (
      <div className="flex flex-col items-center justify-center mt-[100px]">
        <AiOutlineWarning className="text-[42px] text-gray-400" />
        <p className="mt-[10px] text-[16px] font-Inter font-medium text-gray-500 mb-10">
          An Error occurred! <br /> Please login or try again later!
        </p>
      </div>
    );
  } else if (!error && userObj?.data?.users?.length === 0) {
    content = (
      <div className="flex flex-col items-center justify-center mt-[100px]">
        <MdOutlineRecommend className="text-[42px] text-gray-400" />
        <p className="mt-[10px] text-[16px] font-Inter font-medium text-gray-500 mb-10">
          No Suggestion Found!
        </p>
      </div>
    );
  } else if (!error && userObj?.data?.users?.length > 0) {
    content = (
      <div className="grid grid-cols-3 gap-4 mx-8">
        {userObj?.data?.users?.map((user) => {
          return (
            <UserActivitiesCard
              key={user?._id}
              {...{ user, selectedId, setSelectedId }}
            />
          );
        })}
      </div>
    );
  }

  return (
    <div className="max-w-4xl bg-[#FAFBFF]">
      <h1 className="text-[40px] leading-[55px] font-bold text-[#1D252D] text-center mb-12">
        View Users Activities
      </h1>
      <SearchManyUsers setUser={setUser} page={page} role="member" />
      <div>{content}</div>
      <div className="flex justify-around items-center my-12">
        <p className="leading-6 font-medium">
          Showing {userObj?.data?.users?.length} from {userObj?.data?.total}{" "}
          data
        </p>
        <Pagination
          className="bg-[#E3E4EB] px-[1px] py-[3px] rounded-md text-white"
          onChange={(event, value) => {
            setPage(value);
          }}
          count={Math.ceil(userObj?.data?.total / 8) || 1}
          shape="rounded"
        />
      </div>
      <div className="mx-8">
        <ActivitiesTable {...{ selectedId }} />
      </div>
    </div>
  );
};

export default ViewUserActivities;

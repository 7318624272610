// configuration
import React,{useState} from "react";

// configuration
import { Navigate } from "react-router-dom";

// Third party packages
import Pagination from "@mui/material/Pagination";
import { useGetPaidUsersQuery } from "../../../../Redux/features/User/userApi";

const RecentPaidUserTable = ({ data }) => {
  const [paidUserPage, setPaidUserPage] = useState(1)
  const { data: paidUserData } = useGetPaidUsersQuery({ page: paidUserPage, keyword: "" });
  return (
    <div>
      <h1 className="font-bold text-[32px] ml-[-550px] mb-[10px] mt-[100px]">
        Recent Paid User
      </h1>
      <div className="flex justify-start font-poppins font-semibold text-[13px] text-[#000000]  mb-[37px] ml-[25px]">
        <button
          onClick={() => Navigate(-1)}
          style={{ boxShadow: "2px 2px 10px 2px rgba(0, 0, 0, 0.12)" }}
          className="py-3 px-[45px] rounded-2xl bg-[#FFFFFF]"
        >
          Select All
        </button>
      </div>
      <div className=" px-3">
        <table className="w-full">
          <tr className="border-b-[1px]">
            <th>User Id</th>
            <th>Joining Date</th>
            <th>User Name</th>
            <th>Paid User</th>
            <th>Spend</th>
          </tr>
          {paidUserData?.data?.paidUsers.map((item, i) => (
          <tr key={item._id} className="border-b-[1px] hover:bg-gray-300 cursor-pointer">
            <td className="py-2">{i+1}</td>
            <td className="py-2">{`${new Date(item.createdAt).getDate()}/${
                new Date(item.createdAt).getMonth() + 1
              }/${new Date(item.createdAt).getFullYear()}`}</td>
              <td className="py-2">{`${item.firstName} ${item.lastName}`}</td>
              <td className="py-2">{`${item.planTitle} User`}</td>
              <td className="py-2">{`act.spend`}</td>
          </tr>
        ))}
        </table>
      </div>
      <div className="flex justify-around items-center my-12">
        <p className="leading-6 font-medium">Showing {paidUserData?.data?.paidUsers?.length} from {paidUserData?.data?.total} data</p>
        <Pagination
        onChange={(e,value)=>setPaidUserPage(value)}
          className="bg-[#E3E4EB] px-[1px] py-[3px] rounded-md text-white"
          count={Math.ceil(paidUserData?.data?.total/8)}
          shape="rounded"
        />
      </div>
    </div>
  );
};

export default RecentPaidUserTable;

export const getRegistrationRequestUrlByRole = (
  role,
  page,
  searchTerm,
  limit
) => {
  if (role === "lawyer") {
    return `/admin/member/lawyers?_limit=${
      limit || 8
    }&_page=${page}&_name=${searchTerm}`;
  } else if (role === "kazi") {
    return `/admin/member/kazi?_limit=${
      limit || 8
    }&_page=${page}&_name=${searchTerm}`;
  } else if (role === "agent") {
    return `/admin/member/agents?_limit=${
      limit || 8
    }&_page=${page}&_name=${searchTerm}`;
  } else {
    return `/admin/member/members?_limit=${
      limit || 8
    }&_page=${page}&_name=${searchTerm}`;
  }
};

// configuration
import { Route } from "react-router-dom";

// components
import AddUser from "../../Admin__Portion/Components/User/AddUser/AddUser";
import RequireAuth from "../../helper/hooks/loginPersestency/RequireAuth";
import userRoutes from "../User/userRoutes";

export const renderUserRoutes = () => {
  return userRoutes?.map((route, i) => {
    return (
      <Route
        key={i}
        path={route?.path}
        element={<RequireAuth>{route?.element}</RequireAuth>}
      >
        <Route index element={<AddUser />} />
        {route?.nestedRoutes?.map((route, i) => {
          return (
            <Route
              key={i}
              path={route?.path}
              index={i === 1}
              element={<RequireAuth>{route?.element}</RequireAuth>}
            >
              {route?.nestedRoutes?.map((route, i) => {
                return (
                  <Route
                    key={i}
                    path={route?.path}
                    element={<RequireAuth>{route?.element}</RequireAuth>}
                  >
                    {route?.nestedRoutes?.map((route, i) => {
                      return (
                        <Route
                          key={i}
                          path={route?.path}
                          element={<RequireAuth>{route?.element}</RequireAuth>}
                        ></Route>
                      );
                    })}
                  </Route>
                );
              })}
            </Route>
          );
        })}
      </Route>
    );
  });
};

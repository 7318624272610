// configuration
import React from "react";

// Third party packages
import { AiOutlineArrowRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { AllTrendyProducts } from "./AllTrendyProducts";

// components
import useDocumentTitle from "../../../../helper/utilities/useDocumentTitle";
import { Listing } from "./Listing";
import { TopMonthSeller } from "./TopMonthSeller";

export const ProductListingAndTrendyProducts = () => {
  const navigate = useNavigate();
  useDocumentTitle("Admin | Products");
  return (
    <div className="mb-[236px] pl-5 ">
      <div className="w-full h-[180px] flex justify-center items-center">
        <button
          className="bg-[#A72CC7] text-white h-[80px] w-[296px] rounded-[100px] text-[24px] font-bold font-Inter tracking-[1.25px] flex items-center gap-x-[13px] justify-center"
          onClick={() => navigate("/shop/addANewProduct")}
        >
          ADD PRODUCTS
          <AiOutlineArrowRight className="text-[24px]" />
        </button>
      </div>

      {/* listing */}
      <Listing />

      {/* our Trendy products */}
      <AllTrendyProducts />

      {/* Top Month Seller */}
      <TopMonthSeller />
    </div>
  );
};

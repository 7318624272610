// configuration
import React from "react";

// Third party packages
import { useNavigate } from "react-router-dom";

export const AllChatingsForLawyerHeader = ({user}) => {
    // varible declation
    // hook variables
    const navigate = useNavigate();

    // js variables
    return (
        <div className="w-full">
            <div className="w-full flex justify-start">
                <button
                    className="shadow-[3px_3px_10px_3px_rgba(0,0,0,0.12)] rounded-[16px] py-[12px] px-[45px] text-[20px] leading-[30px] font-semibold font-poppins"
                    onClick={() => navigate(-1)}
                >
                    Back to List
                </button>
            </div>
            <div className="mt-[69px] flex justify-center">
                <div style={{ background: 'linear-gradient(130.32deg, #FFA4EB 1.59%, #FCD6BC 37.11%, #F9EBC9 64.89%, #FFC58F 100.54%)' }} className="w-[385px] rounded-[8px] px-[35px] py-[31px]">
                    <div className="flex gap-x-[24px]">
                        <img className="w-[85px] h-[85px] rounded-full" src={user?.profilePhoto?user.profilePhoto:"https://i.ibb.co/f4Vxd2k/Ellipse-18.png"} alt="profile" />
                        <div className="flex flex-col gap-y-[18px]">
                            <h1 className=" text-[24px] leading-[29px] font-medium font-Inter">{user?.firstName + user?.lastName}</h1>
                            <p className="text-left text-[20px] leading-[24px] font-medium font-Inter">Age: {user?.age}</p>
                            <button className="w-[135px] h-[37px] mt-[20px] flex justify-center items-center bg-gradient-to-b from-[#E22989] to-[#A72CC7] text-white rounded-2xl font-Inter font-medium text-base">
                                Selected
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

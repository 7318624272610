// Configuration
import React from "react";

const AssignKaziCard = ({ kazi,selectedKazi, setSelectedKazi }) => {
  return (
    <div
      className="py-8 rounded-lg  h-[222px] box-border"
      style={
        selectedKazi === kazi._id
          ? {
              backgroundImage:
                "linear-gradient(90.26deg, #E3B0FF 0.23%, #A54FD7 97.84%)",
              boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.12)",
              border: "8px solid",
              borderImageSlice: "1",
              borderImageSource:
                "linear-gradient(180deg, #E41272 0%, #A72CC7 100%)",
            }
          : {
              boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.12)",
              backgroundImage:
              "linear-gradient(77.98deg, #81FBB9 -8.03%, #2FCC75 92.83%)",
            }
      }
    >
      <div className="flex justify-start items-center gap-6 px-9 mb-9">
        <img
          className="w-[85px] h-[85px] rounded-full"
          src={kazi.profilePhoto}
          alt=""
        />
        <div className={`${selectedKazi === kazi._id ? "text-white" : "text-black"} text-left`}>
          <h3 className="text-2xl leading-[29px] font-medium mb-4">
            {kazi.firstName}
          </h3>
          <p className="text-xl leading-6">Age : {kazi.age}</p>
        </div>
      </div>
      <div className="flex justify-center items-center gap-6">
        {selectedKazi === kazi._id ? (
          <button
            className="text-base text-white leading-5 font-medium w-[169px] py-[9px] rounded-sm"
            style={{
              backgroundImage:
                "linear-gradient(90deg, #E22989 7.1%, #A72CC7 93.49%)",
            }}
            onClick={() => setSelectedKazi("")}
          >
            Selected
          </button>
        ) : (
          <button
            className="text-base text-white leading-5 font-medium w-[169px] py-[9px] rounded-sm"
            style={{
              backgroundImage:
                "linear-gradient(90deg, #E22989 7.1%, #A72CC7 93.49%)",
            }}
            onClick={() => setSelectedKazi(kazi._id)}
          >
            Select
          </button>
        )}
      </div>
    </div>
  );
};

export default AssignKaziCard;

// Configuration
import React from "react";

// Third party packages
import Scrollbars from "react-custom-scrollbars";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import { useGetAllConnectedConnectionsQuery } from "../../../../../Redux/features/User/userApi";
import { ConversationCard } from "./ConversationCard";

export const ConversationBoxForLawyer = ({ user, handleClick }) => {
  const { data } = useGetAllConnectedConnectionsQuery(user?._id);
  let connections = data?.data?.user;
  // const demo = [
  //   {
  //     name: "Olivia Rellaq",
  //     photo: "https://placeimg.com/192/192/people",
  //     message:
  //       "Nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  //     timestamp: "dfsdfs",
  //     id: 1,
  //   },
  //   {
  //     name: "Roberto Charloz",
  //     photo: "https://placeimg.com/192/192/people",
  //     message:
  //       "Hey, check my design update last night. Tell me what you think and if that is OK. I hear client said they want to change the layout concept",
  //     timestamp: "dfsdfs",
  //     id: 2,
  //   },
  // ];

  return (
    <div className="w-[557px] h-[620px] bg-white shadow-[0px_3px_4px_rgba(62,73,84,0.04)] rounded-[20px] mb-12">
      <div className="h-[91px] w-full shadow-[0px_12px_23px_rgba(62,73,84,0.04)] rounded-tr-[20px] rounded-tl-[20px] flex justify-between items-center px-[29px]">
        <div className="w-[396px] h-[54px] bg-[#F0F0F0] rounded-[49px] flex justify-start items-center px-[9px]">
          <div className="w-[157px] h-[42px] bg-white rounded-[45px] text-[#000000] font-poppins font-semibold text-[16px] flex justify-center items-center">
            All Message
          </div>
        </div>
        <div className="flex h-full items-center gap-x-[20px]">
          <FiSearch className="text-[20px]" />
          <BsThreeDotsVertical className="text-[20px]" />
        </div>
      </div>
      <div>
        <Scrollbars style={{ height: 528 }}>
          {connections?.map((conversation) => {
            return (
              <ConversationCard
                key={conversation.id}
                {...{ conversation }}
                handleClick={handleClick}
              />
            );
          })}
        </Scrollbars>
      </div>
    </div>
  );
};

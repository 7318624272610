// configuration, ex: react-router
import React from "react";
import { AiOutlineWarning } from "react-icons/ai";
import { FaUsersSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useGetAllRegistrationRequestsQuery } from "../../../../../Redux/features/User/registrationRequestApi";

// Third party packages
import { RegistrationRequestCard } from "../../../../Shared/Cards/Registration__Request__Card/RegistrationRequestCard";
import { RegistrationRequestLoader } from "../../../../Shared/Loader/User/RegistrationRequestLoader";

// components

export const RegistrationRequest = () => {
    // hook variable
    const navigate = useNavigate();
    const { data: registration, isLoading, error } = useGetAllRegistrationRequestsQuery({ page: 1, searchTerm: "",limit:"", role:"member"});

    // js variables
    let content;
    if (isLoading) {
        content = (
            <div className="mt-[24px] grid grid-cols-2 gap-[20px]  gap-y-[40px] ml-[-20px]">
                <RegistrationRequestLoader />
                <RegistrationRequestLoader />
                <RegistrationRequestLoader />
                <RegistrationRequestLoader />
            </div>
        );
    } else if (!isLoading && registration?.data?.users.length === 0) {
        content = (
            <div className="flex flex-col items-center justify-center mt-[10%]">
                <FaUsersSlash className="text-[48px] text-gray-400" />
                <p className="mt-[10px] text-[22px] font-Inter font-medium text-gray-500">No User Request Found!</p>
            </div>
        );
    } else if (!isLoading && error) {
        content = (
            <div className="flex flex-col items-center justify-center mt-[10%]">
                <AiOutlineWarning className="text-[48px] text-gray-400" />
                <p className="mt-[10px] text-[22px] font-Inter font-medium text-gray-500">server Error</p>
            </div>
        );
    } else if (!isLoading && registration) {
        content = (
            <div className="mt-[24px] grid grid-cols-2 gap-8  gap-y-[40px] ml-[-20px]">
                {registration?.data?.users?.slice(0, 4).map(user => (
                    <RegistrationRequestCard key={user._id} user={user} />
                ))}
            </div>
        );
    }
    return (
        <div>
            <h1 className="text-[#333333] text-[32px] leading-[44px] font-semibold font-Nunito">Registration Request</h1>
            {content}
            <div className="col-span-2 flex justify-center">
                <button
                    className="w-[111px] h-[37px] mt-[20px] flex justify-center items-center bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] text-white rounded-[4px]"
                    onClick={() => navigate("/user/allRegistrationRequest")}
                >
                    Load All
                </button>
            </div>
        </div>
    );
};

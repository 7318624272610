// Configuration
import React, { useState } from "react";

// Third party packages
import { Pagination } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { AiOutlineWarning } from "react-icons/ai";
import { MdOutlineRecommend } from "react-icons/md";

// Components
import OngoingOrdersCard from "../../../../Shared/Cards/Shop/Order/OngoingOrdersCard";
import { useGetAllOrdersQuery } from "../../../../../Redux/features/Shop/shopApi";
import OrderLoader from "../../../../Shared/Loader/Order/OrderLoader";

const OngoingOrder = () => {
  const [page, setPage] = useState(1);
  const loaderArray = [1, 2, 3, 4];
  // Redux api
  const {
    data: orderResponse,
    isLoading,
    error,
  } = useGetAllOrdersQuery({
    page: page,
    limit: 8,
    status: "ongoing",
  });

  let content;
  if (isLoading) {
    content = (
      <div className="max-w-5xl">
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={16}
          className="pt-6 pl-6 pb-20 mx-0 overflow-hidden"
        >
          {loaderArray.map((loader) => (
            <SwiperSlide key={loader} className="w-[342px]">
              <OrderLoader />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  } else if (error) {
    content = (
      <div className="flex flex-col items-center justify-center my-[100px]">
        <AiOutlineWarning className="text-[42px] text-gray-400" />
        <p className="mt-[10px] text-[16px] font-Inter font-medium text-gray-500">
          An Error occurred! <br /> Please login or try again later!
        </p>
      </div>
    );
  } else if (!error && orderResponse?.orders?.length === 0) {
    content = (
      <div className="flex flex-col items-center justify-center my-[100px]">
        <MdOutlineRecommend className="text-[42px] text-gray-400" />
        <p className="mt-[10px] text-[16px] font-Inter font-medium text-gray-500">
          No Orders Found!
        </p>
      </div>
    );
  } else if (orderResponse?.orders?.length > 0) {
    content = (
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={16}
        className="pt-6 pl-6 pb-20 mx-0 overflow-hidden"
      >
        {orderResponse?.orders?.map((order) => (
          <SwiperSlide key={order?._id} className="w-[342px]">
            <OngoingOrdersCard {...{ order }} />
          </SwiperSlide>
        ))}
      </Swiper>
    );
  }
  return (
    <div>
      <div className="flex justify-between items-center pr-8 pl-6">
        <h1 className="text-[40px] leading-[47px] font-bold">Ongoing Order</h1>
        <Pagination
          className=" px-[1px] py-[3px] rounded-md text-white"
          onChange={(event, value) => {
            setPage(value);
          }}
          count={Math.ceil(orderResponse?.total / 8) || 1}
          shape="rounded"
          variant="outlined"
        />
      </div>
      <div className="max-w-5xl">{content}</div>
    </div>
  );
};

export default OngoingOrder;

import React from 'react';
import { Outlet } from 'react-router-dom';
import MemberShipPackageNav from '../PackagesNav/MemberShipPackageNav';

const MemberShipPackage = () => {
    return (
        <MemberShipPackageNav>
            <Outlet />
        </MemberShipPackageNav>
    );
}

export default MemberShipPackage;

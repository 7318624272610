// Configuration
import React, { useEffect, useState } from "react";

// third party package
import { toast, Toaster } from "react-hot-toast";
import { AiOutlineArrowRight } from "react-icons/ai";
import useDocumentTitle from "../../../../helper/utilities/useDocumentTitle";

// Components
import {
  useAddCategoryMutation,
  useGetAllCategoriesQuery,
} from "../../../../Redux/features/Shop/shopApi";
import AddCategoriesModal from "../../../Shared/Cards/Shop/ShopCategories/AddCategoriesModal";
import DeleteCategories from "./DeleteCategories/DeleteCategories";
import EditCategories from "./EditCategories/EditCategories";

const ShopCategories = () => {
  const [categoryName, setCategoryName] = useState("");
  const [addCategoriesOpen, setAddCategoriesOpen] = useState(false);

  // Redux api for categories
  const [addCategory, { data: response, isLoading, error }] =
    useAddCategoryMutation();

  const {
    data: allCategories,
    isLoading: categoriesDataLoading,
    error: categoriesDataError,
  } = useGetAllCategoriesQuery("shop");

  const showAddCategoriesModal = () => {
    setAddCategoriesOpen(true);
  };
  const handleAddCategoriesOk = async () => {
    await addCategory({ name: categoryName, type: "shop" });
  };
  const handleAddCategoriesCancel = () => {
    setAddCategoriesOpen(false);
  };

  useEffect(() => {
    if (response) {
      setAddCategoriesOpen(false);
      setCategoryName("");
      toast.success(<p>Category Added Successfully</p>);
    }
    if (error) {
      toast.error(
        <p>
          Something Went Wrong! <br /> Please try again later.
        </p>
      );
      setAddCategoriesOpen(false);
      setCategoryName("");
    }
  }, [response, error]);

  // Page Title
  useDocumentTitle("Admin | Category");

  return (
    <div className="px-6">
      <div className="w-full pt-12 pb-[72px] flex justify-center items-center">
        <button
          className="text-white h-[80px] w-[317px] rounded-[100px] text-[24px] font-bold font-Inter tracking-[1.25px] flex items-center gap-x-[13px] justify-center uppercase"
          style={{
            backgroundImage: "linear-gradient(90deg, #E41272 0%, #A72CC7 100%)",
          }}
          onClick={() => showAddCategoriesModal()}
        >
          Add Categories
          <AiOutlineArrowRight className="text-[24px]" />
        </button>
      </div>
      {/* Add categories modal */}
      <AddCategoriesModal
        {...{
          addCategoriesOpen,
          handleAddCategoriesOk,
          handleAddCategoriesCancel,
          isLoading,
          setCategoryName,
          categoryName,
          title: "shopCategory",
          setAddCategoriesOpen,
        }}
      />

      {/* Edit categories */}
      <EditCategories
        {...{
          allCategories,
          categoriesDataError,
          categoriesDataLoading,
          title: "shopCategory",
        }}
      />

      {/* Delete Categories */}
      <DeleteCategories
        {...{
          allCategories,
          categoriesDataError,
          categoriesDataLoading,
          title: "shopCategory",
        }}
      />

      <Toaster />
    </div>
  );
};

export default ShopCategories;

import React from "react";
import { Outlet } from "react-router-dom";
import StatusBoostPackageNav from "../PackagesNav/StatusBoostPackageNav";

const StatusBoostPackage = () => {
  return (
    <StatusBoostPackageNav>
      <Outlet />
    </StatusBoostPackageNav>
  );
};

export default StatusBoostPackage;

// Configuration
import { Route, Routes } from "react-router-dom";

// Third party package
import { decodeToken } from "react-jwt";

// components
import { Login } from "./Admin__Portion/Components/Authentication/Login";
import { Chart } from "./Admin__Portion/Dashboard/Chart";
import { Dashboard } from "./Admin__Portion/Dashboard/Dashboard";
import NotFoundPage from "./Admin__Portion/Shared/components/NotFoundPage/NotFoundPage";
import RequireAuth from "./helper/hooks/loginPersestency/RequireAuth";
import { renderAgentRoutes } from "./routes/RouteRenderContainer/renderAgentRoutes";
import { renderBadgeRoutes } from "./routes/RouteRenderContainer/renderBadgeRoutes";
import { renderCourseRoutes } from "./routes/RouteRenderContainer/renderCourseRoutes";
import { renderKaziRoutes } from "./routes/RouteRenderContainer/renderKaziRoutes";
import { renderLawyerRoutes } from "./routes/RouteRenderContainer/renderLawyerRoutes";
import { renderPackageRoutes } from "./routes/RouteRenderContainer/renderPackageRoutes";
import { renderShopRoutes } from "./routes/RouteRenderContainer/renderShopRoutes";
import { renderUserRoutes } from "./routes/RouteRenderContainer/renderUserRoutes";
import { renderSupportRoutes } from "./routes/RouteRenderContainer/renderSupportRoutes";
import { renderCurrencyRoutes } from "./routes/RouteRenderContainer/renderCurrencyRoutes";
import getCookie from "./helper/cookies/getCookie";
import { Shop } from "./Admin__Portion/Components/Shop/Shop";

function App() {
  //js variables
  const token = getCookie("adminToken");
  const myDecodeToken = decodeToken(token);
  const { role } = myDecodeToken || {};
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        >
          <Route path="/" element={role === "vendor" ? <Shop /> : <Chart />} />
          {renderUserRoutes()}
          {renderLawyerRoutes()}
          {renderShopRoutes()}
          {renderBadgeRoutes()}
          {renderAgentRoutes()}
          {renderKaziRoutes()}
          {renderCourseRoutes()}
          {renderPackageRoutes()}
          {renderSupportRoutes()}
          {renderCurrencyRoutes()}
          <Route path="*" element={<NotFoundPage />} />
        </Route>
        <Route path="/login" element={<Login />} />
      </Routes>
    </div>
  );
}

export default App;

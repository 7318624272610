// Configuration
import React, { useEffect } from "react";

// Third party package
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";

// Components
import {
  useCreateQuizMutation,
  useUpdateQuizMutation,
} from "../../../../../../Redux/features/Course/courseApi";
import Error from "../../../../../Shared/components/accordion/UI/Error/Error";

const AddQuizForm = ({
  id,
  setQuizFormVisible,
  contentForEdit,
  setQuizEditFormVisible,
  formType,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const [createQuiz, { data: quizResponse, isLoading, error }] =
    useCreateQuizMutation();
  const [
    updateModule,
    {
      data: moduleEditResponse,
      isLoading: moduleEditLoading,
      error: moduleEditError,
    },
  ] = useUpdateQuizMutation();

  const onSubmit = async (data) => {
    if (formType === "editForm") {
      data.name = data.name || contentForEdit?.name;
      data.timeCount = Number(data.timeCount) || contentForEdit?.timeCount;
      data.passingScore =
        Number(data.passingScore) || contentForEdit?.passingScore;
      await updateModule({ id: contentForEdit?._id, data: data });
    } else {
      data.timeCount = Number(data.timeCount);
      data.passingScore = Number(data.passingScore);
      data.module = id;
      await createQuiz(data);
    }
  };

  useEffect(() => {
    if (quizResponse) {
      toast.success("Module Created Successfully");
      reset();
      setQuizFormVisible(false);
    }
    if (moduleEditResponse) {
      toast.success("Module Edited Successfully");
      reset();
      setQuizEditFormVisible(false);
    }
    if (error) {
      toast.error("Something went wrong. Please login or try again later");
      reset();
      setQuizFormVisible(false);
    }
    if (moduleEditError) {
      toast.error("Something went wrong. Please login or try again later");
      reset();
      setQuizEditFormVisible(false);
    }
  }, [
    quizResponse,
    error,
    reset,
    setQuizFormVisible,
    setQuizEditFormVisible,
    moduleEditResponse,
    moduleEditError,
  ]);

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col items-start w-full gap-y-5"
      >
        {/* Quiz Name */}
        <div className="w-full flex flex-col">
          <label className="text-left mb-1 text-[12px] font-bold text-[#707276] ml-[5px]">
            Quiz Name
          </label>
          <input
            {...register(
              "name",
              formType !== "editForm" && {
                required: "Quiz Name is required",
              }
            )}
            className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-4 px-[12px]"
            type="text"
            placeholder="Quiz Name"
          />
          {errors?.name && <Error message={errors?.name?.message} />}
        </div>

        {/* Time Count */}
        <div className="w-full flex flex-col">
          <label className="text-left mb-1 text-[12px] font-bold text-[#707276] ml-[5px]">
            Time Count
          </label>
          <input
            {...register(
              "timeCount",
              formType !== "editForm"
                ? {
                    required: "Time Count is required",
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Input must be a number",
                    },
                  }
                : {
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Input must be a number",
                    },
                  }
            )}
            className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-4 px-[12px]"
            type="number"
            placeholder="Number of minutes"
          />
          {errors?.timeCount && <Error message={errors?.timeCount?.message} />}
        </div>

        {/* Passing Score */}
        <div className="w-full flex flex-col">
          <label className="text-left mb-1 text-[12px] font-bold text-[#707276] ml-[5px]">
            Passing Score
          </label>
          <input
            {...register(
              "passingScore",
              formType !== "editForm"
                ? {
                    required: "Passing Score is required",
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Input must be a number",
                    },
                  }
                : {
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Input must be a number",
                    },
                  }
            )}
            className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-4 px-[12px]"
            type="number"
            placeholder="Passing Score"
          />
          {errors?.passingScore && (
            <Error message={errors?.passingScore?.message} />
          )}
        </div>

        <input
          type="submit"
          value={isLoading || moduleEditLoading ? "Loading..." : "Submit"}
          className="py-2 px-8 rounded-md font-Inter bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] text-white font-medium text-lg cursor-pointer hover:bg-[linear-gradient(166deg,rgb(148,45,217)_0%,rgb(242,40,118)_100%)]"
        />
      </form>
    </div>
  );
};

export default AddQuizForm;

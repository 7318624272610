import React from "react";
import { useGetSingleWalletQuery } from "../../../../Redux/features/wallet/walletApi";
import { useNavigate } from "react-router-dom";
import WarningIcon from "@mui/icons-material/Warning";
import SingleWalletCalculation from "./SingleWalletCalculation";
import UserWalletCard from "./UserWalletCard";

const SingleWallet = ({ user }) => {
  const navigate = useNavigate();
  const { data, error } = useGetSingleWalletQuery(user);
  return (
    <div>
      <div className="w-full text-left">
        <button
          onClick={() => navigate(-1)}
          className="py-2 p-8 shadow border font-semibold rounded"
        >
          Go back
        </button>
      </div>
      <div className="mt-12 flex items-center justify-center px-4">
        <div className="w-[450px]">
          <UserWalletCard item={data?.data?.user} useCase="wallet"/>
        </div>
      </div>
      {error && (
        <div className="text-yellow-500">
          <p>
            <WarningIcon />
          </p>
          <p className="">{error?.data?.message}</p>
        </div>
      )}

      {data && <SingleWalletCalculation {...{ data }} />}
    </div>
  );
};

export default SingleWallet;

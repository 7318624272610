import React from "react";
import { useAcceptWithdrawRequestMutation } from "../../../../../Redux/features/wallet/walletApi";

const RequestRow = ({ openedRequest, setOpenedRequest, item, i, useCase }) => {
  const [acceptRequest, { isLoading }] = useAcceptWithdrawRequestMutation();

  return (
    <>
      <tr className="border-b-2 ">
        <td className=" px-4 py-2">{i + 1}</td>
        <td className="text-left px-4 py-2 font-semibold">
          {item?.user?.firstName}
        </td>
        <td className="text-left px-4 py-2">{item?.user?.email}</td>
        <td className=" px-4 py-2 font-semibold">{item?.amount}</td>
        <td className=" px-4 py-2 font-semibold">
          {useCase === "unaccepted" && (
            <button
              onClick={() => acceptRequest(item._id)}
              className="text-white py-1 px-2 bg-yellow-300 "
            >
              {isLoading ? "Loading..." : "Accept"}
            </button>
          )}
          {useCase === "accepted" && (
            <span className="rounded px-2 py-1 bg-green-200">
              {item?.status}
            </span>
          )}
        </td>
      </tr>
      {openedRequest === item._id && (
        <tr>
          <td
            colspan="5"
            className=" pl-16 bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)]"
          >
            <div className="w-full">
              <table className="w-full">
                <thead>
                  <tr className="border-b-3">
                    <th className="text-left border-b-2 py-2 px-4">#_id </th>
                    <th className="text-left border-b-2 py-2 px-4">
                      First Name{" "}
                    </th>
                    <th className="text-left border-b-2 py-2 px-4">
                      Last Name
                    </th>
                  </tr>
                  {item?.referredTo?.map((referral) => {
                    return (
                      <tr>
                        <td className="text-left text-white border-b-2 py-2 px-4">
                          {referral._id}
                        </td>
                        <td className="text-white text-left border-b-2 py-2 px-4">
                          {referral.firstName}
                        </td>
                        <td className="text-white text-left border-b-2 py-2 px-4">
                          {referral.lastName}
                        </td>
                      </tr>
                    );
                  })}
                  {/* {item?.referredTo?.map((item) => {
                    return (
                      <tr>
                        <td>robin</td>
                      </tr>
                    );
                  })} */}
                </thead>
              </table>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default RequestRow;

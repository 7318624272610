import React from "react";
import { DeliveredOrderBy } from "./DeliveredOrderBy";
import { DeliveredOrderDetailes } from "./DeliveredOrderDetailes";
import { DeliveredOrderInfoAndMark } from "./DeliveredOrderInfoAndMark";

export const DeliveredDetailesOrderContainer = () => {
    return (
        <div className="w-full h-auto bg-white shadow-[2px_2px_10px_2px_rgba(0,0,0,0.12)] rounded-[12px] mt-[51px] pb-[37px]">
            <DeliveredOrderBy />
            <DeliveredOrderInfoAndMark />
            <DeliveredOrderDetailes />
        </div>
    );
};

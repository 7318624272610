// Configuration
import React, { useState } from "react";

// third party packages
import { AiOutlineWarning } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { MdOutlineRecommend } from "react-icons/md";

// Components
import EditCategoriesModal from "../../../../Shared/Cards/Shop/ShopCategories/EditCategoriesModal";
import { OvalLoader } from "../../../../Shared/Loader/OvalLoader";

const EditCategories = ({
  allCategories,
  allCourseTypes,
  categoriesDataError,
  courseTypesError,
  categoriesDataLoading,
  courseTypesLoading,
  title,
}) => {
  const [editCategoriesLoading, setEditCategoriesLoading] = useState(false);
  const [editCategoriesOpen, setEditCategoriesOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();

  const showEditCategoriesModal = ({ id, name }) => {
    setEditCategoriesOpen(true);
    setSelectedCategory({ id, name });
  };
  const handleEditCategoriesCancel = () => {
    setEditCategoriesOpen(false);
  };

  let content;
  if (categoriesDataLoading || courseTypesLoading) {
    content = <OvalLoader title={"Loading..."} />;
  } else if (categoriesDataError || courseTypesError) {
    content = (
      <div className="flex flex-col items-center justify-center mt-[100px]">
        <AiOutlineWarning className="text-[42px] text-gray-400" />
        <p className="mt-[10px] text-[16px] font-Inter font-medium text-gray-500">
          An Error occurred! <br /> Please login or try again later!
        </p>
      </div>
    );
  } else if (title !== "courseTypes" && allCategories?.category?.length === 0) {
    content = (
      <div className="flex flex-col items-center justify-center mt-[100px]">
        <MdOutlineRecommend className="text-[42px] text-gray-400" />
        <p className="mt-[10px] text-[16px] font-Inter font-medium text-gray-500">
          No Category Found!
        </p>
      </div>
    );
  } else if (title === "courseTypes" && allCourseTypes?.types?.length === 0) {
    content = (
      <div className="flex flex-col items-center justify-center mt-[100px]">
        <MdOutlineRecommend className="text-[42px] text-gray-400" />
        <p className="mt-[10px] text-[16px] font-Inter font-medium text-gray-500">
          No Types Found!
        </p>
      </div>
    );
  } else if (title !== "courseTypes" && allCategories?.category?.length > 0) {
    content = (
      <div className="flex items-center gap-5 flex-wrap">
        {allCategories?.category?.map((category) => (
          <div key={category?._id}>
            <button
              className="flex items-center justify-center gap-2 text-xs leading-4 font-bold font-Inter text-[#A72CC7] py-4 px-6 border border-[#A72CC7] rounded-tr-full rounded-br-full min-w-[160px]"
              onClick={() =>
                showEditCategoriesModal({
                  id: category?._id,
                  name: category?.name,
                })
              }
            >
              <p>{category?.name}</p> <FiEdit />
            </button>
          </div>
        ))}
      </div>
    );
  } else if (title === "courseTypes" && allCourseTypes?.types?.length > 0) {
    content = (
      <div className="flex items-center gap-5 flex-wrap">
        {allCourseTypes?.types?.map((type) => (
          <div key={type?._id}>
            <button
              className="flex items-center justify-center gap-2 text-xs leading-4 font-bold font-Inter text-[#A72CC7] py-4 px-6 border border-[#A72CC7] rounded-tr-full rounded-br-full min-w-[160px]"
              onClick={() =>
                showEditCategoriesModal({
                  id: type?._id,
                  name: type?.name,
                })
              }
            >
              <p>{type?.name}</p> <FiEdit />
            </button>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="mb-32">
      <h2 className="text-start text-[#272727] text-3xl leading-8 font-semibold font-playFair mb-10">
        Edit {title === "courseTypes" && "Types"}{" "}
        {title === "courseCategory" && "Categories"}
        {title === "shopCategory" && "Categories"}
      </h2>
      <div>{content}</div>
      {/* Edit categories modal */}
      <EditCategoriesModal
        {...{
          editCategoriesOpen,
          handleEditCategoriesCancel,
          editCategoriesLoading,
          setEditCategoriesLoading,
          setEditCategoriesOpen,
          selectedCategory,
          title,
        }}
      />
    </div>
  );
};

export default EditCategories;

import React from "react";
import { useNavigate } from "react-router-dom";

// Third party package
import { IoClose } from "react-icons/io5";

const SingleSupportTicketDetailsHeader = ({ ticket }) => {
  const { subject, uid, status } = ticket || {};

  const navigate = useNavigate();

  const colorGenerator = (status) => {
    if (status === "open") {
      return "bg-green-500";
    } else if (status === "closed") {
      return "bg-red-500";
    }
  };
  return (
    <div className="w-full flex justify-between items-center">
      <div className="flex flex-col items-start gap-y-[4px]">
        <h3 className="text-[22px] font-medium">{subject}</h3>
        <div>
          <span className="px-4 ring-1 ring-gray-400 rounded-[50px] py-1">
            #{uid}
          </span>
        </div>
      </div>
      <div className="flex items-center gap-x-2">
        <div>
          <button
            className={`h-[40px] ${colorGenerator(
              status
            )} px-4 rounded-[50px] text-white capitalize`}
          >
            {status}
          </button>
        </div>
        <div onClick={() => navigate(-1)}>
          <IoClose className="border border-gray-500 text-2xl rounded-full hover:bg-gray-500 hover:text-white cursor-pointer duration-300" />
        </div>
      </div>
    </div>
  );
};

export default SingleSupportTicketDetailsHeader;

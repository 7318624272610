import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CancelIcon from "@mui/icons-material/Cancel";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { addOrRemoveCourse } from "../../../../../Redux/features/Packages/coursePackageSlice";
const CourseCard = ({ item, useCase }) => {
  const dispatch = useDispatch();
  const courses = useSelector((state) => state?.coursePackage?.courses);
  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden my-1 mx-auto">
      <div className="sm:flex sm:items-center px-6 py-4">
        <img
          className="block mx-auto sm:mx-0 sm:flex-shrink-0 w-24 h-24 rounded-full"
          src={item?.image}
          alt="Card_image"
        />
        <div className="w-full mt-4 sm:mt-0 sm:ml-4 text-center sm:text-left">
          <div className="flex justify-between w-full">
            <h2 className="text-xl font-bold text-gray-900">{item?.name}</h2>
            <span
              onClick={() => dispatch(addOrRemoveCourse(item))}
              className="text-xl text-[#e41272] cursor-pointer"
            >
              {useCase === "cancel" ? (
                <CancelIcon fontSize="large" />
              ) : courses?.find((course) => course?._id === item?._id) ? (
                <CheckBoxIcon fontSize="large" />
              ) : (
                <CheckBoxOutlineBlankIcon fontSize="large" />
              )}
            </span>
          </div>

          <p className="text-sm font-medium text-gray-600">
            {item?.category?.name}
          </p>
          <p className="text-gray-700 mt-2">BDT {item?.price}</p>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;

// Configuration
import React, { useState, useEffect } from "react";

// Third party package
import { Button } from "@mui/material";
import { pink } from "@mui/material/colors";
import { Collapse, Modal } from "antd";
import { CaretRightOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { toast } from "react-hot-toast";

// components
import AddQuizForm from "../../../AddCourseQuiz/AddQuizContent/AddQuizForm/AddQuizForm";
import EditQuizQuestionContainer from "../EditQuizQuestionContainer/EditQuizQuestionContainer";
import { useDeleteCourseQuizByIdMutation } from "../../../../../../Redux/features/Course/courseApi";

const EditQuizContainer = ({ module }) => {
  const [quizEditFormVisible, setQuizEditFormVisible] = useState(false);
  const [contentForEdit, setContentForEdit] = useState(null);

  const [deleteCourseQuiz, { data: response, error }] =
    useDeleteCourseQuizByIdMutation();

  const handleQuizFormCancel = () => {
    setQuizEditFormVisible(false);
  };

  const handleEditContent = (content) => {
    setQuizEditFormVisible(true);
    setContentForEdit(content);
  };

  const { confirm } = Modal;
  const showDeleteQuizConfirm = (id) => {
    confirm({
      title: "Do you Want to delete this quiz?",
      icon: <ExclamationCircleFilled />,
      okButtonProps: {
        style: {
          backgroundColor: "red",
        },
      },
      onOk() {
        deleteCourseQuiz(id);
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    if (response) {
      toast.success("Quiz Deleted");
    }
    if (error) {
      toast.error(
        <p>
          Failed to delete quiz. <br /> Please login or Try again later
        </p>
      );
    }
  }, [response, error]);

  const { Panel } = Collapse;
  return (
    <div className="px-6">
      {/* show all content */}
      <div className="mb-4">
        <Collapse
          bordered={false}
          defaultActiveKey={["1"]}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          collapsible={"icon"}
        >
          {module?.quiz?.map((content) => (
            <Panel
              header={
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-xl font-medium">{content?.name}</p>
                    <div className="flex items-center gap-x-4 font-Inter font-medium mt-1">
                      <p>Time: {content?.timeCount}min</p>
                      <p>Passing Score: {content?.passingScore}</p>
                    </div>
                  </div>
                  <div className="flex gap-x-2">
                    <Button
                      onClick={() => handleEditContent(content)}
                      sx={{
                        color: pink[500],
                        borderColor: pink[500],
                      }}
                      variant="outlined"
                      className="whitespace-nowrap"
                    >
                      Edit Quiz
                    </Button>
                    <Button
                      onClick={() => showDeleteQuizConfirm(content?._id)}
                      sx={{
                        color: pink[900],
                        borderColor: pink[900],
                      }}
                      variant="outlined"
                      className="whitespace-nowrap"
                    >
                      Delete Quiz
                    </Button>
                  </div>
                </div>
              }
              key={content?._id}
            >
              <EditQuizQuestionContainer {...{ content }} />
            </Panel>
          ))}
        </Collapse>
      </div>

      {/* Add content form modal */}
      <Modal
        title={<p className="text-xl">Edit Quiz</p>}
        open={quizEditFormVisible}
        onCancel={handleQuizFormCancel}
        footer={null}
      >
        <AddQuizForm
          {...{
            contentForEdit,
            setQuizEditFormVisible,
            formType: "editForm",
          }}
        />
      </Modal>
    </div>
  );
};

export default EditQuizContainer;

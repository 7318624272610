// Configuration
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

// Third party packages
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { DatePicker, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useForm } from "react-hook-form";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { MdCancel } from "react-icons/md";
import dayjs from "dayjs";
import { OvalLoader } from "../Loader/OvalLoader";
import {
  useGetSingleProfessionDetailByIdQuery,
  useUpdateProfessionalDetailsMutation,
} from "../../../Redux/features/User/userApi";
import { usePhotosUploadOnServerMutation } from "../../../Redux/features/fileUpload/fileUploadApi";

// Components
const { RangePicker } = DatePicker;

const EditProfessionalInfo = () => {
  //success and error message co
  const [messageApi, contextHolder] = message.useMessage();
  const [viewport, setViewport] = useState("");
  // current position state
  const [currentPosition, setCurrentPosition] = useState(null);
  // institue state
  const [currentInstitute, setCurrentInstitute] = useState(null);
  const [professionalInfo, setProfessionalInfo] = useState({});
  const [professionalAchievementMoment, setProfessionalAchievementMoment] =
    useState("");
  const [achievementMomentName, setAchievementMomentName] = useState();

  // redux api
  const [updateProfessionalDetails, { isSuccess, isLoading, isError }] =
    useUpdateProfessionalDetailsMutation();
  const [uploadPhotoOnServer, { data: uploadedPhoto }] =
    usePhotosUploadOnServerMutation();

  const { id } = useParams();
  const { data: professionData, isLoading: professionDataLoading } =
    useGetSingleProfessionDetailByIdQuery(id);

  const { position, duty, institute, specialAchievement, workPeriod } =
    professionData?.data?.profession || {};

  const { handleSubmit } = useForm();

  // current position state handler
  const handleCurrentPosition = (event, newValue) => {
    if (typeof newValue === "string") {
      setCurrentPosition({
        title: newValue,
      });
    } else if (newValue && newValue?.inputValue) {
      // Create a new value from the user input
      setCurrentPosition({
        title: newValue?.inputValue,
      });
    } else {
      setCurrentPosition(newValue);
    }
  };
  // current institute handler function
  const handleCurrentInstitute = (event, newValue) => {
    if (typeof newValue === "string") {
      setCurrentInstitute({
        title: newValue,
      });
    } else if (newValue && newValue?.inputValue) {
      // Create a new value from the user input
      setCurrentInstitute({
        title: newValue?.inputValue,
      });
    } else {
      setCurrentInstitute(newValue);
    }
  };
  // working period data handler
  const handleWorkingPeriod = (date, dateString) => {
    setProfessionalInfo({ ...professionalInfo, workPeriod: dateString });
  };
  // handle achivements data chane
  const handleAchivements = (e) => {
    setProfessionalInfo({
      ...professionalInfo,
      specialAchievement: e?.target.value,
    });
  };
  // handle duty data change
  const handleDuty = (e) => {
    setProfessionalInfo({ ...professionalInfo, duty: e.target.value });
  };
  // current position options (MUI Autocomplete)
  const currentPositionOptions = [
    { title: "Developer" },
    { title: "Hr" },
    { title: "Accountant" },
    { title: "Office assistant" },
  ];
  // current institute option (MUI Autocomplete)
  const currentInstituteOptions = [
    { title: "Developer company" },
    { title: "ItCO " },
    { title: "Microsoft" },
    { title: "Google" },
  ];

  const navigate = useNavigate();
  //data submission
  const onSubmit = async () => {
    const data = {
      ...professionalInfo,
    };
    if (currentInstitute) {
      data.institute = currentInstitute?.title;
    }
    if (currentPosition) {
      data.position = currentPosition?.title;
    }
    if (professionalAchievementMoment !== "") {
      data.certificates = { photo: professionalAchievementMoment };
    }

    await updateProfessionalDetails({ data, id });
  };

  const specialAchievementMomentHandler = async (e) => {
    const photo = e.target.files[0];
    setAchievementMomentName(photo?.name);
    if (photo) {
      const formData = new FormData();
      formData.append("image", photo);
      uploadPhotoOnServer(formData);
    }
  };

  useEffect(() => {
    if (uploadedPhoto?.data)
      setProfessionalAchievementMoment(uploadedPhoto?.data[0]?.path);
  }, [uploadedPhoto]);

  // know the current viewport

  const handleResize = () => {
    const { innerWidth } = window;

    if (innerWidth < 768) {
      setViewport("mobile");
    } else if (innerWidth >= 768 && innerWidth < 1024) {
      setViewport("tablet");
    } else {
      setViewport("desktop");
    }
  };

  useEffect(() => {
    handleResize(); // Initial check when component mounts

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const key = "updated";
    if (isLoading) {
      messageApi.open({
        key,
        type: "loading",
        content: "Loading...",
      });
    }

    if (isSuccess) {
      messageApi.open({
        key,
        type: "success",
        content: "Data updated succesfully",
        duration: 2,
      });
    }

    if (isError) {
      messageApi.open({
        key,
        type: "error",
        content: "Server error! try again!!",
      });
    }
    if (!isLoading && !isError && isSuccess) {
      setTimeout(() => {
        navigate(-1);
      }, 2000);
    }
  }, [isSuccess, isLoading, isError, messageApi, navigate, viewport]);
  // filter for mui autocomplete
  const filter = createFilterOptions();
  const dateFormat = "YYYY/MM/DD";

  // Scroll to top
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="max-w-[523px] mx-auto bg-white drop-shadow-lg px-4 py-6 mb-4 rounded text-left">
      <div className="hidden lg:flex justify-end mb-3">
        <MdCancel
          onClick={() => navigate(-1)}
          className="cursor-pointer text-3xl text-slate-600"
        />
      </div>
      {professionDataLoading ? (
        <OvalLoader />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="pb-4">
            <div className="flex flex-col md:flex-row justify-between">
              {/* Position */}
              <div>
                <label className="text-sm block pb-2 text-slate-600 font-medium">
                  Position
                </label>
                <Autocomplete
                  className="mb-2 w-full md:w-56"
                  defaultValue={position}
                  onChange={handleCurrentPosition}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some(
                      (option) => inputValue === option.title
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        inputValue,
                        title: `Add "${inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="free-solo-with-text-demo"
                  options={currentPositionOptions}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === "string") {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.title;
                  }}
                  renderOption={(props, option) => (
                    <li {...props}>{option.title}</li>
                  )}
                  freeSolo
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Position" />
                  )}
                  sx={{
                    "& input": {
                      height: 6,
                      padding: 0,
                    },
                  }}
                />
              </div>
              {/* Institute */}
              <div>
                <label className="text-sm block pb-2 text-slate-600	  font-medium">
                  Institute
                </label>
                <Autocomplete
                  className="mb-2 w-full md:w-56"
                  defaultValue={institute}
                  onChange={handleCurrentInstitute}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some(
                      (option) => inputValue === option.title
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        inputValue,
                        title: `Add "${inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="free-solo-with-text-demo"
                  options={currentInstituteOptions}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === "string") {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.title;
                  }}
                  renderOption={(props, option) => (
                    <li {...props}>{option.title}</li>
                  )}
                  freeSolo
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Institute" />
                  )}
                  sx={{
                    "& input": {
                      height: 6,
                      padding: 0,
                    },
                  }}
                />
              </div>
            </div>
          </div>
          {/* duty */}
          <div className="pb-4">
            <div>
              <label
                htmlFor="nid"
                className="text-sm block pb-2 text-slate-600 font-medium"
              >
                Duty
              </label>
              <TextArea
                rows={4}
                placeholder="Text Here"
                defaultValue={duty}
                onChange={handleDuty}
              />
            </div>
          </div>
          {/* Working period */}
          <div className="pb-4">
            <div>
              <label
                htmlFor="nid"
                className="text-sm block pb-2 text-slate-600	  font-medium"
              >
                Working Period
              </label>
              <RangePicker
                defaultValue={
                  workPeriod && [
                    dayjs(workPeriod[0], dateFormat),
                    dayjs(workPeriod[1], dateFormat),
                  ]
                }
                className="w-full"
                onChange={handleWorkingPeriod}
              />
            </div>
          </div>
          {/* Special achievement */}
          <div className="pb-4">
            <div>
              <label
                htmlFor="nid"
                className="text-sm block pb-2 text-slate-600 font-medium"
              >
                Special Achievements
              </label>
              <TextArea
                rows={4}
                placeholder="Text Here"
                name="achivements"
                defaultValue={specialAchievement}
                onChange={handleAchivements}
              />
            </div>
          </div>
          {/* ---------- Professional Achievement moment ---------- */}
          <section>
            <label className="text-sm block pb-2 text-slate-600 font-medium">
              Profession Proof Certificate(ID Card/Employment Certificate)
            </label>
            <div className="flex items-center bg-gray-100 p-3 w-full rounded-lg mt-3 lg:mt-0 mb-4">
              <AiOutlineCloudUpload className=" mr-2 text-gray-400" />
              <label
                htmlFor="certificates"
                className="outline-none h-full text-sm text-gray-400 bg-gray-100"
              >
                {professionalAchievementMoment ? (
                  <>
                    <span className="text-green-400">
                      {achievementMomentName
                        ? achievementMomentName
                        : "File added"}
                    </span>
                  </>
                ) : (
                  "Upload File"
                )}
              </label>
              <input
                name="certificates"
                type="file"
                id="certificates"
                className="hidden"
                onChange={specialAchievementMomentHandler}
              />
            </div>
          </section>

          <div>
            <input
              type="submit"
              value="Save"
              style={{
                background: "linear-gradient(180deg, #E41272 0%, #942DD9 100%)",
              }}
              className="w-full text-center py-[10px] text-[#fff]  text-lg font-medium rounded cursor-pointer"
            />

            <div className="mt-2">{contextHolder}</div>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditProfessionalInfo;

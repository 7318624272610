// Configuration
import React from "react";
import { useNavigate } from "react-router-dom";

// Third party package
import { TbCurrencyTaka } from "react-icons/tb";

const SingleCourseCard = ({ course, pageTitle }) => {
  const navigate = useNavigate();
  return (
    <div className="shadow-[2px_2px_10px_2px_rgba(0,0,0,0.12)] w-[280px] rounded-2xl">
      <img
        src={course?.image}
        alt="Course Banner"
        className="w-full h-[200px] rounded-t-lg"
      />
      <div className="p-3 bg-white rounded-b-lg">
        <h3 className="text-[#343b6d] text-left text-xl font-Inter font-medium h-28">
          {course?.name || ""}
        </h3>
        <div className="flex items-center gap-4">
          <div className="flex items-center">
            <TbCurrencyTaka className="text-lg text-[#c1c1c1] mr-1" />
            <p className="text-2xl text-[#c1c1c1] font-bold mb-2 line-through">
              {course?.oldPrice || ""}
            </p>
          </div>
          <div className="flex items-center">
            <TbCurrencyTaka className="text-lg mr-1" />
            <p className="text-2xl text-[#343b6d] font-bold mb-2">
              {course?.price || ""}
            </p>
          </div>
        </div>
        {pageTitle === "editCourse" && (
          <button
            onClick={() => navigate(`/courses/editCourse/${course?._id}`)}
            className="py-3 px-[45px] rounded-lg bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] w-[250px] text-white font-medium text-lg"
          >
            Edit Course
          </button>
        )}
        {pageTitle === "viewCourse" && (
          <button
            onClick={() => navigate(`/courses/viewCourse?id=${course?._id}`)}
            className="py-3 px-[45px] rounded-lg bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] w-[250px] text-white font-medium text-lg"
          >
            View Details
          </button>
        )}
        {pageTitle === "addCourseSection" && (
          <button
            onClick={() => navigate(`/courses/addCourseSection/${course?._id}`)}
            className="py-3 px-[45px] rounded-lg bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] w-[250px] text-white font-medium text-lg"
          >
            Add Section
          </button>
        )}
        {pageTitle === "modifyCourseReview" && (
          <button
            onClick={() =>
              navigate(`/courses/modifyCourseReview/${course?._id}`)
            }
            className="py-3 px-[45px] rounded-lg bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] w-[250px] text-white font-medium text-lg"
          >
            Modify Review
          </button>
        )}
        {pageTitle === "addCourseQuiz" && (
          <button
            onClick={() => navigate(`/courses/quiz/${course?._id}`)}
            className="py-3 px-[45px] rounded-lg bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] w-[250px] text-white font-medium text-lg"
          >
            Add Quiz
          </button>
        )}
      </div>
    </div>
  );
};

export default SingleCourseCard;

// Configuration
import React, { useEffect } from "react";

// Third party packages
import { Button, Modal } from "antd";
import { toast } from "react-hot-toast";
import { RxCross2 } from "react-icons/rx";
import { BsFillCartXFill } from "react-icons/bs";

// Components
import { useUpdateSingleProductMutation } from "../../../../../Redux/features/Shop/shopApi";

const OutOfStockModal = ({
  outOfStockModalOpen,
  handleOutOfStockModalCancel,
  setOutOfStockModalOpen,
  selectedProduct,
  setIsProductDeleted,
}) => {
  const [updateSingleProduct, { data: response, isLoading, error }] =
    useUpdateSingleProductMutation();

  const title = (
    <div className="flex items-center gap-1">
      <BsFillCartXFill className="text-2xl" />
      <p className="text-3xl leading-6 font-Inter font-normal">
        Mark Out Of Stock
      </p>
    </div>
  );

  const handleDeleteProductOk = async () => {
    await updateSingleProduct({
      id: selectedProduct,
      data: { stock: 0 },
    });
  };

  useEffect(() => {
    if (response) {
      toast.success("Product successfully marked out of stock");
      setOutOfStockModalOpen(false);
    }
    if (error) {
      toast.error(
        <p>
          Something went wrong <br /> Please try again later
        </p>
      );
      setOutOfStockModalOpen(false);
    }
  }, [response, error, setOutOfStockModalOpen, setIsProductDeleted]);

  return (
    <Modal
      open={outOfStockModalOpen}
      closeIcon={<RxCross2 className="text-[#202020] mx-auto" />}
      title={title}
      onOk={handleDeleteProductOk}
      onCancel={handleOutOfStockModalCancel}
      footer={[
        <Button
          key="back"
          onClick={handleOutOfStockModalCancel}
          className="text-[#E41272] h-12 px-6 text-xl font-bold rounded-[40px]"
        >
          Dismiss
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isLoading}
          onClick={handleDeleteProductOk}
          className="bg-[#E41272] h-12 px-6 text-xl text-white font-bold rounded-[40px]"
        >
          Confirm
        </Button>,
      ]}
    >
      <p className="text-2xl leading-6 font-Inter my-8">
        Confirm Mark This Product Out Of Stock?
      </p>
    </Modal>
  );
};

export default OutOfStockModal;

import React from "react";
import { useGetProfileDetailsQuery } from "../../../../../Redux/features/User/userApi";
import "./CaseStudyCard.css";
export const CaseStudyCard = ({id}) => {
    const {data} = useGetProfileDetailsQuery(id)
    return (
        <div>
            <div className="w-[385px] h-[222px] bg-case-study-card rounded-[8px] px-[35px] py-[31px] mx-auto ">
                <div className="flex gap-x-[24px]">
                    <img className="w-[85px] h-[85px] rounded-full" src={data?.profilePhoto} alt="ssss" />
                    <div className="flex flex-col gap-y-[18px]">
                        <h1 className=" text-[24px] leading-[29px] font-medium">{`${data?.firstName}`}</h1>
                        <p className=" text-[20px] leading-[24px] font-medium">Age: {data?.age}</p>
                        <button className="w-[135px] h-[37px] mt-[20px] flex justify-center items-center bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] text-white rounded-full">
                            Selected
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

/* eslint-disable react-hooks/exhaustive-deps */
import React,{useEffect} from 'react';
import { usePhotosUploadOnServerMutation } from '../../../../Redux/features/fileUpload/fileUploadApi';
import { Oval } from 'react-loader-spinner';
import { AiOutlinePlus } from 'react-icons/ai';
import Error from '../accordion/UI/Error/Error';

const ImageUploader1 = ({ image,setImage,title }) => {
    const [
      photosUploadOnServer,
      {
        data: imageUploadResponse,
        isLoading: imageUploadLoading,
        error: imageUploadError,
      },
    ] = usePhotosUploadOnServerMutation();

    const imageUploadHandler = (e) => {
      if (e.target.files) {
        const formData = new FormData();
        const image = e.target.files[0];
        formData.append("image", image);
        photosUploadOnServer(formData);
      }
    };

    useEffect(() => {
        if (imageUploadResponse) {
          const image = imageUploadResponse?.data[0]?.path;
          setImage(image);
        }
    },[imageUploadResponse])
    return (
      <div className="w-full flex flex-col">
        <label className="text-left mb-3 text-[12px] font-bold text-[#707276] ml-[5px]">
          {title}
        </label>
        <div className="flex justify-start items-center gap-x-[22px] w-full">
          <label
            htmlFor="imageUpload"
            className="flex items-center justify-start pl-6 gap-x-[27px] bg-white w-full h-[60px] shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[4px] cursor-pointer"
          >
            {imageUploadLoading ? (
              <Oval
                height={20}
                width={20}
                color="#CC1A7A"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#A72CC7"
                strokeWidth={4}
                strokeWidthSecondary={4}
              />
            ) : (
              <>
                {image ? (
                  <>
                    <span className="text-green-400 font-semibold font-Inter">
                      Banner Added
                    </span>
                    <img
                      src={image}
                      alt="banner"
                      className="w-[30px] h-[30px] rounded-full"
                    />
                  </>
                ) : (
                  <p className="flex items-center gap-x-2 text-[#707276] text-sm">
                    Upload Banner
                    <AiOutlinePlus />
                  </p>
                )}
              </>
            )}
          </label>
        </div>
        <input
          type="file"
          name="imageUpload"
          id="imageUpload"
          className="hidden"
          onChange={imageUploadHandler}
        />
          {imageUploadError && <Error message={"Couldn't upload photo. please try again"} />}
      </div>
    );
}

export default ImageUploader1;

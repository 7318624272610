import React, { useEffect } from "react";

// Third party packages
import { toast } from "react-hot-toast";

// Components
import { useUpdateOrderStatusMutation } from "../../../../../../../Redux/features/Shop/shopApi";

const DeliveredBtn = ({
  data: order,
  setDeliveredSuccess,
  cancelledSuccess,
}) => {
  const [updateOrderStatus, { data: response, isLoading, error }] =
    useUpdateOrderStatusMutation();

  const handleUpdateDeliveredStatus = async () => {
    const data = {
      status: "Delivered",
    };
    await updateOrderStatus({ id: order?.singleOrder?._id, data: data });
  };

  useEffect(() => {
    if (response) {
      toast.success("Order Delivered Successfully");
      setDeliveredSuccess(true);
    }
    if (error) {
      toast.error(
        <p>
          Something Went Wrong
          <br /> Please try again later
        </p>
      );
    }
  }, [response, error, setDeliveredSuccess]);

  return (
    <div className={`${cancelledSuccess === true && "hidden"}`}>
      {order?.singleOrder?.orderStatus !== "Cancelled" && (
        <button
          className="w-[342px] h-[80px] rounded-[100px] bg-[linear-gradient(90deg,#E41272_0%,_#A72CC7_100%)] text-[24px] text-center text-white tracking-[1.25px] font-semibold font-Inter uppercase"
          onClick={() => handleUpdateDeliveredStatus()}
          disabled={response || order?.singleOrder?.orderStatus === "Delivered"}
        >
          {isLoading
            ? "Loading..."
            : response
            ? "Delivered"
            : order?.singleOrder?.orderStatus === "Delivered"
            ? "Delivered"
            : "Mark as delivered"}
        </button>
      )}
    </div>
  );
};

export default DeliveredBtn;

import React, { useRef ,useEffect,useState} from "react";
import { FiSearch } from "react-icons/fi";
import { useGetAllProductsQuery } from "../../../../../Redux/features/Shop/shopApi";

const SearchProducts = ({  setProducts ,limit,page}) => {
  const input = useRef(null);
  const [inputText, setInputText] = useState("");
  
  const { data, isLoading, error } = useGetAllProductsQuery({keyword:inputText,limit:limit,page:page})
  useEffect(() => {
    setProducts({ data: data?.products, isLoading, error });
  }, [data, isLoading, error, setProducts]);

  useEffect(() => {
    let typingTimer;
    input.current.addEventListener("keyup", function () {
      typingTimer && clearTimeout(typingTimer);

      if (input.current.value) {
        typingTimer = setTimeout(function () {
          setInputText(input.current.value);
        }, 300); //user is "finished typing," send search request
      } else {
        setInputText("");
      }
    });

    //on keydown, clear the countdown
    input.current.addEventListener("keydown", function () {
      typingTimer && clearTimeout(typingTimer);
    });
  });

  return (
    <div>
      <div className="flex mx-auto items-center w-[492px] h-[52px] bg-[#FFFFFF] border border-[#FFE8F3] py-[14px] px-[21px] text-base rounded-lg gap-6">
        <FiSearch className="text-[#353751] text-2xl" />
        <input
          ref={input}
          className="font-poppins font-normal text-[#AAAAAA] focus: outline-none h-full w-full"
          name="searchUser"
          id="search_many"
          placeholder="Search for Products"
        />
      </div>
    </div>
  );
};

export default SearchProducts;

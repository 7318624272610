import { Search } from "@mui/icons-material";
import React, { useEffect, useRef } from "react";
import { useGetUserListByEmailOrNameMutation } from "../../../../Redux/features/User/userApi";

const SearchSingleUser = ({ setUser, role }) => {
  const input = useRef(null);
  //   const [typingTimer, setTypingTimer] = useState(null);
  const [getUserList, { data, isLoading, error }] =
    useGetUserListByEmailOrNameMutation();

  useEffect(() => {
    let typingTimer;
    input.current.addEventListener("keyup", function () {
      typingTimer && clearTimeout(typingTimer);

      if (input.current.value) {
        typingTimer = setTimeout(function () {
          getUserList({ text: input.current.value, role });
        }, 300); //user is "finished typing," send search request
      }
    });

    //on keydown, clear the countdown
    input.current.addEventListener("keydown", function () {
      typingTimer && clearTimeout(typingTimer);
    });
  });

  const handleClick = (item) => {
    setUser(item);
    input.current.value = "";
  };

  return (
    <div className="flex flex-col relative items-center mt-4">
      <div className="flex items-center">
        <Search
          className="absolute text-gray-500 pl-2 text-xl"
          style={{ width: 30 }}
        />
        <input
          ref={input}
          id="search"
          className="shadow drop-shwdow rounded placeholder-gray-500  text-black py-2 pr-3 pl-8  border-2 border-purple-100 focus:border-none focus:outline-none focus:ring-2 w-[80vh]"
          placeholder="search user name or email"
        />
      </div>
      {(isLoading || error || data?.data) && input.current.value && (
        <div id="myElement"
          style={{ zIndex: 999 }}
          className="absolute shadow-md rounded top-12 p-3 overflow-x-auto h-48 bg-white w-full"
        >
          {error && <div>{error?.data?.message}</div>}
          {isLoading && !error && <div>Loading...</div>}
          {!isLoading &&
            !error &&
            data?.data?.users?.map((item) => {
              return (
                <div
                  onClick={() => handleClick(item)}
                  key={item._id}
                  className="flex cursor-pointer items-center my-2 hover:bg-gray-100 p-2 rounded-lg"
                >
                  <div className="w-[45px] h-[45px] mr-3 bg-white rounded-full overflow-hidden">
                    <img src={item?.profilePhoto} alt="go" />
                  </div>
                  <p className="text-xl font-semibold italic text-gray-500">
                    {`${item.firstName} ${item.lastName}`}
                  </p>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default SearchSingleUser;

import React, { useState } from "react";
/* React icon */
import { CiCircleCheck } from "react-icons/ci";
import { MdCheckBox } from "react-icons/md";
/* table user img */
import { useGetRecentBadgeUsersQuery } from "../../../../../Redux/features/Badge/badgeApi";
import { LineWaveLoader } from "../../../../Shared/Loader/LineWaveLoader";
import { Pagination } from "@mui/material";

const AddBadgeTable = () => {
  const [page, setPage] = useState(1);
  const { data, isLoading, isError } = useGetRecentBadgeUsersQuery({
    page,
    limit: 8,
  });
  const { badge, total } = data || {};

  let content;
  if (isLoading) {
    content = (
      <div>
        <LineWaveLoader title="Loading..." />
      </div>
    );
  } else if (!isLoading && isError) {
    content = (
      <div className="w-full h-[200px] flex justify-center items-center text-gray-400">
        Something Went Wrong
      </div>
    );
  } else if (!isLoading && badge) {
    content = (
      <tbody className="w-full">
        {badge?.map((item) => {
          const { userId, badge } = item || {};
          return (
            <tr key={item._id} className="h-[100px]">
              <td className="text-2xl text-[#A560DC] rounded w-[10%]">
                <MdCheckBox />
              </td>
              <td className="flex gap-4 items-center w-[45%]">
                <img
                  src={userId?.profilePhoto}
                  className="w-[50px] h-[50px] rounded-full"
                  alt="not available"
                />
                <div className="text-left">
                  <p className="mb-1">
                    {userId?.firstName} {userId?.lastName}
                  </p>
                  <p className="font-normal text-sm text-[#8E8EA1]">
                    {userId?.email}
                  </p>
                </div>
              </td>
              <td className="w-[30%]">
                <div className="w-full flex items-center justify-center">
                  <img
                    className={`w-[65px] h-[65px] rounded-full border-[3px] border-green-400`}
                    src={badge?.icon}
                    alt="badge"
                  />
                </div>
              </td>
              <td className="w-[15%]">
                <div className="flex gap-[9px] items-center font-normal text-sm text-[#FFFFFF] py-[15px] px-[27px] mr-5 bg-[#A560DC] rounded-[40px]">
                  <CiCircleCheck className="text- lg" />
                  <button>Sent</button>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  }

  return (
    <div
      style={{ boxShadow: "3px 3px 10px 2px rgba(0, 0, 0, 0.12)" }}
      className="bg-[#FFFFFF] pl-12 pr-4 rounded-2xl mb-[50px]"
    >
      <h1 className="font-nunito font-bold text-left text-[32px] text-[#1D252D] py-[15px]">
        Recent
      </h1>
      <table className="font-inter w-full font-semibold text-lg text-[#22242C]">
        {/* Table head */}
        <thead className="w-full">
          <tr className="w-full pt-7 pb-[25px] mb-8 h-[85px] border-t border-t-[#000000] border-b border-b-[#000000]">
            <th className="text-left"># </th>
            <th className="text-left pl-4"> User</th>
            <th className="text-center">Badge</th>
            <th className="text-center">Status</th>
          </tr>
        </thead>
        {/* Table data */}
        {content}
      </table>
      <div className="flex justify-between px-8 items-center w-full py-12">
        <p className="leading-6 font-medium">
          Showing {badge?.length} from {total} data
        </p>
        <Pagination
          className="bg-[#E3E4EB] px-[1px] py-[3px] rounded-md text-white"
          onChange={(event, value) => {
            setPage(value);
          }}
          count={Math.ceil(total / 8) || 1}
          shape="rounded"
        />
      </div>
    </div>
  );
};

export default AddBadgeTable;

// Configuration
import React, { useState } from "react";

// Components
import OtherDetailsDescription from "./OtherDetailsDescription/OtherDetailsDescription";
import OtherDetailsCompare from "./OtherDetailsCompare/OtherDetailsCompare";
import OtherDetailsReviews from "./OtherDetailsReviews/OtherDetailsReviews";

const SingleProductOtherDetails = ({
  product,
  description,
  similarProducts,
}) => {
  const [page, setPage] = useState(1);

  return (
    <div>
      {/* Other details nav */}
      <div>
        <div className="flex justify-start items-center gap-12">
          <button
            onClick={() => setPage(1)}
            className={
              page === 1
                ? `text-[#71717A] text-sm text-left leading-[22px] w-24 pb-4 font-bold border-b-2 border-black duration-500`
                : "text-[#71717A] text-sm text-left leading-[22px] w-24 pb-4 font-bold duration-500"
            }
          >
            Description
          </button>
          <button
            onClick={() => setPage(2)}
            className={
              page === 2
                ? `text-[#71717A] text-sm text-left leading-[22px] w-24 pb-4 font-bold border-b-2 border-black duration-500`
                : "text-[#71717A] text-sm text-left leading-[22px] w-24 pb-4 font-bold duration-500"
            }
          >
            Reviews
          </button>
          <button
            onClick={() => setPage(3)}
            className={
              page === 3
                ? `text-[#71717A] text-sm text-left leading-[22px] w-24 pb-4 font-bold border-b-2 border-black duration-500`
                : "text-[#71717A] text-sm text-left leading-[22px] w-24 pb-4 font-bold duration-500"
            }
          >
            Compare
          </button>
        </div>
        <hr className="border border-[#E4E4E7]" />
      </div>

      <div>
        {page === 1 && <OtherDetailsDescription {...{ description }} />}
        {page === 2 && <OtherDetailsReviews {...{ product }} />}
        {page === 3 && <OtherDetailsCompare {...{ similarProducts }} />}
      </div>
    </div>
  );
};

export default SingleProductOtherDetails;

// Configuration
import React, { useEffect, useState } from "react";
import { useGetAllBadgesQuery } from "../../../Redux/features/Badge/badgeApi";
import { BrandLoader } from "../../Shared/Loader/BrandLoader";
import { AddBadgeFormContainer } from "./AddBadgeFormContainer";

// Components
import { BadgeCard } from "./BadgeCard";
import { BadgeEdit } from "./BadgeEdit";

export const Badge = () => {
    // Hook variable declaration
    const [editOpen, setEditOpen] = useState(false);
    const [editBadgeId, setEditBadgeId] = useState("");
    const [currentBadgeDetailesForEdit, setCurrentBadgeDetailesForEdit] = useState(null);
    const [revalidateGetBadges, setRelavidateGetBadges] = useState(true);
    // Redux Api
    const {
        data: badges,
        isLoading,
        error,
    } = useGetAllBadgesQuery(undefined, {
        skip: revalidateGetBadges,
        refetchOnFocus: true,
    });

    useEffect(() => {
        setRelavidateGetBadges(false);
    }, [revalidateGetBadges]);

    // function declaration
    const modalControll = () => {
        setEditOpen(!editOpen);
    };

    const callBadgeEdit = id => {
        setEditBadgeId(id);
        modalControll();
    };

    // JS Variable declaration
    let content;
    if (isLoading) {
        content = (
            <div>
                <BrandLoader />
            </div>
        );
    } else if (!isLoading && badges?.data?.batch?.length === 0) {
        content = <div className="w-full h-[500px] flex justify-center items-center text-[20px]">No badge found!</div>;
    } else if (error) {
        content = <div className="w-full h-[500px] flex justify-center items-center text-[20px]">Something went wrong! Please try again later.</div>;
    } else if (!isLoading && badges?.data?.batch?.length > 0) {
        content = (
            <div className="w-full grid grid-cols-4 gap-[24px]">
                {badges?.data?.batch?.map(badge => {
                    return (
                        <BadgeCard
                            key={badge._id}
                            badge={badge}
                            callBadgeEdit={callBadgeEdit}
                            setCurrentBadgeDetailesForEdit={setCurrentBadgeDetailesForEdit}
                        />
                    );
                })}
            </div>
        );
    }
    return (
        <>
            <section className="pt-[43px] px-[40px] min-h-screen">
                <AddBadgeFormContainer setRelavidateGetBadges={setRelavidateGetBadges} />
                <div className="mt-[83px]">
                    <h3 className="text-[28px] text-[#32353B] font-semibold font-poppins text-left">All Badges</h3>
                    <div className="mt-[31px]">{content}</div>
                    {editOpen && (
                        <BadgeEdit
                            id={editBadgeId}
                            modalControll={modalControll}
                            setRelavidateGetBadges={setRelavidateGetBadges}
                            currentBadgeDetailesForEdit={currentBadgeDetailesForEdit}
                        />
                    )}
                </div>
            </section>
        </>
    );
};

// configuration
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";

// Third party packages
import { AiOutlineSave } from "react-icons/ai";

// Components
import { useUpdateSingleProductMutation } from "../../../../Redux/features/Shop/shopApi";

export const EditPrice = ({ price, id }) => {
  const [editedPrice, setEditedPrice] = useState("");
  const [updateSingleProduct, { data: response, isLoading, error }] =
    useUpdateSingleProductMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await updateSingleProduct({
      id: id,
      data: { price: editedPrice || price },
    });
  };

  useEffect(() => {
    if (response) {
      toast.success("Price updated successfully");
      setEditedPrice("");
    }
    if (error) {
      toast.error(
        <p>
          Something went wrong <br /> Please login or, try again later
        </p>
      );
    }
  }, [response, error]);

  return (
    <div className="mt-[93px]">
      <h1 className="text-[#000000] text-[32px] font-bold font-Inter text-left">
        Change Price
      </h1>
      <div>
        <form
          onSubmit={handleSubmit}
          className="w-full flex justify-start mt-[32px] items-center gap-x-[100px]"
        >
          <input
            type="number"
            value={editedPrice}
            className="w-[210px] h-[98px] pl-[35px] bg-white rounded-[32px] shadow-[2px_2px_10px_2px_rgba(0,0,0,0.12)] text-[32px] text-[#18181B] outline-none"
            placeholder={"Price"}
            onChange={(e) => setEditedPrice(e.target.value)}
          />
          <button
            type="submit"
            className="flex items-center justify-center min-w-[118px] px-4 h-[48px] bg-[linear-gradient(90deg,#E41272_0%,_#A72CC7_100%)] rounded-[100px] gap-x-[10px] text-white tracking-[1.25px] font-Inter font-semibold text-[16px]"
          >
            {isLoading ? "Loading..." : "SAVE"}{" "}
            <AiOutlineSave className="text-[16px]" />
          </button>
        </form>
      </div>
    </div>
  );
};

// Configuration
import React, { useState } from "react";

// Components
import CourseInfo from "./CourseInfo/CourseInfo";
import CourseOutline from "./CourseOutline/CourseOutline";
import Faq from "./Faq/Faq";
import InstructorInfo from "./InstructorInfo/InstructorInfo";

const CourseDetailsTab = ({ course }) => {
  const [tab, setTab] = useState(1);
  return (
    <div className="w-full h-auto">
      <div className="mt-[73px] w-full bg-gray-200 h-[45px] flex overflow-hidden relative">
        <button
          className={`flex-1 ${
            tab === 1 ? "bg-[#354895] text-white" : "bg-transparent text-black"
          } font-Nunito font-normal`}
          style={{ transition: "background-color 0.5s ease" }}
          onClick={() => setTab(1)}
        >
          <span className="z-40">Course Info</span>
        </button>
        <button
          className={`flex-1 ${
            tab === 2
              ? "text-white font-bold bg-[#354895]"
              : "bg-transparent text-black"
          } font-Nunito font-normal`}
          style={{ transition: "background-color 0.5s ease" }}
          onClick={() => setTab(2)}
        >
          Instructor
        </button>
        <button
          className={`flex-1 ${
            tab === 3
              ? "text-white font-bold bg-[#354895]"
              : "bg-transparent text-black"
          } font-Nunito font-normal`}
          style={{ transition: "background-color 0.5s ease" }}
          onClick={() => setTab(3)}
        >
          Course Outline
        </button>
      </div>
      {tab === 1 && <CourseInfo course={course} />}
      {tab === 2 && <InstructorInfo course={course} />}
      {tab === 3 && <CourseOutline sections={course?.sections} />}
      <Faq course={course} />
    </div>
  );
};
export default CourseDetailsTab;

// configuration, ex: react-router
import React,{useState} from "react";

// components
import { RecentApprovedUserForLawyer } from "./RecentApprovedUserForLawyer";
import { RecentRegReqForLawyer } from "./RecentRegReqForLawyer";
import { RecentRejectedUserForLawyer } from "./RecentRejectedUserForLawyer";
import { ReSubmitForLawyer } from "./ReSubmitForLawyer";

export const ViewRegistrationRequestForLawyer = () => {
    const [type, setType] = useState("");
    return (
        <div className="max-w-[900px] mx-auto">
            {(type==="registration" || !type) && <RecentRegReqForLawyer type={type} setType={setType} role="lawyer"/>}
            {(type==="resubmit" || !type) && <ReSubmitForLawyer type={type} setType={setType} role="lawyer"/>}
            {(type==="approved" || !type) && <RecentApprovedUserForLawyer type={type} setType={setType} role="lawyer"/>}
            {(type==="rejected" || !type) && <RecentRejectedUserForLawyer type={type} setType={setType} role="lawyer"/>}
        </div>
    );
};

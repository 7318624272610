import React from 'react';
/* Pagination icon */
import leftArrow from '../../../../../assets/kazi/leftArrow.png';
import rightArrow from '../../../../../assets/kazi/rightArrow.png';

const DealHistoryPagination = () => {
    return (
        <div className=' flex items-center justify-between mt-[79px] mb-[70px] font-poppins font-medium text-lg' >
            <p className="leading-6 font-medium">Showing 6 from 160 data</p>
            <nav aria-label="Page navigation example " className=' flex items-center'>
                <div className="flex px-[14px] py-[14px] leading-tight text-white bg-[#969BA0] border-gray-300 rounded-lg">
                    <img className='mr-[5px]' src={leftArrow} alt="arrow" />
                    <span>Previous</span>
                </div>

                <ul className="inline-flex ml-[14px] mr-[26px] -space-x-px bg-gray-300 p-[5px] rounded items-center gap-1">
                    <li>
                        <a href="!#" className="leading-tight text-gray-500 bg-white w-[47px] h-[43px] rounded flex items-center justify-center hover:bg-gray-100 hover:text-gray-700 ">1</a>
                    </li>
                    <li>
                        <a href="!#" className="leading-tight text-[#E41272]  w-[47px] h-[43px] rounded flex items-center justify-center hover:bg-gray-100 hover:text-gray-700 ">2</a>
                    </li>
                    <li>
                        <a href="!#" className="leading-tight text-[#E41272] w-[47px] h-[43px] rounded flex items-center justify-center  hover:bg-gray-100 hover:text-gray-700 ">3</a>
                    </li>
                    <li>
                        <a href="!#" className="leading-tight text-[#E41272] w-[47px] h-[43px] rounded flex items-center justify-center  hover:bg-gray-100 hover:text-gray-700 ">4</a>
                    </li>
                </ul>

                <div className="flex px-[14px] py-[14px] leading-tight bg-[#E41272] border border-gray-300 rounded-lg text-white">
                    <span>Next</span>
                    <img className='ml-[5px]' src={rightArrow} alt="arrow" />
                </div>
            </nav>
        </div>
    );
};

export default DealHistoryPagination;
import React from "react";

const ReferUserRow = ({ item, openedRefer, setOpenedRefer }) => {
  const { firstName, lastName } = item?.referredBy || {};
  const referrerFullName = firstName
    ? `${firstName} ${lastName}`
    : "No referrer";
  const handleClick = () => {
    if (item._id === openedRefer) {
      setOpenedRefer("");
    } else {
      setOpenedRefer(item._id);
    }
  };
  return (
    <>
      <tr className="border-b-2 ">
        <td className=" px-4 py-2">
          <span className="relative h-8 w-8">
            <button
              className="bg-[#e41272] px-1.5 rounded text-white"
              onClick={handleClick}
            >
              {openedRefer === item._id ? "-" : "+"}
            </button>
          </span>
        </td>
        <td className="text-left px-4 py-2 font-semibold">{item?.name}</td>
        <td className="text-left px-4 py-2">Demo@gmail.com</td>
        <td className=" px-4 py-2 font-semibold">{item?.referredTo?.length}</td>
        <td className=" px-4 py-2 font-semibold">{referrerFullName}</td>
      </tr>
      {openedRefer === item._id && (
        <tr>
          <td
            colspan="5"
            className=" pl-16 bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)]"
          >
            <div className="w-full">
              <table className="w-full">
                <thead>
                  <tr className="border-b-3">
                    <th className="text-left border-b-2 py-2 px-4">#_id </th>
                    <th className="text-left border-b-2 py-2 px-4">
                      First Name{" "}
                    </th>
                    <th className="text-left border-b-2 py-2 px-4">
                      Last Name
                    </th>
                  </tr>
                  {item?.referredTo?.map((referral) => {
                    return (
                      <tr>
                        <td className="text-left text-white border-b-2 py-2 px-4">{referral._id}</td>
                        <td className="text-white text-left border-b-2 py-2 px-4">
                          {referral.firstName}
                        </td>
                        <td className="text-white text-left border-b-2 py-2 px-4">
                        {referral.lastName}
                        </td>
                      </tr>
                    );
                  })}
                  {/* {item?.referredTo?.map((item) => {
                    return (
                      <tr>
                        <td>robin</td>
                      </tr>
                    );
                  })} */}
                </thead>
              </table>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default ReferUserRow;

import getCookie from "../../../helper/cookies/getCookie";
import { apiSlice } from "../../api/apiSlice";

export const courseApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllCourseTypes: builder.query({
      query: () => ({
        url: "/course/all-types",
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      keepUnusedDataFor: 0,
      providesTags: ["CourseType"],
    }),
    addCourseType: builder.mutation({
      query: (data) => ({
        url: `/course/create-type`,
        method: "POST",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["CourseType"],
    }),
    deleteCourseType: builder.mutation({
      query: (id) => ({
        url: `/course/delete-type/${id}`,
        method: "DELETE",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      invalidatesTags: ["CourseType"],
    }),
    updateCourseType: builder.mutation({
      query: ({ id, data }) => ({
        url: `/course/update-type/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["CourseType"],
    }),
    // basic course related api
    createCourse: builder.mutation({
      query: (data) => ({
        url: `/course/create-course`,
        method: "POST",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["Course"],
    }),
    updateCourse: builder.mutation({
      query: ({ id, data }) => ({
        url: `/course/update-course/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["CourseContents"],
    }),
    getAllCourses: builder.query({
      query: ({ page, limit, categoryId, typeId, skill }) => ({
        url: `/course/all-courses?page=${page}&limit=${limit}&categroyId=${categoryId}&typeId=${typeId}&skill=${skill}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      keepUnusedDataFor: 0,
      providesTags: ["Course"],
    }),
    getSingleCourse: builder.query({
      query: (id) => ({
        url: `/course/get-course/${id}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      providesTags: ["CourseContents"],
    }),
    // section related api
    createSection: builder.mutation({
      query: (data) => ({
        url: `/course/section/create-section`,
        method: "POST",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["CourseContents"],
    }),
    updateSection: builder.mutation({
      query: ({ id, data }) => ({
        url: `/course/section/update-section/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["CourseContents"],
    }),
    getAllSectionByCourseId: builder.query({
      query: (id) => ({
        url: `/course/section/admin/all-sections/${id}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      keepUnusedDataFor: 0,
    }),
    // module related api
    createModule: builder.mutation({
      query: (data) => ({
        url: `/course/module/create-module`,
        method: "POST",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["CourseContents"],
    }),
    updateModule: builder.mutation({
      query: ({ id, data }) => ({
        url: `/course/module/update-module/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["CourseContents"],
    }),
    getAllModuleBySectionId: builder.query({
      query: (id) => ({
        url: `/course/module/admin/all-modules/${id}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      keepUnusedDataFor: 0,
    }),
    // Content related api
    createContent: builder.mutation({
      query: (data) => ({
        url: `/course/content/create-content`,
        method: "POST",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["CourseContents"],
    }),
    updateContent: builder.mutation({
      query: ({ id, data }) => ({
        url: `/course/content/update-content/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["CourseContents"],
    }),
    // review api
    updateReview: builder.mutation({
      query: ({ id, data }) => ({
        url: `/course/admin/review/update/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["CourseReview"],
    }),
    getAllReviewsByCourseId: builder.query({
      query: ({ id, page, limit }) => ({
        url: `/course/admin/review/all/${id}?page=${page}&limit=${limit}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      providesTags: ["CourseReview"],
    }),
    // Quiz api
    createQuiz: builder.mutation({
      query: (data) => ({
        url: `/course/quiz/create-quiz`,
        method: "POST",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["CourseContents"],
    }),
    createQuizQuestion: builder.mutation({
      query: (data) => ({
        url: `/course/quiz/create-question`,
        method: "POST",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["CourseContents"],
    }),
    updateQuiz: builder.mutation({
      query: ({ id, data }) => ({
        url: `/course/quiz/update-quiz/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["CourseContents"],
    }),
    updateQuizQuestions: builder.mutation({
      query: ({ id, data }) => ({
        url: `/course/quiz/update-question/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["CourseContents"],
    }),
    deleteCourseQuizById: builder.mutation({
      query: (id) => ({
        url: `/course/quiz/delete-quiz/${id}`,
        method: "DELETE",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      invalidatesTags: ["CourseContents"],
    }),
    deleteCourseQuizQuestion: builder.mutation({
      query: (id) => ({
        url: `/course/quiz/delete-question/${id}`,
        method: "DELETE",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      invalidatesTags: ["CourseContents"],
    }),
    // Delete api
    deleteCourseContent: builder.mutation({
      query: (id) => ({
        url: `/course/content/delete-content/${id}`,
        method: "DELETE",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      invalidatesTags: ["CourseContents"],
    }),
    deleteCourseModule: builder.mutation({
      query: (id) => ({
        url: `/course/module/delete-module/${id}`,
        method: "DELETE",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      invalidatesTags: ["CourseContents"],
    }),
    deleteCourseSection: builder.mutation({
      query: (id) => ({
        url: `/course/section/delete-section/${id}`,
        method: "DELETE",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      invalidatesTags: ["CourseContents"],
    }),
    deleteCourse: builder.mutation({
      query: (id) => ({
        url: `/course/delete-course/${id}`,
        method: "DELETE",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      invalidatesTags: ["Course"],
    }),
  }),
});

export const {
  useGetAllCourseTypesQuery,
  useAddCourseTypeMutation,
  useDeleteCourseTypeMutation,
  useUpdateCourseTypeMutation,
  useCreateCourseMutation,
  useUpdateCourseMutation,
  useGetAllCoursesQuery,
  useGetSingleCourseQuery,
  useCreateSectionMutation,
  useUpdateSectionMutation,
  useCreateModuleMutation,
  useUpdateModuleMutation,
  useCreateContentMutation,
  useUpdateContentMutation,
  useGetAllModuleBySectionIdQuery,
  useGetAllSectionByCourseIdQuery,
  useUpdateReviewMutation,
  useGetAllReviewsByCourseIdQuery,
  useCreateQuizMutation,
  useCreateQuizQuestionMutation,
  useUpdateQuizMutation,
  useUpdateQuizQuestionsMutation,
  useDeleteCourseQuizByIdMutation,
  useDeleteCourseQuizQuestionMutation,
  useDeleteCourseContentMutation,
  useDeleteCourseModuleMutation,
  useDeleteCourseSectionMutation,
  useDeleteCourseMutation,
} = courseApi;

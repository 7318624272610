import React from "react";
import moment from "moment";
export const MessageRight = ({ message, user }) => {
  return (
    <div className="flex justify-end mr-5 ">
      <div className="flex ">
        <div className="flex flex-col items-end">
          <div className="w-auto mb-[23px] max-w-[600px]">
            <p className="text-base text-[#FFFFFF] text-left rounded-t-[20px] rounded-l-[20px] bg-[#A32BCA] pl-[27px] pr-[22px] pt-[9px] pb-4">
              {message?.message}
            </p>
            <span className="flex text-xs text-left text-[#969696] justify-end mt-[10px]">
              {moment(message?.time).format("LT")}
            </span>
          </div>
        </div>
        <div className=" flex items-end ml-5 mb-[30px]">
          <img
            className="h-[58px] w-[58px] rounded-full"
            src={user?.profilePhoto}
            alt="Profile Pic"
          />
        </div>
      </div>
    </div>
  );
};

import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import getCookie from "../../../../helper/cookies/getCookie";
import { decodeToken } from "react-jwt";

const ShopNav = ({ children }) => {
  const routes = [
    {
      id: 1,
      path: "/shop/listing&TrendyProducts",
      name: "PRODUCTS",
    },
    {
      id: 2,
      path: "categories",
      name: "CATEGORIES",
    },
    {
      id: 3,
      path: "costCalculator",
      name: "COST CALCULATOR",
    },
    {
      id: 4,
      path: "orders",
      name: "ORDERS",
    },
    {
      id: 5,
      path: "returns",
      name: "RETURNS",
    },
  ];
  const navigate = useNavigate();

  //js variables
  const token = getCookie("adminToken");
  const myDecodeToken = decodeToken(token);
  const { role } = myDecodeToken || {};

  const url = new URL(window.location.href);
  const pathname = url.pathname;
  const parts = pathname.split("/");
  const lastPart = parts[parts.length - 1];

  useEffect(() => {
    if (role === "vendor" && pathname === "/") {
      navigate("/shop/listing&TrendyProducts");
    }
    if (lastPart === "shop") {
      navigate("/shop/listing&TrendyProducts");
    }
  }, [lastPart, navigate, role, pathname]);

  return (
    <div>
      <div>
        <div className="pt-10 mb-10 px-5">
          <ul className="grid grid-cols-3 gap-[41px] font-poppins font-normal text-base text-[#000000]">
            {routes.map((route) => (
              <NavLink
                key={route.id}
                className={({ isActive }) =>
                  `w-full  text-center py-[5px] rounded cursor-pointer text-[15px] font-semibold ${
                    isActive
                      ? " text-white bg-[#E41272]"
                      : "text-[#000000] bg-[#FFFFFF]"
                  }`
                }
                to={route.path}
              >
                {route.name}
              </NavLink>
            ))}
          </ul>
        </div>
        <div className="w-full">{children}</div>
      </div>
    </div>
  );
};

export default ShopNav;

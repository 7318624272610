import React from "react";

export const RegistrationRequestLoader = () => {
    return (
        <div className="w-[351px] h-[222px] bg-[#301B6B] rounded-[8px] py-[31px] px-[35px]">
            <div className="flex items-center">
                <div className="h-[85px] w-[85px] bg-gray-100 rounded-full animate-pulse mr-4"></div>
                <div className="flex flex-col gap-y-6">
                    <div className="h-[14px] w-[200px] bg-gray-100 rounded-[4px] animate-pulse"></div>
                    <div className="h-[14px] w-[140px] bg-gray-100 rounded-[4px] animate-pulse"></div>
                </div>
            </div>
        </div>
    );
};

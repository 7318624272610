// Configuration
import React from "react";

// Third party package
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";

// Components
import AddQuizContent from "../AddQuizContent/AddQuizContent";

const AddQuizModule = ({ section }) => {
  const { Panel } = Collapse;
  return (
    <div className="px-6">
      {/* Show all module */}
      <div className="mb-4">
        <Collapse
          bordered={false}
          defaultActiveKey={["1"]}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
        >
          {section?.modules?.map((module) => (
            <Panel
              header={
                <div>
                  <p className="text-xl font-medium">{module?.name}</p>
                  <p className="">{module?.description}</p>
                </div>
              }
              key={module?._id}
            >
              <AddQuizContent {...{ module }} />
            </Panel>
          ))}
        </Collapse>
      </div>
    </div>
  );
};

export default AddQuizModule;

// configuration, ex: react-router
import { CircularProgress, Pagination } from "@mui/material";
import React,{useState} from "react";
import { useGetRejectedUsersQuery } from "../../../../Redux/features/User/userApi";
// components
import { RejectedCardLawyer } from "../../../Shared/Cards/Lawyer/Rejected__Card__For__Lawyer/RejectedCardLawyer";

export const RecentRejectedUserForLawyer = ({type, setType,role}) => {
    const [page, setPage] = useState(1)
    const {data, isLoading, error} = useGetRejectedUsersQuery({limit:8,page:page,role:role,keyword:""})
    // varible declation
    // hook variables

    // js variables

    return (
      <div className="min-h-[350px] relative my-8">
      {/* Registration Request */}
      <div className="w-full ">
        <h1 className="text-[#333333] text-[32px] text-start leading-[44px] font-semibold font-Nunito">
          Recent Rejected
        </h1>
        {!type && data && (
          <div className="mt-[24px] grid grid-cols-2 gap-y-[40px] w-full gap-x-[29px]">
            {data?.data?.users?.slice(0, 2).map((item) => (
              <RejectedCardLawyer key={item._id} user={item} />
            ))}
            <div className="col-span-2 flex justify-center">
              <button
                onClick={() => setType("rejected")}
                className="w-[159px] h-[37px] mt-[20px] flex justify-center items-center bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] text-white rounded-[4px]"
              >
                Load All
              </button>
            </div>
          </div>
        )}
        {type && data && (
          <div>
            <div className="mt-[24px] grid grid-cols-2 gap-y-[40px] w-full gap-x-[29px]">
              {data?.data?.users?.map((item) => (
                <RejectedCardLawyer key={item._id} user={item} />
              ))}
            </div>
            <div className="flex justify-between items-center my-[62px] ">
              <p className="text-[#3E4954] leading-6 font-medium">
                Showing {data?.data?.users?.length} from {data?.data?.total}{" "}
                data
              </p>
              <Pagination
                onChange={(event, value) => setPage(value)}
                className="bg-[#E3E4EB] px-[1px] py-[3px] rounded-md text-white"
                count={Math.ceil(data?.data?.total / 8)}
                shape="rounded"
              />
            </div>
            <div className="col-span-2 flex justify-center">
              <button
                onClick={() => setType("")}
                className="w-[159px] h-[37px] mt-[20px] flex justify-center items-center bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] text-white rounded-[4px]"
              >
                Load less
              </button>
            </div>
          </div>
        )}
        {isLoading && (
          <div>
            <CircularProgress />
          </div>
        )}
        {error && (
          <div className="my-12">
            <p className="text-2xl font-bold">
              {error?.data?.error?.statusCode}
            </p>
            <p>{error?.data?.message}</p>
          </div>
        )}
      </div>
    </div>
    );
};

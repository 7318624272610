import React from 'react';

const ImgNumberIndicator = ({ selectedPhoto,setSelectedPhoto, photos }) => {

    return (
      /* Indicating which number of img are displaying in header img */
      <div className="flex justify-end text-center gap-[14px] pb-[20px]">
        {photos?.map((photo, i) => (
          <div onClick={()=>setSelectedPhoto(photo)}
            className={`cursor-pointer flex items-center justify-center h-[29px] w-[29px] rounded-full ${
              selectedPhoto?._id === photo._id
                ? "bg-[#E41272]  text-white"
                : "bg-[#D9D9D9] text-black"
            }`}
          >
            {" "}
            <p>{i + 1}</p>{" "}
          </div>
        ))}
      </div>
    );
};

export default ImgNumberIndicator;
// normal import
import React, { useState } from "react";

// Components
import RestrictSuspendUserCard from "../../../Shared/Cards/RestrictSuspendUserCard/RestrictSuspendUserCard";
import { RestrictSuspendUserProfile } from "./RestrictSuspendUserProfile/RestrictSuspendUserProfile";

/* service card image */
import { useGetRestrictedOrSuspendedUsersQuery } from "../../../../Redux/features/User/userApi";
import { CircularProgress, Pagination } from "@mui/material";

export const RestrictSuspendUser = () => {
  const [page, setPage] = useState(1);
  const { data, isLoading } = useGetRestrictedOrSuspendedUsersQuery({
    role: "",
    page: page,
    limit: 10,
    keyword: "",
  });
  // varible declation
  // js variables

  return (
    <div className="px-8">
      <RestrictSuspendUserProfile></RestrictSuspendUserProfile>
      <h1 className="font-semibold  mt-12 text-[32px] mb-[20px] ml-[-250px]">
        Recent Restrict/Suspended User
      </h1>
      {!isLoading && (
        <section className="grid grid-cols-1 gap-[10px]">
          {data?.data?.users?.map((user) => {
            return <RestrictSuspendUserCard key={user.id} {...{ user }} />;
          })}
          <div className="flex justify-between px-4 items-center my-12">
            <p className="leading-6 font-medium">
              Showing {data?.data?.users?.length} from {data?.data?.total}
              data
            </p>
            <Pagination
              className="bg-[#E3E4EB] px-[1px] py-[3px] rounded-md text-white"
              onChange={(event, value) => {
                setPage(value);
              }}
              count={Math.ceil(data?.data?.total / 10) || 1}
              shape="rounded"
            />
          </div>
        </section>
      )}
      {isLoading && (
        <div className="w-full flex items-center justify-center h-24 ">
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

// Configuration
import React from "react";
import { Outlet } from "react-router-dom";

// Components
import useDocumentTitle from "../../../helper/utilities/useDocumentTitle";
import PackagesNav from "./PackagesNav/PackagesNav";

export const Packages = () => {
  useDocumentTitle("Admin | Packages");
  return (
    <PackagesNav>
      <Outlet />
    </PackagesNav>
  );
};

// configuration
import React, { useState } from "react";

// Third party packages
import { Pagination } from "@mui/material";
import { Rate } from "antd";
import moment from "moment";
import { useGetAllReviewsQuery } from "../../../../../Redux/features/Services/servicesApi";
import { LineWaveLoader } from "../../../Loader/LineWaveLoader";
import { RatingEditModal } from "./RatingEditModal";

// components

export const AllRatingsPageForEdit = ({ serviceID }) => {
    const [page, setPage] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [reviewForEdit, setReviewForEdit] = useState(null);
    const { data, isLoading, isError, isFetching } = useGetAllReviewsQuery({
        page: page,
        limit: 4,
        id: serviceID,
    });
    const { reviews, total } = data || {};
    const pages = Math.ceil(total / 4);

    // function declaration
    const modalControll = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleReviewEditButton = review => {
        setReviewForEdit(review);
        modalControll();
    };

    // js variables
    let content;
    if (isLoading || isFetching) {
        content = (
            <div className="h-[400px] flex justify-center items-center">
                <LineWaveLoader title="Getting reviews..." />
            </div>
        );
    } else if (!isLoading && reviews?.length === 0) {
        content = (
            <div className="h-[400px] flex justify-center items-center">
                <h3 className="text-[26px] font-Inter text-gray-400">No reviews Found!</h3>
            </div>
        );
    } else if (!isLoading && isError) {
        content = (
            <div className="h-[400px] flex justify-center items-center">
                <h3 className="text-[26px] font-Inter text-gray-400">Something went wrong!</h3>
            </div>
        );
    } else if (!isLoading && reviews?.length > 0) {
        content = (
            <div>
                {/* // Number of reviews */}
                <p className="font-poppins text-[26px] leading-[48px] font-normal text-[#707276] text-left mt-[62px] mb-[20px]">
                    Reviews ({reviews?.length})
                </p>

                <div className="w-full grid grid-cols-1 gap-y-[72px]">
                    {reviews?.map(review => {
                        const { _id, review: reviewText, rating, createdAt, user } = review || {};
                        const { firstName, lastName, profilePhoto } = user || {};
                        const name = `${firstName} ${lastName}`;
                        return (
                            <div className="flex gap-x-[37px]" key={_id}>
                                <div>
                                    <img className="w-[147px] h-[147px] rounded-full" src={profilePhoto} alt="profilePhoto" />
                                </div>
                                <div className="flex-1 flex flex-col gap-y-[32px] border-t-[1.5px] border-[#F4F4F5] pt-[27px]">
                                    <div className="w-full flex justify-between">
                                        <Rate disabled value={rating} />
                                        <button
                                            className="w-[78px] h-[28px] bg-[#E41272] flex justify-center items-center text-white rounded-[4px]"
                                            onClick={() => handleReviewEditButton(review)}
                                        >
                                            Edit
                                        </button>
                                    </div>
                                    <div>
                                        <p className="text-left text-[18px] text-[#18181B] font-PlusJakartaSans">{reviewText}</p>
                                        <div className="flex flex-col gap-y-[16px] items-start mt-[27px]">
                                            <h3 className="text-[#18181B] font-PlusJakartaSans text-[24px] leading-[22px] font-bold">{name}</h3>
                                            <p className="text-[#71717A] text-[22px] font-normal font-PlusJakartaSans">
                                                {moment(createdAt).format("LL")}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>

                {/* pagination */}
                {pages > 1 && (
                    <div className="flex justify-around items-center my-12">
                        <p className="leading-6 font-medium">Showing 4 from {total} data</p>
                        <Pagination count={pages} variant="outlined" color="secondary" onChange={(e, value) => setPage(value)} />
                    </div>
                )}
                {isModalOpen && <RatingEditModal review={reviewForEdit} modalControll={modalControll} />}
            </div>
        );
    }

    return content;
};

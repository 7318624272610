// Configuration
import React from "react";
import { useNavigate } from "react-router";

const SupportTicketTableRow = ({ item, index }) => {
  const { status, category, subject, uid, _id, requestor } = item || {};
  const navigate = useNavigate();

  // function declaration
  const colorChooser = (status) => {
    if (status === "closed") {
      return "text-red-800 bg-red-200";
    } else if (status === "open") {
      return "text-green-800 bg-green-200";
    }
  };
  return (
    <tr className="bg-white">
      <td className="p-3 text-sm text-gray-700 whitespace-nowrap">{uid}</td>
      <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
        {(requestor?.firstName || "") + " " + (requestor?.lastName || "")}
      </td>
      <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
        <span
          className={`p-1.5 text-xs font-medium uppercase tracking-wider rounded-lg bg-opacity-50 ${colorChooser(
            status
          )}`}
        >
          {status}
        </span>
      </td>
      <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
        {category}
      </td>
      <td className="p-3 text-sm text-gray-700 whitespace-nowrap">{subject}</td>
      <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
        <button
          className="bg-red-100 text-red-500 font-medium p-2 rounded-[4px] hover:text-blue-500"
          onClick={() => navigate(`/support/supportTicket/${_id}`)}
        >
          View Details
        </button>
      </td>
    </tr>
  );
};

export default SupportTicketTableRow;

import React from "react";
/* React search icon */
import { FiSearch } from "react-icons/fi";
/* card user img */
import user from "../../../../assets/kazi/profile.png";
/* components */
import ModifyDealHistoryCard from "../../../Shared/Cards/Kazi/ModifyDealHistoryCard/ModifyDealHistoryCard";
import DealHistoryPagination from "./DealHistoryPagination/DealHistoryPagination";

const ModifyDealHistoryForKazi = () => {
  const userData = [
    {
      id: 1,
      img: user,
      name: "John William",
      age: 26,
    },
    {
      id: 2,
      img: user,
      name: "John William",
      age: 26,
    },
    {
      id: 3,
      img: user,
      name: "John William",
      age: 26,
    },
    {
      id: 4,
      img: user,
      name: "John William",
      age: 26,
    },
    {
      id: 5,
      img: user,
      name: "John William",
      age: 26,
    },
    {
      id: 6,
      img: user,
      name: "John William",
      age: 26,
    },
    {
      id: 7,
      img: user,
      name: "John William",
      age: 26,
    },
    {
      id: 8,
      img: user,
      name: "John William",
      age: 26,
    },
    {
      id: 9,
      img: user,
      name: "John William",
      age: 26,
    },
    {
      id: 10,
      img: user,
      name: "John William",
      age: 26,
    },
    {
      id: 11,
      img: user,
      name: "John William",
      age: 26,
    },
    {
      id: 12,
      img: user,
      name: "John William",
      age: 26,
    },
    {
      id: 13,
      img: user,
      name: "John William",
      age: 26,
    },
    {
      id: 14,
      img: user,
      name: "John William",
      age: 26,
    },
  ];

  return (
    <div>
      {/* Search user */}
      <div className="flex mx-auto items-center w-[492px] h-[52px] bg-[#FFFFFF] border border-[#FFE8F3] py-[14px] px-[21px] text-base rounded-lg gap-6 mt-[103px] mb-[65px]">
        <FiSearch className="text-[#353751] text-2xl" />
        <input
          className="font-poppins font-normal text-[#AAAAAA] focus: outline-none h-full w-full"
          type="search"
          name="searchUser"
          id=""
          placeholder="Search user name"
        />
      </div>
      {/* Deal history card */}
      <div className="grid grid-cols-2 gap-[29px]">
        {userData.map((user) => (
          <ModifyDealHistoryCard key={user.id} {...{ user }} />
        ))}
      </div>
      {/* Pagination */}
      <DealHistoryPagination />
    </div>
  );
};

export default ModifyDealHistoryForKazi;

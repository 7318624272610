import React, { useState, useEffect } from "react";

import { CircularProgress, Pagination } from "@mui/material";
import TextArea from "antd/es/input/TextArea";
import { toast, Toaster } from "react-hot-toast";

import { useSendNotificationToManyMutation } from "../../../../Redux/features/notification/notification";
import SearchManyUsers from "../../../Shared/components/SearchUsers/SearchManyUsers";
import UserCard from "./UserCard";

const Notification = ({title}) => {
  const [message, setMessage] = useState("");
  const [searchQuery, setSearchQuery] = useState([]);
  const [userObj, setUser] = useState();
  const [page, setPage] = useState(1);
  const [sendNotification, { data, isLoading, error }] =
    useSendNotificationToManyMutation();

  const handleSendNotification = () => {
    if (searchQuery.length === 0 || !message) {
      toast.error("Please Select user and Type a message");
    } else {
      sendNotification({ message, users: searchQuery });
    }
  };

  useEffect(() => {
    if (data) {
      toast.success(data?.message);
      setMessage("");
      setSearchQuery([]);
    }
    if (error) {
      toast.error(error?.data?.message);
    }
  }, [data, error]);

  return (
    <div className="p-4">
      <div className="mb-6 font-bold text-2xl">
        <h1>{title}</h1>
      </div>
      <SearchManyUsers {...{ setUser, page, limit: 8 }} />
      {userObj?.data && (
        <div className="mt-[44px] grid grid-cols-2 gap-[29px] ml-[30px]">
          {!userObj?.isLoading &&
            !userObj?.error &&
            userObj?.data?.users?.map((item) => (
              <UserCard
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                user={item}
              />
            ))}
        </div>
      )}
      {userObj?.isLoading && (
        <div className="flex items-center justify-center my-24">
          <CircularProgress />
        </div>
      )}
      <div className="flex justify-between items-center my-[62px] ">
        {userObj?.data && (
          <p className="text-[#3E4954] leading-6 font-medium">
            Showing {userObj?.data?.users?.length} from {userObj?.data?.total}{" "}
            data
          </p>
        )}
        {userObj?.data && (
          <Pagination
            className="bg-[#E3E4EB] px-[1px] py-[3px] rounded-md text-white"
            page={page}
            onChange={(event, value) => {
              setPage(value);
              setUser(null);
            }}
            count={Math.ceil(userObj?.data?.total / 8)}
            shape="rounded"
          />
        )}
      </div>
      <div>
        <TextArea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          rows={16}
          placeholder="Write Your Message"
        />
      </div>
      <div className=" flex items-center justify-center flex-col">
        <button
          onClick={handleSendNotification}
          className="cursor-pointer text-white px-8 w-[25%] mb-4 mt-12 py-2 text-[24px] bg-gradient-to-b from-[#DF298C] to-[#B02BBE]"
        >
          {`${isLoading ? "Loading" : "Send Now"}`}
        </button>
      </div>
      <Toaster />
    </div>
  );
};

export default Notification;

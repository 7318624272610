// Configuration
import React, { useEffect } from "react";

// Third party package
import { useForm } from "react-hook-form";
import { Toaster, toast } from "react-hot-toast";

// Components
import Error from "../../../../Shared/components/accordion/UI/Error/Error";
import {
  useUpdateCoursePackageMutation,
  useUpdateShopPackageMutation,
} from "../../../../../Redux/features/Packages/packagesApi";

const EditPriorityPackage = ({ priorityPackage, from }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const [updateCoursePackage, { data: response, isLoading, error }] =
    useUpdateCoursePackageMutation();
  const [
    updateShopPackage,
    { data: shopResponse, isLoading: shopLoading, error: shopError },
  ] = useUpdateShopPackageMutation();

  const onSubmit = async (data) => {
    if (data.discount) {
      data.discount = Number(data.discount);
    } else {
      delete data.discount;
    }
    if (data.priorityPoint) {
      data.priorityPoint = Number(data.priorityPoint);
    } else {
      delete data.priorityPoint;
    }

    if (from === "shop") {
      await updateShopPackage({ id: priorityPackage?._id, data: data });
    } else {
      await updateCoursePackage({ id: priorityPackage?._id, data: data });
    }
  };

  useEffect(() => {
    if (response) {
      toast.success("Course Package Updated Successfully");
      reset();
    }
    if (shopResponse) {
      toast.success("Shop Package Updated Successfully");
      reset();
    }
    if (error) {
      toast.error(
        <p>
          Can't update course package now. <br /> Please login or try again
          later.
        </p>
      );
      reset();
    }
    if (shopError) {
      toast.error(
        <p>
          Can't update shop package now. <br /> Please login or try again later.
        </p>
      );
      reset();
    }
  }, [response, error, reset, shopError, shopResponse]);

  return (
    <div className="pt-5">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col items-start w-full gap-y-5"
      >
        {/* Special Discount on Purchase */}
        <div className="w-full flex flex-col">
          <label className="text-left mb-1 text-[12px] font-bold text-[#707276] ml-[5px]">
            Special Discount on Purchase (In %)
          </label>
          <input
            {...register("discount", {
              pattern: {
                value: /^[0-9]*$/,
                message: "Discount must be a number",
              },
              min: 0,
              max: {
                value: 100,
                message: "Discount can not be more than 100%",
              },
            })}
            className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-4 px-[12px]"
            type="number"
            placeholder="Special Discount"
          />
          {errors?.discount && <Error message={errors?.discount?.message} />}
        </div>

        <div className="w-full flex flex-col">
          <label className="text-left mb-1 text-[12px] font-bold text-[#707276] ml-[5px]">
            Priority Point on Purchase
          </label>
          <input
            {...register("priorityPoint", {
              pattern: {
                value: /^[0-9]*$/,
                message: "Input must be a number",
              },
              min: 0,
            })}
            className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-4 px-[12px]"
            type="number"
            placeholder="Priority Point"
          />
          {errors?.priorityPoint && (
            <Error message={errors?.priorityPoint?.message} />
          )}
        </div>

        <input
          type="submit"
          value={isLoading || shopLoading ? "Loading..." : "Submit"}
          className="py-2 px-8 rounded-md font-Inter bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] text-white font-medium text-lg cursor-pointer hover:bg-[linear-gradient(166deg,rgb(148,45,217)_0%,rgb(242,40,118)_100%)]"
        />
      </form>
      <Toaster />
    </div>
  );
};

export default EditPriorityPackage;

// configuration
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// Third party packages
import { AiOutlineDelete, AiOutlineEyeInvisible } from "react-icons/ai";
import DeleteProductModal from "./DeleteProductModal/DeleteProductModal";
import OutOfStockModal from "./OutOfStockModal/OutOfStockModal";
import { IoClose } from "react-icons/io5";

export const EditHeaderButton = ({ _id, setIsProductDeleted, stock }) => {
  const [deleteProductOpen, setDeleteProductOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [outOfStockModalOpen, setOutOfStockModalOpen] = useState(false);

  const navigate = useNavigate();

  const showDeleteProductModal = (id) => {
    setDeleteProductOpen(true);
    setSelectedProduct(id);
  };
  const handleDeleteProductCancel = () => {
    setDeleteProductOpen(false);
  };

  const showOutOfStockModal = (id) => {
    setOutOfStockModalOpen(true);
    setSelectedProduct(id);
  };
  const handleOutOfStockModalCancel = () => {
    setOutOfStockModalOpen(false);
  };
  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="w-full flex justify-start gap-x-[32px]">
          <button
            disabled={stock === 0}
            className={`flex items-center w-[303px] h-[48px] rounded-[100px] bg-[linear-gradient(90deg,#E41272_0%,_#A72CC7_100%)] text-white justify-center gap-x-[8px] text-[16px] tracking-[1.25px] font-semibold font-Inter ${
              stock === 0 && "cursor-not-allowed"
            }`}
            onClick={() => showOutOfStockModal(_id)}
          >
            {stock === 0 ? "PRODUCT IS OUT OF STOCK" : "MARK AS OUT OF STOCK"}{" "}
            <AiOutlineEyeInvisible />
          </button>
          <button
            className="flex items-center w-[279px] h-[48px] rounded-[100px] bg-[linear-gradient(90deg,#E41272_0%,_#A72CC7_100%)] text-white justify-center gap-x-[8px] text-[16px] tracking-[1.25px] font-semibold font-Inter"
            onClick={() => showDeleteProductModal(_id)}
          >
            DELETE THIS PRODUCT <AiOutlineDelete />
          </button>
        </div>
        <div onClick={() => navigate(-1)}>
          <IoClose className="border border-gray-500 text-3xl rounded-full hover:bg-gray-500 hover:text-white cursor-pointer duration-300" />
        </div>
      </div>
      <OutOfStockModal
        {...{
          handleOutOfStockModalCancel,
          outOfStockModalOpen,
          selectedProduct,
          setOutOfStockModalOpen,
        }}
      />
      <DeleteProductModal
        {...{
          deleteProductOpen,
          handleDeleteProductCancel,
          setDeleteProductOpen,
          selectedProduct,
          setIsProductDeleted,
        }}
      />
    </div>
  );
};

// Configuration
import React from "react";
import { Outlet } from "react-router-dom";

// Components
import SupportNav from "./SupportNav/SupportNav";

const Support = () => {
  return (
    <SupportNav>
      <Outlet />
    </SupportNav>
  );
};

export default Support;

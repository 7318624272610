import React, { useState } from "react";

//Components
import useDocumentTitle from "../../../helper/utilities/useDocumentTitle";
import UserPlanHeader from "./UserPlanHeader";
import { AllPlans } from "./userPlans/AllPlans";

export default function DownGradeUserPlan() {
  const [user, setUser] = useState(null);

  // page title
  useDocumentTitle("Admin | Downgrade User Plan");
  return (
    <div>
      <div className="flex justify-center mb-8">
        <div>
          <section>
            <div className="flex justify-center mb-8">
              <h1 className="font-semibold text-4xl font-Nunito  ">
                Downgrade User Plan
              </h1>
            </div>
            <UserPlanHeader setUser={setUser} user={user} role="member" />
          </section>
          {user && <AllPlans condition="downgrade" user={user} />}
        </div>
      </div>
    </div>
  );
}

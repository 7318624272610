import React from 'react';
/* user img for card */
import { ageCalculator } from '../../../../helper/utilities/ageCalculator';

const SelectUserCard = ({ users, selectedUser, setSelectedUser }) => {
    
    return (
      <div className="pt-[59px]">
        <div className="grid grid-cols-2 gap-[29px]">
          {users?.map((user) => (
            <div
              key={user?._id}
              className="bg-gradient-to-b from-[#A952D1] to-[#6619A3] rounded-lg font-inter font-medium text-2xl"
            >
              <div className="flex gap-6 pt-[31px] pl-[35px] text-white">
                <img
                  className="w-[85px] h-[85px] rounded-full"
                  src={user?.profilePhoto}
                  alt="profile"
                />
                <div className="text-left">
                  <h1 className="mb-4">{user.firstName}</h1>
                  <p className="font-normal text-xl">Age : {ageCalculator(user.dateOfBirth)}</p>
                </div>
              </div>
              <div className="text-center mt-11 mb-[33px]">
                <button
                  onClick={() => setSelectedUser(user)}
                  className={`py-[9px] px-[44px] rounded text-[16px] ${
                    selectedUser?._id === user?._id
                      ? "bg-gradient-to-b from-[#DF298C] to-[#B02BBE] text-white"
                      : "bg-[#FFFFFF] text-[#000000]"
                  }`}
                >
                  {selectedUser?._id === user?._id ? "Selected" : "Select"}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
};

export default SelectUserCard;
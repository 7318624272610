// configuration, ex: react-router
import React, { useState } from "react";

// Third party packages
import Pagination from "@mui/material/Pagination";
import { AiOutlineWarning } from "react-icons/ai";
import { FaUsersSlash } from "react-icons/fa";

// components
import { useGetAskToResubmitUsersQuery } from "../../../../../Redux/features/User/registrationRequestApi";
import { SendForReSubmitCard } from "../../../../Shared/Cards/Send__For__Resubmit__Card/SendForReSubmitCard";
import { RegistrationRequestLoader } from "../../../../Shared/Loader/User/RegistrationRequestLoader";

export const SendForReSubmit = () => {
  const [page, setPage] = useState(1);
  const {
    data: users,
    isLoading,
    error,
  } = useGetAskToResubmitUsersQuery({
    role: "member",
    limit: 8,
    page: page,
    searchText: "",
  });

  // js variables
  let content;
  if (isLoading) {
    content = (
      <div className="mt-[24px] grid grid-cols-2 gap-[20px]  gap-y-[40px] ml-[-20px]">
        <RegistrationRequestLoader />
        <RegistrationRequestLoader />
        <RegistrationRequestLoader />
        <RegistrationRequestLoader />
      </div>
    );
  } else if (!isLoading && users?.data?.users?.length === 0) {
    content = (
      <div className="flex flex-col items-center justify-center mt-[10%]">
        <FaUsersSlash className="text-[48px] text-gray-400" />
        <p className="mt-[10px] text-[22px] font-Inter font-medium text-gray-500">
          No Data Found!
        </p>
      </div>
    );
  } else if (!isLoading && error) {
    content = (
      <div className="flex flex-col items-center justify-center mt-[10%]">
        <AiOutlineWarning className="text-[48px] text-gray-400" />
        <p className="mt-[10px] text-[22px] font-Inter font-medium text-gray-500">
          Server Error
        </p>
      </div>
    );
  } else if (!isLoading && users) {
    content = (
      <div className="mt-[24px] grid grid-cols-2 gap-8  gap-y-[40px] ml-[-20px]">
        {users?.data?.users?.map((user) => (
          <SendForReSubmitCard key={user._id} user={user} />
        ))}
      </div>
    );
  }

  return (
    <div>
      {/* Send For Resubmit */}
      <div className="mt-[109px]">
        <h1 className="text-[#333333] text-[32px] leading-[44px] font-semibold font-Nunito">
          Send For Resubmit
        </h1>
        <div>{content}</div>
        <div className="flex justify-around items-center my-12">
          <p className="leading-6 font-medium">
            Showing {users?.data?.users?.length} from {users?.data?.total} data
          </p>
          <Pagination
            className="bg-[#E3E4EB] px-[1px] py-[3px] rounded-md text-white"
            onChange={(event, value) => {
              setPage(value);
            }}
            count={Math.ceil(users?.data?.total / 8) || 1}
            shape="rounded"
          />
        </div>
      </div>
    </div>
  );
};

// configuration, ex: react-router
import { Pagination } from "@mui/material";
import React, { useState } from "react";
import { AiOutlineWarning } from "react-icons/ai";
import { FaUsersSlash } from "react-icons/fa";
import { useGetApprovedusersQuery } from "../../../../../Redux/features/User/registrationRequestApi";

// components
import { RecentApprovedUserCard } from "../../../../Shared/Cards/Recent__Approved__User__Card/RecentApprovedUserCard";
import { ApprovedUserLoader } from "../../../../Shared/Loader/User/ApprovedUserLoader";

export const RecentApprovedUser = () => {
  const [page, setPage] = useState(1);
  // hook variable declaration
  const {
    data: approvedUsers,
    isLoading,
    error,
  } = useGetApprovedusersQuery({
    role: "member",
    limit: 8,
    page: page,
  });

  // js variable declaration
  let content;
  if (isLoading) {
    content = (
      <div className="mt-[24px] grid grid-cols-2 gap-[20px]  gap-y-[40px] ml-[-20px]">
        <ApprovedUserLoader />
        <ApprovedUserLoader />
        <ApprovedUserLoader />
        <ApprovedUserLoader />
      </div>
    );
  } else if (!isLoading && approvedUsers?.data?.users.length === 0) {
    content = (
      <div className="flex flex-col items-center justify-center mt-[10%]">
        <FaUsersSlash className="text-[48px] text-gray-400" />
        <p className="mt-[10px] text-[22px] font-Inter font-medium text-gray-500">
          No User Request Found!
        </p>
      </div>
    );
  } else if (!isLoading && error) {
    content = (
      <div className="flex flex-col items-center justify-center mt-[10%]">
        <AiOutlineWarning className="text-[48px] text-gray-400" />
        <p className="mt-[10px] text-[22px] font-Inter font-medium text-gray-500">
          server Error
        </p>
      </div>
    );
  } else if (!isLoading && approvedUsers) {
    content = (
      <div className="mt-[24px] grid grid-cols-2 gap-8  gap-y-[40px] ml-[-20px]">
        {approvedUsers?.data?.users?.map((user) => (
          <RecentApprovedUserCard key={user._id} user={user} />
        ))}
      </div>
    );
  }

  return (
    <div>
      {/* Recent Approved Users */}
      <div className="mt-[109px]">
        <h1 className="text-[#333333] text-[32px] leading-[44px] font-semibold font-Nunito">
          Recent Approved Users
        </h1>
        <div>{content}</div>
        <div className="flex justify-around items-center my-12">
          <p className="leading-6 font-medium">
            Showing {approvedUsers?.data?.users?.length} from{" "}
            {approvedUsers?.data?.total} data
          </p>
          <Pagination
            className="bg-[#E3E4EB] px-[1px] py-[3px] rounded-md text-white"
            onChange={(event, value) => {
              setPage(value);
            }}
            count={Math.ceil(approvedUsers?.data?.total / 8) || 1}
            shape="rounded"
          />
        </div>
      </div>
    </div>
  );
};

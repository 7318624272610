import getCookie from "../../../helper/cookies/getCookie";
import { apiSlice } from "../../api/apiSlice";

export const notificationApi = apiSlice.injectEndpoints({
    endpoints: builder => ({
        sendNotificationToMany: builder.mutation({
            query: ({users,message}) => ({
                url: `/admin/action/notify`,
                method: "POST",
                headers: {
                    authorization: `Bearer ${getCookie("adminToken")}`,
                },
                body:{users,message}
            }),
        }),
    }),
});

export const { 
    useSendNotificationToManyMutation
 } = notificationApi;

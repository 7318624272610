import React from "react";
import ReferBonusRow from "./ReferBonusRow";
import { useGetReferPointQuery } from "../../../../../Redux/features/referral/referralApi";

const ReferBonus = () => {
  const { data } = useGetReferPointQuery({
    page: "",
    limit: "",
  });

  return (
    <div>
      <p className="text-2xl font-bold mt-8 mb-4">Refer point</p>
      <div className="p-4 flex justify-between relative">
        <div className="flex absolute top-0 left-0 w-[60%]   flex-col justify-center px-2 ">
          <div className="rounded overflow-hidden shadow-2xl pb-8">
            <div className="w-full py-4 text-white font-bold text-lg bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)]">
              Set Refer Amount
            </div>
            <table className="w-full">
              <thead>
                <tr className="border-b-3">
                  <th className="border-b-2 py-2 px-4">Per Refer Points </th>
                  <th className="border-b-2 py-2 px-4">Amount Per point </th>
                  <th className="border-b-2 py-2 px-4">Currency</th>
                  <th className="border-b-2 py-2 px-4">Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.point?.map((item) => (
                  <ReferBonusRow {...{ item }} key={item?._id} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className=" w-[40%] absolute top-0 right-0 ">
          <div className="mx-2 border rounded overflow-hidden pb-6 shadow-2xl">
            <p className="bg-gray-300 font-bold py-3">Admin Dashboard</p>
            <div className="p-4">
              <form
                className="flex  flex-col justify-center items-start"
                action=""
              >
                <label
                  className="text-sm font-bold text-gray-600 mt-1 ml-1"
                  htmlFor="amount"
                >
                  Per Refer Bonus Amount
                </label>
                <input
                  placeholder="Enter refer amount"
                  className="p-2 my-2 border focus:outline-0 w-full"
                  type="text"
                />
                <label
                  className="text-sm font-bold text-gray-600 mt-1 ml-1"
                  htmlFor="status"
                >
                  Select Status
                </label>
                <select className="p-2 border focus:outline-0 w-full my-2">
                  <option>active</option>
                  <option>deactivated</option>
                </select>
                <button className="bg-[#e41272] my-2 px-4 py-1 rounded text-white">
                  Create
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferBonus;

// Configuration
import React, { useState } from "react";

// Third party packages
import Pagination from "@mui/material/Pagination";

// Components
import AssignLawyerAllCard from "../../../Shared/Cards/Lawyer/Assign__Lawyer__All__Card/AssignLawyerAllCard";
import AssignLawyerToUserCard from "../../../Shared/Cards/Lawyer/Assign__Lawyer__To__User__Card/AssignLawyerToUserCard";
import SearchManyUsers from "../../../Shared/components/SearchUsers/SearchManyUsers";

const AssignLawyer = () => {
  const [userPage, setUserPage] = useState(1);
  const [lawyerPage, setLawyerPage] = useState(1);
  const [userObj, setUser] = useState();
  const [lawyerObj, setLawyer] = useState();
  const [selectedLawyer, setSelectedLawyer] = useState("");

  return (
    <div className="px-8">
      <div className="mb-8">
        <SearchManyUsers setUser={setLawyer} page={lawyerPage} role="lawyer" />
      </div>
      <section className="grid grid-cols-2 gap-[29px]">
        {lawyerObj?.data?.users?.map((lawyer) => (
          <AssignLawyerAllCard
            key={lawyer.id}
            lawyer={lawyer}
            selectedLawyer={selectedLawyer}
            setSelectedLawyer={setSelectedLawyer}
          />
        ))}
      </section>
      <div className="flex justify-between items-center my-[62px] ">
        <p className="text-[#3E4954] leading-6 font-medium">
          Showing {lawyerObj?.data?.users?.length} from {lawyerObj?.data?.total}{" "}
          data
        </p>
        <Pagination
          className="bg-[#E3E4EB] px-[1px] py-[3px] rounded-md text-white"
          count={Math.ceil(lawyerObj?.data?.total / 8) || 1}
          onChange={(event, pageNumber) => setLawyerPage(pageNumber)}
          shape="rounded"
        />
      </div>
      {/* --- Assign Lawyer To User --- */}
      <h1 className="text-[40px] text-left leading-[48px] font-semibold mb-10">
        Assign Lawyer To User:
      </h1>
      <div className="mb-8">
        <SearchManyUsers setUser={setUser} page={userPage} />
      </div>
      <section className="grid grid-cols-2 gap-[29px]">
        {userObj?.data?.users?.map((lawyer) => (
          <AssignLawyerToUserCard
            key={lawyer._id}
            lawyer={lawyer}
            selectedLawyer={selectedLawyer}
          />
        ))}
      </section>
      <div className="flex justify-between items-center my-[62px] ">
        <p className="text-[#3E4954] leading-6 font-medium">
          Showing {userObj?.data?.users?.length} from {userObj?.data?.total}{" "}
          data
        </p>
        <Pagination
          className="bg-[#E3E4EB] px-[1px] py-[3px] rounded-md text-white"
          count={Math.ceil(userObj?.data?.total / 8) || 1}
          onChange={(event, pageNumber) => setUserPage(pageNumber)}
          shape="rounded"
        />
      </div>
    </div>
  );
};

export default AssignLawyer;

// Configuration
import React from "react";
import { useParams } from "react-router-dom";
import { useGetAllMessageQuery } from "../../../../../Redux/features/Chat/ChatApi";
import { useGetProfileDetailsQuery } from "../../../../../Redux/features/User/userApi";

// Components
import { AllChatingsForLawyerHeader } from "./AllChatingsForLawyerHeader";
import { AllChattingListsWithLawyer } from "./AllChattingListsWithLawyer";
import { ConversationBoxForLawyer } from "./ConversationBoxForLawyer";

export const AllChatingListsForLawyer = () => {
  const { id } = useParams();
  const { data } = useGetProfileDetailsQuery(id);

  const [user, setUser] = React.useState({
    from: id,
    to: "",
  });

  const [message, setMessage] = React.useState(null);

  const handleClick = (id) => {
    setUser({ ...user, to: id });
    if (chat?.data?.message) {
      // setMessage(chat?.data?.message)
    }
  };
  const chat = useGetAllMessageQuery(user);
  React.useEffect(() => {
    if (chat?.data?.message) {
      setMessage(chat?.data?.message);
    }
  }, [chat?.data?.message]);
  const res = useGetProfileDetailsQuery(user?.to);
  let receiver = res.data;

  return (
    <div>
      <AllChatingsForLawyerHeader user={data} />
      <section className="w-full flex flex-col items-center gap-y-[46px] mt-[80px]">
        <ConversationBoxForLawyer user={data} handleClick={handleClick} />
        <AllChattingListsWithLawyer
          message={message}
          user={data}
          receiver={receiver}
        />
      </section>
    </div>
  );
};

import React from "react";
import { useNavigate } from "react-router-dom";
import { getDateFromCreatedAt } from "../../../../../helper/utilities/getDateFromCreatedAt";

const OrderHistoryCard = ({ order }) => {
  const navigate = useNavigate();
  // Decision about status design
  let status;
  if (order?.orderStatus === "Delivered") {
    status = (
      <p className="text-base leading-5 font-bold font-SourceCodePro text-[#0ABD1C] capitalize">
        {order?.orderStatus}
      </p>
    );
  }
  if (order?.orderStatus === "Cancelled") {
    status = (
      <p className="text-base leading-5 font-bold font-SourceCodePro text-[#BD0A0A] capitalize">
        {order?.orderStatus}
      </p>
    );
  }
  if (order?.orderStatus === "ongoing") {
    status = (
      <p className="text-base leading-5 font-bold font-SourceCodePro text-[#0ABD1C] capitalize">
        {order?.orderStatus}
      </p>
    );
  }
  return (
    <div
      className="p-6 rounded-xl"
      style={{ boxShadow: "2px 2px 10px 2px rgba(0, 0, 0, 0.12)" }}
    >
      <div
        className="cursor-pointer"
        onClick={() => navigate(`/shop/orderId/${order?._id}`)}
      >
        <img
          className="w-[280px] h-[188px] rounded-xl mb-4"
          src={
            order?.orderItems[0]?.product?.photos[0]?.url ||
            "https://img.freepik.com/free-photo/rag-dolls-with-wheelbarrow-blue-cap_1156-223.jpg?w=740&t=st=1680695543~exp=1680696143~hmac=24c6cb3976d233b748a346dca9024b67675f49dfaa495ba01ff93294f9f20340"
          }
          alt=""
        />
      </div>
      <div className="flex justify-between items-center mb-5">
        <p className="text-lg leading-6 font-medium font-SourceCodePro">
          BDT {order?.totalPrice}
        </p>
        <div className="text-base leading-5 font-bold font-SourceCodedivro">
          {status}
        </div>
      </div>
      <div className="text-left">
        <p className="text-sm leading-[18px] font-SourceCodePro mb-4">
          Order By #{order?.billingInfo?.firstName}{" "}
          {order?.billingInfo?.lastName}
        </p>
        <div className="flex items-center gap-2">
          <div className="w-4 h-4 bg-[#DEDEDE] rounded-full"></div>
          <p className="text-[#666666] text-sm leading-[18px] font-SourceCodePro">
            {getDateFromCreatedAt(order?.createdAt)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OrderHistoryCard;

// configuration
import React from "react";

// components
import { AiFillStar } from "react-icons/ai";

const ServiceReviewsCard = ({ review }) => {
  return (
    <div>
      <div className="flex gap-8 mb-[72px]">
        <img
          className="w-[147px] h-[147px] rounded-full"
          src={review.profilePic}
          alt=""
        />
        <div className="w-[620px]">
          <div className="mb-6 flex justify-between items-center">
            <div className="text-[#F59E0B] text-4xl flex items-center gap-3">
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
            </div>
            <button className="text-white text-sm leading-[18px] font-semibold bg-[#E41272] rounded-[4px] px-[22px] py-[5px]">
              Edit
            </button>
          </div>
          <p className="text-[#18181B] text-lg leading-[30px] mb-8">
            {review.reviewText}
          </p>
          <p className="text-[#18181B] text-2xl leading-[22px] font-bold mb-4">
            {review.reviewerName}
          </p>
          <p className="text-[#71717A] text-xl leading-[22px]">{review.date}</p>
        </div>
      </div>
    </div>
  );
};

export default ServiceReviewsCard;

import React, { useState, useEffect } from "react";

// third party package
import { DatePicker, Select } from "antd";

// Components
import EmailPassPhone from "./AllFields/EmailPassPhone";
import GenderAge from "./AllFields/GenderAge";
import NameField from "./AllFields/NameField";

const AddForm = (props) => {
  const [city, setCity] = useState([]);

  const {
    register,
    handleSubmit,
    onSubmit,
    errors,
    isLoading,
    setDateOfBirth,
    setHometown,
    role,
  } = props;

  const handleDateOfBirth = (date, dateString) => {
    setDateOfBirth(dateString);
  };

  const handleHometown = (value) => {
    setHometown(value);
  };

  useEffect(() => {
    fetch("/json/district.json")
      .then((res) => res.json())
      .then((data) => setCity(data));
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <NameField {...{ register, errors }} />
        <EmailPassPhone {...{ register, errors }} />

        {/* Designation */}
        {role === "member" && (
          <div className="mt-6">
            <label
              htmlFor="designation"
              className="block mb-2 text-sm font-medium text-[#344054] "
            >
              Designation
            </label>
            <div className=" flex h-12 items-center border border-[#D0D5DD] rounded-lg mt-1">
              <div>
                <input
                  {...register("designation", {
                    required: {
                      value: true,
                      message: "Designation is required",
                    },
                  })}
                  type="text"
                  id="designation"
                  className="text-[#667085] text-base block w-full h-full pl-2 focus:outline-none "
                  placeholder="Your designation"
                />
              </div>
            </div>
            <p className="text-left mt-1">
              {errors?.designation?.type === "required" && (
                <span className="w-full text-left text-red-400 text-sm">
                  {errors?.designation?.message}
                </span>
              )}
            </p>
          </div>
        )}

        <GenderAge {...{ register, errors }} />

        {/* Date of birth */}
        <div className="flex flex-col">
          <label className="mt-6 mb-1">Date of Birth</label>
          <DatePicker
            onChange={handleDateOfBirth}
            placeholder="Date of Birth"
            className="w-full"
            size="large"
          />
        </div>

        {/* Hometown */}
        <div className="flex flex-col">
          <label className="mt-6 mb-1">Hometown</label>
          <Select
            className="w-full mb-2"
            onChange={handleHometown}
            placeholder="Select Hometown"
            showSearch
            filterOption={(input, option) =>
              (option?.children ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            size="large"
          >
            {city.map((town) => {
              return (
                <Select.Option key={town.id} value={town.value}>
                  {town.name}
                </Select.Option>
              );
            })}
          </Select>
        </div>

        {/* Submit button */}
        <div className="flex justify-center w-full mt-8 mb-[30px] font-medium text-base">
          <button
            className="bg-gradient-to-t from-[#942DD9] to-[#F22876] text-white py-3 px-56 rounded-[8px]"
            type="submit"
          >
            {isLoading ? "Saving..." : "Save"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddForm;

// Configuration
import React, { useState, useEffect } from "react";

// Third party package
import { Button } from "@mui/material";
import { pink } from "@mui/material/colors";
import { Collapse, Modal } from "antd";
import { CaretRightOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { toast } from "react-hot-toast";

// Components
import AddQuestionForm from "../../../AddCourseQuiz/AddQuizQuestionContainer/AddQuestionForm/AddQuestionForm";
import { useDeleteCourseQuizQuestionMutation } from "../../../../../../Redux/features/Course/courseApi";

const EditQuizQuestionContainer = ({ content }) => {
  const [questionEditFormVisible, setQuestionEditFormVisible] = useState(false);
  const [contentForEdit, setContentForEdit] = useState(null);

  const [deleteCourseQuizQuestion, { data: response, error }] =
    useDeleteCourseQuizQuestionMutation();

  const handleQuestionFormCancel = () => {
    setQuestionEditFormVisible(false);
  };

  const handleEditContent = (content) => {
    setQuestionEditFormVisible(true);
    setContentForEdit(content);
  };

  const { confirm } = Modal;
  const showDeleteQuestionConfirm = (id) => {
    confirm({
      title: "Do you Want to delete this question?",
      icon: <ExclamationCircleFilled />,
      okButtonProps: {
        style: {
          backgroundColor: "red",
        },
      },
      onOk() {
        deleteCourseQuizQuestion(id);
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    if (response) {
      toast.success("Quiz Question Deleted");
    }
    if (error) {
      toast.error(
        <p>
          Failed to delete question. <br /> Please login or Try again later
        </p>
      );
    }
  }, [response, error]);

  const { Panel } = Collapse;

  return (
    <div className="px-6">
      {/* show all content */}
      <div className="mb-4">
        <Collapse
          bordered={false}
          defaultActiveKey={["1"]}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          collapsible={"icon"}
        >
          {content?.question?.map((q) => (
            <Panel
              header={
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-xl font-medium">{q?.question}</p>
                  </div>
                  <div className="flex gap-x-2">
                    <Button
                      onClick={() => handleEditContent(q)}
                      sx={{
                        color: pink[500],
                        borderColor: pink[500],
                      }}
                      variant="outlined"
                      className="whitespace-nowrap"
                    >
                      Edit Question
                    </Button>
                    <Button
                      onClick={() => showDeleteQuestionConfirm(q?._id)}
                      sx={{
                        color: pink[900],
                        borderColor: pink[900],
                      }}
                      variant="outlined"
                      className="whitespace-nowrap"
                    >
                      Delete Question
                    </Button>
                  </div>
                </div>
              }
              key={q?._id}
            >
              <div>
                {q?.options?.map((option, index) => (
                  <p
                    key={index}
                    className="ml-6 text-base font-Inter font-base"
                  >
                    Opt{index + 1}: {option}
                  </p>
                ))}
              </div>
              <h3 className="ml-6 mt-3 font-Inter font-medium text-lg">
                Answer: {q?.answer}
              </h3>
            </Panel>
          ))}
        </Collapse>
      </div>

      {/* Add question form modal */}
      <Modal
        title={<p className="text-xl">Edit Quiz</p>}
        open={questionEditFormVisible}
        onCancel={handleQuestionFormCancel}
        footer={null}
      >
        <AddQuestionForm
          {...{
            contentForEdit,
            setQuestionEditFormVisible,
            formType: "editForm",
          }}
        />
      </Modal>
    </div>
  );
};

export default EditQuizQuestionContainer;

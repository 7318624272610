import React, { useState } from "react";
import SearchManyUsers from "../../../Shared/components/SearchUsers/SearchManyUsers";
import UserWalletCard from "./UserWalletCard";
import { useSearchParams } from "react-router-dom";
import SingleWallet from "./singleWallet";
import { Pagination } from "@mui/material";

const UserWallets = () => {
  const [page, setPage] = useState(1);
  const [userObj, setUser] = useState({});
  const [searchParams] = useSearchParams();
  const user = searchParams.get("user");
  const { data } = userObj;
  return (
    <div>
      {!user && (
        <div>
          <SearchManyUsers {...{ setUser, limit: 10, page }} />
          <div className="grid mt-12 grid-cols-2 gap-4">
            {data?.users?.map((item) => (
              <UserWalletCard {...{ item }} />
            ))}
          </div>
          <div className="flex justify-around items-center my-12">
            <p className="leading-6 font-medium">
              Showing {userObj?.data?.users?.length} from {userObj?.data?.total}{" "}
              data
            </p>
            <Pagination
              className="bg-[#E3E4EB] px-[1px] py-[3px] rounded-md text-white"
              onChange={(event, value) => {
                setPage(value);
              }}
              count={Math.ceil(userObj?.data?.total / 10) || 1}
              shape="rounded"
            />
          </div>
        </div>
      )}
      {user && <SingleWallet {...{ user }} />}
    </div>
  );
};

export default UserWallets;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { apiBaseUrl } from "../../config/index";
export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseUrl,
  }),
  tagTypes: [
    "Badges",
    "SingleService",
    "AllReviews",
    "RecentBadges",
    "Course",
    "Categories",
    "ShopProduct",
    "UpdateProduct",
    "CourseReview",
    "SupportTicketClosed",
    "referral",
    "registrationRequest",
    "approvedUsers",
    "rejectedUsers",
    "askedToResubmit",
    "UpdateUserInfo",
  ],
  endpoints: (builder) => ({}),
});

import React from "react";
import AgentPackageNav from "./AgentPackageNav/AgentPackageNav";
import { Outlet } from "react-router-dom";

const AgentPackage = () => {
  return (
    <AgentPackageNav>
      <Outlet />
    </AgentPackageNav>
  );
};

export default AgentPackage;

import React from "react";
import { Outlet } from "react-router-dom";
import ShopBundleNav from "../PackagesNav/ShopBundleNav";
// import ShopPackageNav from "../PackagesNav/ShopPackageNav";

const ShopBundle = () => {
  return (
    <ShopBundleNav>
      <Outlet />
    </ShopBundleNav>
  );
};

export default ShopBundle;

// configuration
import React from "react";

export const OnGoingOrderDetailes = ({ item }) => {
  const { description, category, shiping, color, size } = item?.product || {};
  return (
    <div className="mt-[35px] px-[22px]">
      <div>
        <h2 className="text-[#707276] text-[16px] font-OpenSans font-bold text-left uppercase">
          description
        </h2>
        <p
          className="text-[16px] font-normal font-OpenSans text-left text-[#000000] mt-[12px] normal-case"
          dangerouslySetInnerHTML={{ __html: description }}
        ></p>
      </div>
      <div className="mt-[55px] w-full flex flex-col items-start gap-y-[32px] text-left">
        <div>
          <h4 className="text-[#707276] font-bold text-[16px] font-OpenSans uppercase">
            Category
          </h4>
          <p className="mt-[10px] text-[16px] text-[#000000] font-OpenSans font-normal">
            {category || ""}
          </p>
        </div>
        {shiping && (
          <div>
            <h4 className="text-[#707276] font-bold text-[16px] font-OpenSans uppercase">
              Shipping
            </h4>
            <p className="mt-[10px] text-[16px] text-[#000000] font-OpenSans font-normal">
              {shiping || ""} BDT
            </p>
          </div>
        )}
        {color && (
          <div>
            <h4 className="text-[#707276] font-bold text-[16px] font-OpenSans uppercase">
              Colors
            </h4>
            <p className="mt-[10px] text-[16px] text-[#000000] font-OpenSans font-normal capitalize">
              {color || ""}
            </p>
          </div>
        )}
        {size && (
          <div>
            <h4 className="text-[#707276] font-bold text-[16px] font-OpenSans uppercase">
              Size Style
            </h4>
            <p className="mt-[10px] text-[16px] text-[#000000] font-OpenSans font-normal">
              {size || ""}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

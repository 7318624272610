import React from "react";

const CourseLoader = () => {
  return (
    <div className="animate-pulse">
      <div className="shadow-[2px_2px_10px_2px_rgba(0,0,0,0.12)] w-[280px] rounded-2xl">
        <div className="w-full h-[200px] bg-slate-200 rounded-t-lg"></div>
        <div className="p-3 bg-white rounded-b-lg">
          <div className="bg-slate-200 h-5 mb-3 rounded-xl"></div>
          <div className="h-4 bg-slate-200 mb-1 rounded-xl"></div>
          <button className="py-3 px-[45px] rounded-2xl bg-slate-200 w-[150px] text-white font-medium text-lg"></button>
        </div>
      </div>
    </div>
  );
};

export default CourseLoader;

// configuration, ex: react-router
import React, { useState } from "react";

// Third Party Packages
import { AiOutlineWarning } from "react-icons/ai";
import { FaUsersSlash } from "react-icons/fa";
import Pagination from "@mui/material/Pagination";

// components
import { RecentRejectedUserCard } from "../../../../Shared/Cards/Recent__Rejected__User__Card/RecentRejectedUserCard";
import { RejectedUsersLoader } from "../../../../Shared/Loader/User/RejectedUsersLoader";
import { useGetRecentRejectedUsersQuery } from "../../../../../Redux/features/User/registrationRequestApi";

export const RecentRejectedUser = () => {
  const [page, setPage] = useState(1);
  // redux api
  const {
    data: rejectedUsers,
    isLoading,
    error,
  } = useGetRecentRejectedUsersQuery({
    role: "member",
    limit: 8,
    page: page,
  });

  // js variables
  let content;
  if (isLoading) {
    content = (
      <div className="mt-[24px] grid grid-cols-2 gap-[20px]  gap-y-[40px] ml-[-20px]">
        <RejectedUsersLoader />
        <RejectedUsersLoader />
        <RejectedUsersLoader />
        <RejectedUsersLoader />
      </div>
    );
  } else if (!isLoading && rejectedUsers?.data?.users.length === 0) {
    content = (
      <div className="flex flex-col items-center justify-center mt-[10%]">
        <FaUsersSlash className="text-[48px] text-gray-400" />
        <p className="mt-[10px] text-[22px] font-Inter font-medium text-gray-500">
          No User Found!
        </p>
      </div>
    );
  } else if (!isLoading && error) {
    content = (
      <div className="flex flex-col items-center justify-center mt-[10%]">
        <AiOutlineWarning className="text-[48px] text-gray-400" />
        <p className="mt-[10px] text-[22px] font-Inter font-medium text-gray-500">
          Server Error
        </p>
      </div>
    );
  } else if (!isLoading && rejectedUsers) {
    content = (
      <div className="mt-[24px] grid grid-cols-2 gap-8  gap-y-[40px] ml-[-20px]">
        {rejectedUsers?.data?.users?.slice(0, 4).map((user) => (
          <RecentRejectedUserCard key={user._id} user={user} />
        ))}
      </div>
    );
  }
  return (
    <div>
      <h1 className="text-[#333333] text-[32px] leading-[44px] font-semibold font-Nunito mt-10">
        Recent Rejected Users
      </h1>
      {content}
      <div className="flex justify-around items-center my-12">
        <p className="leading-6 font-medium">
          Showing {rejectedUsers?.data?.users?.length} from{" "}
          {rejectedUsers?.data?.total} data
        </p>
        <Pagination
          className="bg-[#E3E4EB] px-[1px] py-[3px] rounded-md text-white"
          onChange={(event, value) => {
            setPage(value);
          }}
          count={Math.ceil(rejectedUsers?.data?.total / 8) || 1}
          shape="rounded"
        />
      </div>
    </div>
  );
};

// Configuration
import React, { useEffect } from "react";
import { useParams } from "react-router";

// Third party package
import { AiOutlineWarning } from "react-icons/ai";

// Component
import { useGetSingleCourseQuery } from "../../../../Redux/features/Course/courseApi";
import { LineWaveLoader } from "../../../Shared/Loader/LineWaveLoader";
import SingleCourseLeft from "./SingleCourseLeft/SingleCourseLeft";
import useDocumentTitle from "../../../../helper/utilities/useDocumentTitle";

const SingleCoursePage = ({ courseId }) => {
  const { id } = useParams();

  const { data, isLoading, isError } = useGetSingleCourseQuery(courseId || id);
  const { course } = data || {};

  // Page Title
  useDocumentTitle("Admin | Course Details");

  let content;
  if (isLoading) {
    content = (
      <div className="min-h-[350px]">
        <LineWaveLoader />
      </div>
    );
  } else if (!isLoading && isError) {
    content = (
      <div className="w-full flex justify-center items-center min-h-[300px]">
        <div className="flex flex-col items-center justify-center mt-[100px]">
          <AiOutlineWarning className="text-[42px] text-gray-400" />
          <p className="mt-[10px] text-[16px] font-Inter font-medium text-gray-500">
            An Error occurred! <br /> Please login or try again later!
          </p>
        </div>
      </div>
    );
  } else if (!isLoading && data) {
    content = (
      <div className="max-w-[1200px] mx-auto h-auto flex flex-col gap-y-[20px] pt-[44px] px-[26px]">
        <div className="text-left max-w-[960px] mx-auto">
          <SingleCourseLeft {...{ course }} />
        </div>
      </div>
    );
  }

  // Scroll to top
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="pb-[50px]">
      <section>{content}</section>
    </div>
  );
};

export default SingleCoursePage;

import { apiSlice } from "../../api/apiSlice";
export const authenticationApi = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: data => ({
                url: "/admin/auth/login",
                method: "POST",
                body: data,
            }),
        }),
    }),
});

export const { useLoginMutation } = authenticationApi;

// configuration
import React, { useEffect } from "react";
import { toast, Toaster } from "react-hot-toast";

// Third party packages
import { useDispatch, useSelector } from "react-redux";
import { useSuspendUserMutation } from "../../../../../Redux/features/RestrictSuspend/User/restrictSuspendApi";

// components
import {
  resetRestrictInfo,
  setFor,
} from "../../../../../Redux/features/RestrictSuspend/User/restrictSuspendUserSlice";
import options from "../../../../Shared/variables/restrictSuspend/restrictSuspendOptions";

export const RestrictSuspendOptions = ({ searchQuery }) => {
  // hook variable declaration
  const disPatch = useDispatch();
  const restrictSuspend = useSelector((state) => state.restrictSuspend);
  const [suspendUser, { data, isLoading, error }] = useSuspendUserMutation();
  // function declaration
  const handleRestrictSuspendFor = (value) => {
    disPatch(setFor(Number(value)));
  };

  const restrictSuspendActionHandler = async () => {
    if (searchQuery?.length === 0) {
      toast.error(`Select an account to ${restrictSuspend?.action}`);
      return;
    }

    if (!restrictSuspend?.for) {
      toast.error(`Please add a duration to ${restrictSuspend?.action}`);
      return;
    }
    await suspendUser({
      data: {
        suspendFor: restrictSuspend?.for,
        suspended: restrictSuspend?.action?.toLowerCase() === "suspend",
        restricted: restrictSuspend?.action?.toLowerCase() === "restrict",
      },
      users: searchQuery,
    });
  };

  useEffect(() => {
    if (data) {
      toast.success(data?.message);
      disPatch(resetRestrictInfo());
    }
    if (error) {
      toast.error(error?.data?.message);
    }
  }, [data, error, disPatch]);

  return (
    <div className="mt-[40px] min-h-screen">
      <div className="w-full grid grid-cols-4 gap-x-[14px] gap-y-[20px]">
        {options.map((option) => {
          return (
            <button
              className={`w-[189px] h-[65px] rounded-[4px] drop-shadow-[4px_4px_4px_rgba(0,0,0,0.12)] text-[24px] font-Inter font-semibold ${
                restrictSuspend?.for === option.value
                  ? "bg-[linear-gradient(180deg,#DF2983_0%,#B02BBE_100%)] text-white"
                  : "bg-white text-[#000000]"
              }`}
              onClick={() => handleRestrictSuspendFor(option.value)}
              key={option.id}
            >
              {option.label}
            </button>
          );
        })}
      </div>
      <div className="mt-[83px] flex justify-start items-center gap-x-[26px]">
        <p className="text-[32px] text-[#1D252D] font-bold font-Inter">
          Custom :
        </p>
        <input
          className="border-[1px] border-[#000000] h-[65px] w-[141px] text-[32px] text-[#757575] font-Inter font-normal px-[20px] rounded-[4px] drop-shadow-[4px_4px_4px_rgba(0,0,0,0.12)]"
          type="number"
          name="for"
          id="for"
          onChange={(e) => handleRestrictSuspendFor(e.target.value)}
        />
      </div>
      <div className="w-full flex justify-center mt-[72px]">
        <button
          className={`w-[189px] h-[65px] rounded-[4px] drop-shadow-[4px_4px_4px_rgba(0,0,0,0.12)] text-[24px] font-Inter font-semibold bg-[linear-gradient(180deg,#DF2983_0%,#B02BBE_100%)] text-white`}
          onClick={restrictSuspendActionHandler}
        >
          {isLoading ? "Loading..." : "Save"}
        </button>
      </div>
      <Toaster />
    </div>
  );
};

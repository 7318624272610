// components
import AddBadge from "../../Admin__Portion/Components/User/AddBadge/AddBadge";
import AddUser from "../../Admin__Portion/Components/User/AddUser/AddUser";
import DownGradeUserPlan from "../../Admin__Portion/Components/User/DownGradeUserPlan";
import ManageResourceLimit from "../../Admin__Portion/Components/User/ManageResourceLimit/ManageResourceLimit";
import MarraigeCertificateVerification from "../../Admin__Portion/Components/User/MarraigeCertificateVerification/MarraigeCertificateVerification";
import MarkPriorityUser from "../../Admin__Portion/Components/User/PriorityUser/MarkPriorityUser";
import { RestrictSuspendUser } from "../../Admin__Portion/Components/User/RestrictSuspendUser/RestrictSuspendUser";
import UpgradeUserPlan from "../../Admin__Portion/Components/User/UpgradeUserPlan";
import { User } from "../../Admin__Portion/Components/User/User";
import SingleProfileDetails from "../../Admin__Portion/Components/User/ViewEditProfile/SingleProfileDetails/SingleProfileDetails";
import ViewEditProfile from "../../Admin__Portion/Components/User/ViewEditProfile/ViewEditProfile";
import ViewEditProfileContainer from "../../Admin__Portion/Components/User/ViewEditProfile/ViewEditProfileContainer";
import { AllRejectedUsers } from "../../Admin__Portion/Components/User/ViewRegistrationRequest/allViewRequests/AllRejectedUsers";
import { RegistrationRequestAdmin } from "../../Admin__Portion/Components/User/ViewRegistrationRequest/allViewRequests/RegistrationRequestAdmin";
import ViewRegistrationForUserInfo from "../../Admin__Portion/Components/User/ViewRegistrationRequest/ViewRegistrationForUserInfo/ViewRegistrationForUserInfo";
import { ViewRegistrationRequest } from "../../Admin__Portion/Components/User/ViewRegistrationRequest/ViewRegistrationRequest";
import ViewUserActivities from "../../Admin__Portion/Components/User/ViewUserActivities/ViewUserActivities";
import SendForPayment from "../../Admin__Portion/Components/User/View_Payment/SendForPayment";
import { DocumentsVerificationUserProfiles } from "../../Admin__Portion/Shared/components/DocumentVerification/documentVerificationUserProfiles";
import EditEducationalInfo from "../../Admin__Portion/Shared/ProfileEditForms/EditEducationalInfo";
import EditFamilyInfo from "../../Admin__Portion/Shared/ProfileEditForms/EditFamilyInfo";
import EditOthersInfo from "../../Admin__Portion/Shared/ProfileEditForms/EditOthersInfo";
import EditPersonalInfo from "../../Admin__Portion/Shared/ProfileEditForms/EditPersonalInfo";
import EditPhysicalInfo from "../../Admin__Portion/Shared/ProfileEditForms/EditPhysicalInfo";
import EditProfessionalInfo from "../../Admin__Portion/Shared/ProfileEditForms/EditProfessionalInfo";
import EditSiblingsInfo from "../../Admin__Portion/Shared/ProfileEditForms/EditSiblingsInfo";

const userRoutes = [
  {
    id: 1,
    path: "/user",
    element: <User />,
    nestedRoutes: [
      { id: 1, path: "/user/addUser", element: <AddUser /> },
      {
        id: 2,
        path: "registrationRequest",
        element: <ViewRegistrationRequest />,
      },
      {
        id: 3,
        path: "upgradeUser",
        element: <UpgradeUserPlan />,
      },
      {
        id: 4,
        path: "downgradeUser",
        element: <DownGradeUserPlan notificationFor="downgrade" />,
      },
      {
        id: 5,
        path: "sendPayment",
        element: <SendForPayment notificationFor="Payment" />,
      },
      {
        id: 6,
        path: "sendUpgrade",
        element: <SendForPayment notificationFor="Upgrade" />,
      },
      {
        id: 7,
        path: "sendOffer",
        element: <SendForPayment notificationFor="Offer" />,
      },
      {
        id: 8,
        path: "userProfile",
        element: <ViewEditProfileContainer />,
        nestedRoutes: [
          {
            id: 1,
            path: "",
            element: <ViewEditProfile />,
          },
          {
            id: 2,
            path: "profile/:id",
            element: <SingleProfileDetails />,
            nestedRoutes: [
              {
                id: 1,
                path: "personalInfo/:id",
                element: <EditPersonalInfo />,
              },
              {
                id: 2,
                path: "professionalInfo/:id",
                element: <EditProfessionalInfo />,
              },
              {
                id: 3,
                path: "educationalInfo/:id",
                element: <EditEducationalInfo />,
              },
              {
                id: 4,
                path: "physicalInfo/:id",
                element: <EditPhysicalInfo />,
              },
              {
                id: 5,
                path: "othersInfo/:id",
                element: <EditOthersInfo />,
              },
              {
                id: 6,
                path: "familyInfo/:id",
                element: <EditFamilyInfo />,
              },
              {
                id: 7,
                path: "siblingsInfo/:id",
                element: <EditSiblingsInfo />,
              },
            ],
          },
        ],
      },
      {
        id: 9,
        path: "usersActivities",
        element: <ViewUserActivities />,
      },
      {
        id: 10,
        path: "manageResource",
        element: <ManageResourceLimit />,
      },
      {
        id: 11,
        path: "addBadge",
        element: <AddBadge />,
      },
      {
        id: 12,
        path: "documentsVerification",
        element: <DocumentsVerificationUserProfiles role="member"/>,
      },
      {
        id: 13,
        path: "marriageCertificate",
        element: <MarraigeCertificateVerification />,
      },
      {
        id: 14,
        path: "markPriority",
        element: <MarkPriorityUser />,
      },
      {
        id: 15,
        path: "allRegistrationRequest",
        element: <RegistrationRequestAdmin />,
      },
      {
        id: 16,
        path: "registrationRequest/:requestedId",
        element: <ViewRegistrationForUserInfo />,
      },
      {
        id: 17,
        path: "allRejectedUsers",
        element: <AllRejectedUsers />,
      },
      {
        id: 18,
        path: "restrictUser",
        element: <RestrictSuspendUser />,
      },
    ],
  },
];

export default userRoutes;

// Configuration
import React from "react";
import LatestInvoiceTable from "./LatestInvoiceTable/LatestInvoiceTable";

// Components
import NewUserChart from "./NewUserChart/NewUserChart";
import ServerStatusChart from "./ServerStatusChart/ServerStatusChart";
import VisitorStatusChart from "./VisitorStatusChart/VisitorStatusChart";

export const Chart = () => {
  return (
    <div className="mt-10 pl-8">
      {/* Visitor Status chart */}
      <VisitorStatusChart />

      <div className="grid grid-cols-2 gap-4 mt-12 mb-10">
        <NewUserChart />
        <ServerStatusChart />
      </div>

      {/* Latest invoice table */}
      <LatestInvoiceTable />
    </div>
  );
};

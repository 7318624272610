// configuration
import React from "react";

// Third party packages
import { BiEdit } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

export const ListingCard = ({ product }) => {
  const navigate = useNavigate();

  const name =
    product?.name?.length > 30
      ? product?.name?.substr(0, 30) + "..."
      : product?.name;

  return (
    <div className="w-full h-auto">
      <div className="w-full h-[48px] flex justify-end mb-[50px]">
        <button
          className="bg-[#E41272] w-[122px] text-white flex items-center text-[20px] font-bold font-Inter rounded-[100px] justify-center gap-x-[8px]"
          onClick={() => navigate(`/shop/productEdit/${product?._id}`)}
        >
          Edit
          <BiEdit />
        </button>
      </div>
      <div className="w-full h-[320px] bg-[linear-gradient(104.63deg,#75204C_1.61%,_#360C41_97.81%)] rounded-[18px] relative px-[35px] py-[31px] shadow-[2px_2px_10px_2px_rgba(0,0,0,0.12)]">
        <img
          src={product?.photos[0]?.url}
          alt="product"
          className="absolute -top-[30%] left-[5%] w-72 h-64"
        />
        <div className="text-white relative flex flex-col items-start justify-end h-full font-playFair">
          <p className="text-[14px] font-normal">{product?.brand || ""}</p>
          <p
            onClick={() => navigate(`/shop/product/${product?._id}`)}
            className="text-[24px] font-normal cursor-pointer hover:text-green-300"
          >
            {name}
          </p>
          <p className="text-[32px] font-bold">{product?.price} BDT</p>
        </div>
      </div>
    </div>
  );
};

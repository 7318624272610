import React from "react";
import { useNavigate } from "react-router-dom";

const ServiceOwnerInfo = ({ service }) => {
    const navigate = useNavigate();
    const { role, price, _id } = service || {};
    const { firstName, lastName, designation, aboutYou, profilePhoto, citizenShip, role: userRole } = role || {};
    const name = `${firstName} ${lastName}`;

    let serviceEditRedirectPath;
    if (userRole?.includes("agent")) {
        serviceEditRedirectPath = `/agent/serviceEdit/${_id}`;
    } else if (userRole?.includes("kazi")) {
        serviceEditRedirectPath = `/kazi/serviceEdit/${_id}`;
    } else if (userRole?.includes("lawyer")) {
        serviceEditRedirectPath = `/lawyer/serviceEdit/${_id}`;
    }

    return (
        <div>
            <section className="flex justify-between">
                <div className="w-[321px]">
                    <div className="flex items-center gap-[10px] mb-4">
                        <img className="w-[65px] h-[65px] rounded-full border-2 border-black" src={profilePhoto} alt="" />
                        <div>
                            <p className="text-[21px] leading-7 font-semibold mb-[10px]">{name}</p>
                            <p className="text-sm leading-5 font-light">{designation || "not provided"}</p>
                        </div>
                    </div>
                    {aboutYou && <p className="text-sm leading-5 mb-10">{aboutYou}</p>}
                    <div className="flex justify-between items-center">
                        <p className="uppercase pl-3">{citizenShip[0] || "Banglades"}</p>
                        <button className="text-xs text-white leading-[17px] font-semibold uppercase bg-black rounded-[3px] w-[85px] py-2">
                            Contact
                        </button>
                    </div>
                </div>
                <div>
                    <h1 className="text-[32px] leading-7 font-bold mb-[26px] mt-2">$ {price}</h1>
                    <button
                        className="text-xl leading-[30px] font-semibold text-white text-center w-[199px] py-3 rounded-2xl"
                        style={{
                            boxShadow: "3px 3px 10px 3px rgba(0, 0, 0, 0.12)",
                            backgroundImage: "linear-gradient(93.32deg, #DC298D 8.25%, #A82BC5 93.83%)",
                        }}
                        onClick={() => navigate(serviceEditRedirectPath)}
                    >
                        Edit Now
                    </button>
                </div>
            </section>
        </div>
    );
};

export default ServiceOwnerInfo;

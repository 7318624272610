// Configuration
import React from "react";

// Third party packages
import Pagination from "@mui/material/Pagination";
import { Input } from "antd";
import { FiSearch } from "react-icons/fi";

// Components
import agentImg from "../../../../assets/AdminRuffImage/adminRuffImg.jpg";
import AgentModifyDealHistoryCard from "../../../Shared/Cards/Agent/AgentModifyDealHistoryCard/AgentModifyDealHistoryCard";

const ModifyDealHistory = () => {
    const agentsData = [
        { id: 1, agentName: "John William", age: 25, img: agentImg },
        { id: 2, agentName: "John William", age: 25, img: agentImg },
        { id: 3, agentName: "John William", age: 25, img: agentImg },
        { id: 4, agentName: "John William", age: 25, img: agentImg },
        { id: 5, agentName: "John William", age: 25, img: agentImg },
        { id: 6, agentName: "John William", age: 25, img: agentImg },
    ];
    return (
        <div className="mx-8">
            <div>
                <Input
                    className="max-w-lg mx-auto border-2 border-[#FFE8F3] flex py-2 px-4 mb-[65px]"
                    style={{ boxShadow: "0px 4px 4px rgba(62, 73, 84, 0.04)" }}
                    placeholder="Search user name"
                    prefix={<FiSearch className="text-2xl mr-4" />}
                />
            </div>
            <section className="grid grid-cols-2 gap-[29px]">
                {agentsData.map(agent => (
                    <AgentModifyDealHistoryCard key={agent.id} {...{ agent }} />
                ))}
            </section>
            <div className="flex justify-between items-center my-[70px] ">
                <p className="text-[#3E4954] leading-6 font-medium">Showing 20 from 180 data</p>
                <Pagination className="bg-[#E3E4EB] px-[1px] py-[3px] rounded-md text-white" count={8} shape="rounded" />
            </div>
        </div>
    );
};

export default ModifyDealHistory;

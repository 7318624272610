// configuration
import React from "react";

export const BillingSummaryLeft = ({ data }) => {
  return (
    <div className="left_Side">
      <h2 className="text-[#000000] text-[16px] font-bold font-OpenSans text-left">
        Billing Summary
      </h2>
      <div className="w-full flex flex-col items-start gap-y-[12px] mt-[29px]">
        <div className="w-full flex justify-between items-center">
          <p className="text-[14px] font-OpenSans font-normal text-[#4F4F4F]">
            Subtotal
          </p>
          <p className="text-[15px] font-semibold font-OpenSans text-[#4F4F4F]">
            5000
          </p>
        </div>
        <div className="w-full flex justify-between items-center">
          <p className="text-[14px] font-OpenSans font-normal text-[#4F4F4F]">
            Discount
          </p>
          <p className="text-[15px] font-semibold font-OpenSans text-[#4F4F4F]">
            -1000
          </p>
        </div>
        <div className="w-full flex justify-between items-center">
          <p className="text-[14px] font-OpenSans font-normal text-[#4F4F4F]">
            Shipping
          </p>
          <p className="text-[15px] font-semibold font-OpenSans text-[#4F4F4F]">
            {data?.singleProduct?.deliveryCharge || 0}
          </p>
        </div>
        <div className="w-full flex justify-between items-center">
          <p className="text-[14px] font-OpenSans font-normal text-[#4F4F4F]">
            Tax
          </p>
          <p className="text-[15px] font-semibold font-OpenSans text-[#4F4F4F]">
            {data?.singleProduct?.tax || 0}
          </p>
        </div>
      </div>
    </div>
  );
};

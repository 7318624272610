import React from 'react';
import WalletNav from './currencyNav/walletNav';
import { Outlet } from 'react-router-dom';

const Wallet = () => {
    return (
        <WalletNav>
            <Outlet />
        </WalletNav>
    );
}

export default Wallet;

// configuration
import React from "react";

export const OnGoingOrderBillingDetailes = ({ billingInfo }) => {
  const { firstName, lastName, email, address1, city, state, zipCode, phone } =
    billingInfo || {};
  return (
    <div className="biiling__container">
      <p className="text-[#000000] text-[16px] font-bold font-OpenSans text-left">
        Billing Address
      </p>
      <div className="flex flex-col items-start mt-[24px] gap-y-[20px]">
        <p className="text-[#4F4F4F] text-[15px] font-semibold font-OpenSans capitalize">
          {(firstName || "") + " " + (lastName || "")}
        </p>
        <p className="text-[#4F4F4F] text-[15px] font-semibold font-OpenSans">
          {email || ""}
        </p>
        <div className="text-left">
          <p className="text-[12px] text-[#828282] font-normal font-OpenSans">
            Street Address
          </p>
          <p className="text-[#666666] text-[14px] font-normal font-SourceCodePro capitalize">
            {`${address1 || ""}, ${city || ""} ${zipCode || ""}, ${
              state || ""
            }`}
          </p>
        </div>
        <div className="w-full grid grid-cols-2 mt-[36px]">
          <div className="w-full flex flex-col items-start">
            <p className="text-[#828282] font-normal font-OpenSans text-[12px]">
              State/Province
            </p>
            <p className="text-[15px] font-semibold font-OpenSans text-[#4F4F4F] capitalize">
              {state || ""}
            </p>
          </div>
          <div className="w-full flex flex-col items-start">
            <p className="text-[#828282] font-normal font-OpenSans text-[12px]">
              City
            </p>
            <p className="text-[15px] font-semibold font-OpenSans text-[#4F4F4F] capitalize">
              {city || ""}
            </p>
          </div>
        </div>
        <div className="w-full grid grid-cols-2 mt-[36px]">
          <div className="w-full flex flex-col items-start">
            <p className="text-[#828282] font-normal font-OpenSans text-[12px]">
              Zip/Postal Code
            </p>
            <p className="text-[15px] font-semibold font-OpenSans text-[#4F4F4F]">
              {zipCode || ""}
            </p>
          </div>
          <div className="w-full flex flex-col items-start">
            <p className="text-[#828282] font-normal font-OpenSans text-[12px]">
              Phone
            </p>
            <p className="text-[15px] font-semibold font-OpenSans text-[#4F4F4F]">
              {phone || ""}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

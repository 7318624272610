// Configuration
import React, { useEffect, useState } from "react";

// Third party packages
import { Button, Modal } from "antd";

// Components
import { BsLightningCharge } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import { useAddCategoryMutation } from "../../../../../Redux/features/Shop/shopApi";
import { useAddCourseTypeMutation } from "../../../../../Redux/features/Course/courseApi";
import { toast } from "react-hot-toast";

const AddCategoriesModal = ({
  addCategoriesOpen,
  handleAddCategoriesCancel,
  title: dynamicTitle,
  setAddCategoriesOpen,
}) => {
  const [categoryName, setCategoryName] = useState("");
  // Redux api for categories
  const [addCategory, { data: response, isLoading, error }] =
    useAddCategoryMutation();

  const [
    addCourseType,
    { data: typeResponse, isLoading: typeLoading, error: typeError },
  ] = useAddCourseTypeMutation();

  const handleAddCategoriesOk = async () => {
    if (dynamicTitle === "courseCategory") {
      await addCategory({ name: categoryName, type: "course" });
    }
    if (dynamicTitle === "shopCategory") {
      await addCategory({ name: categoryName, type: "shop" });
    }
    if (dynamicTitle === "courseTypes") {
      await addCourseType({ name: categoryName });
    }
  };

  useEffect(() => {
    if (response) {
      setAddCategoriesOpen(false);
      setCategoryName("");
      toast.success(<p>Category Added Successfully</p>);
    }
    if (typeResponse) {
      setAddCategoriesOpen(false);
      setCategoryName("");
      toast.success(<p>Type Added Successfully</p>);
    }
    if (error || typeError) {
      toast.error(
        <p>
          Something Went Wrong! <br /> Please try again later.
        </p>
      );
      setAddCategoriesOpen(false);
      setCategoryName("");
    }
  }, [response, error, typeError, typeResponse, setAddCategoriesOpen]);

  const title = (
    <div className="flex items-center gap-1">
      <BsLightningCharge className="text-xl" />
      <p className="text-3xl leading-6 font-Inter font-normal">
        New {dynamicTitle === "courseTypes" && "Type"}{" "}
        {dynamicTitle === "courseCategory" && "Category"}
        {dynamicTitle === "shopCategory" && "Category"}
      </p>
    </div>
  );
  return (
    <Modal
      open={addCategoriesOpen}
      closeIcon={<RxCross2 className="text-[#202020] mx-auto" />}
      title={title}
      onOk={handleAddCategoriesOk}
      onCancel={handleAddCategoriesCancel}
      footer={[
        <Button
          key="back"
          onClick={handleAddCategoriesCancel}
          className="text-[#E41272] h-12 px-6 text-xl font-bold rounded-[40px]"
        >
          Dismiss
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isLoading || typeLoading}
          onClick={handleAddCategoriesOk}
          className="bg-[#E41272] h-12 px-6 text-xl text-white font-bold rounded-[40px]"
        >
          Submit
        </Button>,
      ]}
    >
      <p className="text-2xl leading-6 font-Inter my-8">
        Write the {dynamicTitle === "courseTypes" && "type"}{" "}
        {dynamicTitle === "courseCategory" && "category"}
        {dynamicTitle === "shopCategory" && "category"} name
      </p>
      <textarea
        name="newCategoryName"
        value={categoryName}
        id="newCategoryName"
        rows="4"
        className="border border-[#EEF6FF] rounded w-full px-4 py-3 text-xl leading-5 font-Inter mb-6 focus:outline-none"
        style={{ boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.12)" }}
        onChange={(e) => setCategoryName(e.target.value)}
      />
    </Modal>
  );
};

export default AddCategoriesModal;

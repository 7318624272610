// configuration
import React from "react";
import { useNavigate } from "react-router-dom";

export const LawyerClientChatCard = ({ user }) => {
  // varible declation
  // hook variables
  const navigate = useNavigate();
  return (
    <div className="w-[385px] h-[222px] bg-[linear-gradient(130.32deg,#FFA4EB_1.59%,_#FCD6BC_37.11%,#F9EBC9_64.89%,#FFC58F_100.54%)] shadow-xl rounded-[8px] px-[35px] py-[31px]">
      <div className="flex gap-x-[24px]">
        <img
          className="w-[85px] h-[85px] rounded-full"
          src="https://scontent.frjh5-1.fna.fbcdn.net/v/t39.30808-6/310814492_3383940818533755_1197676094327108122_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=09cbfe&_nc_eui2=AeGA9lfn6WujkVz6fYFMVoFwjDMuNZrRHNWMMy41mtEc1bicZO-AvSF9FXCNTfRYimndHxJlLdYlNYLMqh4qcFXK&_nc_ohc=TN6NAhipGjoAX-h57ft&_nc_ht=scontent.frjh5-1.fna&oh=00_AfDPBpJVgq-X6Vevi0OxzDPpHdzuL4wvqYREm9DWp8TuSw&oe=63A5391C"
          alt="profile"
        />
        <div className="flex flex-col gap-y-[18px]">
          <h1 className=" text-[24px] leading-[29px] font-medium">
            {user?.firstName + user?.lastName}
          </h1>
          <p className=" text-[20px] leading-[24px] font-medium">
            Age:{user?.age}
          </p>
          <button
            className="w-[135px] h-[37px] mt-[20px] flex justify-center items-center bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] text-white rounded-[4px]"
            onClick={() => navigate(`/lawyer/chatLists/${user._id}`)}
          >
            Check Again
          </button>
        </div>
      </div>
    </div>
  );
};

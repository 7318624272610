import getCookie from "../../../helper/cookies/getCookie";
import { getRegistrationRequestUrlByRole } from "../../../helper/utilities/registrationRequest";
import { apiSlice } from "../../api/apiSlice";

export const registrationRequestApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRegistrationRequest: builder.query({
      query: ({ page, searchTerm, role, limit }) => ({
        url: getRegistrationRequestUrlByRole(role, page, searchTerm, limit),
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      keepUnusedDataFor: 0,
    }),
    getAllRegistrationRequests: builder.query({
      query: ({ page, searchTerm, role, limit }) => ({
        // url: getRegistrationRequestUrlByRole(role, page, searchTerm, limit),
        url: `/admin/member/registrationRequest?_limit=${limit}&_role=${role}&_page=${page}&_keyword=${searchTerm}`,
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      providesTags: ["registrationRequest"],
      keepUnusedDataFor: 0,
    }),
    getRecentRejectedUsers: builder.query({
      query: ({ role, limit, page }) => ({
        url: `/admin/member/rejected?_role=${role}&_limit=${limit}&_page=${page}`,
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      providesTags: ["rejectedUsers"],
      keepUnusedDataFor: 0,
    }),
    getApprovedusers: builder.query({
      query: ({ role, limit, page }) => ({
        url: `/admin/member/approved?_role=${role}&_limit=${limit}&_page=${page}`,
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      providesTags: ["approvedUsers"],
      keepUnusedDataFor: 0,
    }),
    approveMember: builder.mutation({
      query: (id) => ({
        url: `/admin/action/approve/${id}`,
        method: "POST",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      invalidatesTags: ["registrationRequest", "approvedUsers"],
    }),
    rejectMember: builder.mutation({
      query: (id) => ({
        url: `/admin/action/reject/${id}`,
        method: "POST",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      invalidatesTags: ["registrationRequest","rejectedUsers"],
    }),
    sendToResubmitMember: builder.mutation({
      query: (id) => ({
        url: `/admin/action/member/askedToResubmit/${id}`,
        method: "POST",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      invalidatesTags: ["registrationRequest","askedToResubmit"],
    }),
    getAskToResubmitUsers: builder.query({
      query: ({ role, limit, page, searchText }) => ({
        url: `/admin/member/askedToResubmit?_role=${role}&_limit=${limit}&_page=${page}&_name=${searchText}`,
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      providesTags: ["askedToResubmit"],
    }),
  }),
});

export const {
  useGetRegistrationRequestQuery,
  useGetApprovedusersQuery,
  useApproveMemberMutation,
  useRejectMemberMutation,
  useSendToResubmitMemberMutation,
  useGetRecentRejectedUsersQuery,
  useGetAskToResubmitUsersQuery,
  useGetAllRegistrationRequestsQuery
} = registrationRequestApi;

// Configuration
import React, { useEffect } from "react";

// Third party package
import { Select } from "antd";
import { Oval } from "react-loader-spinner";
import { AiOutlinePlus } from "react-icons/ai";

// Components
import { useGetAllCourseTypesQuery } from "../../../../../Redux/features/Course/courseApi";
import { useGetAllCategoriesQuery } from "../../../../../Redux/features/Shop/shopApi";
import Error from "../../../../Shared/components/accordion/UI/Error/Error";
import { RichTextEditor } from "../../../../Shared/components/TextEditor/RichTextEditor";
import FaqInputField from "./FaqInputField";
import InstructorInfoField from "./InstructorInfoField";
import { usePhotosUploadOnServerMutation } from "../../../../../Redux/features/fileUpload/fileUploadApi";
import { Toaster, toast } from "react-hot-toast";

const AddCourseInputField = ({
  register,
  errors,
  control,
  setCategory,
  setType,
  setSkillLevel,
  setCourseDetails,
  setWhatIsInThisCourse,
  customError,
  setCustomError,
  formType,
  setImage,
  image,
  instructorImageExtra,
  setInstructorImageExtra,
  instructorImage,
  setInstructorImage,
}) => {
  // Redux api
  const [
    photosUploadOnServer,
    {
      data: imageUploadResponse,
      isLoading: imageUploadLoading,
      error: imageUploadError,
    },
  ] = usePhotosUploadOnServerMutation();
  const { data: categoryResponse } = useGetAllCategoriesQuery("course");
  const { data: typeResponse } = useGetAllCourseTypesQuery();

  const categoryOptions = categoryResponse
    ? categoryResponse?.category?.map((category) => {
        return {
          value: category?._id,
          label: category?.name,
        };
      })
    : [];
  const typeOptions = typeResponse
    ? typeResponse?.types?.map((type) => {
        return {
          value: type?._id,
          label: type?.name,
        };
      })
    : [];

  const imageUploadHandler = (e) => {
    if (e.target.files) {
      const formData = new FormData();
      const image = e.target.files[0];
      formData.append("image", image);
      photosUploadOnServer(formData);
    }
  };

  useEffect(() => {
    if (imageUploadResponse) {
      const image = imageUploadResponse?.data[0]?.path;
      setImage(image);
    }
    if (imageUploadError) {
      toast.error(
        <p>
          Failed to upload banner. <br /> Please login first or try again later.
        </p>
      );
    }
  }, [imageUploadResponse, setImage, imageUploadError]);

  return (
    <div className="flex flex-col items-start w-full gap-y-[40px]">
      {/* Course Name */}
      <div className="w-full flex flex-col">
        <label className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]">
          Course Name
        </label>
        <input
          {...register(
            "name",
            formType === "addForm" && {
              required: "Course Name is required",
            }
          )}
          className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-4 px-[12px]"
          type="text"
          placeholder="Course Name"
        />
        {errors?.name && <Error message={errors?.name?.message} />}
      </div>

      {/* Course short description */}
      <div className="w-full flex flex-col">
        <label className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]">
          Short Description
        </label>
        <textarea
          {...register(
            "description",
            formType === "addForm" && {
              required: "Short description is required",
            }
          )}
          className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-2 px-[12px]"
          type="text"
          placeholder="Short description"
        />
        {errors?.description && (
          <Error message={errors?.description?.message} />
        )}
      </div>

      {/* Course banner */}
      <div className="w-full flex flex-col">
        <label className="text-left mb-3 text-[12px] font-bold text-[#707276] ml-[5px]">
          Course Banner
        </label>
        <div className="flex justify-start items-center gap-x-[22px] w-full">
          <label
            htmlFor="imageUpload"
            className="flex items-center justify-start pl-6 gap-x-[27px] bg-white w-full h-[60px] shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[4px] cursor-pointer"
          >
            {imageUploadLoading ? (
              <Oval
                height={20}
                width={20}
                color="#CC1A7A"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#A72CC7"
                strokeWidth={4}
                strokeWidthSecondary={4}
              />
            ) : (
              <>
                {image ? (
                  <>
                    <span className="text-green-400 font-semibold font-Inter">
                      Banner Added
                    </span>
                    <img
                      src={image}
                      alt="banner"
                      className="w-[30px] h-[30px] rounded-full"
                    />
                  </>
                ) : (
                  <p className="flex items-center gap-x-2 text-[#707276] text-sm">
                    Upload Banner
                    <AiOutlinePlus />
                  </p>
                )}
              </>
            )}
          </label>
        </div>
        <input
          type="file"
          name="imageUpload"
          id="imageUpload"
          className="hidden"
          onChange={imageUploadHandler}
        />
        {formType === "addForm" && customError[0].courseBannerError && (
          <Error message={customError[0].courseBannerError} />
        )}
      </div>

      {/* Course category */}
      <div className="w-full flex flex-col">
        <label className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]">
          Course Category
        </label>
        <Select
          placeholder="Select Category"
          bordered={false}
          className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-4 px-[12px] text-start flex items-center"
          options={categoryOptions}
          onSelect={(value) => setCategory(value)}
          onChange={() =>
            setCustomError((prevState) => [
              {
                categoryError: "",
              },
            ])
          }
        />
        {formType === "addForm" && customError[0].categoryError && (
          <Error message={customError[0].categoryError} />
        )}
      </div>

      {/* Course type */}
      <div className="w-full flex flex-col">
        <label className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]">
          Course Type
        </label>
        <Select
          placeholder="Select Type"
          bordered={false}
          className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-4 px-[12px] text-start flex items-center"
          options={typeOptions}
          onSelect={(value) => setType(value)}
          onChange={() =>
            setCustomError((prevState) => [
              {
                typeError: "",
              },
            ])
          }
        />
        {formType === "addForm" && customError[0]?.typeError && (
          <Error message={customError[0]?.typeError} />
        )}
      </div>

      {/* Skill Level */}
      <div className="w-full flex flex-col">
        <label className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]">
          Skill Level
        </label>
        <Select
          placeholder="Select Level"
          bordered={false}
          className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-4 px-[12px] text-start flex items-center"
          options={[
            {
              value: "Beginner",
              label: "Beginner",
            },
            {
              value: "Intermediate",
              label: "Intermediate",
            },
            {
              value: "Advanced",
              label: "Advanced",
            },
          ]}
          onSelect={(value) => setSkillLevel(value)}
          onChange={() =>
            setCustomError((prevState) => [
              {
                skillLevelError: "",
              },
            ])
          }
        />
        {formType === "addForm" && customError[0]?.skillLevelError && (
          <Error message={customError[0]?.skillLevelError} />
        )}
      </div>

      {/* Course duration */}
      <div className="w-full flex flex-col">
        <label className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]">
          Course Duration
        </label>
        <input
          {...register(
            "duration",
            formType === "addForm" && {
              required: "Course Duration is required",
            }
          )}
          className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-4 px-[12px]"
          type="text"
          placeholder="Duration"
        />
        {errors?.duration && <Error message={errors?.duration?.message} />}
      </div>

      {/* Course Section */}
      <div className="w-full flex flex-col">
        <label className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]">
          Section
        </label>
        <input
          {...register(
            "section",
            formType === "addForm"
              ? {
                  required: "Section Number is required",
                  pattern: {
                    value: /^[0-9]*$/,
                    message: "Input must be a number",
                  },
                  min: 1,
                }
              : {
                  pattern: {
                    value: /^[0-9]*$/,
                    message: "Input must be a number",
                  },
                  min: 1,
                }
          )}
          className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-4 px-[12px]"
          type="number"
          placeholder="Section Number"
        />
        {errors?.section && <Error message={errors.section.message} />}
      </div>

      {/* Course price */}
      <div className="w-full flex flex-col">
        <label className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]">
          Price
        </label>
        <input
          {...register(
            "price",
            formType === "addForm"
              ? {
                  required: "Price is required",
                  pattern: {
                    value: /^[0-9]*$/,
                    message: "Input must be a number",
                  },
                  min: 1,
                }
              : {
                  pattern: {
                    value: /^[0-9]*$/,
                    message: "Input must be a number",
                  },
                  min: 1,
                }
          )}
          className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-4 px-[12px]"
          type="number"
          placeholder="Price"
        />
        {errors?.price && <Error message={errors.price.message} />}
      </div>

      {/* Course old price */}
      <div className="w-full flex flex-col">
        <label className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]">
          Old Price
        </label>
        <input
          {...register("oldPrice", {
            pattern: {
              value: /^[0-9]*$/,
              message: "Input must be a number",
            },
            min: 1,
          })}
          className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-4 px-[12px]"
          type="number"
          placeholder="Price"
        />
        {errors?.oldPrice && <Error message={errors.oldPrice.message} />}
      </div>

      {/* what Is In This Course */}
      <div className="w-full flex flex-col">
        <label className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]">
          What Is In This Course ?
        </label>
        <div className="min-h-[250px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-4 px-[12px]">
          <RichTextEditor {...{ setDescription: setWhatIsInThisCourse }} />
        </div>
        {customError[0]?.whatIsInThisCourseError && (
          <Error message={customError[0]?.whatIsInThisCourseError} />
        )}
      </div>

      {/* Course details */}
      <div className="w-full flex flex-col">
        <label className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]">
          Course Details
        </label>
        <div className="min-h-[250px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-4 px-[12px]">
          <RichTextEditor {...{ setDescription: setCourseDetails }} />
        </div>
        {customError[0]?.courseDetailsError && (
          <Error message={customError[0]?.courseDetailsError} />
        )}
      </div>

      {/* Frequently asked question */}
      <FaqInputField {...{ register, errors, formType }} />

      {/* Course instructor info */}
      {formType === "addForm" && (
        <InstructorInfoField
          {...{
            register,
            errors,
            control,
            formType,
            instructorImageExtra,
            setInstructorImageExtra,
            instructorImage,
            setInstructorImage,
            customError,
          }}
        />
      )}

      <Toaster />
    </div>
  );
};

export default AddCourseInputField;

// Configuration
import React from "react";
import { useParams } from "react-router";

// Components
import { OvalLoader } from "../../../Shared/Loader/OvalLoader";
import ErrorMessage from "../../../Shared/components/ErrorMessage/ErrorMessage";
import { useGetSingleProductQuery } from "../../../../Redux/features/Shop/shopApi";
import SingleProductImages from "./SingleProductImages/SingleProductImages";
import SingleProductOtherDetails from "./SingleProductOtherDetails/SingleProductOtherDetails";
import SingleProductBasicDetails from "./SingleProductBasicDetails/SingleProductBasicDetails";

const SingleProductDetails = () => {
  const { id } = useParams();
  const {
    data: productData,
    isLoading,
    isError,
  } = useGetSingleProductQuery(id);
  const { product, similarProducts } = productData || {};

  const { description } = product || {};

  let content;
  if (isLoading) {
    content = (
      <div className="h-[20vh]">
        <OvalLoader title={"Loading..."} />
      </div>
    );
  } else if (!isLoading && isError) {
    content = (
      <div className="md:h-[40vh] w-full flex justify-center items-center">
        <ErrorMessage />
      </div>
    );
  } else if (!isLoading && productData) {
    content = (
      <div className="flex flex-col items-start mb-20 px-[16px]">
        <div className="w-full">
          <div>
            <SingleProductBasicDetails {...{ product }} />
          </div>
          <div className="mb-16">
            <SingleProductImages {...{ product }} />
          </div>
          <div className="text-left">
            <SingleProductOtherDetails
              {...{ product, description, similarProducts }}
            />
          </div>
        </div>
      </div>
    );
  }

  return content;
};

export default SingleProductDetails;

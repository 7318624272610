// Configuration
import React, { useEffect } from "react";

// Third party packages
import { Button, Modal } from "antd";
import { toast } from "react-hot-toast";
import { RiDeleteBinLine } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";

// Components
import { useDeleteCategoryMutation } from "../../../../../Redux/features/Shop/shopApi";
import { useDeleteCourseTypeMutation } from "../../../../../Redux/features/Course/courseApi";

const DeleteCategoriesModal = ({
  deleteCategoriesOpen,
  handleDeleteCategoriesCancel,
  setDeleteCategoriesOpen,
  selectedCategory,
  title: dynamicTitle,
}) => {
  const [deleteCategory, { data: response, isLoading, error }] =
    useDeleteCategoryMutation();

  const [
    deleteCourseType,
    { data: typeResponse, isLoading: typeLoading, error: typeError },
  ] = useDeleteCourseTypeMutation();

  const title = (
    <div className="flex items-center gap-1">
      <RiDeleteBinLine className="text-2xl" />
      <p className="text-3xl leading-6 font-Inter font-normal">
        Delete {dynamicTitle === "courseTypes" && "Type"}{" "}
        {dynamicTitle === "courseCategory" && "Category"}
        {dynamicTitle === "shopCategory" && "Category"}
      </p>
    </div>
  );

  const handleDeleteCategoriesOk = async () => {
    if (dynamicTitle === "courseTypes") {
      await deleteCourseType(selectedCategory);
    } else {
      await deleteCategory(selectedCategory);
    }
  };

  useEffect(() => {
    if (response) {
      toast.success("Category deleted successfully");
      setDeleteCategoriesOpen(false);
    }
    if (typeResponse) {
      toast.success("Type deleted successfully");
      setDeleteCategoriesOpen(false);
    }
    if (error || typeError) {
      toast.error(
        <p>
          Something went wrong <br /> Please try again later
        </p>
      );
      setDeleteCategoriesOpen(false);
    }
  }, [response, error, setDeleteCategoriesOpen, typeError, typeResponse]);

  return (
    <Modal
      open={deleteCategoriesOpen}
      closeIcon={<RxCross2 className="text-[#202020] mx-auto" />}
      title={title}
      onOk={handleDeleteCategoriesOk}
      onCancel={handleDeleteCategoriesCancel}
      footer={[
        <Button
          key="back"
          onClick={handleDeleteCategoriesCancel}
          className="text-[#E41272] h-12 px-6 text-xl font-bold rounded-[40px]"
        >
          Dismiss
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isLoading || typeLoading}
          onClick={handleDeleteCategoriesOk}
          className="bg-[#E41272] h-12 px-6 text-xl text-white font-bold rounded-[40px]"
        >
          Confirm
        </Button>,
      ]}
    >
      <p className="text-2xl leading-6 font-Inter my-8">
        Confirm to Delete the {dynamicTitle === "courseTypes" && "type"}{" "}
        {dynamicTitle === "courseCategory" && "category"}
        {dynamicTitle === "shopCategory" && "category"}
      </p>
    </Modal>
  );
};

export default DeleteCategoriesModal;

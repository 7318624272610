import React, { useState } from "react";

// Third party packages
import Pagination from "@mui/material/Pagination";

/* service card image */
import { useGetAllServiceByIdQuery } from "../../../../../../Redux/features/Services/servicesApi";
import { ServiceCard } from "../../../../components/LawyerAgentKaziServices/Card/ServiceCard";
import { TBFaceError } from "../../../../components/UI/TBFaceError";
import { ServiceCardSkeletonLoader } from "../../../Loader/ServiceCardSkeletonLoader";

const AllServicesCard = ({ userID }) => {
  const [page, setPage] = useState(1);
  const { data, isLoading, isError } = useGetAllServiceByIdQuery({
    id: userID,
    limit: 8,
    page: page,
  });

  const { services, total } = data || {};
  const paginationPage = Math.ceil(total / 3);

  const loaderArr = [1, 2, 3];

  let content;
  if (isLoading) {
    content = (
      <div className="grid grid-cols-1 gap-4">
        {loaderArr.map((item) => {
          return <ServiceCardSkeletonLoader key={item} />;
        })}
      </div>
    );
  } else if (!isLoading && services?.length === 0) {
    content = (
      <div className="mt-[100px] font-medium text-[18px] text-gray-500 text-center">
        No Services Found!
      </div>
    );
  } else if (!isLoading && isError) {
    content = <TBFaceError />;
  } else if (!isLoading && services?.length > 0) {
    content = (
      <div className="grid grid-cols-1">
        {services?.map((service) => {
          return <ServiceCard key={service?._id} service={service} />;
        })}
      </div>
    );
  }

  return (
    <div className="pb-[200px]">
      {content}
      {paginationPage > 1 && (
        <div className="mt-[40px] flex justify-center">
          <Pagination
            count={paginationPage}
            variant="outlined"
            color="secondary"
            onChange={(event, pageNumber) => setPage(pageNumber)}
          />
        </div>
      )}
    </div>
  );
};
export default AllServicesCard;

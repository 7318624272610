import React from "react";

const NameField = ({ register, errors }) => {
  return (
    <div className="grid grid-cols-2 items-center gap-8 font-medium text-[#344054]">
      <div className="flex flex-col">
        <label htmlFor="firstName">First Name</label>
        <input
          {...register("firstName", {
            required: {
              value: true,
              message: "First Name is required",
            },
          })}
          className="border border-[#D0D5DD] shadow rounded-lg focus: outline-none py-3 px-4 mt-1 font-normal text-base text-[#667085]"
          type="text"
          id="firstName"
          placeholder="First name"
        />
        <p className="text-left mt-1">
          {errors?.firstName?.type === "required" && (
            <span className="w-full text-left text-red-400 text-sm">
              {errors?.firstName?.message}
            </span>
          )}
        </p>
      </div>
      <div className="flex flex-col">
        <label htmlFor="lastName">Last Name</label>
        <input
          {...register("lastName", {
            required: {
              value: true,
              message: "Last Name is required",
            },
          })}
          className="border border-[#D0D5DD] shadow rounded-lg focus: outline-none py-3 px-4 mt-1 font-normal text-base text-[#667085]"
          type="text"
          id="lastName"
          placeholder="Last name"
        />
        <p className="text-left mt-1">
          {errors?.lastName?.type === "required" && (
            <span className="w-full text-left text-red-400 text-sm">
              {errors?.lastName?.message}
            </span>
          )}
        </p>
      </div>
    </div>
  );
};

export default NameField;

import { createSlice } from "@reduxjs/toolkit";

const initialState = { renderWith: "monthly" };

const usePlanSlice = createSlice({
    name: "restrictSuspend",
    initialState,
    reducers: {
        setRenderWith: (state, action) => {
            state.renderWith = action.payload;
        },
    },
});

export const { setRenderWith } = usePlanSlice.actions;
export default usePlanSlice.reducer;

import { TextareaAutosize } from "@mui/base";
import { Divider, Rating } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useUpdateReviewByAdminMutation } from "../../../../../Redux/features/Services/reviewsApi";
import { Modal } from "../../../Modal/Modal";

export const RatingEditModal = ({ review, modalControll }) => {
    // hook variable
    const [rating, setRating] = useState(2);
    const [updatedReview, setUpdatedReview] = useState("");

    // Redux API
    const [updateReviewByAdmin, { isSuccess, isLoading }] = useUpdateReviewByAdminMutation();
    const { _id, rating: reviewRating, review: reviewText } = review || {};

    // function declaration
    const handleUpdateReview = () => {
        const data = {
            review: updatedReview,
            rating: rating,
        };
        updateReviewByAdmin({
            id: _id,
            data: data,
        });
    };

    useEffect(() => {
        setRating(reviewRating);
        setUpdatedReview(reviewText);
    }, [reviewRating, reviewText]);

    useEffect(() => {
        if (isSuccess) {
            modalControll();
        }
    }, [isSuccess, modalControll]);
    return (
        <Modal modalControll={modalControll}>
            <div className="p-[10px] relative">
                <AiOutlineClose className="absolute right-3 top-2 text-gray-400 hover:text-gray-600 duration-300" onClick={modalControll} />
                <h3 className="text-left text-[26px] font-PlusJakartaSans text-black mb-[5px]">Update Review</h3>
                <Divider light />
                <div className="w-full flex flex-col gap-y-[10px] my-[15px]">
                    <Rating value={rating} onChange={(e, value) => setRating(value)} />
                    <TextareaAutosize value={updatedReview} className="outline-none" onChange={e => setUpdatedReview(e.target.value)} />
                    <button
                        className="bg-[#E41272] hover:bg-[#f72083] duration-300 mt-[10px] text-white py-[6px]"
                        onClick={handleUpdateReview}
                        disabled={isLoading}
                    >
                        {isLoading ? "wait..." : "Update"}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

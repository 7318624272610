// configuration
import React from "react";

// Components
import { OnGoingOrderDetailes } from "./OnGoingOrderDetailes";
import { OnGoingOrderInfoAndMark } from "./OnGoingOrderInfoAndMark";

export const OnGoingOrderDetailesContainer = ({ data }) => {
  return (
    <div>
      {data?.singleOrder?.orderItems?.length > 0 &&
        data?.singleOrder?.orderItems?.map((item) => (
          <div
            key={item?._id}
            className="w-full h-auto bg-white shadow-[2px_2px_10px_2px_rgba(0,0,0,0.12)] rounded-[12px] mt-7 pb-[37px]"
          >
            <OnGoingOrderInfoAndMark {...{ item }} />
            <OnGoingOrderDetailes {...{ item }} />
          </div>
        ))}
    </div>
  );
};

// configuration
import React from "react";

// Third party packages
import { TopMonthSellerCard } from "../../../Shared/Cards/Shop/Products/TopMonthSellerCard";

// components
import picture from "./Picture.png";

export const TopMonthSeller = () => {
    const arr = [1, 2, 3, 4];
    return (
        <div>
            <div className="mb-[40px]">
                <p className="text-[32px] font-semibold font-playFair text-left">Top Month Seller</p>
            </div>
            <div className="grid grid-cols-2 gap-y-[74px]">
                {arr.map(p => {
                    return <TopMonthSellerCard key={p} {...{ picture }} />;
                })}
            </div>
        </div>
    );
};

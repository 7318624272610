// Configuration
import React from "react";

// Third party packages
import Chart from "react-apexcharts";
import useDocumentTitle from "../../../../helper/utilities/useDocumentTitle";

// Components
import ManageResourceMemory from "./ManageResourceMemory";
import ManageUsageByCategory from "./ManageUsageByCategory";

const ManageResourceLimit = () => {
    // page title
    useDocumentTitle("Admin | Manage Resource Limit");
    return (
        <div>
            <h1 className="text-[#1D252D] text-[40px] leading-[55px] font-bold text-center mb-12">Manage Resource Limit</h1>
            <section className="bg-white mx-8 rounded-[22px] flex items-center mb-14" style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.03)" }}>
                <div className="flex-1 text-center py-14 flex items-center justify-center gap-5">
                    <Chart
                        type="donut"
                        width={158}
                        height={158}
                        series={[70, 30]}
                        options={{
                            labels: ["Storage Limit", " "],
                            colors: ["#E41272", "#F7F7F7"],
                            dataLabels: {
                                enabled: false,
                            },
                            legend: {
                                show: false,
                            },
                        }}
                    />
                    <div className="text-left">
                        <p className="text-[#202020] text-4xl leading-[54px] mb-2 font-semibold">70%</p>
                        <p className="whitespace-nowrap opacity-50 text-black text-lg leading-7 font-medium">Storage Limit</p>
                    </div>
                </div>
                <div className="flex-1 text-center py-14 flex items-center justify-center gap-5">
                    <Chart
                        type="donut"
                        width={158}
                        height={158}
                        series={[30, 70]}
                        options={{
                            labels: ["Bandwidth Limit", " "],
                            colors: ["#A62BC6", "#F7F7F7"],
                            dataLabels: {
                                enabled: false,
                            },
                            legend: {
                                show: false,
                            },
                        }}
                    />
                    <div className="text-left">
                        <p className="text-[#202020] text-4xl leading-[54px] mb-2 font-semibold">30%</p>
                        <p className="whitespace-nowrap opacity-50 text-black text-lg leading-7 font-medium">Bandwidth Limit</p>
                    </div>
                </div>
            </section>
            {/* Manage resource memory & Usage by category */}
            <ManageResourceMemory />
            <ManageUsageByCategory />
        </div>
    );
};

export default ManageResourceLimit;

import React from "react";

const GenderAge = ({ register, errors }) => {
  return (
    <div>
      <div className="flex flex-col">
        <label htmlFor="gender" className="mt-6">
          Gender
        </label>
        <select
          {...register("gender", {
            required: {
              value: true,
              message: "Please Select a Gender",
            },
          })}
          className="border border-[#D0D5DD] shadow rounded-lg focus: outline-none py-3 px-3 mt-1 font-normal text-base text-[#667085]"
          id="gender"
        >
          <option value="">Select Gender</option>
          <option value="man">Male</option>
          <option value="woman">Female</option>
        </select>
        <p className="text-left mt-1">
          {errors?.gender?.type === "required" && (
            <span className="w-full text-left text-red-400 text-sm">
              {errors?.gender?.message}
            </span>
          )}
        </p>
      </div>
    </div>
  );
};

export default GenderAge;

import React from "react";
import { IoClose } from "react-icons/io5";

// Third party package
import { TbCurrencyTaka } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

const SingleProductBasicDetails = ({ product }) => {
  const { name, price, oldPrice, discount } = product || {};

  const editedOldPrice = oldPrice
    ? oldPrice
    : discount > 0
    ? Math.ceil((Number(price) * discount) / 100 + price)
    : "";

  const navigate = useNavigate();
  return (
    <div className="">
      <div className="flex justify-between items-center">
        <h1 className="text-[#18181B] text-left text-4xl leading-12 font-Inter font-bold mb-2">
          Product Name - {name}
        </h1>
        <div onClick={() => navigate(-1)}>
          <IoClose className="border border-gray-500 text-3xl rounded-full hover:bg-gray-500 hover:text-white cursor-pointer duration-300" />
        </div>
      </div>
      <div className="flex items-center gap-3 mb-2">
        <p className="text-xl leading-8 font-bold">Prize -</p>
        <div className="flex items-center text-[#18181B] text-3xl leading-10 font-bold">
          <TbCurrencyTaka />
          <p>{price}</p>
        </div>
        {editedOldPrice && (
          <div className="flex items-center text-[#71717A] text-xl leading-8 font-bold line-through">
            <TbCurrencyTaka />
            <p>{editedOldPrice}</p>
          </div>
        )}
      </div>
      <hr className="mb-4" />
    </div>
  );
};

export default SingleProductBasicDetails;

import getCookie from "../../../helper/cookies/getCookie";
import { apiSlice } from "../../api/apiSlice";

export const supportTicketApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllTickets: builder.query({
      query: ({ status, page, limit }) => ({
        url: `/ticket/admin/getAllTickets?status=${status}&page=${page}&limit=${limit}`,
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
    }),
    getTicketByID: builder.query({
      query: (id) => ({
        url: `/ticket/admin/getTicket/${id}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      providesTags: ["SupportTicketClosed"],
    }),
    closeTicket: builder.mutation({
      query: ({ id, data }) => ({
        url: `/ticket/admin/closeTicket/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["SupportTicketClosed"],
    }),
  }),
});

export const {
  useGetAllTicketsQuery,
  useGetTicketByIDQuery,
  useCloseTicketMutation,
} = supportTicketApi;

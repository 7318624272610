import React from "react";
import { useNavigate } from "react-router-dom";

const DeliveredOrdersCard = ({ order }) => {
  const { firstName, lastName, address1, city, state } =
    order?.billingInfo || {};

  const navigate = useNavigate();

  return (
    <div
      className="py-4 px-2 rounded-lg w-[281px] cursor-pointer hover:opacity-80 duration-500"
      style={{
        boxShadow:
          "0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05)",
      }}
      onClick={() => navigate(`/shop/orderId/${order?._id}`)}
    >
      <div>
        <img
          className="w-[264px] h-[160px] rounded-lg"
          src={
            order?.orderItems[0]?.product?.photos
              ? order?.orderItems[0]?.product?.photos[0]?.url
              : "https://img.freepik.com/free-photo/rag-dolls-with-wheelbarrow-blue-cap_1156-223.jpg?w=740&t=st=1680695543~exp=1680696143~hmac=24c6cb3976d233b748a346dca9024b67675f49dfaa495ba01ff93294f9f20340"
          }
          alt=""
        />
      </div>
      <div className="text-left">
        <h3 className="text-xl leading-6 font-semibold font-Inter text-[#202020] pt-4 pb-1">
          Order by #{(firstName || "") + " " + (lastName || "")}
        </h3>
        <p className="text-sm leading-[18px] font-SourceCodePro text-[#666666] mb-3">
          Order Id #{order?.orderId}
        </p>
        <p className="text-[#202020] opacity-80 mb-2 text-lg font-medium font-Inter">
          BDT {order?.totalPrice}
        </p>
        <p className="text-base leading-5 font-Inter text-[#202020] opacity-80 font-normal">
          {`${address1 || ""}, ${city || ""}, ${state || ""}`}
        </p>
      </div>
    </div>
  );
};

export default DeliveredOrdersCard;

import React, { useState } from "react";

// Components
import useDocumentTitle from "../../../helper/utilities/useDocumentTitle";
import UserPlanHeader from "./UserPlanHeader";
import { AllPlans } from "./userPlans/AllPlans";
import PackageForm from "./PackageForm";
import { Toaster } from "react-hot-toast";

const UpgradeUserPlan = () => {
  const [user, setUser] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [pack, setPack] = useState();

  // page title
  useDocumentTitle("Admin | Upgrade User Plan");
  return (
    <>
      <div className="w-full">
        <section>
          <div className="flex justify-center mb-8">
            <h1 className="font-semibold text-4xl font-Nunito">
              Upgrade User Plan
            </h1>
          </div>
          <UserPlanHeader setUser={setUser} user={user} role="member" />
        </section>
        {user && (
          <AllPlans
            {...{ user, condition: "upgrade", setShowPopup, setPack }}
          />
        )}
      </div>
      {showPopup && <PackageForm {...{ showPopup, setShowPopup, pack, user }} />}
      <Toaster />
    </>
  );
};
export default UpgradeUserPlan;

import React from "react";
import PackageCard from "../../../Shared/Cards/PackagesCard/PackageCard";

const AddMemberShipPackagePage = () => {
  return (
    <div className="px-6 flex flex-wrap gap-x-12 gap-y-6 mb-10">
      <PackageCard />
      <PackageCard />
      <PackageCard />
      <PackageCard />
    </div>
  );
};

export default AddMemberShipPackagePage;

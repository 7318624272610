// Configuration
import React, { useState } from "react";

// Third party package
import { MdOutlineRecommend } from "react-icons/md";

// Components
import {
  useGetAllShopBundlesQuery,
  useGetShopPackagesQuery,
} from "../../../../Redux/features/Packages/packagesApi";
import { OvalLoader } from "../../../Shared/Loader/OvalLoader";
import ErrorMessage from "../../../Shared/components/ErrorMessage/ErrorMessage";
import EditBundle from "./ModifyBundle/EditBundle";
import BundleCards from "./ModifyBundle/BundleCards";
import EditPriorityPackage from "../course/EditPriorityPackage/EditPriorityPackage";

const ModifyShopBundle = () => {
  const [editMode, setEditMode] = useState(false);
  const [selectedBundle, setSelectedBundle] = useState({});
  const { data: response, isLoading, error } = useGetAllShopBundlesQuery();

  const {
    data: packageResponse,
    isLoading: packageLoading,
    error: packageError,
  } = useGetShopPackagesQuery();

  let packageContent;
  if (packageLoading) {
    packageContent = (
      <div className="mt-20">
        <OvalLoader title={"Loading..."} />
      </div>
    );
  } else if (packageError) {
    packageContent = <ErrorMessage />;
  } else if (packageResponse?.shopPackages?.length === 0) {
    packageContent = (
      <div className="flex flex-col items-center justify-center mt-[100px]">
        <MdOutlineRecommend className="text-[42px] text-gray-400" />
        <p className="mt-[10px] text-[16px] font-Inter font-medium text-gray-500">
          No Shop Package Found!
        </p>
      </div>
    );
  } else if (packageResponse?.shopPackages?.length > 0) {
    const priorityPackage = packageResponse?.shopPackages?.find(
      (shop) => shop?.name === "Priority"
    );
    packageContent = (
      <div>
        <EditPriorityPackage {...{ priorityPackage, from: "shop" }} />
      </div>
    );
  }

  let content;
  if (isLoading) {
    content = (
      <div className="mt-20">
        <OvalLoader title={"Loading..."} />
      </div>
    );
  } else if (error) {
    content = <ErrorMessage />;
  } else if (response?.data?.length === 0) {
    content = (
      <div className="flex flex-col items-center justify-center mt-[100px]">
        <MdOutlineRecommend className="text-[42px] text-gray-400" />
        <p className="mt-[10px] text-[16px] font-Inter font-medium text-gray-500">
          No Shop Package Found!
        </p>
      </div>
    );
  } else if (response?.data?.length > 0) {
    content = editMode ? (
      <EditBundle {...{ selectedBundle }} />
    ) : (
      <div className="grid grid-cols-3 md:grid-cols-4 gap-4">
        {response.data.map((item) => (
          <BundleCards
            key={item._id}
            {...{ item, setEditMode, setSelectedBundle }}
          />
        ))}
      </div>
    );
  }

  return (
    <>
      <div className="mx-6 mb-12 p-5 shadow-[2px_2px_10px_rgba(0,0,0,0.1)]">
        <h1 className="text-left mb-6 text-2xl font-Inter font-semibold">
          Modify Priority Package
        </h1>
        <div>{packageContent}</div>
      </div>
      <div className="mx-6 mb-12 p-5 shadow-[2px_2px_10px_rgba(0,0,0,0.1)]">
        <h1 className="text-left mb-6 text-2xl font-Inter font-semibold">
          Modify Bundle
        </h1>
        <div>{content}</div>
      </div>
    </>
  );
};

export default ModifyShopBundle;

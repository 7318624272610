import React, { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useMarkAsPriorityMutation } from "../../../../Redux/features/User/PriorityUser/priorityUserApi";
import { setExpiryDate, setLifeTimeMarked, setResetMarkPriority } from "../../../../Redux/features/User/PriorityUser/priorityUserSlice";
import dateGeneratorFromToday from "../../../../helper/utilities/dateGeneratorFromToday";
import Error from "../../../Shared/components/accordion/UI/Error/Error";

export const PriorityUserTable = ({ day, setDay, setUser }) => {
    const dispatch = useDispatch();
    const [customError, setCustomError] = useState("");
    const priorityData = useSelector(state => state?.markPriority);
    const [markAsPriority, { isLoading, isSuccess, isError, error }] = useMarkAsPriorityMutation();
    const tableData = [
        { id: 1, day: "1 Day", value: 1 },
        { id: 2, day: "3 Day", value: 3 },
        { id: 3, day: "7 Day", value: 7 },
        { id: 4, day: "10Day", value: 10 },
        { id: 5, day: "12 Day", value: 12 },
        { id: 6, day: "15 Day", value: 15 },
        { id: 7, day: "17 Day", value: 17 },
        { id: 8, day: "20 Day", value: 20 },
        { id: 9, day: "25 Day", value: 25 },
        { id: 10, day: "45 Day", value: 45 },
        { id: 11, day: "50 Day", value: 50 },
        { id: 12, day: "2 Month", value: 60 },
        { id: 13, day: "3 Month", value: 90 },
        { id: 14, day: "6 Month", value: 180 },
        { id: 15, day: "1 Year", value: 365 },
        { id: 16, day: "Lifetime", value: "lifeTime" },
    ];

    useEffect(() => {
        setCustomError("");
        const markFor = day;
        if (markFor === "lifeTime") {
            dispatch(setLifeTimeMarked(true));
            dispatch(setExpiryDate({ date: "" }));
            return;
        } else {
            dispatch(setLifeTimeMarked(false));
            const date = dateGeneratorFromToday(markFor);
            dispatch(
                setExpiryDate({
                    date: date,
                })
            );
        }
    }, [day, dispatch]);

    useEffect(() => {
        if (isSuccess) {
            setCustomError("");
            dispatch(setResetMarkPriority());
            setDay(null);
            setUser(null);
        } else if (!isSuccess && isError) {
            setCustomError(error.data.message || "Something went wrong");
        }
    }, [isSuccess, customError, isError, dispatch, setDay, setUser, error]);

    // function declaration
    const handleDay = e => {
        const markFor = e.target.value;
        setDay(markFor);
    };

    const handleMarkForPriritySubmit = () => {
        if (priorityData?.user === "") {
            setCustomError("Please select a user first");
            return;
        }
        if (priorityData.isLifeTime === false && priorityData.setExpiryDate === "") {
            setCustomError("Please select How many days you want to add for priority!");
            return;
        }
        markAsPriority(priorityData);
    };

    return (
        <section className="my-8">
            <div className="mb-4">
                <h1 className="text-2xl font-bold">Mark As Priority User For :</h1>
            </div>
            <div className="grid grid-cols-4 gap-2">
                {tableData?.map(item => {
                    return (
                        <button
                            key={item.id}
                            value={item.value}
                            tabIndex={item.id}
                            className="p-2 shadow-lg w-[189px] h-[65px] rounded day-btn active:text-white font-semibold hover:text-white text-base "
                            onClick={handleDay}
                            style={
                                item.value === Number(day)
                                    ? {
                                          background: "linear-gradient(180deg, #E41272 0%, #942DD9 100%)",
                                          color: "white",
                                      }
                                    : {}
                            }
                        >
                            {item.day}
                        </button>
                    );
                })}
            </div>
            <div className="mt-8">
                <label htmlFor="custom" className="font-bold text-2xl">
                    Custom:
                </label>
                <input
                    type="number"
                    name="custom"
                    className="py-2 px-4 outline outline-1 ml-2 focus:outline-none focus:ring focus:border-blue-500"
                    placeholder="27 Day"
                    onChange={e => setDay(Number(e.target.value))}
                />
            </div>
            {customError && <Error message={customError} />}
            <div className="flex justify-center my-4 mt-8">
                <button
                    className="px-6 py-1 rounded  text-white text-[24px]  w-[189px] h-[65px] font-semibold text-base] flex items-center justify-center gap-x-[10px]"
                    style={{
                        background: "linear-gradient(180deg, #E41272 0%, #942DD9 100%)",
                    }}
                    onClick={handleMarkForPriritySubmit}
                >
                    {isLoading ? (
                        <>
                            <p>Saving</p>
                            <Oval
                                height={30}
                                width={30}
                                color="#FFFFFF"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="oval-loading"
                                secondaryColor="#808080"
                                strokeWidth={4}
                                strokeWidthSecondary={4}
                            />
                        </>
                    ) : (
                        <>Save</>
                    )}
                </button>
            </div>
        </section>
    );
};

import React from "react";

const AgentModifyDealHistoryCard = ({ agent }) => {
  return (
    <div
      className="py-8 rounded-lg bg-white h-[222px] box-border"
      style={{
        backgroundImage:
          "radial-gradient(48.96% 129.2% at 48.96% 40.19%, #FFFFFF 0%, #FBE493 0.01%, #C8EBE2 0.02%, #C999F9 0.03%, #FEFFE3 0.04%, #EADBC8 0.05%, #FEB0DA 60.94%, #FF78D9 99.98%, #F77BDB 99.99%)",
        boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.12)",
      }}
    >
      <div className="flex justify-start items-center gap-6 px-9 mb-9">
        <img
          className="w-[85px] h-[85px] rounded-full"
          src={agent.img}
          alt=""
        />
        <div className={"text-left"}>
          <h3 className="text-2xl leading-[29px] font-medium mb-4">
            {agent.agentName}
          </h3>
          <p className="text-xl leading-6">Age : {agent.age}</p>
        </div>
      </div>
      <div className="flex justify-center items-center gap-6">
        <button
          className="text-base text-white leading-5 font-medium w-[187px] py-[9px] rounded-[4px]"
          style={{
            backgroundImage:
              "linear-gradient(180deg, #E22989 0%, #A72CC7 100%)",
          }}
        >
          Modify Deal History
        </button>
      </div>
    </div>
  );
};

export default AgentModifyDealHistoryCard;

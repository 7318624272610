import React,{useState} from "react";
import ReferUserRow from "./ReferUserRow";
import { useGetAllRefersQuery } from "../../../../../Redux/features/referral/referralApi";

const ReferUser = () => {
    const [openedRefer,setOpenedRefer] = useState("")
    const {data} = useGetAllRefersQuery({page:1,limit:"10"})
  return (
    <div className="p-6">
      <p className="text-2xl font-bold mt-8 mb-4">Refer Users</p>
      <div className="rounded overflow-hidden shadow-2xl pb-8">
        <div className="w-full py-3 text-white font-bold text-lg bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)]">
          Refer user
        </div>
        <table className="w-full">
          <thead>
            <tr className="border-b-3">
            <th className="border-b-2 py-2 px-4"># </th>
              <th className="text-left border-b-2 py-2 px-4">Name </th>
              <th className="text-left border-b-2 py-2 px-4">Email</th>
              <th className="border-b-2 py-2 px-4">Total Refer </th>
              <th className="border-b-2 py-2 px-4">Referer</th>
            </tr>
          </thead>
          <tbody>
            {data?.referral?.map(item=><ReferUserRow key={item._id} {...{item,openedRefer,setOpenedRefer}} />)}
            
            
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ReferUser;

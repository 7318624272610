// Configuration
import React, { useState } from "react";

// Third party package
import { Button } from "@mui/material";
import { pink } from "@mui/material/colors";
import { Collapse, Modal } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";

// Components
import AddModuleForm from "./AddModuleForm/AddModuleForm";
import AddContentContainer from "../AddContentContainer/AddContentContainer";

const AddModuleContainer = ({ section }) => {
  const [moduleFormVisible, setModuleFormVisible] = useState(false);

  const handleModuleFormCancel = () => {
    setModuleFormVisible(false);
  };

  const { Panel } = Collapse;
  return (
    <div className="px-6">
      {/* Show all module */}
      <div className="mb-4">
        <Collapse
          bordered={false}
          defaultActiveKey={["1"]}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
        >
          {section?.modules?.map((module) => (
            <Panel
              header={
                <div>
                  <p className="text-xl font-medium">{module?.name}</p>
                  <p className="">{module?.description}</p>
                </div>
              }
              key={module?._id}
            >
              <AddContentContainer {...{ module }} />
            </Panel>
          ))}
        </Collapse>
      </div>

      {/* Add module button */}
      <div>
        <Button
          onClick={() => setModuleFormVisible(true)}
          sx={{
            color: pink[500],
            borderColor: pink[500],
          }}
          variant="outlined"
        >
          Add Module
        </Button>
      </div>

      {/* Add module form modal */}
      <Modal
        title={<p className="text-xl">Add Module</p>}
        open={moduleFormVisible}
        onCancel={handleModuleFormCancel}
        footer={null}
      >
        <AddModuleForm {...{ id: section?._id, setModuleFormVisible }} />
      </Modal>
    </div>
  );
};

export default AddModuleContainer;

import getCookie from "../../../helper/cookies/getCookie";
import { apiSlice } from "../../api/apiSlice";

export const badgeApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllBadges: builder.query({
      query: () => ({
        url: "/admin/batch",
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      providesTags: ["Badges"],
      keepUnusedDataFor: 0,
    }),
    createBadge: builder.mutation({
      query: (data) => ({
        url: `/admin/batch/add`,
        method: "POST",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["Badges"],
    }),
    deleteBadge: builder.mutation({
      query: (id) => ({
        url: `/admin/batch/${id}`,
        method: "DELETE",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      async onQueryStarted(id, { queryFulfilled, dispatch }) {
        dispatch(
          apiSlice.util.updateQueryData("getAllBadges", undefined, (draft) => {
            const updatedArray = draft?.data?.batch?.filter(
              (item) => item?._id !== id
            );

            const data = {
              message: true,
              success: true,
              data: {
                batch: updatedArray,
                total: updatedArray?.length,
              },
            };

            return data;
          })
        );
      },
    }),
    updateBatch: builder.mutation({
      query: ({ id, data }) => ({
        url: `/admin/batch/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      async onQueryStarted(
        { id, data: currentData },
        { queryFulfilled, dispatch }
      ) {
        const pathResult = dispatch(
          apiSlice.util.updateQueryData("getAllBadges", undefined, (draft) => {
            let updatedArray = draft?.data?.batch?.filter(
              (item) => item._id !== id
            );
            updatedArray = [
              ...updatedArray,
              {
                _id: id,
                icon: currentData.icon,
                name: currentData.name,
                description: currentData.description,
              },
            ];

            const data = {
              message: true,
              success: true,
              data: {
                batch: updatedArray,
                total: updatedArray?.length,
              },
            };

            return data;
          })
        );

        try {
          await queryFulfilled;
        } catch (error) {
          pathResult.undo();
        }
      },
    }),
    addBadgeToMember: builder.mutation({
      query: ({ id, badgeId }) => ({
        url: `/admin/action/addbadge?_user=${id}&_badge=${badgeId}`,
        method: "POST",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      invalidatesTags: ["RecentBadges"],
    }),
    getRecentBadgeUsers: builder.query({
      query: ({page,limit}) => ({
        url: `/batch/recent-users?page=${page}&limit=${limit}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      keepUnusedDataFor: 0,
      providesTags: ["RecentBadges"],
    }),
  }),
});

export const {
  useCreateBadgeMutation,
  useGetAllBadgesQuery,
  useDeleteBadgeMutation,
  useUpdateBatchMutation,
  useAddBadgeToMemberMutation,
  useGetRecentBadgeUsersQuery,
} = badgeApi;

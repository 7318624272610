// Configuration
import React, { useEffect } from "react";

// Third party packages
import { Button, Modal } from "antd";
import { toast } from "react-hot-toast";
import { RiDeleteBinLine } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";

// Components
import { useDeleteProductMutation } from "../../../../../Redux/features/Shop/shopApi";

const DeleteProductModal = ({
  deleteProductOpen,
  handleDeleteProductCancel,
  setDeleteProductOpen,
  selectedProduct,
  setIsProductDeleted,
}) => {
  const [deleteProduct, { data: response, isLoading, error }] =
    useDeleteProductMutation();

  const title = (
    <div className="flex items-center gap-1">
      <RiDeleteBinLine className="text-2xl" />
      <p className="text-3xl leading-6 font-Inter font-normal">
        Delete Product
      </p>
    </div>
  );

  const handleDeleteProductOk = async () => {
    await deleteProduct(selectedProduct);
  };

  useEffect(() => {
    if (response) {
      toast.success("Product deleted successfully");
      setIsProductDeleted(true);
      setDeleteProductOpen(false);
    }
    if (error) {
      toast.error(
        <p>
          Something went wrong <br /> Please try again later
        </p>
      );
      setDeleteProductOpen(false);
    }
  }, [response, error, setDeleteProductOpen, setIsProductDeleted]);

  return (
    <Modal
      open={deleteProductOpen}
      closeIcon={<RxCross2 className="text-[#202020] mx-auto" />}
      title={title}
      onOk={handleDeleteProductOk}
      onCancel={handleDeleteProductCancel}
      footer={[
        <Button
          key="back"
          onClick={handleDeleteProductCancel}
          className="text-[#E41272] h-12 px-6 text-xl font-bold rounded-[40px]"
        >
          Dismiss
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isLoading}
          onClick={handleDeleteProductOk}
          className="bg-[#E41272] h-12 px-6 text-xl text-white font-bold rounded-[40px]"
        >
          Confirm
        </Button>,
      ]}
    >
      <p className="text-2xl leading-6 font-Inter my-8">
        Confirm to Delete this Product
      </p>
    </Modal>
  );
};

export default DeleteProductModal;

import getCookie from "../../../helper/cookies/getCookie";
import { apiSlice } from "../../api/apiSlice";

export const userApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    regAsMember: builder.mutation({
      query: (data) => ({
        url: "/member/register/personalDetail",
        method: "POST",
        body: data,
      }),
    }),
    setProfessionalDetails: builder.mutation({
      query: (data) => ({
        url: "/member/register/professionalDetail",
        method: "POST",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
    }),
    getProfileDetails: builder.query({
      query: (id) => ({
        url: `/member/personalDetail/${id}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      keepUnusedDataFor: 30,
      providesTags: ["UpdateUserInfo"],
    }),
    getUserListByEmailOrName: builder.mutation({
      query: ({ text, role }) => ({
        url: `/admin/member/searchByNameOrEmail?_text=${text}&_role=${role}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("accessToken")}`,
        },
      }),
    }),
    getPaidUsers: builder.query({
      query: ({ page, keyword }) => ({
        url: `/admin/member/paidUsers?_page=${page}&_limit=10&_keyword=${keyword}`,
        method: "Get",
      }),
    }),
    getApprovedUsers: builder.query({
      query: ({ role, page, limit, keyword }) => ({
        url: `/admin/member/approved?_role=${role}&_limit=${limit}&_page=${page}&_keyword=${keyword}`,
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      providesTags: ["approvedUsers"],
    }),
    getRestrictedOrSuspendedUsers: builder.query({
      query: ({ role, page, limit, keyword }) => ({
        url: `/admin/member/restrictedAndSuspended?_role=${role}&_limit=${limit}&_page=${page}&_keyword=${keyword}`,
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
    }),
    getRegistrationRequests: builder.query({
      query: ({ role, page, limit, keyword }) => ({
        url: `/admin/member/registrationRequest?_role=${role}&_limit=${limit}&_page=${page}&_keyword=${keyword}`,
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
    }),
    getUserAskedToResubmit: builder.query({
      query: ({ role, page, limit, keyword }) => ({
        url: `/admin/member/askedToResubmit?_role=${role}&_limit=${limit}&_page=${page}&_keyword=${keyword}`,
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      providesTags: ["askedToResubmit"],
    }),
    getRejectedUsers: builder.query({
      query: ({ role, page, limit, keyword }) => ({
        url: `/admin/member/rejected?_role=${role}&_limit=${limit}&_page=${page}&_keyword=${keyword}`,
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      providesTags: ["rejectedUsers"],
    }),
    updatePersonalDetails: builder.mutation({
      query: ({ data, id }) => ({
        url: `/admin/member/personalDetail/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["UpdateUserInfo"],
    }),
    updateProfessionalDetails: builder.mutation({
      query: ({ data, id }) => ({
        url: `/admin/member/professionalDetail/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["UpdateUserInfo"],
    }),
    updateEducationalDetails: builder.mutation({
      query: ({ data, id }) => ({
        url: `/admin/member/educationalDetail/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["UpdateUserInfo"],
    }),
    updatePhysicalDetails: builder.mutation({
      query: ({ data, id }) => ({
        url: `/admin/member/physicalDetail/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["UpdateUserInfo"],
    }),
    updateOthersDetails: builder.mutation({
      query: ({ data, id }) => ({
        url: `/admin/member/othersDetail/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["UpdateUserInfo"],
    }),
    updateFamilyDetails: builder.mutation({
      query: ({ data, id }) => ({
        url: `/admin/member/familyDetail/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["UpdateUserInfo"],
    }),
    updateSiblingDetail: builder.mutation({
      query: ({ data, id }) => ({
        url: `/admin/member/siblingDetail/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["UpdateUserInfo"],
    }),
    getUsersActivity: builder.query({
      query: ({ id, limit, page }) => ({
        // url: `/admin/member/activity/${id}`,
        url: `/admin/member/activity/${id}?_limit=${limit}&_page=${page}`,
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
    }),

    assignAgentToUser: builder.mutation({
      query: ({ agent, user }) => ({
        url: `/admin/action/assignAgent?user=${user}&agent=${agent}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
    }),
    assignKaziToUser: builder.mutation({
      query: ({ kazi, user }) => ({
        url: `/admin/action/assignkazi?user=${user}&kazi=${kazi}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
    }),
    AssignLawyerToUser: builder.mutation({
      query: ({ lawyer, user }) => ({
        url: `/admin/action/assignLawyer?user=${user}&lawyer=${lawyer}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
    }),
    registerUser: builder.mutation({
      query: (data) => ({
        url: "/admin/member/personalDetail/",
        method: "POST",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
    }),
    getAllProfilesByRole: builder.query({
      query: () => ({
        url: `/member/users?_limit=200&_page=1&_role=member`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      keepUnusedDataFor: 0,
    }),
    getAllConnectedConnections: builder.query({
      query: (id) => ({
        url: `/member/connections/admin?id=${id}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      keepUnusedDataFor: 0,
    }),
    getSingleProfessionDetailById: builder.query({
      query: (id) => ({
        url: `/admin/member/professionalDetail/${id}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      providesTags: ["UpdateUserInfo"],
    }),
    getSingleEducationDetailById: builder.query({
      query: (id) => ({
        url: `/admin/member/educationalDetail/${id}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      providesTags: ["UpdateUserInfo"],
    }),
    getSingleSiblingDetailById: builder.query({
      query: (id) => ({
        url: `/member/get-sibling-detail-by-admin/${id}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      providesTags: ["UpdateUserInfo"],
    }),
  }),
});

export const {
  useAssignAgentToUserMutation,
  useAssignKaziToUserMutation,
  useAssignLawyerToUserMutation,
  useGetPaidUsersQuery,
  useRegAsMemberMutation,
  useSetProfessionalDetailsMutation,
  useGetProfileDetailsQuery,
  useGetUserListByEmailOrNameMutation,
  useGetApprovedUsersQuery,
  useGetRegistrationRequestsQuery,
  useGetRejectedUsersQuery,
  useGetUserAskedToResubmitQuery,
  useUpdatePersonalDetailsMutation,
  useUpdateEducationalDetailsMutation,
  useUpdatePhysicalDetailsMutation,
  useUpdateOthersDetailsMutation,
  useUpdateProfessionalDetailsMutation,
  useUpdateFamilyDetailsMutation,
  useUpdateSiblingDetailMutation,
  useGetUsersActivityQuery,
  useRegisterUserMutation,
  useGetAllProfilesByRoleQuery,
  useGetRestrictedOrSuspendedUsersQuery,
  useGetAllConnectedConnectionsQuery,
  useGetSingleProfessionDetailByIdQuery,
  useGetSingleEducationDetailByIdQuery,
  useGetSingleSiblingDetailByIdQuery,
} = userApi;

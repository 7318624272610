import AgentPackage from "../../Admin__Portion/Components/Packages/AgentPackage/AgentPackage";
import ModifyAgentPackage from "../../Admin__Portion/Components/Packages/AgentPackage/ModifyAgentPackage/ModifyAgentPackage";
import AddCoursePackagePage from "../../Admin__Portion/Components/Packages/course/AddCoursePackagePage";
import CoursePackage from "../../Admin__Portion/Components/Packages/course/CoursePackage";
import ModifyCoursePackage from "../../Admin__Portion/Components/Packages/course/ModifyCoursePackage";
import KaziPackage from "../../Admin__Portion/Components/Packages/KaziPackage/KaziPackage";
import ModifyKaziPackage from "../../Admin__Portion/Components/Packages/KaziPackage/ModifyKaziPackage/ModifyKaziPackage";
import LawyerPackage from "../../Admin__Portion/Components/Packages/LawyerPackage/LawyerPackage";
import ModifyLawyerPackage from "../../Admin__Portion/Components/Packages/LawyerPackage/ModifyLawyerPackage/ModifyLawyerPackage";
import AddMemberShipPackagePage from "../../Admin__Portion/Components/Packages/memberShip/AddMemberShipPackagePage";
import MemberShipPackage from "../../Admin__Portion/Components/Packages/memberShip/MemberShipPackage";
import ModifyMemberShipPackage from "../../Admin__Portion/Components/Packages/memberShip/ModifyMemberShipPackage";
import { Packages } from "../../Admin__Portion/Components/Packages/Packages";
import AddShopBundlePage from "../../Admin__Portion/Components/Packages/shop/AddShopBundlePage";
import ModifyShopBundle from "../../Admin__Portion/Components/Packages/shop/ModifyShopBundle";
import ShopBundle from "../../Admin__Portion/Components/Packages/shop/ShopBundle";
import AddStatusPackagePage from "../../Admin__Portion/Components/Packages/StatusBoost/AddStatusPackagePage";
import ModifyStatusPackage from "../../Admin__Portion/Components/Packages/StatusBoost/ModifyStatusPackage";
import StatusBoostPackage from "../../Admin__Portion/Components/Packages/StatusBoost/StatusBoostPackage";
import Notification from "../../Admin__Portion/Components/Partials/notification/Notification";

const packageRoutes = [
  {
    id: 1,
    path: "/packages",
    element: <Packages />,
    nestedRoutes: [
      {
        id: 1,
        path: "membershipPackage",
        element: <MemberShipPackage />,
        nestedRoutes: [
          {
            id: 1,
            path: "addPackage",
            element: <AddMemberShipPackagePage />,
          },
          {
            id: 2,
            path: "modifyPackage",
            element: <ModifyMemberShipPackage />,
          },
          {
            id: 3,
            path: "packageUsers",
            element: <></>,
          },
          {
            id: 4,
            path: "notifyToUpgrade",
            element: <Notification title="Send Notification to Upgrade" />,
          },
          {
            id: 5,
            path: "notifyToPay",
            element: <Notification title="Send Notification to Pay" />,
          },
        ],
      },
      {
        id: 2,
        path: "shopBundle",
        element: <ShopBundle />,
        nestedRoutes: [
          {
            id: 1,
            path: "addBundle",
            element: <AddShopBundlePage />,
          },
          {
            id: 2,
            path: "modifyBundle",
            element: <ModifyShopBundle />,
          },
          {
            id: 3,
            path: "bundleUsers",
            element: <></>,
          },
          {
            id: 4,
            path: "notifyToUpgrade",
            element: <Notification title="Send Notification to Upgrade" />,
          },
          {
            id: 5,
            path: "notifyToPay",
            element: <Notification title="Send Notification to Pay" />,
          },
        ],
      },
      {
        id: 3,
        path: "courseBundle",
        element: <CoursePackage />,
        nestedRoutes: [
          {
            id: 1,
            path: "addBundle",
            element: <AddCoursePackagePage />,
          },
          {
            id: 2,
            path: "modifyBundle",
            element: <ModifyCoursePackage />,
          },
          {
            id: 3,
            path: "bundleUsers",
            element: <></>,
          },
          {
            id: 4,
            path: "notifyToUpgrade",
            element: <Notification title="Send Notification to Upgrade" />,
          },
          {
            id: 5,
            path: "notifyToPay",
            element: <Notification title="Send Notification to Pay" />,
          },
        ],
      },
      {
        id: 4,
        path: "statusPackage",
        element: <StatusBoostPackage />,
        nestedRoutes: [
          {
            id: 1,
            path: "addPackage",
            element: <AddStatusPackagePage />,
          },
          {
            id: 2,
            path: "modifyPackage",
            element: <ModifyStatusPackage />,
          },
          {
            id: 3,
            path: "packageUsers",
            element: <></>,
          },
          {
            id: 4,
            path: "notifyToUpgrade",
            element: <Notification title="Send Notification to Upgrade" />,
          },
          {
            id: 5,
            path: "notifyToPay",
            element: <Notification title="Send Notification to Pay" />,
          },
          {
            id: 5,
            path: "sendFreeBoostOffer",
            element: <Notification title="Send Free boost offer" />,
          },
        ],
      },
      {
        id: 5,
        path: "lawyerPackage",
        element: <LawyerPackage />,
        nestedRoutes: [
          {
            id: 1,
            path: "modifyPackage",
            element: <ModifyLawyerPackage />,
          },
        ],
      },
      {
        id: 6,
        path: "agentPackage",
        element: <AgentPackage />,
        nestedRoutes: [
          {
            id: 1,
            path: "modifyPackage",
            element: <ModifyAgentPackage />,
          },
        ],
      },
      {
        id: 7,
        path: "kaziPackage",
        element: <KaziPackage />,
        nestedRoutes: [
          {
            id: 1,
            path: "modifyPackage",
            element: <ModifyKaziPackage />,
          },
        ],
      },
    ],
  },
];

export default packageRoutes;

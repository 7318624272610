import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  courses: [],
};

const coursePackage = createSlice({
  name: "coursePackage",
  initialState,
  reducers: {
    addOrRemoveCourse: (state, action) => {
      state?.courses?.find((item) => item?._id === action?.payload?._id)
        ? (state.courses = state?.courses?.filter(
            (item) => item?._id !== action?.payload?._id
          ))
        : (state.courses = [...state?.courses, action.payload]);
    },
    storeCourseOfBundle: (state, action) => {
      state.courses = action.payload
    },
    resetCourseBundleStore: (state) => {
      state.courses = [];
    },
  },
});

export const { addOrRemoveCourse, resetCourseBundleStore, storeCourseOfBundle } =
  coursePackage.actions;
export default coursePackage.reducer;

// Configuration
import React, { useState } from "react";

// Third party package
import { Rating } from "@mui/material";
import { useUpdateReviewMutation } from "../../../../../Redux/features/Course/courseApi";
import { useEffect } from "react";
import { toast } from "react-hot-toast";

const SendReviewForm = ({ reviewForEdit, setReviewEditFormVisible }) => {
  const [review, setReview] = useState("");
  const [rating, setRating] = useState(0);

  const [updateReview, { data: response, isLoading, error }] =
    useUpdateReviewMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      review: review || reviewForEdit?.review,
      rating: rating > 0 ? rating : reviewForEdit?.rating,
    };
    await updateReview({ id: reviewForEdit?._id, data: data });
  };
  const handleRatingChange = (e, value) => {
    setRating(value);
  };

  useEffect(() => {
    if (response) {
      toast.success("Review Edited Successfully");
      setReviewEditFormVisible(false);
      setReview("");
      setRating(0);
    }
    if (error) {
      toast.error("Something went wrong. Please try again later.");
    }
  }, [response, error, setReviewEditFormVisible]);

  return (
    <div>
      <div className="py-[20px]">
        <h3 className="text-2xl font-Inter font-medium">How was experience?</h3>
        <Rating name="size-small" onChange={handleRatingChange} size="large" />
      </div>
      <form onSubmit={(e) => handleSubmit(e)}>
        <textarea
          name="reviewText"
          id="reviewText"
          className="bg-slate-100 w-full outline-none p-[20px] text-gray-500 font-fira"
          cols="30"
          rows="10"
          placeholder="Describe your experience here.."
          onChange={(e) => setReview(e.target.value)}
          onKeyDown={(e) => {
            if (e.keyCode === 13 && !e.shiftKey) {
              handleSubmit(e);
            }
          }}
        ></textarea>
        <input
          type="submit"
          value={isLoading ? "Loading..." : "Send Review"}
          className="bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] text-white px-3 py-2 w-full flex justify-center items-center mt-[20px] font-outfit font-medium cursor-pointer"
        />
      </form>
    </div>
  );
};
export default SendReviewForm;

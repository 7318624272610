import React, { useState, useEffect } from "react";
import { useUpdateReferPointMutation } from "../../../../../Redux/features/referral/referralApi";

const ReferBonusRow = ({ item }) => {
  const [edit, setEdit] = useState(false);
  const [point, setPoint] = useState(item?.point);
  const [ammountPerPoint, setAmountPerPoint] = useState(item?.ammountPerPoint);
  const [updatePoint, { data }] = useUpdateReferPointMutation();

  const handleUpdatePoint = () => {
    // if(typeof(Number(point)==))
    if (Number(point)) {
      updatePoint({
        data: { point: Number(point), ammountPerPoint: ammountPerPoint },
        id: item._id,
      });
    }
  };
  useEffect(() => {
    if (data) {
      setEdit(false);
    }
  }, [data]);
  return (
    <>
      {!edit && (
        <tr className="border-b-2 ">
          <td className=" px-4 py-2">{item?.point}</td>
          <td className=" px-4 py-2">{item?.ammountPerPoint}</td>
          <td className=" px-4 py-2">{item?.currency}</td>
          <td className=" px-4 py-2">
            <button
              onClick={() => setEdit(true)}
              className="bg-[#e41272] px-4 py-1 rounded text-white"
            >
              Edit
            </button>
          </td>
        </tr>
      )}
      {edit && (
        <tr className="border-b-2 ">
          <td className=" px-4 py-2">
            <input
              value={point}
              onChange={(e) => setPoint(e.target.value)}
              placeholder="Enter point"
              type="text"
              className="p-2 h-10 border-1 w-24 outline-0 focus:outline-0 bg-gray-100 rounded"
            />
          </td>
          <td className=" px-4 py-2">
            <input
              value={ammountPerPoint}
              onChange={(e) => setAmountPerPoint(e.target.value)}
              placeholder="Enter amount per point"
              type="text"
              className="p-2 h-10 border-1 w-24 outline-0 focus:outline-0 bg-gray-100 rounded"
            />
          </td>
          <td className=" px-4 py-2">
            <select name="status" id="status">
              <option value="DBT">BDT</option>
              <option value="USD">USD</option>
            </select>
          </td>
          <td className=" px-4 py-2">
            <button
              onClick={handleUpdatePoint}
              className="bg-[#e41272] px-4 py-1 rounded text-white"
            >
              save
            </button>
            <button
              onClick={() => setEdit(false)}
              className="bg-yellow-300 px-4 py-1 rounded text-white ml-2"
            >
              cancel
            </button>
          </td>
        </tr>
      )}
    </>
  );
};

export default ReferBonusRow;

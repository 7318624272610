// configuration
import React from "react";

export const OnGoingOrderInfoAndMark = ({ item }) => {
  const { name, price, photos } = item?.product || {};

  return (
    <>
      <div className="mt-[8px]">
        <img
          src={
            photos
              ? photos[0]?.url
              : "https://img.freepik.com/free-photo/rag-dolls-with-wheelbarrow-blue-cap_1156-223.jpg?w=740&t=st=1680695543~exp=1680696143~hmac=24c6cb3976d233b748a346dca9024b67675f49dfaa495ba01ff93294f9f20340"
          }
          alt="productImage"
          className="w-full h-[485px] rounded-t-[12px]"
        />
      </div>
      <div className="mt-[45px] px-[30px]">
        <h1 className="text-[40px] font-SourceCodePro font-semibold text-[#000000] text-left normal-case">
          {name || ""}
        </h1>
        <h3 className="mt-[8px] text-[32px] font-semibold font-SourceCodePro text-[#E41272] text-left">
          BDT <span>{price || ""}</span>
        </h3>
      </div>
      <div className="h-[1px] bg-[#67868F] w-full mt-[49px]"></div>
    </>
  );
};

import React from "react";

const ServiceInfoExtra = ({ service }) => {
    const { title, description, extraOffer, recuirements } = service || {};
    return (
        <div>
            <h1 className="text-[#32353B] text-[42px] leading-[60px] font-bold mb-9">{title}</h1>
            {/* Image section will be here */}
            <h2 className="text-2xl leading-[48px] font-bold mb-6">What you deliver with this Offer</h2>
            <p className="text-[#757575] text-xl leading-10 mb-10">{description}</p>
            <h2 className="text-2xl leading-[48px] font-bold mb-[10px]">Get more with Offer Add-ons</h2>
            {/* Advertisement */}
            <div
                className="rounded-lg bg-cover ml-[38px] mt-[60px] mb-8"
                style={{
                    backgroundImage: "linear-gradient(104.74deg, #E41272 28.77%, #630465 76.53%, #942DD9 76.53%)",
                }}
            >
                <div className="flex justify-between items-center py-5 px-[30px]">
                    <div>
                        <p className="leading-[30px] font-semibold text-white mb-1">{extraOffer[0]?.title}</p>
                        <p className="text-xs leading-[18px] text-white">Additional 1 working day</p>
                    </div>
                    <div>
                        <button
                            className="bg-white text-[28px] leading-7 font-semibold rounded-lg pt-[7px] pb-2 px-3"
                            style={{ boxShadow: "2px 2px 8px 2px rgba(0, 0, 0, 0.1)" }}
                        >
                            +৳ {extraOffer[0]?.price}
                        </button>
                    </div>
                </div>
            </div>
            <h2 className="text-2xl leading-[48px] font-bold mb-[10px]">What do you need from the Buyer to get started?</h2>
            <p className="text-[#757575] text-xl leading-10 ">{recuirements}</p>
        </div>
    );
};

export default ServiceInfoExtra;

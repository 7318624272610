import React from "react";
import { useState } from "react";
import RequestRow from "./RequestRow";
import { Pagination } from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';

const WithdrawContainer = ({data,isLoading,setPage,title, useCase}) => {
  
  const [openedRequest, setOpenedRequest] = useState();

  return (
    <div className="p-6">
      {/* <p className="text-2xl font-bold mt-8 mb-4">Withdraw Requests</p> */}
      <div className="rounded overflow-hidden shadow-2xl pb-8">
        <div className="w-full py-3 text-white font-bold text-lg bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)]">
          {title}
        </div>
        {data?.data?.requests?.length <= 0 && <div className="flex flex-col items-center justify-center mt-12 font-bold">
          <WarningIcon />
          <p>No user request found</p>
          </div>}
        {data?.data?.requests?.length > 0 && (
          <table className="w-full">
            <thead>
              <tr className="border-b-3">
                <th className="border-b-2 py-2 px-4"># </th>
                <th className="text-left border-b-2 py-2 px-4">Name </th>
                <th className="text-left border-b-2 py-2 px-4">Email</th>
                <th className="border-b-2 py-2 px-4">Amount</th>
                <th className="border-b-2 py-2 px-4">Action</th>
              </tr>
            </thead>
            <tbody>
              {isLoading && <div>Loading...</div>}
              {!isLoading &&
                data?.data?.requests?.map((item, i) => (
                  <RequestRow
                    key={item._id}
                    {...{ openedRequest, setOpenedRequest, item, i,useCase }}
                  />
                ))}
              {data?.data?.total >= 20 && (
                <tr>
                  <td colspan="5">
                    <div className="flex justify-between px-4 items-center my-12">
                      <p className="leading-6 font-medium">
                        Showing {data?.users?.length} from {data?.total} data
                      </p>
                      <Pagination
                        className="bg-[#E3E4EB] px-[1px] py-[3px] rounded-md text-white"
                        onChange={(event, value) => {
                          setPage(value);
                        }}
                        count={Math.ceil(data?.data?.total / 20) || 1}
                        shape="rounded"
                      />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default WithdrawContainer;

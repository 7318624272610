// configuration
import React, { useState } from "react";

// components
import { AgentPaymentCard } from "../../../Shared/Cards/Agent/Payment/AgentPaymentCard";

export const AgentPayment = () => {
    // varible declation
    // hook variables
    const [payment, setPayment] = useState(null);

    // js variables
    const paymentData = [
        {
            id: 1,
            name: "Rakesh07",
            date: "28/2/2021",
            amount: 1000,
        },
        {
            id: 2,
            name: "Rakesh07",
            date: "28/2/2021",
            amount: 1000,
        },
        {
            id: 3,
            name: "Rakesh07",
            date: "28/2/2021",
            amount: 1000,
        },
        {
            id: 4,
            name: "Rakesh07",
            date: "28/2/2021",
            amount: 1000,
        },
        {
            id: 5,
            name: "Rakesh07",
            date: "28/2/2021",
            amount: 1000,
        },
        {
            id: 6,
            name: "Rakesh07",
            date: "28/2/2021",
            amount: 1000,
        },
        {
            id: 7,
            name: "Rakesh07",
            date: "28/2/2021",
            amount: 1000,
        },
        {
            id: 8,
            name: "Rakesh07",
            date: "28/2/2021",
            amount: 1000,
        },
        {
            id: 9,
            name: "Rakesh07",
            date: "28/2/2021",
            amount: 1000,
        },
        {
            id: 10,
            name: "Rakesh07",
            date: "28/2/2021",
            amount: 1000,
        },
        {
            id: 11,
            name: "Rakesh07",
            date: "28/2/2021",
            amount: 1000,
        },
        {
            id: 12,
            name: "Rakesh07",
            date: "28/2/2021",
            amount: 1000,
        },
    ];

    //function declaration
    const cardToggole = id => {
        if (payment === null || payment !== id) {
            setPayment(id);
        } else {
            setPayment(null);
        }
    };

    return (
        <div className="bg-[#FFFFFF] font-sans font-normal text-sm">
            <h1 className="font-bold text-2xl text-[#333333] text-left pt-[31px] pb-[33px] pl-[30px]">Payment Request</h1>
            <div className="flex justify-around pb-[34px] text-[#98A4B5]">
                <p>Username</p>
                <p>Date</p>
                <p>Total</p>
            </div>
            <div className="grid grid-cols-1 gap-y-[16px]">
                {paymentData?.map(paymentInfo => (
                    <AgentPaymentCard key={paymentInfo.id} {...{ paymentInfo, payment, setPayment, cardToggole }} />
                ))}
            </div>
        </div>
    );
};

import AddAgent from "../../Admin__Portion/Components/Agent/AddAgent/AddAgent";
import Agent from "../../Admin__Portion/Components/Agent/Agent";
import { AgentPayment } from "../../Admin__Portion/Components/Agent/AgentPayment/AgentPayment";
import AgentProfile from "../../Admin__Portion/Components/Agent/AgentProfile/AgentProfile";
import ModifyAgentProfileContainer from "../../Admin__Portion/Components/Agent/AgentProfile/ModifyAgentProfileContainer";
import ModifyAgentSingleProfileDetails from "../../Admin__Portion/Components/Agent/AgentProfile/ModifyAgentSingleProfileDetails/ModifyAgentSingleProfileDetails";
import AssignAgent from "../../Admin__Portion/Components/Agent/AssignAgent/AssignAgent";
import ModifyDealHistory from "../../Admin__Portion/Components/Agent/ModifyDealHistory/ModifyDealHistory";
import ModifyReviewAndRatingsForAgent from "../../Admin__Portion/Components/Agent/ModifyReviewAndRatings/ModifyReviewAndRatings";
import AgentSingleProfileDetails from "../../Admin__Portion/Components/Agent/ViewAllAgents/AgentSingleProfileDetails/AgentSingleProfileDetails";
import ViewAllAgents from "../../Admin__Portion/Components/Agent/ViewAllAgents/ViewAllAgents";
import ViewAllAgentsContainer from "../../Admin__Portion/Components/Agent/ViewAllAgents/ViewAllAgentsContainer";
import { ViewRegistrationRequestForAgent } from "../../Admin__Portion/Components/Agent/ViewRegistrationForAgent/ViewRegistrationRequestForAgent";
import AllServices from "../../Admin__Portion/Components/Lawyer/ModifyCaseStudy/AllServices/AllServices";
import ModifyCaseStudy from "../../Admin__Portion/Components/Lawyer/ModifyCaseStudy/ModifyCaseStudy";
import SingleUserCases from "../../Admin__Portion/Components/Lawyer/ModifyCaseStudy/SingleUserCases/SingleUserCases";
import ViewRegistrationForUserInfo from "../../Admin__Portion/Components/User/ViewRegistrationRequest/ViewRegistrationForUserInfo/ViewRegistrationForUserInfo";
import DocumentVerification from "../../Admin__Portion/Shared/components/DocumentVerification/documentVerification";
import { DocumentsVerificationUserProfiles } from "../../Admin__Portion/Shared/components/DocumentVerification/documentVerificationUserProfiles";
import { ServiceEdit } from "../../Admin__Portion/Shared/components/LawyerAgentKaziServices/ServiceEdit/ServiceEdit";
import SingleServicePage from "../../Admin__Portion/Shared/components/LawyerAgentKaziServices/Single__Service__Page/SingleServicePage";
import ModifyCaseForm from "../../Admin__Portion/Shared/components/ModifyCaseForm/ModifyCaseForm";
import { ServiceUserProfiles } from "../../Admin__Portion/Shared/components/ViewServices/serviceUserProfiles";
import EditEducationalInfo from "../../Admin__Portion/Shared/ProfileEditForms/EditEducationalInfo";
import EditOthersInfo from "../../Admin__Portion/Shared/ProfileEditForms/EditOthersInfo";
import EditPersonalInfo from "../../Admin__Portion/Shared/ProfileEditForms/EditPersonalInfo";
import EditPhysicalInfo from "../../Admin__Portion/Shared/ProfileEditForms/EditPhysicalInfo";
import EditProfessionalInfo from "../../Admin__Portion/Shared/ProfileEditForms/EditProfessionalInfo";

const agentRoutes = [
  {
    id: 1,
    path: "/agent",
    element: <Agent />,
    nestedRoutes: [
      {
        id: 1,
        path: "allAgents",
        element: <ViewAllAgentsContainer />,
        nestedRoutes: [
          {
            id: 1,
            path: "",
            element: <ViewAllAgents />,
          },
          {
            id: 2,
            path: "profile/:id",
            element: <AgentSingleProfileDetails />,
          },
        ],
      },
      {
        id: 2,
        path: "payment",
        element: <AgentPayment />,
      },
      {
        id: 3,
        path: "registrationRequest",
        element: <ViewRegistrationRequestForAgent />,
      },
      {
        id: 5,
        path: "assignAgent",
        element: <AssignAgent />,
      },
      {
        id: 6,
        path: "documentsVerification",
        element: <DocumentsVerificationUserProfiles role="agent" />,
      },
      {
        id: 7,
        path: "agentsProfiles",
        element: <ModifyAgentProfileContainer />,
        nestedRoutes: [
          {
            id: 1,
            path: "",
            element: <AgentProfile />,
          },
          {
            id: 2,
            path: "profile/:id",
            element: <ModifyAgentSingleProfileDetails />,
            nestedRoutes: [
              {
                id: 1,
                path: "personalInfo/:id",
                element: <EditPersonalInfo />,
              },
              {
                id: 2,
                path: "professionalInfo/:id",
                element: <EditProfessionalInfo />,
              },
              {
                id: 3,
                path: "educationalInfo/:id",
                element: <EditEducationalInfo />,
              },
              {
                id: 4,
                path: "physicalInfo/:id",
                element: <EditPhysicalInfo />,
              },
              {
                id: 5,
                path: "othersInfo/:id",
                element: <EditOthersInfo />,
              },
            ],
          },
        ],
      },
      {
        id: 8,
        path: "registrationRequest",
        element: <ViewRegistrationRequestForAgent />,
      },
      {
        id: 10,
        path: "modifyDealStatus",
        element: <ModifyCaseStudy {...{ role: "agent" }} />,
      },
      {
        id: 11,
        path: "reviewRatingsForAgent",
        element: <ModifyReviewAndRatingsForAgent />,
      },
      {
        id: 12,
        path: "dealHistory",
        element: <ModifyDealHistory />,
      },
      {
        id: 13,
        path: "addAgent",
        element: <AddAgent />,
      },
      {
        id: 14,
        path: "documentsVerificationContent",
        element: <DocumentVerification />,
      },

      {
        id: 9,
        path: "agentServices",
        element: <ServiceUserProfiles role="agent" />,
      },
      {
        id: 10,
        path: "allServices",
        element: <AllServices />,
      },
      {
        id: 11,
        path: "singleService/:id",
        element: <SingleServicePage />,
      },
      {
        id: 12,
        path: "serviceEdit/:id",
        element: <ServiceEdit />,
      },
      {
        id: 13,
        path: "modifyDeal/:id",
        element: <SingleUserCases {...{ role: "agent" }} />,
      },
      {
        id: 14,
        path: "editCase/:id",
        element: <ModifyCaseForm role="agent" />,
      },
      {
        id: 15,
        path: "registrationRequest/:requestedId",
        element: <ViewRegistrationForUserInfo />,
      },
    ],
  },
];

export default agentRoutes;

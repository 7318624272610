import React from 'react';

const CertificateSelectButton = ({item, index, selected, setSelected}) => {
    return (
      <button
        onClick={() => setSelected(item)}
        className={`${
          item?._id === selected?._id ? "bg-[#e41272] text-white" : " bg-gray-200"
        } font-semibold px-4 py-2 rounded`}
      >
        Marriage {index + 1}
      </button>
    );
}

export default CertificateSelectButton;

import React from "react";

// Components
import Error from "../../../../Shared/components/accordion/UI/Error/Error";

const FaqInputField = ({ register, errors, formType }) => {
  return (
    <div className="w-full">
      <div>
        <h1 className="text-[#32353B] text-xl font-poppins font-medium text-left">
          Frequently Asked Question
        </h1>
        <hr className="mt-2 mb-4" />
      </div>
      {/* For whom is this course? */}
      <div className="w-full flex flex-col mb-4">
        <label
          htmlFor="name"
          className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]"
        >
          For whom is this course?
        </label>
        <textarea
          {...register(
            "forWhomIsThisCourse",
            formType === "addForm" && {
              required: "FAQ answer is required",
            }
          )}
          className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-2 px-[12px]"
          type="text"
          placeholder="Answer..."
        />
        {errors?.forWhomIsThisCourse && (
          <Error message={errors?.forWhomIsThisCourse?.message} />
        )}
      </div>
      {/* What do you need to know for the course? */}
      <div className="w-full flex flex-col mb-4">
        <label
          htmlFor="name"
          className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]"
        >
          What do you need to know for the course?
        </label>
        <textarea
          {...register(
            "whatNeedToKnowForTheCourse",
            formType === "addForm" && {
              required: "FAQ answer is required",
            }
          )}
          className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-2 px-[12px]"
          type="text"
          placeholder="Answer..."
        />
        {errors?.whatNeedToKnowForTheCourse && (
          <Error message={errors?.whatNeedToKnowForTheCourse?.message} />
        )}
      </div>
      {/* What is required for the course? */}
      <div className="w-full flex flex-col mb-4">
        <label
          htmlFor="name"
          className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]"
        >
          What is required for the course?
        </label>
        <textarea
          {...register(
            "whatRequiredForTheCourse",
            formType === "addForm" && {
              required: "FAQ answer is required",
            }
          )}
          className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-2 px-[12px]"
          type="text"
          placeholder="Answer..."
        />
        {errors?.whatRequiredForTheCourse && (
          <Error message={errors?.whatRequiredForTheCourse?.message} />
        )}
      </div>
      {/* Will the certificate be available with this course? */}
      <div className="w-full flex flex-col mb-4">
        <label
          htmlFor="name"
          className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]"
        >
          Will the certificate be available with this course?
        </label>
        <textarea
          {...register(
            "willCertificateBeAvailable",
            formType === "addForm" && {
              required: "FAQ answer is required",
            }
          )}
          className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-2 px-[12px]"
          type="text"
          placeholder="Answer..."
        />
        {errors?.willCertificateBeAvailable && (
          <Error message={errors?.willCertificateBeAvailable?.message} />
        )}
      </div>
    </div>
  );
};

export default FaqInputField;

import React, { useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import WarningIcon from "@mui/icons-material/Warning";
import SearchCourses from "./SearchCourses";
import CourseCard from "./CourseCard";
import "./addCourse.css";

const CourseShowCase = ({ setShowDrop }) => {
  const [courseObj, setCourses] = useState();

  return (
    <div
      id="myElement"
      className=" bg-gray-300 top-[350px] p-3 shadow-xl rounded-lg h-[300px] overflow-y-auto absolute w-full"
    >
      <div>
        <span
          onClick={() => setShowDrop(false)}
          className="cursor-pointer absolute left-2 text-[#e41272] top-2"
        >
          <CancelIcon />
        </span>
        <SearchCourses
          {...{
            setCourses,
            page: "",
            limit: "",
            categoryId:"",
            typeId:"",
          }}
        />
        <div className="mt-6">
          {courseObj?.data?.length === 0 && (
            <div className="text-yellow-500 text-xl font-semibold">
              <WarningIcon fontSize="large" />
              <p>Course Unavailable</p>
            </div>
          )}
          {courseObj?.data?.map((item) => (
            <CourseCard {...{ item, key: item._id, useCase: "select" }} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CourseShowCase;

import getCookie from "../../../../helper/cookies/getCookie";
import { apiSlice } from "../../../api/apiSlice";

export const priorityUserApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    markAsPriority: builder.mutation({
      query: (data) => ({
        url: "/admin/priority-member/add-priority-member",
        method: "POST",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
    }),
    getAllPriorityMembers: builder.query({
      query: (gender ) => ({
        url: `/admin/priority-member/get-all-priority-member?gender=${gender}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const { useMarkAsPriorityMutation,useGetAllPriorityMembersQuery } = priorityUserApi;

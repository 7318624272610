// configuration
import React from "react";

// Third party packages
import { Outlet } from "react-router-dom";
import useDocumentTitle from "../../../../helper/utilities/useDocumentTitle";
import { KaziNav } from "./KaziNav";

export const Kazi = () => {
    useDocumentTitle("Admin | Kazi");
    return (
        <KaziNav>
            <Outlet />
        </KaziNav>
    );
};

// configuration
import React from "react";

// Third party packages
import { FaArrowUp } from "react-icons/fa";

export const KaziPaymentCard = ({ paymentInfo, payment, cardToggole }) => {
    return (
        <div
            onClick={() => cardToggole(paymentInfo.id)}
            className={`${
                paymentInfo.id === payment && "bg-gradient-to-r from-[#2C95AC] to-[#2C3CC7]"
            } shadow-[2px_2px_10px_rgba(0,0,0,0.12)] duration-500 transition-all cursor-pointer ${
                paymentInfo.id === payment ? "h-[195px]" : "h-[116px]"
            } duration-300`}
        >
            <section className="flex flex-col justify-center h-full">
                <div className="flex items-center justify-around h-full">
                    <div className="flex items-center gap-x-[37px]">
                        <div
                            className={`text-[30px] p-5 ${
                                paymentInfo.id === payment ? "text-[#FFFFFF] bg-[#4192B8]" : "text-[#2CC798] bg-[#EAF9F5]"
                            } rounded-[12px]`}
                        >
                            <FaArrowUp />
                        </div>
                        <p className={`${paymentInfo.id === payment ? "text-[#FFFFFF]" : "text-[#333333]"} text-[18px] font-medium`}>
                            {paymentInfo.name}
                        </p>
                    </div>
                    <div>
                        <p className={`${paymentInfo.id === payment ? "text-[#FFFFFF]" : "text-[#98A4B5]"} text-[14px] font-normal`}>
                            {paymentInfo.date}
                        </p>
                    </div>
                    <div>
                        <p
                            className={`font-medium text-[18px] ${
                                paymentInfo.id === payment ? "text-[#FFFFFF]" : "text-[#E41272]"
                            } text-[18px] font-medium`}
                        >
                            ৳{paymentInfo.amount}
                        </p>
                    </div>
                </div>
                {paymentInfo.id === payment && (
                    <section>
                        <div className="flex items-center justify-start gap-4 mt-[17px] pb-[17px] ml-[80px]">
                            <button className="font-poppins text-semibold text-2xl text-[#FFFFFF] bg-[#1DEC57] py-[10px] px-[35px] rounded-[32px]">
                                Accept
                            </button>
                            <button className="font-poppins text-semibold text-2xl text-[#FFFFFF] bg-[#F5AC12] py-[10px] px-[35px] rounded-[32px]">
                                Decline
                            </button>
                        </div>
                    </section>
                )}
            </section>
        </div>
    );
};

import React from "react";

const EmailPassPhone = ({ register, errors }) => {
  return (
    <div>
      {/* Email */}
      <div className="flex flex-col mt-6 font-medium text-[#344054]">
        <label htmlFor="email">Email</label>
        <input
          {...register("email", {
            required: {
              value: true,
              message: "Please Provide a valid email",
            },
          })}
          className="border border-[#D0D5DD] shadow rounded-lg focus: outline-none py-3 px-4 mt-1 font-normal text-base text-[#667085]"
          type="email"
          id="email"
          placeholder="you@company.com"
        />
        <p className="text-left mt-1">
          {errors?.email?.type === "required" && (
            <span className="w-full text-left text-red-400 text-sm">
              {errors?.email?.message}
            </span>
          )}
        </p>
      </div>
      {/* Password */}
      <div className="flex flex-col mt-6 font-medium text-[#344054]">
        <label htmlFor="password">Password</label>
        <input
          {...register("password", {
            required: {
              value: true,
              message: "Please Provide a Strong Password",
            },
            minLength: {
              value: 8,
              message: "password should be minimum 8 characters",
            },
          })}
          className="border border-[#D0D5DD] shadow rounded-lg focus: outline-none py-3 px-4 mt-1 font-normal text-base text-[#667085]"
          type="password"
          id="Password"
          placeholder="Password"
        />
        <p className="text-left mt-1">
          {errors?.password?.type === "required" && (
            <span className="w-full text-left text-red-400 text-sm">
              {errors?.password?.message}
            </span>
          )}
          {errors?.password?.type === "minLength" && (
            <span className="w-full text-left text-red-400 text-sm">
              {errors?.password?.message}
            </span>
          )}
        </p>
      </div>
      <div className="flex flex-col mt-6 font-medium text-[#344054]">
        <label htmlFor="confirmPassword">Confirm Password</label>
        <input
          {...register("confirmPassword", {
            required: {
              value: true,
              message: "Please Confirm Your Password",
            },
            minLength: {
              value: 8,
              message: "password should be minimum 8 characters",
            },
          })}
          className="border border-[#D0D5DD] shadow rounded-lg focus: outline-none py-3 px-4 mt-1 font-normal text-base text-[#667085]"
          type="password"
          id="confirmPassword"
          placeholder="Confirm Password"
        />
        <p className="text-left mt-1">
          {errors?.confirmPassword?.type === "required" && (
            <span className="w-full text-left text-red-400 text-sm">
              {errors?.confirmPassword?.message}
            </span>
          )}
          {errors.confirmPassword?.type === "minLength" && (
            <span className="w-full text-left text-red-400 text-sm">
              {errors?.confirmPassword?.message}
            </span>
          )}
        </p>
      </div>
      {/* Phone number */}
      <div className="mt-6">
        <label
          htmlFor="phoneNumber"
          className="block mb-2 text-sm font-medium text-[#344054] "
        >
          Phone number
        </label>
        <div className=" flex h-12 items-center border border-[#D0D5DD] rounded-lg mt-1">
          <div>
            <input
              {...register("phone")}
              type="text"
              id="phoneNumber"
              className="text-[#667085] text-base block w-full h-full pl-2 focus:outline-none "
              placeholder="+880 177 004-5304"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailPassPhone;

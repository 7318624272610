import React from 'react';

const AddStatusPackagePage = () => {
    return (
        <div>
            Add Status package
        </div>
    );
}

export default AddStatusPackagePage;

// Configuration
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

// Third party packages
import { toast } from "react-hot-toast";

// Components
import { useUpdateCourseMutation } from "../../../../../Redux/features/Course/courseApi";
import AddCourseInputField from "../../AddCourse/AddCourseInputField/AddCourseInputField";

const EditCourseForm = ({ id, course }) => {
  const [category, setCategory] = useState("");
  const [type, setType] = useState("");
  const [skillLevel, setSkillLevel] = useState("");
  const [courseDetails, setCourseDetails] = useState(null);
  const [whatIsInThisCourse, setWhatIsInThisCourse] = useState(null);
  const [image, setImage] = useState("");
  const [customError, setCustomError] = useState([
    {
      categoryError: "",
    },
    {
      typeError: "",
    },
    {
      skillLevelError: "",
    },
    {
      whatIsInThisCourseError: "",
    },
    {
      courseDetailsError: "",
    },
  ]);
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm();

  // Redux api
  const [updateCourse, { data: courseResponse, isLoading, error }] =
    useUpdateCourseMutation();

  const onSubmit = async (data) => {
    data.price = Number(data.price) || course?.price;
    data.oldPrice = Number(data.oldPrice) || course?.oldPrice;
    data.category = category || course?.category;
    data.type = type || course?.type;
    data.skillLevel = skillLevel || course?.skillLevel;
    data.whatIsInThisCourse = whatIsInThisCourse || course?.whatIsInThisCourse;
    data.courseDetails = courseDetails || course?.courseDetails;
    data.name = data.name || course?.name;
    data.description = data.description || course?.name;
    data.image = image || course?.image;
    data.duration = data.duration || course?.duration;
    data.section = data.section || course?.section;
    data.forWhomIsThisCourse =
      data.forWhomIsThisCourse || course?.forWhomIsThisCourse;
    data.whatNeedToKnowForTheCourse =
      data.whatNeedToKnowForTheCourse || course?.whatNeedToKnowForTheCourse;
    data.whatRequiredForTheCourse =
      data.whatRequiredForTheCourse || course?.whatRequiredForTheCourse;
    data.willCertificateBeAvailable =
      data.willCertificateBeAvailable || course?.willCertificateBeAvailable;

    await updateCourse({ id: id, data: data });
  };

  useEffect(() => {
    if (courseResponse) {
      toast.success("Course Edited Successfully");
      reset();
    }
    if (error) {
      toast.error("Something Went Wrong. Please try again later.");
    }
  }, [courseResponse, error, reset]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <AddCourseInputField
          {...{
            register,
            errors,
            control,
            setCategory,
            setType,
            setSkillLevel,
            setCourseDetails,
            setWhatIsInThisCourse,
            customError,
            setCustomError,
            image,
            setImage,
            formType: "editForm",
          }}
        />
        <input
          type="submit"
          value={isLoading ? "Loading..." : "Submit"}
          className="bg-[#E41272] text-white w-[129px] h-[47px] rounded-[3px] border-[1px] border-[#3D66D7] mt-[18px] cursor-pointer font-medium hover:bg-opacity-80"
        />
      </form>
    </div>
  );
};

export default EditCourseForm;

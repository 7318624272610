// Configuration
import React, { useState } from "react";

// Third party package
import { Pagination } from "@mui/material";

// Components
import { useGetAllTicketsQuery } from "../../../../../Redux/features/SupportTicket/supportTicketApi";
import { LineWaveLoader } from "../../../../Shared/Loader/LineWaveLoader";
import ErrorMessage from "../../../../Shared/components/ErrorMessage/ErrorMessage";
import SupportTicketTableRow from "./SupportTicketTableRow/SupportTicketTableRow";

const SupportTicketTable = ({ status }) => {
  const [page, setPage] = useState(1);
  const tableColumns = [
    "Ticket No.",
    "User",
    "Status",
    "Category",
    "Subject",
    "Action",
  ];

  const { data, isError, isLoading, isFetching } = useGetAllTicketsQuery({
    status: status,
    page: page,
    limit: 10,
  });
  const { tickets, total } = data || {};
  const totalPage = Math.ceil(total / 10);

  let content;
  if (isLoading || isFetching) {
    content = (
      <tbody className="h-[260px] w-full flex justify-center items-center">
        <tr>
          <td> </td>
          <td>
            <LineWaveLoader />
          </td>
        </tr>
      </tbody>
    );
  } else if (!isLoading && isError) {
    content = (
      <div className="h-[220px] w-full flex justify-center items-center">
        <ErrorMessage />
      </div>
    );
  } else if (!isLoading && total === 0) {
    content = (
      <div className="h-[120px] w-full flex justify-center items-center text-gray-400 font-Inter">
        No Ticket found
      </div>
    );
  } else if (!isLoading && total > 0) {
    content = (
      <table className="w-full">
        <thead className="bg-gray-50 border-b-2 border-gray-200">
          <tr>
            {tableColumns?.map((item, index) => {
              return (
                <th
                  key={index}
                  className="w-[100px] p-3 text-sm font-semibold tracking-wide text-center"
                >
                  {item}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-100">
          {tickets?.map((item, index) => {
            return (
              <SupportTicketTableRow item={item} key={item._id} index={index} />
            );
          })}
        </tbody>
      </table>
    );
  }

  return (
    <>
      <div className="overflow-auto rounded-lg shadow">
        {content}

        <div className="w-full flex justify-start px-6 py-4">
          <Pagination
            count={totalPage}
            variant="outlined"
            color="secondary"
            onChange={(e, value) => setPage(value)}
            size="small"
          />
        </div>
      </div>
    </>
  );
};

export default SupportTicketTable;

// Configuration
import React, { useState } from "react";

// Third party packages
import { AiOutlineWarning } from "react-icons/ai";
import { MdOutlineRecommend } from "react-icons/md";
import Pagination from "@mui/material/Pagination";

// Components
import { useGetAllCoursesQuery } from "../../../../Redux/features/Course/courseApi";
import CourseLoader from "../../../Shared/Loader/Courses/CourseLoader";
import SingleCourseCard from "../../../Shared/Cards/CoursesAllCard/SingleCourseCard/SingleCourseCard";
import useDocumentTitle from "../../../../helper/utilities/useDocumentTitle";

const ModifyCourseReview = () => {
  const [page, setPage] = useState(1);
  const loaderArr = [1, 2, 3, 4, 5, 6];
  const {
    data: coursesResponse,
    isLoading,
    error,
  } = useGetAllCoursesQuery({
    page: page,
    limit: 9,
    categoryId: "",
    typeId: "",
    skill: "",
  });

  // Page Title
  useDocumentTitle("Admin | Course Review");

  let content;
  if (isLoading) {
    content = (
      <div className="grid grid-cols-3 gap-8">
        {loaderArr.map((loader) => {
          return <CourseLoader key={loader} />;
        })}
      </div>
    );
  } else if (error) {
    content = (
      <div className="flex flex-col items-center justify-center mt-[100px]">
        <AiOutlineWarning className="text-[42px] text-gray-400" />
        <p className="mt-[10px] text-[16px] font-Inter font-medium text-gray-500">
          An Error occurred! <br /> Please login or try again later!
        </p>
      </div>
    );
  } else if (!error && coursesResponse?.courses?.length === 0) {
    content = (
      <div className="flex flex-col items-center justify-center mt-[100px]">
        <MdOutlineRecommend className="text-[42px] text-gray-400" />
        <p className="mt-[10px] text-[16px] font-Inter font-medium text-gray-500">
          No Courses Found!
        </p>
      </div>
    );
  } else if (!error && coursesResponse?.courses?.length > 0) {
    content = (
      <div className="flex justify-between flex-wrap gap-10 mb-20">
        {coursesResponse?.courses?.map((course) => {
          return (
            <SingleCourseCard
              key={course?._id}
              {...{ course, pageTitle: "modifyCourseReview" }}
            />
          );
        })}
      </div>
    );
  }

  return (
    <div className="px-6">
      <div>{content}</div>
      <div className="mb-12 flex justify-end">
        {coursesResponse?.total > 9 && (
          <Pagination
            className="bg-[#E3E4EB] px-[1px] py-[3px] rounded-md text-white"
            onChange={(event, value) => {
              setPage(value);
            }}
            count={Math.ceil(coursesResponse?.total / 9) || 1}
            shape="rounded"
          />
        )}
      </div>
    </div>
  );
};

export default ModifyCourseReview;

// Configuration
import React from "react";
import { Link } from "react-router-dom";

// Components
import { ageCalculator } from "../../../../../helper/utilities/ageCalculator";

const ViewAllLawyersCard = ({ lawyer }) => {
  const age = ageCalculator(lawyer?.dateOfBirth);
  return (
    <div className="py-10 rounded-lg bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.12)]">
      <div className="flex justify-start items-center gap-6 px-9 mb-9">
        <img
          className="w-[85px] h-[85px] rounded-full"
          src={lawyer?.profilePhoto}
          alt=""
        />
        <div className="text-left">
          <h3 className="text-2xl leading-[29px] font-medium mb-4 whitespace-nowrap">
            {(lawyer?.firstName ? lawyer?.firstName : "") +
              " " +
              (lawyer?.lastName ? lawyer?.lastName : "")}
          </h3>
          <p className="text-xl leading-6">
            Age : {lawyer?.age ? lawyer?.age : age ? age : ""}
          </p>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <Link
          to={`profile/${lawyer?._id}`}
          className="text-base text-white leading-5 font-medium w-[135px] py-[9px] rounded-sm mr-2"
          style={{
            backgroundImage:
              "linear-gradient(180deg, #E22989 0%, #A72CC7 100%)",
          }}
        >
          View Profile
        </Link>
      </div>
    </div>
  );
};

export default ViewAllLawyersCard;

import React from "react";
import { useNavigate } from "react-router-dom";
import { getDateFromCreatedAt } from "../../../../../helper/utilities/getDateFromCreatedAt";

const OngoingOrdersCard = ({ order }) => {
  const navigate = useNavigate();
  const { firstName, lastName, address1, city, state } =
    order?.billingInfo || {};

  return (
    <div
      className="text-left w-[342px] rounded-xl py-4"
      style={{ boxShadow: "2px 2px 10px 2px rgba(0, 0, 0, 0.12)" }}
    >
      <p className="text-lg leading-6 font-medium font-SourceCodePro mx-6 mb-2 capitalize">
        Order by #{(firstName || "") + " " + (lastName || "")}
      </p>
      <p className="text-sm leading-[18px] font-SourceCodePro text-[#666666] mx-6">
        Order Id #{order?.orderId}
      </p>
      <p className="text-sm leading-[18px] font-SourceCodePro text-[#666666] mx-6 mb-4">
        {getDateFromCreatedAt(order?.createdAt)}
      </p>
      <div>
        <img
          src={
            order?.orderItems[0]?.product?.photos[0]?.url ||
            "https://img.freepik.com/free-photo/rag-dolls-with-wheelbarrow-blue-cap_1156-223.jpg?w=740&t=st=1680695543~exp=1680696143~hmac=24c6cb3976d233b748a346dca9024b67675f49dfaa495ba01ff93294f9f20340"
          }
          alt=""
          className="w-[342px] h-[198px]"
        />
      </div>
      <div className="p-6">
        <p className="text-base leading-5 font-medium font-SourceCodePro mb-4">
          BDT {order?.totalPrice}
        </p>
        <p className="text-sm leading-[18px] font-SourceCodePro text-[#666666] capitalize">
          {`${address1 || ""}, ${city || ""}, ${state || ""}`}
        </p>
      </div>
      <div className="flex justify-center items-center">
        <button
          className="text-xl leading-[25px] font-medium font-SourceCodePro text-white rounded-[100px] w-24 py-2"
          style={{
            backgroundImage:
              "linear-gradient(270deg, #A72CC7 0%, #E41272 100%)",
          }}
          onClick={() => navigate(`/shop/orderId/${order?._id}`)}
        >
          View
        </button>
      </div>
    </div>
  );
};

export default OngoingOrdersCard;

// configuration
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Third party packages
import { useForm } from "react-hook-form";
import { FaRegEnvelope } from "react-icons/fa";
import { MdLockOutline } from "react-icons/md";

// components
import logo from "../../../assets/Logo/logoBlack.png";
import setCookie from "../../../helper/cookies/setCookie";
import useDocumentTitle from "../../../helper/utilities/useDocumentTitle";
import { useLoginMutation } from "../../../Redux/features/Authentication/authenticationApi";
import Error from "../../Shared/components/accordion/UI/Error/Error";
import { EmailField } from "./Fields/EmailField";
import { PasswordField } from "./Fields/PasswordField";

export const Login = () => {
  // hook variable declaration
  const [customError, setCustomError] = useState("");
  const [login, { data: response, isLoading, error }] = useLoginMutation();
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  // useEffect declaration
  useEffect(() => {
    if (response?.success) {
      setCookie("adminToken", response.data.token);
      reset();
      response?.data?.user?.role === "vendor"
        ? navigate("/shop")
        : navigate("/");
      window.location.reload();
    }
  }, [response, reset, navigate]);

  useEffect(() => {
    if (error) {
      setCustomError(error?.data?.message);
    }
  }, [error]);

  // function declaration
  const onSubmit = async (data) => {
    login(data);
  };

  const emailHandler = () => {
    setCustomError("");
  };

  // page title
  useDocumentTitle("Admin | Login");

  return (
    <div>
      <div className="h-screen flex w-full justify-center items-center">
        <div className="bg-white rounded-2xl shadow-2xl md:flex w-[100%] md:w-2/4 lg:max-w-[500px]">
          <div className="w-full p-5">
            <div className="text-left font-bold">
              <div className="gradient_text font-george">
                <img className="w-[150px]" src={logo} alt="logo" />
              </div>
            </div>
            <div className="py-10">
              <div className="flex w-full justify-center flex-col items-center">
                <h2 className="text-3xl font-bold gradient_text text-center mb-1">
                  Admin Login
                </h2>
                <div className="border-2 w-10 border-primary inline-block"></div>
              </div>
              <div>
                <form
                  className="w-64 mx-auto flex justify-center flex-col"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <EmailField
                    {...{
                      register,
                      errors,
                      name: "email",
                      placeholder: "Email",
                      icon: <FaRegEnvelope className=" m-2 text-gray-400" />,
                      emailHandler,
                    }}
                  />
                  <PasswordField
                    {...{
                      register,
                      errors,
                      name: "password",
                      placeHolder: "Password",
                      icon: <MdLockOutline className=" m-2 text-gray-400" />,
                      id: "password",
                      setCustomError,
                      type: "password",
                    }}
                  />
                  <div className="col-span-2">
                    {customError && <Error message={customError} />}
                  </div>
                  <input
                    type="submit"
                    value={isLoading ? "Loading..." : "Login"}
                    className="cursor-pointer mt-3 border-primary rounded-full px-12 py-2 bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] hover:bg-[linear-gradient(166deg,rgb(242,40,118)_20%,rgb(148,45,217)_100%)] text-white duration-500 transition-all"
                  />
                </form>
              </div>
              {/*Input Field*/}
            </div>
          </div>{" "}
          <div className="h-[200px] w-full bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] rounded-br-2xl rounded-bl-2xl p-2 md:hidden text-white">
            <h2 className="font-bold text-3xl mb-2">Hello, Friend!</h2>
            <div className="border-2 w-10 border-white inline-block"></div>
            <p className="mb-4">
              Fill up your information and start journey with us
            </p>
            <Link
              to="/signup"
              className="border-2 border-white rounded-full px-3 lg:px-12 py-2 hover:bg-white hover:text-primary duration-500 transition-all"
            >
              Sign Up
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

// configuration
import React, { useCallback, useEffect } from "react";

// Third party packages
import { useDropzone } from "react-dropzone";
import { toast } from "react-hot-toast";
import { AiOutlineCloudUpload } from "react-icons/ai";

// Components
import { useUploadNewProductImageMutation } from "../../../../Redux/features/Shop/shopApi";
import { OvalLoader } from "../../../Shared/Loader/OvalLoader";

export const ProductNewPhotoUploadContainer = ({ _id }) => {
  const [uploadNewProductImage, { data, isLoading, error }] =
    useUploadNewProductImageMutation();

  const onDrop = useCallback(
    async (acceptedFiles) => {
      // Do something with the files
      const formData = new FormData();
      for (let i = 0; i < acceptedFiles.length; i++) {
        formData.append("photos", acceptedFiles[i]);
      }
      await uploadNewProductImage({ id: _id, data: formData });
    },
    [_id, uploadNewProductImage]
  );

  useEffect(() => {
    if (data) {
      toast.success("Product Images Uploaded Successfully");
    }
    if (error) {
      toast.error("Something Went Wrong. Please try again later.");
    }
  }, [data, error]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <div className="mt-[93px]">
      <h1 className="text-[#000000] text-[32px] font-bold font-Inter text-left">
        Upload New Photo
      </h1>
      <div className="w-full flex justify-center mt-[43px]">
        <div
          {...getRootProps()}
          className="w-[343px] h-[216px] bg-white border-[1px] rounded-[8px] border-[#E5E7EB]"
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <div className="text-green-400 w-full flex justify-center items-center h-full text-[12px] font-medium font-Inter">
              Drop the files here ...
            </div>
          ) : isLoading ? (
            <OvalLoader title="Loading..." />
          ) : (
            <div className="w-full flex flex-col h-full justify-center items-center gap-y-[16px]">
              <div className="w-[56px] h-[56px] bg-[#E5E7EB] rounded-full flex justify-center items-center">
                <AiOutlineCloudUpload className="text-[24px] text-[rgba(32,32,32,0.8)]" />
              </div>
              <p className="text-[12px] text-gray-400 font-Inter ">
                <span className="text-[#E41272] font-bold tracking-[1.25px]">
                  CLICK TO UPLOAD
                </span>{" "}
                or drag and drop <br /> PNG, JPG (max. 800x400px)
              </p>
              <button className="uppercase text-[12px] tracking-[1.25px] text-[#E41272] font-bold font-Inter w-[107px] h-[48px] border-[1px] border-[#E41272] rounded-[40px]">
                browse
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

import React from "react";

/* Third party package */
import { useNavigate } from "react-router-dom";

// components
import { ageCalculator } from "../../../../../helper/utilities/ageCalculator";

const ModifyCaseStudyCard = ({ user, role }) => {
  /* Used to navigate to allServices page */
  const navigate = useNavigate();
  const age = ageCalculator(user?.dateOfBirth);

  // Route decision for different role
  let dynamicRoute = "";
  if (role === "lawyer") {
    dynamicRoute = `/lawyer/modifyCase/${user?._id}`;
  }
  if (role === "agent") {
    dynamicRoute = `/agent/modifyDeal/${user?._id}`;
  }
  if (role === "kazi") {
    dynamicRoute = `/kazi/modifyDeal/${user?._id}`;
  }

  return (
    <div
      style={{ boxShadow: "2px 2px 10px 2px rgba(0, 0, 0, 0.12)" }}
      className="bg-[#FFF0FE] rounded-lg font-inter font-medium text-2xl"
    >
      <div className="flex gap-6 pt-[31px] pl-[35px] text-[#000000]">
        <img
          className="w-[85px] h-[85px] rounded-full"
          src={user?.profilePhoto}
          alt="profile"
        />
        <div className="text-left">
          <h1 className="mb-4">
            {(user?.firstName ? user?.firstName : "") +
              " " +
              (user?.lastName ? user?.lastName : "")}
          </h1>
          <p className="font-normal text-xl">
            Age : {user?.age ? user?.age : age ? age : ""}
          </p>
        </div>
      </div>
      <div className="text-center mt-11 mb-[33px]">
        <button
          onClick={() => navigate(dynamicRoute)}
          className="py-[9px] px-[44px] rounded text-[16px] bg-gradient-to-b from-[#DF298C] to-[#B02BBE] text-white"
        >
          {role === "agent" && "Modify Deal Status"}
          {role === "kazi" && "Modify Deal Status"}
          {role === "lawyer" && "Modify Case Study"}
        </button>
      </div>
    </div>
  );
};

export default ModifyCaseStudyCard;

import React from "react";

// Third party package
import { BiUserCircle } from "react-icons/bi";
import { HiChatBubbleBottomCenterText } from "react-icons/hi2";
import { MdEdit } from "react-icons/md";
import { Link } from "react-router-dom";

const ViewEditProfileCard = ({ user }) => {
  return (
    <div
      className="rounded-[20px] w-96 py-8 shadow-md"
      style={{ boxShadow: "0px 4px 4px rgba(62, 73, 84, 0.04)" }}
    >
      <img
        className="w-36 h-36 rounded-full mx-auto"
        src={user?.profilePhoto}
        alt=""
      />
      <h1 className="mt-3 text-xl leading-8 font-medium text-center">
        {user?.firstName + " " + user?.lastName}
      </h1>
      <p className="text-[#A0A0A0] text-sm leading-5 text-center">
        {user?.userType}
      </p>
      <div className="text-[#E41272] text-2xl flex justify-center items-center gap-6 mt-9 mb-3">
        <Link
          to={`profile/${user?._id}`}
          className="p-4 bg-[#FFDFF4] rounded-full"
        >
          <BiUserCircle />
        </Link>
        <div className="p-4 bg-[#FFDFF4] rounded-full">
          <HiChatBubbleBottomCenterText />
        </div>
        <Link
          to={`profile/${user?._id}`}
          className="p-4 bg-[#FFDFF4] rounded-full"
        >
          <MdEdit />
        </Link>
      </div>
    </div>
  );
};

export default ViewEditProfileCard;

// configuration
import React from "react";

// third party packages
import { useNavigate } from "react-router-dom";
import { ageCalculator } from "../../../../helper/utilities/ageCalculator";

export const RegistrationRequestCard = ({ user }) => {
  const navigate = useNavigate();
  const { profilePhoto, dateOfBirth } = user || {};

  return (
    <>
      <div className="bg-[#301B6B] rounded-[8px] pl-[35px] py-[31px] max-w-[385px]">
        <div className="flex gap-x-[24px]">
          <img
            className="w-[85px] h-[85px] rounded-full"
            src={
              profilePhoto
                ? profilePhoto
                : "https://i.ibb.co/f4Vxd2k/Ellipse-18.png"
            }
            alt="profile"
          />
          <div className="flex flex-col text-left gap-y-[18px]">
            <h1 className="text-[#FFFFFF] text-[24px] leading-[29px] font-medium">
              {user?.firstName} {user?.lastName}
            </h1>
            {dateOfBirth && (
              <p className="text-[#FFFFFF] text-[20px] leading-[24px] font-medium">
                Age: {ageCalculator(dateOfBirth)}
              </p>
            )}
          </div>
        </div>
        <div className="flex items-center justify-center">
          <button
            className="w-[135px] h-[37px] mt-[20px] bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] text-white rounded-[4px]"
            onClick={() => navigate(`/user/registrationRequest/${user._id}`)}
          >
            View Request
          </button>
        </div>
      </div>
    </>
  );
};

import React from "react";

export const BadgeCard = ({ badge, callBadgeEdit, setCurrentBadgeDetailesForEdit }) => {
    const { name, icon, _id } = badge || {};
    return (
        <div className="w-[190px] h-[253px] shadow-[2px_2px_12px_2px_rgba(0,0,0,0.12)] rounded-[16px] bg-white py-[14px] px-[31px]">
            <div className="w-full flex justify-center">
                <img src={icon} alt="badge" className="w-[113px] h-[113px] rounded-full" />
            </div>
            <div className="mt-[15px] flex flex-col items-center justify-center gap-y-[12px]">
                <h3 className="text-[#000000] text-[20px] font-poppins text-center w-full">{name}</h3>
                <button
                    className="w-[82px] h-[32px] border-[1px] border-[#E41272] rounded-[37px] text-[#E41272] text-[14px] font-Montserrat font-bold"
                    onClick={() => {
                        callBadgeEdit(_id);
                        setCurrentBadgeDetailesForEdit(badge);
                    }}
                >
                    Edit
                </button>
            </div>
        </div>
    );
};

import React from "react";

// Third party packages
import { Outlet } from "react-router";
import useDocumentTitle from "../../../helper/utilities/useDocumentTitle";

// components
import UserNav from "./UserNav/UserNav";

export const User = () => {
    useDocumentTitle("Admin | User");
    return (
        <UserNav>
            <Outlet />
        </UserNav>
    );
};

// configuration
import { Route } from "react-router-dom";

// components
import AddCourse from "../../Admin__Portion/Components/Courses/AddCourse/AddCourse";
import RequireAuth from "../../helper/hooks/loginPersestency/RequireAuth";
import courseRoutes from "../Course/courseRoutes";

export const renderCourseRoutes = () => {
  return courseRoutes?.map((route, i) => {
    return (
      <Route
        key={i}
        path={route.path}
        element={<RequireAuth>{route.element}</RequireAuth>}
      >
        <Route index element={<AddCourse />} />;
        {route?.nestedRoutes?.map((route, i) => {
          return (
            <Route
              key={i}
              path={route.path}
              index={i === 1}
              element={<RequireAuth>{route.element}</RequireAuth>}
            ></Route>
          );
        })}
      </Route>
    );
  });
};

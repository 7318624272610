import React, { useEffect, useState } from "react";

// Third party package
import { AiOutlinePlus } from "react-icons/ai";
import { Oval } from "react-loader-spinner";

// Components
import { useCreateBadgeMutation } from "../../../Redux/features/Badge/badgeApi";
import { usePhotosUploadOnServerMutation } from "../../../Redux/features/fileUpload/fileUploadApi";
import Error from "../../Shared/components/UI/Error";

export const BadgeForm = ({ setRelavidateGetBadges }) => {
    // Hook variable declaration
    const [uploadedBadge, setUploadedBadge] = useState("");
    const [customErr, setCustomErr] = useState("");

    // Redux api
    const [photosUploadOnServer, { data: uploadedPhotos, isLoading: photoUploadLoading }] = usePhotosUploadOnServerMutation();
    const [createBadge, { isLoading, isSuccess }] = useCreateBadgeMutation();

    useEffect(() => {
        if (uploadedPhotos) {
            const badge = uploadedPhotos?.data[0]?.path;
            setUploadedBadge(badge);
        }
    }, [uploadedPhotos]);

    // Function declaration
    const badgeUploadHandler = e => {
        setCustomErr("");
        if (e.target.files) {
            const formData = new FormData();
            const badge = e.target.files[0];
            formData.append("image", badge);
            photosUploadOnServer(formData);
        }
    };

    const badgeSubmitHandler = async e => {
        e.preventDefault();
        let name = e.target.name.value;
        if (!uploadedBadge || name?.length===0) {
            return setCustomErr("Please upload photo and type a name");
        }
        const data = {
            name: name,
            icon: uploadedBadge,
            description: name,
        };
        await createBadge(data);
        e.target.reset();

        // reset form
    };

    useEffect(() => {
        if (isSuccess) {
            setUploadedBadge("");
            setRelavidateGetBadges(false);
            setTimeout(() => {
                setRelavidateGetBadges(true);
            }, 2000);
        }
    }, [isSuccess, setRelavidateGetBadges]);
    return (
        <form className="mt-[17px] flex flex-col items-start" onSubmit={badgeSubmitHandler}>
            <input onChange={()=>setCustomErr("")}
                type="text"
                name="name"
                className="w-full h-[60px] shadow-[2px_2px_10px_2px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none px-[12px] text-[#707276] text-[14px] font-OpenSans"
                placeholder="Badge Name"
            />
            <div className="flex items-center mt-[38px] gap-x-[22px]">
                <span className="text-[28px] text-[#000000] font-poppins">Icon</span>
                <label
                    htmlFor="badgeIcon"
                    className="flex items-center justify-center gap-x-[27px] bg-white w-[173px] h-[44px] shadow-[2px_2px_10px_2px_rgba(0,0,0,0.12)] rounded-[16px] cursor-pointer"
                >
                    {photoUploadLoading ? (
                        <Oval
                            height={20}
                            width={20}
                            color="#CC1A7A"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="oval-loading"
                            secondaryColor="#A72CC7"
                            strokeWidth={4}
                            strokeWidthSecondary={4}
                        />
                    ) : (
                        <>
                            {uploadedBadge ? (
                                <>
                                    <span className="text-green-400 font-semibold font-Inter">Added</span>
                                    <img src={uploadedBadge} alt="badge" className="w-[30px] h-[30px] rounded-full" />
                                </>
                            ) : (
                                <>
                                    Upload Photo
                                    <AiOutlinePlus />
                                </>
                            )}
                        </>
                    )}
                </label>
            </div>
            <input type="file" name="badgeIcon" id="badgeIcon" className="hidden" onChange={badgeUploadHandler} />
            <input
                type="submit"
                value="Done"
                className={`w-[158px] h-[55px] rounded-[96px] text-white bg-[linear-gradient(90deg,_#CC1A7A_0%,_#A72CC7_95.57%)] mt-[30px] text-[24px] font-bold font-Inter ${
                    isLoading ? "cursor-wait" : "cursor-pointer"
                }`}
            />
            {customErr && <Error {...{message:customErr}} />}
        </form>
    );
};

// Configuration
import React, { useState } from "react";

// Third party packages
import { Table } from "antd";

const LatestInvoiceTable = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  //   Dummy data
  const data = [
    {
      key: "1",
      invoiceNumber: "INV-24",
      customer: "John Doe",
      date: "28/2/2021",
      status: "paid",
    },
    {
      key: "2",
      invoiceNumber: "INV-29",
      customer: "Jim Green",
      date: "28/2/2021",
      status: "paid",
    },
    {
      key: "3",
      invoiceNumber: "INV-36",
      customer: "Joe Black",
      date: "28/2/2021",
      status: "pending",
    },
  ];

  //  Table Columns info
  const columns = [
    {
      title: "Invoice Number",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      render: (text) => (
        <span className="text-[#333333] text-sm leading-[18px] font-medium">
          {text}
        </span>
      ),
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      render: (text) => (
        <span className="text-[#98A4B5] text-sm leading-[18px]">{text}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <p
          className={`text-sm font-medium leading-[18px] w-24 text-center rounded-md py-1 capitalize ${
            text === "paid" && "text-[#8559E4] bg-[#FD6291] bg-opacity-10"
          } ${
            text === "pending" && "text-[#E82F83] bg-[#701FFD] bg-opacity-5"
          } `}
        >
          {text}
        </p>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text) => (
        <span className="text-[#98A4B5] text-sm leading-[18px]">{text}</span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: () => (
        <button className="text-white bg-[#98A4B5] rounded-md px-2 py-1">
          View
        </button>
      ),
    },
  ];

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <div className="max-w-[900px] mb-12 rounded-xl bg-white">
      <h1 className="text-left text-[#333333] text-2xl leading-8 font-bold pl-8 py-5">
        Latest Invoice
      </h1>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    </div>
  );
};

export default LatestInvoiceTable;

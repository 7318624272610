import React from "react";

// Third party package
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import EditQuizModule from "./EditQuizModule/EditQuizModule";

const EditCourseQuiz = ({ course }) => {
  const { Panel } = Collapse;
  return (
    <div>
      <div className="my-4 text-left">
        <Collapse
          bordered={false}
          defaultActiveKey={["1"]}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          collapsible="icon"
        >
          {course?.sections?.map((section) => (
            <Panel
              header={
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-xl font-medium">{section?.name}</p>
                  </div>
                  <div></div>
                </div>
              }
              key={section?._id}
            >
              <EditQuizModule {...{ section }} />
            </Panel>
          ))}
        </Collapse>
      </div>
    </div>
  );
};

export default EditCourseQuiz;

// configuration
import { Route } from "react-router-dom";

// components
import RequireAuth from "../../helper/hooks/loginPersestency/RequireAuth";
import packageRoutes from "../Package/packageRoute";

export const renderPackageRoutes = () => {
  return packageRoutes?.map((route, i) => {
    return (
      <Route
        key={i}
        path={route.path}
        element={<RequireAuth>{route.element}</RequireAuth>}
      >
        {/* <Route index element={<AddCourse />} />; */}
        {route?.nestedRoutes?.map((route, i) => {
          return (
            <Route
              key={i}
              path={route.path}
              // index={i === 1}
              element={<RequireAuth>{route.element}</RequireAuth>}
            >
              {route?.nestedRoutes?.map((route, i) => {
                return (
                  <Route
                    key={i}
                    path={route.path}
                    // index={i === 1}
                    element={<RequireAuth>{route.element}</RequireAuth>}
                  ></Route>
                );
              })}
            </Route>
          );
        })}
      </Route>
    );
  });
};

// Configuration
import React, { useEffect } from "react";

// Third party package
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import {
  useCreateModuleMutation,
  useUpdateModuleMutation,
} from "../../../../../../Redux/features/Course/courseApi";

// Components
import Error from "../../../../../Shared/components/accordion/UI/Error/Error";

const AddModuleForm = ({
  id,
  setModuleFormVisible,
  moduleForEdit,
  setModuleEditFormVisible,
  formType,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const [createModule, { data: moduleResponse, isLoading, error }] =
    useCreateModuleMutation();
  const [
    updateModule,
    {
      data: moduleEditResponse,
      isLoading: moduleEditLoading,
      error: moduleEditError,
    },
  ] = useUpdateModuleMutation();

  const onSubmit = async (data) => {
    if (formType === "editForm") {
      data.name = data.name || moduleForEdit?.name;
      data.description = data.description || moduleForEdit?.description;
      data.content = Number(data.content) || moduleForEdit?.content;
      await updateModule({ id: moduleForEdit?._id, data: data });
    } else {
      data.content = Number(data.content);
      data.section = id;
      await createModule(data);
    }
  };

  useEffect(() => {
    if (moduleResponse) {
      toast.success("Module Created Successfully");
      reset();
      setModuleFormVisible(false);
    }
    if (moduleEditResponse) {
      toast.success("Module Edited Successfully");
      reset();
      setModuleEditFormVisible(false);
    }
    if (error) {
      toast.error(error?.data?.message);
      reset();
      setModuleFormVisible(false);
    }
    if (moduleEditError) {
      toast.error("Something went wrong. Please login or try again later");
      reset();
      setModuleEditFormVisible(false);
    }
  }, [
    moduleResponse,
    error,
    reset,
    setModuleFormVisible,
    setModuleEditFormVisible,
    moduleEditResponse,
    moduleEditError,
  ]);

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col items-start w-full gap-y-5"
      >
        {/* Module Name */}
        <div className="w-full flex flex-col">
          <label className="text-left mb-1 text-[12px] font-bold text-[#707276] ml-[5px]">
            Module Name
          </label>
          <input
            {...register(
              "name",
              formType !== "editForm" && {
                required: "Module Name is required",
              }
            )}
            className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-4 px-[12px]"
            type="text"
            placeholder="Module Name"
          />
          {errors?.name && <Error message={errors?.name?.message} />}
        </div>
        {/* Module description */}
        <div className="w-full flex flex-col">
          <label className="text-left mb-1 text-[12px] font-bold text-[#707276] ml-[5px]">
            Description
          </label>
          <textarea
            {...register(
              "description",
              formType !== "editForm" && {
                required: "Description is required",
              }
            )}
            className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-2 px-[12px]"
            type="text"
            placeholder="Description"
          />
          {errors?.description && (
            <Error message={errors?.description?.message} />
          )}
        </div>
        {/* Number of content */}
        <div className="w-full flex flex-col">
          <label className="text-left mb-1 text-[12px] font-bold text-[#707276] ml-[5px]">
            Number of Content
          </label>
          <input
            {...register(
              "content",
              formType !== "editForm"
                ? {
                    required: "Number of content is required",
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Input must be a number",
                    },
                  }
                : {
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Input must be a number",
                    },
                  }
            )}
            className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-4 px-[12px]"
            type="number"
            placeholder="Number of Content"
          />
          {errors?.content && <Error message={errors?.content?.message} />}
        </div>

        <input
          type="submit"
          value={isLoading || moduleEditLoading ? "Loading..." : "Submit"}
          className="py-2 px-8 rounded-md font-Inter bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] text-white font-medium text-lg cursor-pointer hover:bg-[linear-gradient(166deg,rgb(148,45,217)_0%,rgb(242,40,118)_100%)]"
        />
      </form>
    </div>
  );
};

export default AddModuleForm;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CancelIcon from "@mui/icons-material/Cancel";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  addOrRemoveProducts,
  decrementQuantity,
  incrementQuantity,
} from "../../../../../Redux/features/Packages/shopPackageSlice";

const ProductCard = ({ item, useCase }) => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state?.shopPackage?.products);

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden my-1 mx-auto">
      <div className="sm:flex sm:items-center px-6 py-4">
        <img
          className="block mx-auto sm:mx-0 sm:flex-shrink-0 w-24 h-24 rounded-full"
          src={item?.photos[0]?.url}
          alt="Card_image"
        />
        <div className="w-full mt-4 sm:mt-0 sm:ml-4 text-center sm:text-left">
          <div className="flex justify-between w-full">
            <h2 className="text-xl font-bold text-gray-900">{item?.name}</h2>
            <div
              className={`flex w-[230px] ${
                useCase !== "cancel" ? "justify-end" : "justify-between"
              }`}
            >
              {useCase === "cancel" && (
                <div className="flex items-center space-x-2">
                  <button
                    onClick={() => dispatch(decrementQuantity(item._id))}
                    className="px-3 py-1 bg-gray-200 rounded"
                    type="button"
                  >
                    -
                  </button>
                  <span className="shadow-inner border w-8 py-1 flex justify-center items-center rounded">
                    {item?.quantity}
                  </span>
                  <button
                    onClick={() => dispatch(incrementQuantity(item._id))}
                    className="px-3 py-1 bg-gray-200 rounded"
                    type="button"
                  >
                    +
                  </button>
                </div>
              )}
              <span
                onClick={() => dispatch(addOrRemoveProducts(item))}
                className="text-xl text-[#e41272] cursor-pointer"
              >
                {useCase === "cancel" ? (
                  <CancelIcon fontSize="large" />
                ) : products?.find((product) => product?._id === item?._id) ? (
                  <CheckBoxIcon fontSize="large" />
                ) : (
                  <CheckBoxOutlineBlankIcon fontSize="large" />
                )}
              </span>
            </div>
          </div>

          <p className="text-sm font-medium text-gray-600">{item?.category}</p>
          <p className="text-gray-700 mt-2">BDT {item?.price}</p>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;

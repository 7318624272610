// Configuration
import React from "react";

// Third party packages
import { Collapse } from "antd";

// Components
import Contents from "./Contents";
const { Panel } = Collapse;

const Modules = ({ modules }) => {
  return (
    <div>
      {modules?.map((module) => {
        return (
          <Collapse
            accordion
            style={{ width: "100%", marginBottom: "12px" }}
            key={module?._id}
          >
            <Panel header={module?.name}>
              <div className="w-full flex flex-col">
                <Contents contents={module?.contents} />
              </div>
            </Panel>
          </Collapse>
        );
      })}
    </div>
  );
};
export default Modules;

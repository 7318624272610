// configuration
import { Route } from "react-router-dom";

// components
import supportRoutes from "../SupportRoutes/supportRoutes";
import RequireAuth from "../../helper/hooks/loginPersestency/RequireAuth";
import SupportTicket from "../../Admin__Portion/Components/Support/SupportTicket/SupportTicket";

export const renderSupportRoutes = () => {
  return supportRoutes?.map((route, i) => {
    return (
      <Route
        key={i}
        path={route.path}
        element={<RequireAuth>{route.element}</RequireAuth>}
      >
        <Route index element={<SupportTicket />} />;
        {route?.nestedRoutes?.map((route, i) => {
          return (
            <Route
              key={i}
              path={route.path}
              index={i === 1}
              element={<RequireAuth>{route.element}</RequireAuth>}
            ></Route>
          );
        })}
      </Route>
    );
  });
};

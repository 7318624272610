// configuration
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

// Third party packages
import { Toaster } from "react-hot-toast";
import { AiOutlineWarning } from "react-icons/ai";

// components
import { useGetSingleOrderQuery } from "../../../../../Redux/features/Shop/shopApi";
import { OvalLoader } from "../../../../Shared/Loader/OvalLoader";
import { OnGoingOrderBillingContainer } from "./BillingDetailes/OnGoingOrderBillingContainer";
import { BillingSummary } from "./BillingSummary/BillingSummary";
import { OnGoingOrderBy } from "./OrderInfoContainer/OnGoingOrderBy";
import { OnGoingOrderDetailesContainer } from "./OrderInfoContainer/OnGoingOrderDetailesContainer";
import useDocumentTitle from "../../../../../helper/utilities/useDocumentTitle";

export const OnGoingOrderMark = () => {
  const { id } = useParams();
  const { data, isLoading, isError } = useGetSingleOrderQuery(id);

  // Page Title
  useDocumentTitle("Admin | Order");

  // Scroll to top
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="mt-[70px] px-[45px]">
      {isLoading ? (
        <OvalLoader title={"Loading..."} />
      ) : isError ? (
        <div className="flex flex-col items-center justify-center my-[100px]">
          <AiOutlineWarning className="text-[42px] text-gray-400" />
          <p className="mt-[10px] text-[16px] font-Inter font-medium text-gray-500">
            An Error occurred! <br /> Please login or try again later!
          </p>
        </div>
      ) : (
        data?.success === true && (
          <>
            <OnGoingOrderBy {...{ data }} />
            <OnGoingOrderDetailesContainer {...{ data }} />
            <OnGoingOrderBillingContainer {...{ data }} />
            <BillingSummary {...{ data }} />
          </>
        )
      )}
      <Toaster />
    </div>
  );
};

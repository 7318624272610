// Configuration
import React, { useState } from "react";

// Third party packages
import Pagination from "@mui/material/Pagination";
import { BsCircleFill } from "react-icons/bs";

// Components
import liveLinkGenerator from "../../../../../helper/utilities/liveLinkGenerator";
import { useGetAllServiceByIdQuery } from "../../../../../Redux/features/Services/servicesApi";

export const AllServicesOfReviewsRatings = ({ userID, setServiceID, setUser }) => {
    const [page, setPage] = useState(1);
    const { data } = useGetAllServiceByIdQuery({
        id: userID,
        limit: 3,
        page: page,
    });
    const { services, total } = data || {};
    const pages = Math.ceil(total / 3);
    return (
        <div className="mt-[80px]">
            <div className="w-full flex justify-between px-[16px] py-[40px] mx-auto">
                <h1 className="text-[32px] font-Manrope font-bold">All Services</h1>
                <button
                    className="bg-[#E41272] px-[12px] py-[4px] rounded-[4px] text-white cursor-pointer hover:bg-[#f02682]"
                    onClick={() => {
                        setUser(null);
                        setServiceID("");
                    }}
                >
                    Reset
                </button>
            </div>
            <div className="gap-y-[20px]">
                {services?.map(service => {
                    let { role, title, photos, description, price, _id: serviceID } = service || {};
                    description = description?.length > 144 ? description.slice(0, 144) + "..." : description;
                    const { firstName, lastName, role: userRole } = role || {};
                    const name = `${firstName} ${lastName}`;
                    return (
                        <div
                            style={{ background: "linear-gradient(180deg, #EEE6FF 0%, #FFDAE3 0.01%, #F4E6FF 5.73%, #FFF2E6 100%)" }}
                            className="pt-6 pl-6 pr-9 pb-2 mb-6 rounded-xl font-SourceCodePro font-semibold text-base w-[617px] mx-auto"
                            key={serviceID}
                        >
                            <img src={liveLinkGenerator(photos[0])} className="w-full h-[258px] rounded-[12px]" alt="service" />
                            <div className="text-left text-sm">
                                <h1 className="font-medium text-lg text-[#000000] my-4">{title}</h1>
                                <p className="font-normal text-[#666666]">{description}</p>
                                <h5 className="text-[#000000] my-4">{name}</h5>
                            </div>
                            <div className="flex justify-between">
                                <div className="flex items-center gap-[6px]">
                                    <BsCircleFill className="text-base text-[#DEDEDE]" />
                                    <p className="font-normal text-sm text-[#666666]">{userRole}</p>
                                </div>
                                <div className="flex items-center gap-[15px]">
                                    <p className="text-base text-[#000000]">৳{price}</p>
                                    <button
                                        className="bg-[#E41272] rounded py-[5px] px-[22px] text-sm text-[#FFFFFF]"
                                        onClick={() => setServiceID(serviceID)}
                                    >
                                        Get Reviews
                                    </button>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            {pages > 1 && (
                <div className="mt-[80px]">
                    <Pagination count={pages} variant="outlined" color="secondary" onChange={(e, value) => setPage(value)} />
                </div>
            )}
        </div>
    );
};

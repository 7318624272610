import React from "react";

const SingleWalletCalculation = ({ data }) => {
  return (
    <div className="p-4 grid grid-cols-4 gap-3">
      <div className="bg-[#f22876] px-2 py-4 rounded">
        <p className="font-semibold text-white">Total Deposit</p>
        <p className="text-white text-4xl font-bold ">
          {data?.data?.totalDeposit}
        </p>
      </div>
      <div className="bg-[#f22876] px-2 py-4 rounded">
        <p className="font-semibold text-white">Total Point</p>
        <p className="text-white text-4xl font-bold ">{data?.data?.point}</p>
      </div>
      <div className="bg-[#f22876] px-2 py-4 rounded">
        <p className="font-semibold text-white">Point Spent</p>
        <p className="text-white text-4xl font-bold ">
          {data?.data?.spentPoint}
        </p>
      </div>
      <div className="bg-[#f22876] px-2 py-4 rounded">
        <p className="font-semibold text-white">Point Purchased</p>
        <p className="text-white text-4xl font-bold ">
          {data?.data?.pointPurchased}
        </p>
      </div>
    </div>
  );
};

export default SingleWalletCalculation;

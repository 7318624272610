import React, { useEffect } from "react";
import { useFieldArray } from "react-hook-form";

// Components
import Error from "../../../../Shared/components/accordion/UI/Error/Error";
import { usePhotosUploadOnServerMutation } from "../../../../../Redux/features/fileUpload/fileUploadApi";
import { toast } from "react-hot-toast";
import { Oval } from "react-loader-spinner";
import { AiOutlinePlus } from "react-icons/ai";

const InstructorInfoField = ({
  register,
  errors,
  control,
  formType,
  instructorImageExtra,
  setInstructorImageExtra,
  instructorImage,
  setInstructorImage,
  customError,
}) => {
  const { fields, append, remove } = useFieldArray({
    name: "instructor",
    control,
  });

  // Redux api
  const [
    photosUploadOnServer,
    {
      data: imageUploadResponse,
      isLoading: imageUploadLoading,
      error: imageUploadError,
    },
  ] = usePhotosUploadOnServerMutation();

  const [
    addedPhotosUploadOnServer,
    {
      data: addedImageUploadResponse,
      isLoading: addedImageUploadLoading,
      error: addedImageUploadError,
    },
  ] = usePhotosUploadOnServerMutation();

  const instructorImageUploadHandler = (e) => {
    if (e.target.files) {
      const formData = new FormData();
      const image = e.target.files[0];
      formData.append("image", image);
      photosUploadOnServer(formData);
    }
  };

  const addedInstructorImageUploadHandler = (e) => {
    if (e.target.files) {
      const formData = new FormData();
      const image = e.target.files[0];
      formData.append("image", image);
      addedPhotosUploadOnServer(formData);
    }
  };

  useEffect(() => {
    if (imageUploadResponse) {
      const image = imageUploadResponse?.data[0]?.path;
      setInstructorImage(image);
    }
    if (imageUploadError) {
      toast.error(
        <p>
          Failed to upload image. <br /> Please login first or try again later.
        </p>
      );
    }
    if (addedImageUploadError) {
      toast.error(
        <p>
          Failed to upload image. <br /> Please login first or try again later.
        </p>
      );
    }
    if (addedImageUploadResponse) {
      setInstructorImageExtra((prevState) => [
        ...prevState,
        addedImageUploadResponse?.data[0]?.path,
      ]);
    }
  }, [
    imageUploadResponse,
    setInstructorImage,
    imageUploadError,
    addedImageUploadResponse,
    setInstructorImageExtra,
    addedImageUploadError,
  ]);

  return (
    <div className="w-full">
      <div>
        <h1 className="text-[#32353B] text-xl font-poppins font-medium text-left">
          Instructor
        </h1>
        <hr className="mt-2 mb-4" />
      </div>
      <div className="grid grid-cols-2 gap-4">
        {/* Instructor Name */}
        <div className="w-full flex flex-col">
          <label className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]">
            Instructor Name
          </label>
          <input
            {...register(
              "nameInstructor",
              formType === "addForm" && {
                required: "Instructor Name is required",
              }
            )}
            className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-4 px-[12px]"
            type="text"
            placeholder="Instructor Name"
          />
          {errors?.nameInstructor && (
            <Error message={errors?.nameInstructor?.message} />
          )}
        </div>

        {/* Instructor Designation */}
        <div className="w-full flex flex-col">
          <label className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]">
            Designation
          </label>
          <input
            {...register(
              "designationInstructor",
              formType === "addForm" && {
                required: "Designation is required",
              }
            )}
            className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-4 px-[12px]"
            type="text"
            placeholder="Designation"
          />
          {errors?.designationInstructor && (
            <Error message={errors?.designationInstructor?.message} />
          )}
        </div>

        {/* Instructor img */}
        <div className="w-full flex flex-col">
          <label className="text-left mb-3 text-[12px] font-bold text-[#707276] ml-[5px]">
            Instructor Image
          </label>
          <div className="flex justify-start items-center gap-x-[22px] w-full">
            <label
              htmlFor="instructorImage"
              className="flex items-center justify-start pl-6 gap-x-[27px] bg-white w-full h-[60px] shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[4px] cursor-pointer"
            >
              {imageUploadLoading ? (
                <Oval
                  height={20}
                  width={20}
                  color="#CC1A7A"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#A72CC7"
                  strokeWidth={4}
                  strokeWidthSecondary={4}
                />
              ) : (
                <>
                  {instructorImage ? (
                    <>
                      <span className="text-green-400 font-semibold font-Inter">
                        Image Added
                      </span>
                      <img
                        src={instructorImage}
                        alt="instructorImage"
                        className="w-[30px] h-[30px] rounded-full"
                      />
                    </>
                  ) : (
                    <p className="flex items-center gap-x-2 text-[#707276] text-sm">
                      Upload Image
                      <AiOutlinePlus />
                    </p>
                  )}
                </>
              )}
            </label>
          </div>
          <input
            type="file"
            name="instructorImage"
            id="instructorImage"
            className="hidden"
            onChange={instructorImageUploadHandler}
          />
          {formType === "addForm" && customError[0].instructorImageError && (
            <Error message={customError[0].instructorImageError} />
          )}
        </div>

        {/* Instructor bio */}
        <div className="w-full flex flex-col">
          <label className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]">
            About Instructor
          </label>
          <textarea
            {...register(
              "bioInstructor",
              formType === "addForm" && {
                required: "Instructor bio is required",
              }
            )}
            className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-2 px-[12px]"
            type="text"
            placeholder="Instructor Bio"
          />
          {errors?.bioInstructor && (
            <Error message={errors?.bioInstructor?.message} />
          )}
        </div>
      </div>
      {fields.map((field, index) => {
        return (
          <div key={field.id}>
            <hr className="mt-4" />
            <div className="grid grid-cols-2 gap-4 mt-2">
              {/* Instructor Name */}
              <div className="w-full flex flex-col">
                <label className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]">
                  Instructor Name
                </label>
                <input
                  {...register(`instructor.${index}.name`, {
                    required: "Instructor Name is required",
                  })}
                  className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-4 px-[12px]"
                  type="text"
                  placeholder="Instructor Name"
                />
                {errors?.name && <Error message={errors?.name?.message} />}
              </div>

              {/* Instructor Designation */}
              <div className="w-full flex flex-col">
                <label className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]">
                  Designation
                </label>
                <input
                  {...register(`instructor.${index}.designation`, {
                    required: "Designation is required",
                  })}
                  className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-4 px-[12px]"
                  type="text"
                  placeholder="Designation"
                />
                {errors?.designation && (
                  <Error message={errors?.designation?.message} />
                )}
              </div>

              {/* Instructor img */}
              <div className="w-full flex flex-col">
                <label className="text-left mb-3 text-[12px] font-bold text-[#707276] ml-[5px]">
                  Instructor Image
                </label>
                <div className="flex justify-start items-center gap-x-[22px] w-full">
                  <label
                    htmlFor="addedInstructorImage"
                    className="flex items-center justify-start pl-6 gap-x-[27px] bg-white w-full h-[60px] shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[4px] cursor-pointer"
                  >
                    {addedImageUploadLoading ? (
                      <Oval
                        height={20}
                        width={20}
                        color="#CC1A7A"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#A72CC7"
                        strokeWidth={4}
                        strokeWidthSecondary={4}
                      />
                    ) : (
                      <>
                        {instructorImageExtra[index] ? (
                          <>
                            <span className="text-green-400 font-semibold font-Inter">
                              Image Added
                            </span>
                            <img
                              src={instructorImageExtra[index]}
                              alt="instructorImage"
                              className="w-[30px] h-[30px] rounded-full"
                            />
                          </>
                        ) : (
                          <p className="flex items-center gap-x-2 text-[#707276] text-sm">
                            Upload Image
                            <AiOutlinePlus />
                          </p>
                        )}
                      </>
                    )}
                  </label>
                </div>
                <input
                  {...register(`instructor.${index}.image`)}
                  type="file"
                  name="addedInstructorImage"
                  id="addedInstructorImage"
                  className="hidden"
                  onChange={addedInstructorImageUploadHandler}
                />
              </div>

              {/* Instructor bio */}
              <div className="w-full flex flex-col">
                <label className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]">
                  About Instructor
                </label>
                <textarea
                  {...register(`instructor.${index}.bio`, {
                    required: "Instructor bio is required",
                  })}
                  className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-2 px-[12px]"
                  type="text"
                  placeholder="Instructor Bio"
                />
                {errors?.bio && <Error message={errors?.bio?.message} />}
              </div>
              <button
                className="p-3 text-sm text-center font-medium bg-red-100 text-red-500 rounded-lg"
                type="button"
                onClick={() => remove(index)}
              >
                Remove
              </button>
            </div>
          </div>
        );
      })}
      <button
        type="button"
        className="py-3 px-10 text-sm text-center font-medium text-gray-400 bg-gray-100 rounded-lg mt-4"
        onClick={() => {
          append({
            name: "",
            designation: "",
            image: "",
            bio: "",
          });
        }}
      >
        + Add More Instructor
      </button>
    </div>
  );
};

export default InstructorInfoField;

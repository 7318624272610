// configuration
import React from "react";

// Third party packages
import { OnGoingOrderBillingDetailes } from "./OnGoingOrderBillingDetailes";
import { OnGoingOrderShippingContainer } from "./OnGoingOrderShippingContainer";

export const OnGoingOrderBillingContainer = ({ data }) => {
  const { billingInfo, shippingInfo } = data?.singleOrder || {};
  return (
    <div className="w-full h-auto bg-white shadow-[2px_2px_10px_2px_rgba(0,0,0,0.12)] rounded-[12px] mt-[27px] px-[39px] py-[31px]">
      <OnGoingOrderBillingDetailes {...{ billingInfo }} />
      <OnGoingOrderShippingContainer {...{ shippingInfo }} />
    </div>
  );
};

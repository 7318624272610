import getCookie from "../../../helper/cookies/getCookie";
import { apiSlice } from "../../api/apiSlice";

export const referralApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getReferPoint: builder.query({
      query: () => ({
        url: "/refer/get-refer-point",
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      providesTags:["referral"],
      keepUnusedDataFor: 0,
    }),
    getAllRefers: builder.query({
      query: ({page,limit}) => ({
        url: `/refer/get-all-refer?page=${page}&limit=${limit}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      // keepUnusedDataFor: 0,
    }),
    
    updateReferPoint: builder.mutation({
      query: ({id,data}) => ({
        url: `/refer/update-refer-point/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      invalidatesTags:["referral"],
    }),
    deleteReferPoint: builder.mutation({
      query: ({id,data}) => ({
        url: `/refer/delete-refer-point/${id}`,
        method: "DELETE",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body:data
      }),
      invalidatesTags:["referral"],
    }),
  }),
});

export const {
  useDeleteReferPointMutation,
  useGetReferPointQuery,
  useUpdateReferPointMutation,
  useGetAllRefersQuery
  
} = referralApi;

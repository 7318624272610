// configuration
import { Route } from "react-router-dom";

// Components
import RequireAuth from "../../helper/hooks/loginPersestency/RequireAuth";
import kaziRoutes from "../Kazi/kaziRoutes";
import AddKazi from "../../Admin__Portion/Components/Kazi/AddKazi/AddKazi";

export const renderKaziRoutes = () => {
  return kaziRoutes.map((route) => {
    return (
      <Route
        key={route.id}
        path={route.path}
        element={<RequireAuth>{route.element}</RequireAuth>}
      >
        <Route index element={<AddKazi />} />
        {route?.nestedRoutes?.map((route, i) => {
          return (
            <Route
              key={route.id}
              path={route.path}
              element={<RequireAuth>{route.element}</RequireAuth>}
            >
              {route.nestedRoutes?.map((route) => (
                <Route
                  key={route.id}
                  path={route.path}
                  element={<RequireAuth>{route.element}</RequireAuth>}
                >
                  {route.nestedRoutes?.map((route) => (
                    <Route
                      key={route.id}
                      path={route.path}
                      element={<RequireAuth>{route.element}</RequireAuth>}
                    >
                      {route.nestedRoutes?.map((route) => (
                        <Route
                          key={route.id}
                          path={route.path}
                          element={<RequireAuth>{route.element}</RequireAuth>}
                        />
                      ))}
                    </Route>
                  ))}
                </Route>
              ))}
            </Route>
          );
        })}
      </Route>
    );
  });
};

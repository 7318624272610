import React from "react";
import { Outlet } from "react-router-dom";
import useDocumentTitle from "../../../helper/utilities/useDocumentTitle";
import ShopNav from "./ShopNav/ShopNav";

export const Shop = () => {
    useDocumentTitle("Admin | Shop");
    return (
        <ShopNav>
            <Outlet />
        </ShopNav>
    );
};

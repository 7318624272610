import getCookie from "../../../helper/cookies/getCookie";
import { apiSlice } from "../../api/apiSlice";

export const serviceApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllServiceById: builder.query({
      query: ({ id, limit, page }) => ({
        url: `/service/admin/all/${id}?page=${page}&limit=${limit}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
    }),
    getSingleServiceById: builder.query({
      query: (id) => ({
        url: `/service/admin/${id}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      providesTags: ["SingleService"],
      keepUnusedDataFor: 600,
    }),
    updateSingleService: builder.mutation({
      query: ({ id, data }) => ({
        url: `/service/admin/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["SingleService"],
    }),
    getAllReviews: builder.query({
      query: ({ id, page, limit }) => ({
        url: `/service/admin/review/all/${id}?page=${page}&limit=${limit}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      keepUnusedDataFor: 0,
      providesTags: ["AllReviews"],
    }),
    getAllCases: builder.query({
      query: ({ id, page, limit }) => ({
        url: `/service-study/admin/all?id=${id}&page=${page}&limit=${limit}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      keepUnusedDataFor: 0,
    }),
    updateCaseStudy: builder.mutation({
      query: ({ id, data }) => ({
        url: `/service-study/admin/update/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
    }),
    getSingleCaseById: builder.query({
      query: (id) => ({
        url: `/service-study/admin/${id}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
    }),
  }),
});

export const {
  useGetAllServiceByIdQuery,
  useGetSingleServiceByIdQuery,
  useUpdateSingleServiceMutation,
  useGetAllReviewsQuery,
  useGetAllCasesQuery,
  useUpdateCaseStudyMutation,
  useGetSingleCaseByIdQuery,
} = serviceApi;

import React, { useEffect } from "react";

// Third party package
import { toast } from "react-hot-toast";

// Components
import { useUpdateOrderStatusMutation } from "../../../../../../../Redux/features/Shop/shopApi";

const CancelBtn = ({ data: order, setCancelledSuccess, deliveredSuccess }) => {
  const [updateOrderStatus, { data: response, isLoading, error }] =
    useUpdateOrderStatusMutation();

  const handleUpdateCancelStatus = async () => {
    const data = {
      status: "Cancelled",
    };
    await updateOrderStatus({ id: order?.singleOrder?._id, data: data });
  };

  useEffect(() => {
    if (response) {
      toast.success("Order Cancelled Successfully");
      setCancelledSuccess(true);
    }
    if (error) {
      toast.error(
        <p>
          Something Went Wrong
          <br /> Please try again later
        </p>
      );
    }
  }, [response, error, setCancelledSuccess]);

  return (
    <div className={`${deliveredSuccess === true && "hidden"}`}>
      {order?.singleOrder?.orderStatus !== "Delivered" && (
        <button
          className="w-[184px] h-[80px] border-[1px] border-[#E41272] rounded-[100px] uppercase text-[24px] tracking-[1.25px] font-semibold font-Inter text-[#E41272]"
          onClick={() => handleUpdateCancelStatus()}
          disabled={response || order?.singleOrder?.orderStatus === "Cancelled"}
        >
          {isLoading
            ? "Loading..."
            : response
            ? "Cancelled"
            : order?.singleOrder?.orderStatus === "Cancelled"
            ? "Cancelled"
            : "Cancel"}
        </button>
      )}
    </div>
  );
};

export default CancelBtn;

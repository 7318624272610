import React from "react";
import moment from "moment";
export const MessageLeft = ({ message ,receiver}) => {
    return (
        <div className="ml-[15px]">
            <div className="flex mb-[17px]">
                <img
                    className=" h-[58px] mr-[26px] rounded-full"
                    src={receiver?.profilePhoto}
                    alt="Profile Pic"
                />
                <div className="w-[500px]">
                    <p className="text-sm text-left text-[#000000] rounded-b-[20px] rounded-r-[20px] bg-[#F2F2F2] pl-[27px] pr-[22px] pt-[9px] pb-4">
                        {message?.message}
                    </p>
                    <p className="text-xs text-left text-[#969696] mt-3">{moment(message?.time).format('LT')}</p>
                </div>
            </div>
        </div>
    );
};

// configuration
import React from "react";

export const BillingSummaryRight = ({ data }) => {
  return (
    <div className="right_side pr-[47px]">
      <div className="w-full flex justify-between items-center">
        <h3 className="text-[16px] font-bold font-OpenSans text-[#000000]">
          Grand Total
        </h3>
        <h3 className="text-[16px] font-bold font-OpenSans text-[#000000]">
          BDT <span>{data?.singleOrder?.totalPrice || ""}</span>
        </h3>
      </div>
      <div className="max-w-[333px] mx-auto mt-[19px]">
        <p className="text-[12px] font-normal font-OpenSans text-[#828282] text-left">
          Order Comment
        </p>
        <p className="text-[#000000] text-[16px] font-normal font-OpenSans text-left mt-[6px]">
          Lorem ipsum dolor sit amet consectetur. Aenean donec nunc eu amet
          tincidunt suspendisse augue lorem neque. Enim hac.
        </p>
        <button className="w-full h-[40px] flex justify-center items-center text-white bg-[linear-gradient(137.27deg,_#33C04A_19.41%,_#0E881A_65.49%)] shadow-[0px_4px_4px_rgba(14,53,191,0.25)] rounded-[6px] mt-[37px] text-[16px] font-bold font-OpenSans">
          User paid BDT {data?.singleOrder?.totalPrice || ""} via Bkash
        </button>
      </div>
    </div>
  );
};

import React from "react";
import { Outlet } from "react-router-dom";
import KaziPackageNav from "./KaziPackageNav/KaziPackageNav";

const KaziPackage = () => {
  return (
    <KaziPackageNav>
      <Outlet />
    </KaziPackageNav>
  );
};

export default KaziPackage;

// Configuration
import React, { useState } from "react";

// Third party package
import { Button } from "@mui/material";
import { pink } from "@mui/material/colors";
import { Collapse, Modal } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";

// Components
import AddQuizForm from "./AddQuizForm/AddQuizForm";
import AddQuizQuestionContainer from "../AddQuizQuestionContainer/AddQuizQuestionContainer";

const AddQuizContent = ({ module }) => {
  const [quizFormVisible, setQuizFormVisible] = useState(false);

  const handleQuizFormCancel = () => {
    setQuizFormVisible(false);
  };

  const { Panel } = Collapse;

  return (
    <div className="px-6">
      {/* show all quiz */}
      <div className="mb-4">
        <Collapse
          bordered={false}
          defaultActiveKey={["1"]}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
        >
          {module?.quiz?.map((content) => (
            <Panel
              header={
                <div>
                  <p className="text-xl font-medium">{content?.name}</p>
                  <div className="flex items-center gap-x-4 font-Inter font-medium mt-1">
                    <p>Time: {content?.timeCount}min</p>
                    <p>Passing Score: {content?.passingScore}</p>
                  </div>
                </div>
              }
              key={content?._id}
            >
              <AddQuizQuestionContainer {...{ content }} />
            </Panel>
          ))}
        </Collapse>
      </div>
      {/* Add quiz button */}
      {module?.quiz?.length === 0 && (
        <div>
          <Button
            onClick={() => setQuizFormVisible(true)}
            sx={{
              color: pink[500],
              borderColor: pink[500],
            }}
            variant="outlined"
          >
            Add Quiz
          </Button>
        </div>
      )}

      {/* Add quiz form modal */}
      <Modal
        title={<p className="text-xl">Add Quiz</p>}
        open={quizFormVisible}
        onCancel={handleQuizFormCancel}
        footer={null}
      >
        <AddQuizForm {...{ id: module?._id, setQuizFormVisible }} />
      </Modal>
    </div>
  );
};

export default AddQuizContent;

import AddKazi from "../../Admin__Portion/Components/Kazi/AddKazi/AddKazi";
import AssignKazi from "../../Admin__Portion/Components/Kazi/Assign_Kazi/AssignKazi";
import { Kazi } from "../../Admin__Portion/Components/Kazi/KaziNav/Kazi";
import { KaziPayment } from "../../Admin__Portion/Components/Kazi/KaziPayment/KaziPayment";
import ModifyDealHistoryForKazi from "../../Admin__Portion/Components/Kazi/ModifyDealHistory/ModifyDealHistoryForKazi";
import { ModifyKaziProfile } from "../../Admin__Portion/Components/Kazi/ModifyKaziProfile/ModifyKaziProfile";
import ModifyKaziProfileContainer from "../../Admin__Portion/Components/Kazi/ModifyKaziProfile/ModifyKaziProfileContainer/ModifyKaziProfileContainer";
import ModifyKaziSingleProfileDetails from "../../Admin__Portion/Components/Kazi/ModifyKaziProfile/ModifyKaziSingleProfileDetails/ModifyKaziSingleProfileDetails";
import { ModifyReviewRatingsForKazi } from "../../Admin__Portion/Components/Kazi/ModifyReviewRatingsForKazi/ModifyReviewRatingsForKazi";
import KaziSingleProfileDetails from "../../Admin__Portion/Components/Kazi/ViewAllKazi/KaziSingleProfileDetails/KaziSingleProfileDetails";
import ViewAllKazi from "../../Admin__Portion/Components/Kazi/ViewAllKazi/ViewAllKazi";
import ViewAllKaziContainer from "../../Admin__Portion/Components/Kazi/ViewAllKazi/ViewAllKaziContainer";
import ViewRegistrationRequestForKazi from "../../Admin__Portion/Components/Kazi/ViewRegistrationRequest/ViewRegistrationRequestForKazi";
import AllServices from "../../Admin__Portion/Components/Lawyer/ModifyCaseStudy/AllServices/AllServices";
import ModifyCaseStudy from "../../Admin__Portion/Components/Lawyer/ModifyCaseStudy/ModifyCaseStudy";
import SingleUserCases from "../../Admin__Portion/Components/Lawyer/ModifyCaseStudy/SingleUserCases/SingleUserCases";
import ViewRegistrationForUserInfo from "../../Admin__Portion/Components/User/ViewRegistrationRequest/ViewRegistrationForUserInfo/ViewRegistrationForUserInfo";
import { DocumentsVerificationUserProfiles } from "../../Admin__Portion/Shared/components/DocumentVerification/documentVerificationUserProfiles";
import { ServiceEdit } from "../../Admin__Portion/Shared/components/LawyerAgentKaziServices/ServiceEdit/ServiceEdit";
import SingleServicePage from "../../Admin__Portion/Shared/components/LawyerAgentKaziServices/Single__Service__Page/SingleServicePage";
import ModifyCaseForm from "../../Admin__Portion/Shared/components/ModifyCaseForm/ModifyCaseForm";
import { ServiceUserProfiles } from "../../Admin__Portion/Shared/components/ViewServices/serviceUserProfiles";
import EditEducationalInfo from "../../Admin__Portion/Shared/ProfileEditForms/EditEducationalInfo";
import EditOthersInfo from "../../Admin__Portion/Shared/ProfileEditForms/EditOthersInfo";
import EditPersonalInfo from "../../Admin__Portion/Shared/ProfileEditForms/EditPersonalInfo";
import EditPhysicalInfo from "../../Admin__Portion/Shared/ProfileEditForms/EditPhysicalInfo";
import EditProfessionalInfo from "../../Admin__Portion/Shared/ProfileEditForms/EditProfessionalInfo";

const kaziRoutes = [
  {
    id: 1,
    path: "/kazi",
    element: <Kazi />,
    nestedRoutes: [
      {
        id: 1,
        path: "allKazi",
        element: <ViewAllKaziContainer />,
        nestedRoutes: [
          {
            id: 1,
            path: "",
            element: <ViewAllKazi />,
          },
          {
            id: 2,
            path: "profile/:id",
            element: <KaziSingleProfileDetails />,
          },
        ],
      },
      {
        id: 2,
        path: "payment",
        element: <KaziPayment />,
      },
      {
        id: 3,
        path: "registrationRequest",
        element: <ViewRegistrationRequestForKazi />,
      },
      {
        id: 5,
        path: "assignKazi",
        element: <AssignKazi />,
      },
      {
        id: 6,
        path: "documentsVerification",
        element: <DocumentsVerificationUserProfiles role="kazi" />,
      },
      {
        id: 7,
        path: "KaziProfiles",
        element: <ModifyKaziProfileContainer />,
        nestedRoutes: [
          {
            id: 1,
            path: "",
            element: <ModifyKaziProfile />,
          },
          {
            id: 2,
            path: "profile/:id",
            element: <ModifyKaziSingleProfileDetails />,
            nestedRoutes: [
              {
                id: 1,
                path: "personalInfo/:id",
                element: <EditPersonalInfo />,
              },
              {
                id: 2,
                path: "professionalInfo/:id",
                element: <EditProfessionalInfo />,
              },
              {
                id: 3,
                path: "educationalInfo/:id",
                element: <EditEducationalInfo />,
              },
              {
                id: 4,
                path: "physicalInfo/:id",
                element: <EditPhysicalInfo />,
              },
              {
                id: 5,
                path: "othersInfo/:id",
                element: <EditOthersInfo />,
              },
            ],
          },
        ],
      },
      {
        id: 10,
        path: "modifyDealStatus",
        element: <ModifyCaseStudy {...{ role: "kazi" }} />,
      },
      {
        id: 11,
        path: "reviewRatingsForKazi",
        element: <ModifyReviewRatingsForKazi />,
      },
      {
        id: 12,
        path: "dealHistory",
        element: <ModifyDealHistoryForKazi />,
      },
      {
        id: 13,
        path: "addKazi",
        element: <AddKazi />,
      },
      {
        id: 14,
        path: "kaziServices",
        element: <ServiceUserProfiles role="kazi" />,
      },
      {
        id: 15,
        path: "allServices",
        element: <AllServices />,
      },
      {
        id: 16,
        path: "singleService/:id",
        element: <SingleServicePage />,
      },
      {
        id: 17,
        path: "serviceEdit/:id",
        element: <ServiceEdit />,
      },
      {
        id: 18,
        path: "modifyDeal/:id",
        element: <SingleUserCases {...{ role: "kazi" }} />,
      },
      {
        id: 19,
        path: "editCase/:id",
        element: <ModifyCaseForm role="kazi" />,
      },
      {
        id: 20,
        path: "registrationRequest/:requestedId",
        element: <ViewRegistrationForUserInfo />,
      },
    ],
  },
];

export default kaziRoutes;

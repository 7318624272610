import React, { useState } from "react";
import SearchProducts from "./SearchProducts";
import ProductCard from "./ProductCard";

import CancelIcon from "@mui/icons-material/Cancel";
import WarningIcon from "@mui/icons-material/Warning";

const ProductShowCase = ({ setShowDrop }) => {

  const [productObj, setProducts] = useState();

  return (
    <div
      id="myElement"
      className=" bg-gray-300 top-[350px] p-3 shadow-xl rounded-lg h-[300px] overflow-y-auto absolute w-full"
    >
      <div>
        <span
          onClick={() => setShowDrop(false)}
          className="cursor-pointer absolute left-2 text-[#e41272] top-2"
        >
          <CancelIcon />
        </span>
        <SearchProducts {...{ setProducts,limit:"", page:"" }} />
        <div className="mt-6">
          {productObj?.error && (
            <div className="text-yellow-500 text-xl font-semibold">
              <WarningIcon fontSize="large" />
              <p>{productObj?.error?.data?.message}</p>
            </div>
          )}

          {!productObj?.error &&
            productObj?.data?.map((item) => (
              <ProductCard {...{ item, key: item._id, useCase: "select" }} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default ProductShowCase;

export const lawyerSubCategory = [
    {
        value: "Divorce",
        label: "Divorce",
    },
    {
        value: "Child Custody",
        label: "Child Custody",
    },
    {
        value: "Child Support",
        label: "Child Support",
    },
];

// Configuration
import React from "react";

// Third party packages
import { Divider } from "@mui/material";
import { BadgeForm } from "./BadgeForm";

// Redux API

export const AddBadgeFormContainer = ({ setRelavidateGetBadges }) => {
    return (
        <div>
            <h3 className="font-semibold text-[#32353B] text-[28px] font-poppins text-left mb-[22px]">Create Badge</h3>
            <Divider />
            <BadgeForm setRelavidateGetBadges={setRelavidateGetBadges} />
        </div>
    );
};

// Configuration
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

// Third party package
import { Toaster } from "react-hot-toast";

// Component
import { useGetSingleCourseQuery } from "../../../../../Redux/features/Course/courseApi";
import EditCourseForm from "../EditCourseForm/EditCourseForm";
import EditCourseSection from "../EditCourseSection/EditCourseSection";
import useDocumentTitle from "../../../../../helper/utilities/useDocumentTitle";
import EditCourseQuiz from "../EditCourseQuiz/EditCourseQuiz";

const EditSingleCourse = () => {
  const { id } = useParams();
  const { data } = useGetSingleCourseQuery(id);
  const { course } = data || {};

  // Page Title
  useDocumentTitle("Admin | Course Edit");

  // Scroll to top
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <div className="px-5 mx-auto">
        <div className="w-full h-auto shadow-[2px_2px_10px_2px_rgba(0,0,0,0.12)] bg-white rounded-[12px] px-[22px] py-[29px] mb-8">
          <h1 className="text-[#32353B] text-[28px] font-inter font-semibold text-left">
            Edit Course - {course?.name}
          </h1>
          <div className="w-full h-[1px] bg-[#E9ECF2] mt-[22px] mb-[30px]"></div>
          <EditCourseForm {...{ id, course }} />
        </div>

        <div className="w-full h-auto shadow-[2px_2px_10px_2px_rgba(0,0,0,0.12)] bg-white rounded-[12px] px-[22px] py-[29px] mb-8">
          <h1 className="text-[#32353B] text-[28px] font-inter font-semibold text-left">
            Edit Course Contents
          </h1>
          <div className="w-full h-[1px] bg-[#E9ECF2] mt-[22px] mb-[30px]"></div>
          <EditCourseSection {...{ course }} />
        </div>

        <div className="w-full h-auto shadow-[2px_2px_10px_2px_rgba(0,0,0,0.12)] bg-white rounded-[12px] px-[22px] py-[29px] mb-8">
          <h1 className="text-[#32353B] text-[28px] font-inter font-semibold text-left">
            Edit Course Quiz
          </h1>
          <div className="w-full h-[1px] bg-[#E9ECF2] mt-[22px] mb-[30px]"></div>
          <EditCourseQuiz {...{ course }} />
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default EditSingleCourse;

import React from "react";
import { CheckCircle } from "@mui/icons-material";

const Offers = ({ plan }) => {
  return (
    <div>
      {plan?.title?.toLowerCase()?.includes("free") && (
        <>
          <p className="whitespace-nowrap">Browse Basic Features</p>
          <p className="mb-2 hover:ml-[-20px] text-lg flex justify-start duration-300 font-medium cursor-pointer text-[#2F3659]">
        <CheckCircle style={{ color: "#2F3659" }} />Register/Login</p>
          <p className="mb-2 hover:ml-[-20px] text-lg flex justify-start duration-300 font-medium cursor-pointer text-[#2F3659]">
        <CheckCircle style={{ color: "#2F3659" }} />Complete Profile</p>
        </>
      )}
      {/* {index !== 0 && <p className="mb-2 hover:ml-[-20px] text-lg flex justify-start duration-300 font-medium cursor-pointer text-[#2F3659]">
        <CheckCircle style={{ color: "#2F3659" }} />All feature of {planages[index - 1]?.title}</p>} */}
      <p className="mb-2 hover:ml-[-20px] text-lg flex justify-start duration-300 font-medium cursor-pointer text-[#2F3659]">
        <CheckCircle style={{ color: "#2F3659" }} />{plan?.chatRequestPerDay} chat request every 24hours </p>
      <p className="mb-2 hover:ml-[-20px] text-lg flex justify-start duration-300 font-medium cursor-pointer text-[#2F3659]">
        <CheckCircle style={{ color: "#2F3659" }} />{plan?.swapPerDay || "Unlimited"} Swap every 24hours</p>
      <p className="mb-2 hover:ml-[-20px] text-lg flex justify-start duration-300 font-medium cursor-pointer text-[#2F3659]">
        <CheckCircle style={{ color: "#2F3659" }} />{plan?.messageLimit || "Unlimited"} Message Per Day</p>
      {plan?.videoCall !== 0 && (
        <p className="mb-2 hover:ml-[-20px] text-lg flex justify-start duration-300 font-medium cursor-pointer text-[#2F3659]">
        <CheckCircle style={{ color: "#2F3659" }} />Video Chat: {plan?.videoCall} Minutes a Day</p>
      )}
      {plan?.videoCallPerMonth !== 0 && (
        <p className="mb-2 hover:ml-[-20px] text-lg flex justify-start duration-300 font-medium cursor-pointer text-[#2F3659]">
        <CheckCircle style={{ color: "#2F3659" }} />{plan?.videoCallPerMonth} Minutes+ Video Chat Per Month</p>
      )}
      {plan?.timelinePost && <p className="mb-2 hover:ml-[-20px] text-lg flex justify-start duration-300 font-medium cursor-pointer text-[#2F3659]">
        <CheckCircle style={{ color: "#2F3659" }} />Timeline Post</p>}
      {plan?.courseDiscount !== 0 && (
        <p className="mb-2 hover:ml-[-20px] text-lg flex justify-start duration-300 font-medium cursor-pointer text-[#2F3659]">
        <CheckCircle style={{ color: "#2F3659" }} />Course Discount {plan?.courseDiscount}%</p>
      )}
      {plan?.uploadMarriageCertificate && <p className="mb-2 hover:ml-[-20px] text-lg flex justify-start duration-300 font-medium cursor-pointer text-[#2F3659]">
        <CheckCircle style={{ color: "#2F3659" }} />Upload Marriage Certificate</p>}
      {plan?.freeKaziContact && <p className="mb-2 hover:ml-[-20px] text-lg flex justify-start duration-300 font-medium cursor-pointer text-[#2F3659]">
        <CheckCircle style={{ color: "#2F3659" }} />Free Kazi Contact</p>}
      {plan?.shoppingDiscount !== 0 && (
        <p className="mb-2 hover:ml-[-20px] text-lg flex justify-start duration-300 font-medium cursor-pointer text-[#2F3659]">
        <CheckCircle style={{ color: "#2F3659" }} />{plan?.shoppingDiscount}% Shopping Discount</p>
      )}
      {plan?.lawyerDiscount !== 0 && (
        <p className="mb-2 hover:ml-[-20px] text-lg flex justify-start duration-300 font-medium cursor-pointer text-[#2F3659]">
        <CheckCircle style={{ color: "#2F3659" }} />{plan?.lawyerDiscount}% Discount on Lawyer Fee</p>
      )}
      {plan?.agentDiscount !== 0 && (
        <p className="mb-2 hover:ml-[-20px] text-lg flex justify-start duration-300 font-medium cursor-pointer text-[#2F3659]">
        <CheckCircle style={{ color: "#2F3659" }} />{plan?.agentDiscount}% Discount on Agent Fee</p>
      )}
      {plan?.kaziDiscount !== 0 && (
        <p className="mb-2 hover:ml-[-20px] text-lg flex justify-start duration-300 font-medium cursor-pointer text-[#2F3659]">
        <CheckCircle style={{ color: "#2F3659" }} />{plan?.kaziDiscount}% Discount on Agent Fee</p>
      )}
      {plan?.specialSupport && <p className="mb-2 hover:ml-[-20px] text-lg flex justify-start duration-300 font-medium cursor-pointer text-[#2F3659]">
        <CheckCircle style={{ color: "#2F3659" }} />Special Support</p>}
      {plan?.videoBioData && <p className="mb-2 hover:ml-[-20px] text-lg flex justify-start duration-300 font-medium cursor-pointer text-[#2F3659]">
        <CheckCircle style={{ color: "#2F3659" }} />Video Biodata</p>}
      {plan?.profileBoost !== 0 && (
        <p className="mb-2 hover:ml-[-20px] text-lg flex justify-start duration-300 font-medium cursor-pointer text-[#2F3659]">
        <CheckCircle style={{ color: "#2F3659" }} />{plan?.profileBoost} Profile Boost Per Month</p>
      )}
    </div>
  );
};

export default Offers;

const serviceDeliverTimeOptions = [
    {
        value: "in 9 days",
        label: "in 3 days",
    },
    {
        value: "in 9 days",
        label: "in 5 days",
    },
    {
        value: "in 9 days",
        label: "in 7 days",
    },
    {
        value: "in 9 days",
        label: "in 9 days",
    },
];

export default serviceDeliverTimeOptions;

// Components
import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";

const PackagesNav = ({ children }) => {
  const navigate = useNavigate();

  const url = new URL(window.location.href);
  const pathname = url.pathname;
  const parts = pathname.split("/");
  const lastPart = parts[parts.length - 1];

  useEffect(() => {
    if (lastPart === "packages") {
      navigate("/packages/membershipPackage");
    }
    if (lastPart === "membershipPackage") {
      navigate("/packages/membershipPackage/addPackage");
    }
    if (lastPart === "shopPackage") {
      navigate("/packages/shopPackage/addPackage");
    }
    if (lastPart === "coursePackage") {
      navigate("/packages/coursePackage/addPackage");
    }
    if (lastPart === "statusPackage") {
      navigate("/packages/statusPackage/addPackage");
    }
    if (lastPart === "lawyerPackage") {
      navigate("/packages/lawyerPackage/modifyPackage");
    }
    if (lastPart === "agentPackage") {
      navigate("/packages/agentPackage/modifyPackage");
    }
    if (lastPart === "kaziPackage") {
      navigate("/packages/kaziPackage/modifyPackage");
    }
  }, [lastPart, navigate]);
  const routes = [
    {
      id: 1,
      path: "membershipPackage",
      name: "Membership Package",
    },
    {
      id: 2,
      path: "shopBundle",
      name: "Shop Bundle",
    },
    {
      id: 3,
      path: "courseBundle",
      name: "Course Bundle",
    },
    {
      id: 4,
      path: "statusPackage",
      name: "Profile/Status Boost Package",
    },
    {
      id: 5,
      path: "lawyerPackage",
      name: "Lawyer Package",
    },
    {
      id: 6,
      path: "agentPackage",
      name: "Agent Package",
    },
    {
      id: 7,
      path: "kaziPackage",
      name: "Kazi Package",
    },
  ];

  return (
    <div>
      <div className="max-w-[1200px]">
        <div className="pt-10 mb-10 px-5">
          <ul className="grid grid-cols-3 gap-[41px] font-poppins font-normal text-base text-[#000000]">
            {routes.map((route) => (
              <NavLink
                key={route.id}
                className={({ isActive }) =>
                  `w-full  text-center py-[5px] rounded cursor-pointer text-[15px] font-semibold ${
                    isActive
                      ? " text-white bg-[#E41272]"
                      : "text-[#000000] bg-[#FFFFFF]"
                  }`
                }
                to={route.path}
              >
                {route.name}
              </NavLink>
            ))}
          </ul>
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default PackagesNav;

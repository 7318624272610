// conffiguration
import React from "react";
import { DeliveredOrderBillingDetailes } from "./DeliveredOrderBillingDetailes";
import { DeliveredOrderShippingDetailes } from "./DeliveredOrderShippingDetailes";

export const DeliveredOrderBillingContainer = () => {
    return (
        <div className="w-full h-auto bg-white shadow-[2px_2px_10px_2px_rgba(0,0,0,0.12)] rounded-[12px] mt-[27px] px-[39px] py-[31px]">
            <DeliveredOrderBillingDetailes />
            <DeliveredOrderShippingDetailes />
        </div>
    );
};

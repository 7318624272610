import getCookie from "../../../helper/cookies/getCookie";
import { apiSlice } from "../../api/apiSlice";

export const certificateApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserCertificates: builder.query({
      query: (id) => ({
        url: `/admin/member/certificates/${id}`,
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
    }),
    changeStatusOfCertificates: builder.mutation({
      query: ({id, photo,status}) => ({
        url: `/admin/action/certificate/status?id=${id}&photo=${photo}&status=${status}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
    }),
    
  }),
});

export const {
  useGetUserCertificatesQuery,
  useChangeStatusOfCertificatesMutation
} = certificateApi;

import React from "react";
import { BsCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import liveLinkGenerator from "../../../../../helper/utilities/liveLinkGenerator";

export const ServiceCard = ({ service }) => {
    const navigate = useNavigate();
    let { role, title, photos, description, price, _id: serviceID } = service || {};
    description = description?.length > 144 ? description.slice(0, 144) + "..." : description;

    const { firstName, lastName, role: userRole } = role || {};
    const name = `${firstName} ${lastName}`;

    const redirectPath = `/${userRole}/singleService/${serviceID}`;

    return (
        <div
            style={{ background: "linear-gradient(180deg, #EEE6FF 0%, #FFDAE3 0.01%, #F4E6FF 5.73%, #FFF2E6 100%)" }}
            className="pt-6 pl-6 pr-9 pb-2 mb-6 rounded-xl font-SourceCodePro font-semibold text-base w-[617px] mx-auto"
        >
            <img src={liveLinkGenerator(photos[0])} className="w-full h-[258px] rounded-[12px]" alt="service" />
            <div className="text-left text-sm">
                <h1 className="font-medium text-lg text-[#000000] my-4">{title}</h1>
                <p className="font-normal text-[#666666]">{description}</p>
                <h5 className="text-[#000000] my-4">{name}</h5>
            </div>
            <div className="flex justify-between">
                <div className="flex items-center gap-[6px]">
                    <BsCircleFill className="text-base text-[#DEDEDE]" />
                    <p className="font-normal text-sm text-[#666666]">{userRole}</p>
                </div>
                <div className="flex items-center gap-[15px]">
                    <p className="text-base text-[#000000]">৳{price}</p>
                    <button className="bg-[#E41272] rounded py-[5px] px-[22px] text-sm text-[#FFFFFF]" onClick={() => navigate(redirectPath)}>
                        Edit
                    </button>
                </div>
            </div>
        </div>
    );
};

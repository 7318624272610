import React from "react";

const EditableTextArea = ({ title, type, name, value, handleChange , editedBundle}) => {
  return (
    <div className="w-full flex flex-col mb-4">
      <label className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]">
        {title}
      </label>
      <textarea
        value={editedBundle[name]}
        onChange={handleChange}
        name={name}
        className="h-[150px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-2 px-[12px]"
        type="text"
        placeholder={title}
      />
    </div>
  );
};

export default EditableTextArea;

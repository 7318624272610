import React from "react";
import { useNavigate } from "react-router-dom";

import SearchSingleUser from "../../Shared/components/SearchUsers/SearchSingleUser";

export default function UserPlanHeader({ user, setUser, role }) {
  const navigate = useNavigate();

  // function declaration
  return (
    <div>
      <div className="flex justify-center">
        <SearchSingleUser setUser={setUser} role={role} />
      </div>
      {!user && (
        <p className="text-warning my-4 text-yellow-500 font-bold text-2xl">
          Search and select a user
        </p>
      )}
      {user && (
        <div className=" mt-8 flex justify-center">
          <div className="py-6 text-white w-[385px] h-[237px] rounded bg-[#301B6B]">
            <div className="mb-6 pl-10 flex ">
              <div className="mr-4">
                <img
                  src={user?.profilePhoto}
                  className="w-[85px] h-[85px] rounded-full"
                  alt="Profile"
                />
              </div>
              <div className="flex flex-col items-start">
                <h1 className="text-2xl font-medium">{`${user?.firstName} ${user?.lastName}`}</h1>
                <p className="font-xl font-normal mt-[20px]">Age:{user?.age}</p>
              </div>
            </div>
            <div className="flex justify-center">
              <button
                onClick={() =>
                  navigate(`/user/registrationRequest/${user._id}`)
                }
                className="px-6 py-1 rounded bg-[background: linear-gradient(180deg, #E22989 0%, #A72CC7 100%)]"
                style={{
                  background:
                    "linear-gradient(180deg, #E41272 0%, #942DD9 100%)",
                }}
              >
                View Profile
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

// configuration
import React, { useState } from "react";

// Third party packages
import { Pagination } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";

// components
import { TrendyProductCard } from "../../../Shared/Cards/Shop/Products/TrendyProductCard";

// css files
import "swiper/css";
import "swiper/css/pagination";
import { useGetTrendyProductsQuery } from "../../../../Redux/features/Shop/shopApi";
import { LineWaveLoader } from "../../../Shared/Loader/LineWaveLoader";
import ErrorMessage from "../../../Shared/components/ErrorMessage/ErrorMessage";
import { MdOutlineRecommend } from "react-icons/md";

export const AllTrendyProducts = () => {
  const [page, setPage] = useState(1);

  const {
    data: trendyProducts,
    isLoading,
    error,
  } = useGetTrendyProductsQuery({ limit: 8, page: page });

  let content;
  if (isLoading) {
    content = (
      <div>
        <LineWaveLoader />
      </div>
    );
  } else if (error) {
    content = (
      <div>
        <ErrorMessage />
      </div>
    );
  } else if (!error && trendyProducts?.products?.length === 0) {
    content = (
      <div className="flex flex-col items-center justify-center mt-[100px]">
        <MdOutlineRecommend className="text-[42px] text-gray-400" />
        <p className="mt-[10px] text-[16px] font-Inter font-medium text-gray-500">
          No Trendy Products Found!
        </p>
      </div>
    );
  } else if (!error && trendyProducts?.products?.length > 0) {
    content = (
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={32}
        className="max-w-[1024px] pb-10"
      >
        {trendyProducts?.products?.map((product) => {
          return (
            <SwiperSlide key={product?._id} className="w-[243px]">
              <TrendyProductCard {...{ product }} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    );
  }

  return (
    <div className="mb-[115px]">
      <div className="w-full flex justify-between mb-[30px]">
        <p className="text-[#272727] text-[32px] font-semibold font-playFair text-left">
          Our Trendy Products
        </p>
        <Pagination
          onChange={(e, value) => {
            setPage(value);
          }}
          count={Math.ceil(trendyProducts?.total / 8) || 1}
          variant="outlined"
          shape="rounded"
        />
      </div>

      <div>{content}</div>
    </div>
  );
};

import AddLawyer from "../../Admin__Portion/Components/Lawyer/AddLawyer/AddLawyer";
import AssignLawyer from "../../Admin__Portion/Components/Lawyer/Assign__Lawyer/AssignLawyer";
import { Lawyer } from "../../Admin__Portion/Components/Lawyer/Lawyer";
import Payment from "../../Admin__Portion/Components/Lawyer/LawyerPayment/Payment";
import { AllChatingListsForLawyer } from "../../Admin__Portion/Components/Lawyer/Lawyer__Client__Chat/All__Chating__Lists/AllChatingListsForLawyer";
import { LawyerClientChat } from "../../Admin__Portion/Components/Lawyer/Lawyer__Client__Chat/LawyerClientChat";
import AllServices from "../../Admin__Portion/Components/Lawyer/ModifyCaseStudy/AllServices/AllServices";
import ModifyCaseStudy from "../../Admin__Portion/Components/Lawyer/ModifyCaseStudy/ModifyCaseStudy";
import SingleUserCases from "../../Admin__Portion/Components/Lawyer/ModifyCaseStudy/SingleUserCases/SingleUserCases";
import { ModifyCaseHistory } from "../../Admin__Portion/Components/Lawyer/Modify__Case__History/ModifyCaseHistory";
import { AllRatingsPageForLawyer } from "../../Admin__Portion/Components/Lawyer/Modify__Review__Ratings/AllRatingsPageForLawyer/AllRatingsPageForLawyer";
import { ModifyReviewRatingsForLawyer } from "../../Admin__Portion/Components/Lawyer/Modify__Review__Ratings/ModifyReviewRatingsForLawyer";
import ModifyLawyerProfileContainer from "../../Admin__Portion/Components/Lawyer/Modify___Lawyer__Profile/ModifyLawyerProfileContainer";
import { ModifyLawyerProfiles } from "../../Admin__Portion/Components/Lawyer/Modify___Lawyer__Profile/ModifyLawyerProfiles";
import ModifyLawyerSingleProfileDetails from "../../Admin__Portion/Components/Lawyer/Modify___Lawyer__Profile/ModifyLawyerSingleProfileDetails/ModifyLawyerSingleProfileDetails";
import LawyerSingleProfileDetails from "../../Admin__Portion/Components/Lawyer/View__All__Lawyers/LawyerSingleProfileDetails/LawyerSingleProfileDetails";
import ViewAllLawyers from "../../Admin__Portion/Components/Lawyer/View__All__Lawyers/ViewAllLawyers";
import ViewAllLawyersContainer from "../../Admin__Portion/Components/Lawyer/View__All__Lawyers/ViewAllLawyersContainer";
import { ViewRegistrationRequestForLawyer } from "../../Admin__Portion/Components/Lawyer/View__Registration__Request/ViewRegistrationRequestForLawyer";
import ViewRegistrationForUserInfo from "../../Admin__Portion/Components/User/ViewRegistrationRequest/ViewRegistrationForUserInfo/ViewRegistrationForUserInfo";
import DocumentVerification from "../../Admin__Portion/Shared/components/DocumentVerification/documentVerification";
import { DocumentsVerificationUserProfiles } from "../../Admin__Portion/Shared/components/DocumentVerification/documentVerificationUserProfiles";
import { ServiceEdit } from "../../Admin__Portion/Shared/components/LawyerAgentKaziServices/ServiceEdit/ServiceEdit";
import SingleServicePage from "../../Admin__Portion/Shared/components/LawyerAgentKaziServices/Single__Service__Page/SingleServicePage";
import ModifyCaseForm from "../../Admin__Portion/Shared/components/ModifyCaseForm/ModifyCaseForm";
import { ServiceUserProfiles } from "../../Admin__Portion/Shared/components/ViewServices/serviceUserProfiles";
import EditEducationalInfo from "../../Admin__Portion/Shared/ProfileEditForms/EditEducationalInfo";
import EditOthersInfo from "../../Admin__Portion/Shared/ProfileEditForms/EditOthersInfo";
import EditPersonalInfo from "../../Admin__Portion/Shared/ProfileEditForms/EditPersonalInfo";
import EditPhysicalInfo from "../../Admin__Portion/Shared/ProfileEditForms/EditPhysicalInfo";
import EditProfessionalInfo from "../../Admin__Portion/Shared/ProfileEditForms/EditProfessionalInfo";

const lawyerRoutes = [
  {
    id: 1,
    path: "/lawyer",
    element: <Lawyer />,
    nestedRoutes: [
      {
        id: 1,
        path: "modifyCaseStudy",
        element: <ModifyCaseStudy {...{ role: "lawyer" }} />,
      },
      {
        id: 2,
        path: "registrationRequest",
        element: <ViewRegistrationRequestForLawyer />,
      },
      {
        id: 3,
        path: "addLawyer",
        element: <AddLawyer />,
      },
      {
        id: 4,
        path: "allLawyers",
        element: <ViewAllLawyersContainer />,
        nestedRoutes: [
          {
            id: 1,
            path: "",
            element: <ViewAllLawyers />,
          },
          {
            id: 2,
            path: "profile/:id",
            element: <LawyerSingleProfileDetails />,
          },
        ],
      },
      {
        id: 5,
        path: "assignLawyer",
        element: <AssignLawyer />,
      },
      {
        id: 6,
        path: "reviewRatings",
        element: <ModifyReviewRatingsForLawyer />,
      },
      {
        id: 7,
        path: "lawyerProfile",
        element: <ModifyLawyerProfileContainer />,
        nestedRoutes: [
          {
            id: 1,
            path: "",
            element: <ModifyLawyerProfiles />,
          },
          {
            id: 2,
            path: "profile/:id",
            element: <ModifyLawyerSingleProfileDetails />,
            nestedRoutes: [
              {
                id: 1,
                path: "personalInfo/:id",
                element: <EditPersonalInfo />,
              },
              {
                id: 2,
                path: "professionalInfo/:id",
                element: <EditProfessionalInfo />,
              },
              {
                id: 3,
                path: "educationalInfo/:id",
                element: <EditEducationalInfo />,
              },
              {
                id: 4,
                path: "physicalInfo/:id",
                element: <EditPhysicalInfo />,
              },
              {
                id: 5,
                path: "othersInfo/:id",
                element: <EditOthersInfo />,
              },
            ],
          },
        ],
      },
      {
        id: 8,
        path: "caseHistory",
        element: <ModifyCaseHistory />,
      },
      {
        id: 9,
        path: "lawyerServices",
        element: <ServiceUserProfiles role="lawyer" />,
      },
      {
        id: 10,
        path: "editCase/:id",
        element: <ModifyCaseForm role="lawyer" />,
      },
      {
        id: 11,
        path: "documentsVerification",
        element: <DocumentsVerificationUserProfiles role="lawyer" />,
      },
      {
        id: 12,
        path: "documentsVerificationContent",
        element: <DocumentVerification />,
      },
      {
        id: 13,
        path: "clientChat",
        element: <LawyerClientChat />,
      },
      {
        id: 14,
        path: "chatLists/:id",
        element: <AllChatingListsForLawyer />,
      },
      {
        id: 15,
        path: "payment",
        element: <Payment />,
      },
      {
        id: 16,
        path: "allRatings",
        element: <AllRatingsPageForLawyer />,
      },
      {
        id: 17,
        path: "allServices",
        element: <AllServices />,
      },
      {
        id: 18,
        path: "singleService/:id",
        element: <SingleServicePage />,
      },
      {
        id: 19,
        path: "serviceEdit/:id",
        element: <ServiceEdit />,
      },
      {
        id: 20,
        path: "modifyCase/:id",
        element: <SingleUserCases {...{ role: "lawyer" }} />,
      },
      {
        id: 16,
        path: "registrationRequest/:requestedId",
        element: <ViewRegistrationForUserInfo />,
      },
    ],
  },
];

export default lawyerRoutes;

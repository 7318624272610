// Configuration
import React, { useState } from "react";

// Third party packages
import { Pagination } from "@mui/material";
import { AiOutlineWarning } from "react-icons/ai";
import { MdOutlineRecommend } from "react-icons/md";

// Components
import { useGetAllOrdersQuery } from "../../../../../Redux/features/Shop/shopApi";
import DeliveredOrdersCard from "../../../../Shared/Cards/Shop/Order/DeliveredOrdersCard";
import OrderLoader from "../../../../Shared/Loader/Order/OrderLoader";

const DeliveredOrder = () => {
    const [page, setPage] = useState(1);
    const loaderArray = [1, 2, 3, 4, 5, 6];
    const {
        data: orderResponse,
        isLoading,
        error,
    } = useGetAllOrdersQuery({
        page: page,
        limit: 6,
        status: "Delivered",
    });

    let content;
    if (isLoading) {
        content = (
            <div className="grid grid-cols-3 gap-3 pt-5 pb-8 pl-1">
                {loaderArray.map(loader => (
                    <OrderLoader key={loader} />
                ))}
            </div>
        );
    } else if (error) {
        content = (
            <div className="flex flex-col items-center justify-center my-[100px]">
                <AiOutlineWarning className="text-[42px] text-gray-400" />
                <p className="mt-[10px] text-[16px] font-Inter font-medium text-gray-500">
                    An Error occurred! <br /> Please login or try again later!
                </p>
            </div>
        );
    } else if (!error && orderResponse?.orders?.length === 0) {
        content = (
            <div className="flex flex-col items-center justify-center my-[100px]">
                <MdOutlineRecommend className="text-[42px] text-gray-400" />
                <p className="mt-[10px] text-[16px] font-Inter font-medium text-gray-500">No Delivered Orders Found!</p>
            </div>
        );
    } else if (orderResponse?.orders?.length > 0) {
        content = (
            <div className="grid grid-cols-3 gap-3 pt-5 pb-8 pl-1">
                {orderResponse?.orders?.map(order => (
                    <DeliveredOrdersCard key={order._id} {...{ order }} />
                ))}
            </div>
        );
    }

    return (
        <div className="mb-20 pl-6">
            <h1 className="text-[40px] text-left leading-[47px] font-bold">Delivered Order</h1>
            <div>{content}</div>
            <Pagination
                onChange={(event, value) => {
                    setPage(value);
                }}
                count={Math.ceil(orderResponse?.total / 6) || 1}
                variant="outlined"
                shape="rounded"
            />
        </div>
    );
};

export default DeliveredOrder;

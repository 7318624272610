// Configuration
import React from "react";
import { Outlet, useParams } from "react-router-dom";

// Components
import { useGetProfileDetailsQuery } from "../../../../../Redux/features/User/userApi";
import ProfileAccordion from "../../../../Shared/ProfileAccordion/ProfileAccordion";

const SingleProfileDetails = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetProfileDetailsQuery(id);
  return (
    <div>
      <div>
        <Outlet />
      </div>
      <ProfileAccordion
        {...{ data, isLoading, edit: true, profileRole: "member" }}
      ></ProfileAccordion>
    </div>
  );
};

export default SingleProfileDetails;

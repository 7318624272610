import React from "react";
import { useNavigate } from "react-router-dom";

export const DocumentVerificationProfileCard = ({user,role}) => {
    const navigate = useNavigate();
    return (
      <div>
        <div className=" bg-[#FFFFF0] max-w-[385px] rounded-[8px] px-[35px] py-[31px]">
          <div className="flex gap-x-[24px]">
            <img
              className="w-[85px] h-[85px] rounded-full"
              src={user.profilePhoto}
              alt="profile"
            />
            <div className="flex flex-col gap-y-[18px]">
              <h1 className=" text-[24px] leading-[29px] font-medium">{`${user.firstName} ${user.lastName}`}</h1>
              <p className=" text-[20px] leading-[24px] font-medium">
                Age: {user.age}
              </p>
              <button
                className="w-[135px] h-[37px] mt-[20px] flex justify-center items-center bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] text-white rounded-[4px]"
                onClick={() =>
                  navigate(`/${role==="member"?"user":role}/documentsVerification?id=${user._id}`)
                }
              >
                Select
              </button>
            </div>
          </div>
        </div>
      </div>
    );
};

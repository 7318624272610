// Configuration
import React, { useEffect, useState } from "react";

// Third party packages
import { Select } from "antd";
import { useForm } from "react-hook-form";
import { AiOutlineSave } from "react-icons/ai";

// Components
import Error from "../../../Shared/components/accordion/UI/Error/Error";
import {
  useGetAllCategoriesQuery,
  useUpdateSingleProductMutation,
} from "../../../../Redux/features/Shop/shopApi";
import { toast } from "react-hot-toast";

export const FullEditForm = ({
  name,
  category,
  size,
  shiping,
  discount,
  stock,
  id,
}) => {
  // hook variable declaration
  const [categories, setCategories] = useState("");
  const [customError, setCustomError] = useState([
    {
      categoryError: "",
    },
  ]);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const { data: categoryResponse } = useGetAllCategoriesQuery("shop");
  const [updateSingleProduct, { data: response, isLoading, error }] =
    useUpdateSingleProductMutation();

  // category options
  const categoryOptions = categoryResponse
    ? categoryResponse?.category?.map((category) => {
        return {
          value: category?.name,
          label: category?.name,
        };
      })
    : [];

  const onSubmit = async (data) => {
    if (!data.name) {
      delete data.name;
    }
    if (!data.shiping) {
      delete data.shiping;
    } else {
      data.shiping = Number(data.shiping);
    }
    if (!data.discount) {
      delete data.discount;
    } else {
      data.discount = Number(data.discount);
    }
    if (!data.stock) {
      delete data.stock;
    } else {
      data.stock = Number(data.stock);
    }
    if (categories) {
      data.category = categories;
    }
    if (!data.size) {
      delete data.size;
    }
    await updateSingleProduct({
      id: id,
      data: data,
    });
  };

  useEffect(() => {
    if (response) {
      toast.success("Product updated successfully");
      setCategories("");
      reset();
    }
    if (error) {
      toast.error(
        <p>
          Something went wrong <br /> Please login or, try again later
        </p>
      );
    }
  }, [response, error, reset]);

  return (
    <div className="mt-[97px]">
      <div className="px-5 mx-auto">
        <div className="w-full h-auto shadow-[2px_2px_10px_2px_rgba(0,0,0,0.12)] bg-white rounded-[12px] px-[22px] py-[29px]">
          <h1 className="text-[#32353B] text-[28px] font-poppins font-semibold text-left">
            Full Edit
          </h1>
          <div className="w-full h-[1px] bg-[#E9ECF2] mt-[22px] mb-[30px]"></div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col items-start w-full gap-y-[40px]"
          >
            {/* Product Name */}
            <div className="w-full flex flex-col">
              <label
                htmlFor="name"
                className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]"
              >
                Product Name
              </label>
              <input
                {...register("name")}
                className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-19px px-[12px]"
                type="text"
                placeholder="Product Name"
              />
            </div>

            {/* product category */}
            <div className="w-full flex flex-col">
              <label
                htmlFor="name"
                className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]"
              >
                Product Category
              </label>
              <Select
                placeholder="Select Categories"
                bordered={false}
                className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-19px px-[12px] text-start flex items-center"
                options={categoryOptions}
                onSelect={(value) => setCategories(value)}
                onChange={() => setCustomError("")}
                value={categories}
              />
              {customError[0]?.categoryError && (
                <Error message={customError[0].categoryError} />
              )}
            </div>

            {/* stock */}
            <div className="w-full flex flex-col">
              <label
                htmlFor="name"
                className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]"
              >
                Product In Stock
              </label>
              <input
                {...register("stock", {
                  pattern: {
                    value: /^[0-9]*$/,
                    message: "Input must be a number",
                  },
                })}
                className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-19px px-[12px]"
                type="number"
                placeholder="Type Stock number"
              />
              {errors?.stock && <Error message={errors.stock.message} />}
            </div>

            {/* shipping charge */}
            <div className="w-full flex flex-col">
              <label
                htmlFor="name"
                className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]"
              >
                Shipping Charge
              </label>
              <input
                {...register("shiping", {
                  pattern: {
                    value: /^[0-9]*$/,
                    message: "Input must be a number",
                  },
                })}
                className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-19px px-[12px]"
                type="text"
                placeholder="Charge"
              />
              {errors?.shiping && <Error message={errors.shiping.message} />}
            </div>

            {/* discount prices */}
            <div className="w-full flex flex-col">
              <label
                htmlFor="name"
                className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]"
              >
                DISCOUNT IN PERCENT (%)
              </label>
              <input
                {...register("discount", {
                  pattern: {
                    value: /^[0-9]*$/,
                    message: "Input must be a number",
                  },
                })}
                className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-19px px-[12px]"
                type="text"
                placeholder="Type Discount number"
              />
              {errors?.discount && <Error message={errors.discount.message} />}
            </div>

            {/* size style */}
            <div className="w-full flex flex-col">
              <label
                htmlFor="size"
                className="text-left mb-[10px] text-[12px] font-bold text-[#707276] ml-[5px]"
              >
                SIZE STYLE
              </label>
              <input
                {...register("size")}
                className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-19px px-[12px]"
                type="text"
                placeholder="Size"
              />
            </div>

            <div>
              <button
                type="submit"
                className="flex items-center justify-center min-w-[118px] px-4 h-[48px] bg-[linear-gradient(90deg,#E41272_0%,_#A72CC7_100%)] rounded-[100px] gap-x-[10px] text-white tracking-[1.25px] font-Inter font-semibold text-[16px]"
              >
                {isLoading ? "Loading..." : "SAVE"}{" "}
                <AiOutlineSave className="text-[16px]" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { useGetAllPlansQuery } from "../../../../Redux/features/usersPlan/userPlansApi";
import PlanCard from "../PlanCard";

export const AllPlans = ({ isMonthly, user, condition, setShowPopup,setPack }) => {
  const [availablePlans, setAvailablePlan] = useState([]);
  const { data: plans, isLoading, error } = useGetAllPlansQuery();
  useEffect(() => {
    plans?.data?.packages &&
      setAvailablePlan(
        plans?.data?.packages?.filter((item) => {
          const price = user?.userMatrimonyPackageInfo?.package?.priceMonth;
          console.log(item.priceMonth, price, "both pride");
          if (condition === "downgrade") {
            return item.priceMonth < price;
          }
          return item.priceMonth > price;
        })
      );
  }, [plans, user, condition]);
  let content;

  if (isLoading) {
    content = "Loading...";
  } else if (!isLoading && error) {
    content = "SomeThing went wrong...";
  } else if (
    !isLoading &&
    plans.success &&
    plans?.data?.packages?.length === 0
  ) {
    content = "No Plan Found";
  } else if (!isLoading && plans.success && plans?.data?.packages?.length > 0) {
    content = (
      <div className="grid grid-cols-2 gap-8 w-full">
        {availablePlans?.map((plan) => {
          return (
            <PlanCard
              {...{
                key: plan._id,
                condition,
                setShowPopup,
                plan,
                setPack,
              }}
            />
          );
        })}
      </div>
    );
  }
  return <section className=" mt-8">{content}</section>;
};

import React from 'react';

/* Third party package */
import { useNavigate } from 'react-router-dom';

const ModifyDealHistoryCard = ({ user }) => {
    const navigate = useNavigate();
    return (
        <div key={user.id} style={{ boxShadow: "2px 2px 10px 2px rgba(0, 0, 0, 0.12)", background: "linear-gradient(109.61deg, #6B3095 4.08%, #9D43F9 95.09%)" }} className='rounded-lg font-inter font-medium text-2xl'>
            <div className='flex gap-6 pt-[31px] pl-[35px] text-[#000000]'>
                <img src={user?.img} alt="profile" />
                <div className='text-left'>
                    <h1 className='mb-4'>{user.name}</h1>
                    <p className='font-normal text-xl'>Age : {user.age}</p>
                </div>
            </div>
            <div className='text-center mt-11 pb-[33px]'>
                <button onClick={() => navigate('/kazi/historyDetails')} className='py-[9px] px-[14px] rounded text-[16px] bg-gradient-to-b from-[#E22989] to-[#A72CC7] text-white'>Modify Deal History</button>
            </div>
        </div>
    );
};

export default ModifyDealHistoryCard;
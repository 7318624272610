import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useDocumentTitle from "../../../../helper/utilities/useDocumentTitle";
import { setUserInfo } from "../../../../Redux/features/User/PriorityUser/priorityUserSlice";
import UserPlanHeader from "../UserPlanHeader";
import "./MarkPriorityUser.css";
import { PriorityUserTable } from "./PriorityUserTable";
import RecentUserCard from "./RecentUserCard";
import { useGetAllPriorityMembersQuery } from "../../../../Redux/features/User/PriorityUser/priorityUserApi";
import { LineWaveLoader } from "../../../Shared/Loader/LineWaveLoader";
import ErrorMessage from "../../../Shared/components/ErrorMessage/ErrorMessage";

export default function MarkPriorityUser() {
  const [loadAll, setLoadAll ] = useState(false)
  const [user, setUser] = useState(null);
  const [day, setDay] = useState("");
  const dispatch = useDispatch();


  // page title
  useDocumentTitle("Admin | Mark  As Priority");

  // set user detaile to redux slice for use next time
  useEffect(() => {
    if (user) {
      const { _id, gender } = user || {};
      dispatch(
        setUserInfo({
          user: _id,
          gender: gender,
        })
      );
    }
  }, [user, dispatch]);

  const { data, isLoading, error } = useGetAllPriorityMembersQuery("")
  return (
    <div className="flex justify-center">
      <div>
        <section>
          <div className="flex justify-center">
            <h1 className="text-xl font-semibold">
              Mark As Priority User For ({day}) Days
            </h1>
          </div>
          <div className="flex justify-center">
            <UserPlanHeader setUser={setUser} user={user} role="member" />
          </div>
        </section>
        <PriorityUserTable {...{ day, setDay, setUser }} />
        <section>
          <div>
            <div className="mb-4 mt-12">
              <h1 className="text-2xl font-bold">Recent Priority Users</h1>
            </div>
            {data?.data?.length !== 0 && (
              <div className="grid grid-cols-2 gap-4">
                {data?.data?.slice(0, loadAll? data?.data?.length:4)?.map((user) => {
                  return <RecentUserCard user={user} key={user._id} />;
                })}
              </div>
            )}
            { isLoading && 
              <div>
                <LineWaveLoader />
              </div>
            }
            {data?.data?.length === 0 && (
              <div>
                <p className="text-center font-semibold text-xl">No User found</p>
              </div>
            )}
            {error && (
              <div>
                <ErrorMessage />
              </div>
            )}
            <div className="flex justify-center my-4">
              {data?.data?.length > 4 &&<button
                className="px-4 py-1 rounded text-white"
                onClick={()=>setLoadAll(!loadAll)}
                style={{
                  background:
                    "linear-gradient(180deg, #E41272 0%, #942DD9 100%)",
                }}
              >
                {loadAll? "Load Less":"Load All"}
              </button>}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

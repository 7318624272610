import React from "react";

const OrderLoader = () => {
  return (
    <div>
      <div
        className="text-left w-[342px] rounded-xl pt-4 animate-pulse"
        style={{ boxShadow: "2px 2px 10px 2px rgba(0, 0, 0, 0.12)" }}
      >
        <p className="text-sm h-[18px] font-SourceCodePro bg-slate-200 rounded-lg text-[#666666] mx-6 mb-4"></p>
        <div>
          <div className="w-[292px] mx-6 h-[198px] bg-slate-200 rounded-lg"></div>
        </div>
        <div className="p-6">
          <p className="text-xl h-6 font-semibold font-SourceCodePro mb-2 bg-slate-200 rounded-lg"></p>
          <p className="text-sm h-[18px] font-SourceCodePro bg-slate-200 rounded-lg"></p>
        </div>
      </div>
    </div>
  );
};

export default OrderLoader;

import React from "react";

const CertificateSelector = ({ item, selected, setSelected, title, index }) => {
  return (
    <button
      onClick={() => setSelected(item)}
      className={`py-1 rounded ${
        selected?._id === item?._id
          ? "bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] text-white"
          : "bg-gray-200"
      }`}
    >
      {title === "edu"
        ? item.degree || "certificate " + (index + 1)
        : title === "prof"
        ? item.position || "certificate " + (index + 1)
        : "Certificate" + (index + 1)}
    </button>
  );
};

export default CertificateSelector;

// Configuration
import React from "react";

// Third party package
import ReactApexChart from "react-apexcharts";

const NewUserChart = () => {
  return (
    <div className="flex flex-col items-center rounded-xl w-[410px] bg-white">
      <div className="w-full px-8 pt-8 pb-6 shadow-[0px_12px_23px_rgba(62,73,84,0.04)]">
        <h1 className="text-left text-xl leading-8 font-medium font-poppins">
          New User
        </h1>
      </div>
      <ReactApexChart
        options={{
          chart: {
            type: "line",
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          stroke: {
            curve: "smooth",
            width: 2,
          },
          colors: ["#E41272"],
          grid: {
            borderColor: "#DDDDDD",
            strokeDashArray: 7,
          },
          xaxis: {
            categories: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
            labels: {
              style: {
                colors: "#3E4954",
              },
            },
          },
          yaxis: {
            labels: {
              show: false,
            },
          },
        }}
        series={[
          {
            name: "New User",
            data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 139, 213, 180],
          },
        ]}
        type="line"
        height={300}
        width={390}
      />
    </div>
  );
};

export default NewUserChart;

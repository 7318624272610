// Configuration
import React from "react";

// Third party package
import { MdOutlineRecommend } from "react-icons/md";

// Components
import ErrorMessage from "../../../../Shared/components/ErrorMessage/ErrorMessage";
import { OvalLoader } from "../../../../Shared/Loader/OvalLoader";
import { useGetProfessionalPackagesQuery } from "../../../../../Redux/features/Packages/packagesApi";
import EditMonthlySupportPackage from "../../LawyerPackage/EditMonthlySupportPackage/EditMonthlySupportPackage";

const ModifyAgentPackage = () => {
  const {
    data: response,
    isLoading,
    error,
  } = useGetProfessionalPackagesQuery("agent");

  let content;
  if (isLoading) {
    content = (
      <div className="mt-20">
        <OvalLoader title={"Loading..."} />
      </div>
    );
  } else if (error) {
    content = <ErrorMessage />;
  } else if (response?.data?.packages?.length === 0) {
    content = (
      <div className="flex flex-col items-center justify-center mt-[100px]">
        <MdOutlineRecommend className="text-[42px] text-gray-400" />
        <p className="mt-[10px] text-[16px] font-Inter font-medium text-gray-500">
          No Agent Package Found!
        </p>
      </div>
    );
  } else if (response?.data?.packages?.length > 0) {
    const monthlySupportPackage = response?.data?.packages?.find(
      (pack) => pack?.name === "Monthly Support"
    );
    content = (
      <div>
        <EditMonthlySupportPackage
          {...{ monthlySupportPackage, from: "Agent" }}
        />
      </div>
    );
  }

  return (
    <div className="mx-6 mb-12 p-5 shadow-[2px_2px_10px_rgba(0,0,0,0.1)]">
      <h1 className="text-left text-2xl font-Inter font-semibold">
        Modify Monthly Support Package
      </h1>
      <div>{content}</div>
    </div>
  );
};

export default ModifyAgentPackage;

import getCookie from "../../../helper/cookies/getCookie";
import { apiSlice } from "../../api/apiSlice";

export const shopApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllCategories: builder.query({
      query: (type) => ({
        url: `/shop/category/get-all-categories?type=${type}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      keepUnusedDataFor: 600,
      providesTags: ["Categories"],
    }),
    addCategory: builder.mutation({
      query: (data) => ({
        url: `/shop/category/add-category`,
        method: "POST",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["Categories"],
    }),
    deleteCategory: builder.mutation({
      query: (id) => ({
        url: `/shop/category/delete-category/${id}`,
        method: "DELETE",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      invalidatesTags: ["Categories"],
    }),
    updateCategory: builder.mutation({
      query: ({ id, data }) => ({
        url: `/shop/category/update-category/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["Categories"],
    }),
    addNewProducts: builder.mutation({
      query: (data) => ({
        url: `/shop/product/add-product`,
        method: "POST",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["ShopProduct"],
    }),
    getAllProducts: builder.query({
      query: ({ page, limit, keyword }) => ({
        url: `/shop/product/get-all-products?_page=${page}&_limit=${limit}&_keyword=${keyword}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      providesTags: ["ShopProduct"],
    }),
    getSingleProduct: builder.query({
      query: (id) => ({
        url: `/shop/product/${id}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      keepUnusedDataFor: 0,
      providesTags: ["UpdateProduct"],
    }),
    updateSingleProduct: builder.mutation({
      query: ({ id, data }) => ({
        url: `/shop/product/update-product/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["UpdateProduct"],
    }),
    deleteSingleProductImage: builder.mutation({
      query: ({ id, data }) => ({
        url: `/shop/product/delete-image/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["UpdateProduct"],
    }),
    updatePrimaryImage: builder.mutation({
      query: ({ id, data }) => ({
        url: `/shop/product/change-primary-image/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["UpdateProduct"],
    }),
    uploadNewProductImage: builder.mutation({
      query: ({ id, data }) => ({
        url: `/shop/product/upload-new-image/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["UpdateProduct"],
    }),
    deleteProduct: builder.mutation({
      query: (id) => ({
        url: `/shop/product/${id}`,
        method: "DELETE",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      invalidatesTags: ["ShopProduct"],
    }),
    // Order api
    getAllOrders: builder.query({
      query: ({ page, limit, status }) => ({
        url: `/shop/order/admin/orders?page=${page}&limit=${limit}&status=${status}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      keepUnusedDataFor: 0,
    }),
    getSingleOrder: builder.query({
      query: (id) => ({
        url: `/shop/order/admin/${id}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
      keepUnusedDataFor: 0,
    }),
    updateOrderStatus: builder.mutation({
      query: ({ id, data }) => ({
        url: `/shop/order/admin/order/update/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
        body: data,
      }),
    }),
    getTrendyProducts: builder.query({
      query: ({ limit, page }) => ({
        url: `/shop/product/get-trendy-products?_limit=${limit}&_page=${page}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
    }),
    getAllReviewsByProductID: builder.query({
      query: ({ productID }) => ({
        url: `/shop/product/admin/review/${productID}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("adminToken")}`,
        },
      }),
    }),
  }),
});

export const {
  useAddCategoryMutation,
  useGetAllCategoriesQuery,
  useDeleteCategoryMutation,
  useUpdateCategoryMutation,
  useAddNewProductsMutation,
  useGetAllProductsQuery,
  useGetSingleProductQuery,
  useUpdateSingleProductMutation,
  useDeleteSingleProductImageMutation,
  useUpdatePrimaryImageMutation,
  useUploadNewProductImageMutation,
  useDeleteProductMutation,
  useGetAllOrdersQuery,
  useGetSingleOrderQuery,
  useUpdateOrderStatusMutation,
  useGetTrendyProductsQuery,
  useGetAllReviewsByProductIDQuery,
} = shopApi;

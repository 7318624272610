import React from "react";
import { useGetAllWalletQuery } from "../../../../Redux/features/wallet/walletApi";
import WalletCalculation from "./WalletCalculation";
import UserWallets from "./UserWallets";


const WalletContainer = () => {
  const { data } = useGetAllWalletQuery({
    page: "",
    limit: "",
  });
  return (
    <div className="p-4">
      <WalletCalculation {...{data}} />
      <h1 className="text-2xl font-bold mb-8 mt-12">User Wallets</h1>
      <UserWallets />
    </div>
  );
};

export default WalletContainer;

// configuration
import React from "react";

// components
import { BillingSummaryLeft } from "./BillingSummaryLeft";
import { BillingSummaryRight } from "./BillingSummaryRight";

export const BillingSummary = ({ data }) => {
  return (
    <div className="w-full h-auto bg-white shadow-[2px_2px_10px_2px_rgba(0,0,0,0.12)] rounded-[12px] my-[32px] px-[20px] py-[19px]">
      <div className="w-full grid grid-cols-2 gap-x-[72px]">
        <BillingSummaryLeft {...{ data }} />
        <BillingSummaryRight {...{ data }} />
      </div>
    </div>
  );
};

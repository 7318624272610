// configuration
import React, { useState } from "react";

// Third party packages
import { Swiper, SwiperSlide } from "swiper/react";
import { MdOutlineRecommend } from "react-icons/md";
import { AiOutlineWarning } from "react-icons/ai";
import Skeleton from "@mui/material/Skeleton";
import { Pagination } from "@mui/material";

// components
import { ListingCard } from "../../../Shared/Cards/Shop/Products/ListingCard";
import { useGetAllProductsQuery } from "../../../../Redux/features/Shop/shopApi";

// css files
import "swiper/css";
import "swiper/css/pagination";

export const Listing = () => {
  const [page, setPage] = useState(1);
  const {
    data: allProducts,
    isLoading,
    error,
  } = useGetAllProductsQuery({
    page: page,
    limit: 6,
    keyword: "",
  });

  // js variables
  const loaderArr = [1, 2, 3, 4, 5];

  let content;
  if (isLoading) {
    content = (
      <Swiper slidesPerView={"auto"} spaceBetween={30}>
        {loaderArr?.map((item) => {
          return (
            <SwiperSlide key={item} className="w-[615px] overflow-hidden">
              <div className="w-[615px] h-[320px] bg-[linear-gradient(104.63deg,#75204C_1.61%,_#360C41_97.81%)] rounded-[18px] px-[35px] py-[31px] relative shadow-[2px_2px_10px_2px_rgba(0,0,0,0.12)]">
                <div className="mt-[140px]">
                  <Skeleton
                    animation="wave"
                    style={{ backgroundColor: "#b3afaf" }}
                    width="60%"
                  />
                  <Skeleton
                    animation="wave"
                    style={{ backgroundColor: "#b3afaf" }}
                  />
                  <Skeleton
                    animation="wave"
                    style={{ backgroundColor: "#b3afaf" }}
                    width="30%"
                  />
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    );
  } else if (error) {
    content = (
      <div className="flex flex-col items-center justify-center mt-[100px]">
        <AiOutlineWarning className="text-[42px] text-gray-400" />
        <p className="mt-[10px] text-[16px] font-Inter font-medium text-gray-500">
          An Error occurred! <br /> Please login or try again later!
        </p>
      </div>
    );
  } else if (!error && allProducts?.products?.length === 0) {
    content = (
      <div className="flex flex-col items-center justify-center mt-[100px]">
        <MdOutlineRecommend className="text-[42px] text-gray-400" />
        <p className="mt-[10px] text-[16px] font-Inter font-medium text-gray-500">
          No Products Found!
        </p>
      </div>
    );
  } else if (!error && allProducts?.products?.length > 0) {
    content = (
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={30}
        className="max-w-[1024px]"
      >
        {allProducts?.products?.map((product) => {
          return (
            <SwiperSlide
              key={product?._id}
              className="w-[615px] overflow-hidden"
            >
              <ListingCard {...{ product }} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    );
  }

  return (
    <div className="mb-[115px]">
      <div className="w-full flex justify-between mb-[30px]">
        <p className="text-[#272727] font-semibold text-[32px] text-left">
          Listing
        </p>
        <Pagination
          onChange={(e, value) => {
            setPage(value);
          }}
          count={Math.ceil(allProducts?.total / 6) || 1}
          variant="outlined"
          shape="rounded"
        />
      </div>
      <div>{content}</div>
    </div>
  );
};

import React from "react";

// components
import image from "../../../../assets/AdminRuffImage/pageUnderConstruction.png";

const NotFoundPage = () => {
  return (
    <div>
      <img className="w-3/5 my-16 mx-auto" src={image} alt="" />
    </div>
  );
};

export default NotFoundPage;

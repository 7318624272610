// configuration
import React from "react";
import { useNavigate } from "react-router-dom";

export const RatingsHeader = () => {
    const navigate = useNavigate();
    return (
        <div className="w-full">
            <div className="w-full flex justify-start">
                <button
                    className="shadow-[3px_3px_10px_3px_rgba(0,0,0,0.12)] rounded-[16px] py-[12px] px-[45px] text-[20px] leading-[30px] font-semibold font-poppins"
                    onClick={() => navigate("/lawyer/reviewRatings")}
                >
                    Back to List
                </button>
            </div>
            <div className="mt-[69px] flex justify-center">
                <div className=" bg-[#FFFFF0] w-[385px] rounded-[8px] px-[35px] py-[31px]">
                    <div className="flex gap-x-[24px]">
                        <img className="w-[85px] h-[85px] rounded-full" src="https://i.ibb.co/f4Vxd2k/Ellipse-18.png" alt="profile" />
                        <div className="flex flex-col gap-y-[18px]">
                            <h1 className=" text-[24px] leading-[29px] font-medium font-Inter">Jhon William</h1>
                            <p className=" text-[20px] leading-[24px] font-medium font-Inter">Age: 26</p>
                            <button className="w-[135px] h-[37px] mt-[20px] flex justify-center items-center bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] text-white rounded-[4px] font-Inter">
                                Selected
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

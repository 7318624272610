import React from 'react';

const ModifyStatusPackage = () => {
    return (
        <div>
            Modify Status Package
        </div>
    );
}

export default ModifyStatusPackage;

// Configuration
import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";

const CoursesNav = ({ children }) => {
  const routes = [
    {
      id: 1,
      path: "addCourse",
      name: "Add Course",
    },
    {
      id: 2,
      path: "viewCourse",
      name: "View Course",
    },
    {
      id: 3,
      path: "addCourseSection",
      name: "Add Course Section",
    },
    {
      id: 4,
      path: "editCourse",
      name: "Edit Course",
    },
    {
      id: 5,
      path: "categories",
      name: "Categories",
    },
    {
      id: 6,
      path: "types",
      name: "Types",
    },
    {
      id: 7,
      path: "quiz",
      name: "Quiz",
    },
    {
      id: 8,
      path: "modifyReview",
      name: "Modify Review",
    },
  ];
  const navigate = useNavigate();

  const url = new URL(window.location.href);
  const pathname = url.pathname;
  const parts = pathname.split("/");
  const lastPart = parts[parts.length - 1];

  useEffect(() => {
    if (lastPart === "courses") {
      navigate("/courses/addCourse");
    }
  }, [lastPart, navigate]);

  return (
    <div>
      <div className="max-w-[1050px]">
        <div className="pt-10 mb-10 px-5">
          <ul className="grid grid-cols-3 gap-[41px] font-poppins font-normal text-base text-[#000000]">
            {routes.map((route) => (
              <NavLink
                key={route.id}
                className={({ isActive }) =>
                  `w-full  text-center py-[5px] rounded cursor-pointer text-[15px] font-semibold ${
                    isActive
                      ? " text-white bg-[#E41272]"
                      : "text-[#000000] bg-[#FFFFFF]"
                  }`
                }
                to={route.path}
              >
                {route.name}
              </NavLink>
            ))}
          </ul>
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default CoursesNav;

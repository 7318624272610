// Configuration
import React, { useState } from "react";

import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useAssignLawyerToUserMutation } from "../../../../../Redux/features/User/userApi";

const AssignLawyerToUserCard = ({ lawyer, selectedLawyer }) => {
  const [beforeError, setBeforeError] = useState("");
  const [assignLawyer, { data, isLoading }] = useAssignLawyerToUserMutation();
  const handleClick = () => {
    setBeforeError("");
    if (selectedLawyer) {
      assignLawyer({ user: lawyer._id, lawyer: selectedLawyer });
    } else {
      setBeforeError("Please Select a lawyer first");
    }
  };
  return (
    <div className="py-10 rounded-lg bg-[#FFD0F5]">
      <div className="flex justify-start items-center gap-6 px-9 mb-9">
        <img
          className="w-[85px] h-[85px] rounded-full"
          src={lawyer.profilePhoto}
          alt=""
        />
        <div className="text-left">
          <h3 className="text-2xl leading-[29px] font-medium mb-4">
            {`${lawyer.firstName} ${lawyer.lastName}`}
          </h3>
          <p className="text-xl leading-6">Age : {lawyer.age}</p>
        </div>
      </div>
      <div className="flex justify-center items-center gap-6">
        <button
          onClick={handleClick}
          className="text-base text-white leading-5 font-medium w-[169px] py-[9px] rounded-sm"
          style={{
            backgroundImage:
              "linear-gradient(180deg, #E22989 0%, #A72CC7 100%)",
          }}
        >
          {isLoading ? "Loading..." : "Assign To This User"}
        </button>
      </div>
      {beforeError && (
        <p className="text-[#ff3333] flex items-center justify-center mt-4">
          <ErrorIcon />
          <span>{beforeError}</span>
        </p>
      )}
      {data && (
        <p className="text-[#4BB543] flex items-center justify-center mt-4">
          <CheckCircleOutlineIcon />
          <span>Lawyer assigned</span>
        </p>
      )}
    </div>
  );
};

export default AssignLawyerToUserCard;

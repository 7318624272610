/* eslint-disable react-hooks/exhaustive-deps */
import React,{useEffect,useState} from 'react';
import Error from '../../../../Shared/components/accordion/UI/Error/Error';
import { Toaster, toast } from 'react-hot-toast';
import InputField from '../../partials/InputField';
import SelectProductButton from '../AddShopBundle/SelectProductButton';
import ImageUploader1 from '../../../../Shared/components/InmageUploader/ImageUploader1';
import TextareaField from '../../partials/TextareaField';
import { useForm } from 'react-hook-form';
import { useCreateCourseBundleMutation } from '../../../../../Redux/features/Packages/packagesApi';
import { useSelector } from 'react-redux';

const EditBundle = () => {
  const [image, setImage] = useState(null);
  const products = useSelector((state) => state?.shopPackage?.products);
  const [customErr, setCustomErr] = useState("");

  // redux api
  const [createShopBundle, { data, isLoading, error }] =
    useCreateCourseBundleMutation();
  const { register, handleSubmit, reset } = useForm();
  const onsubmit = (formData) => {
    if (!image) {
      return setCustomErr("Image is Required");
    }
    if (products?.length === 0) {
      return setCustomErr("Please select some product");
    }
    let newData = {
      products,
      ...formData,
      price: Number(formData.price),
      priceInPoint: Number(formData.priceInPoint),
      image,
    };
    createShopBundle(newData);
  };
  useEffect(() => {
    if (data) {
      reset();
      setImage(null);
      toast.success("Bundle created successfully");
    }
    if (error) {
      toast.error("Failed! Please try again");
    }
  }, [data, error]);

  return (
    <div className="min-h-[200vh]">
      <div className="px-5 mx-auto">
        <div className="w-full h-auto shadow-[2px_2px_10px_2px_rgba(0,0,0,0.12)] bg-white rounded-[12px] px-[22px] py-[29px] mb-8">
          <h1 className="text-[#32353B] text-[28px] font-poppins font-semibold text-left">
            Add New Bundle
          </h1>
          <div className="w-full h-[1px] bg-[#E9ECF2] mt-[22px] mb-[30px]"></div>
          <div>
            <form onSubmit={handleSubmit(onsubmit)}>
              <InputField
                {...{
                  register,
                  title: "Bundle Name",
                  fieldName: "name",
                  type: "text",
                  required: true,
                }}
              />
              <TextareaField
                {...{
                  register,
                  title: "Description",
                  fieldName: "description",
                }}
              />
              <div className="mb-3">
                <ImageUploader1
                  {...{ image, setImage, title: "Upload Cover Image" }}
                />
              </div>

              <SelectProductButton />
              <InputField
                {...{
                  register,
                  title: "Price",
                  fieldName: "price",
                  type: "number",
                  required: true,
                }}
              />
              <InputField
                {...{
                  register,
                  title: "Price In Point",
                  fieldName: "priceInPoint",
                  type: "number",
                  required: true,
                }}
              />
              <input
                type="submit"
                value={isLoading ? "Loading" : "Submit"}
                className="bg-[#E41272] text-white w-[129px] h-[47px] rounded-[3px] border-[1px] border-[#3D66D7] mt-[18px] cursor-pointer font-medium hover:bg-opacity-80"
              />
              {customErr && <Error message={customErr} />}
            </form>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default EditBundle;

import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useGetUserCertificatesQuery } from "../../../../Redux/features/Certificates/certificatedApi";
import { BackToList } from "../../../Components/Lawyer/Document_Verification/AllDocumentspage/backtolistbutton/BackToList";
import { CaseStudyCard } from "../../Cards/Lawyer/Case_Study/CaseStudyCard";
import Certificates from "./Certificates";
import Selector from "./Selector";

const DocumentVerification = ({ propsUserId }) => {
  const [title, setTitle] = useState("edu");
  const [certificates, setCertificates] = useState();
  const [searchParams] = useSearchParams();
  const { data } = useGetUserCertificatesQuery(searchParams.get("id"));

  /* Certificate Images object */
  useEffect(() => {
    if (data) {
      if (title === "edu") {
        setCertificates(data?.data?.educations);
      }
      if (title === "prof") {
        setCertificates(data?.data?.professions);
      }
    }
  }, [data, title]);

  return (
    <div className="bg-[#FAFBFF]">
      <BackToList></BackToList>
      <CaseStudyCard id={propsUserId || searchParams.get("id")}></CaseStudyCard>
      <Selector {...{ title, setTitle }} />

      <Certificates {...{ title, certificates }} />
    </div>
  );
};

export default DocumentVerification;

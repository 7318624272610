// configuration
import React from "react";

// Third party packages
import { FiSearch } from "react-icons/fi";

// components
import { ModifyCaseHistoryProfileCard } from "../../../Shared/Cards/Lawyer/Modify__Case__History__Card/ModifyCaseHistoryProfileCard";

export const ModifyCaseHistory = () => {
    // varible declation
    // hook variables

    // js variables
    const demo = [1, 2, 3, 4, 5, 6, 7, 8];
    return (
        <div>
            <div className="flex mx-auto items-center w-[492px] h-[52px] bg-[#FFFFFF] border border-[#FFE8F3] py-[14px] px-[21px] text-base rounded-lg gap-6">
                <FiSearch className="text-[#353751] text-2xl" />
                <input
                    className="font-poppins font-normal text-[#AAAAAA] focus: outline-none h-full w-full"
                    type="search"
                    name="searchUser"
                    id=""
                    placeholder="Search user name"
                />
            </div>
            <div className="grid grid-cols-2 gap-[29px] mt-[65px]">
                {demo.map(p => {
                    return <ModifyCaseHistoryProfileCard key={p} />;
                })}
            </div>
        </div>
    );
};

import React, { useEffect, useState } from "react";

// Third party package
import { AiOutlinePlus } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import { Oval } from "react-loader-spinner";
import { useDeleteBadgeMutation, useUpdateBatchMutation } from "../../../Redux/features/Badge/badgeApi";
import { usePhotosUploadOnServerMutation } from "../../../Redux/features/fileUpload/fileUploadApi";

// Components
import { Modal } from "../../Shared/Modal/Modal";

export const BadgeEdit = ({ id, modalControll, currentBadgeDetailesForEdit }) => {
    const { name, icon } = currentBadgeDetailesForEdit || {};
    // Hook variable declaration
    const [uploadedBadgeForEdit, setUploadedBadgeForEdit] = useState("");
    const [badgeName, setBadgeName] = useState("");

    // Redux api
    const [photosUploadOnServer, { data: uploadedPhotos, isLoading: photoUploadLoading }] = usePhotosUploadOnServerMutation();
    const [deleteBadge, { isLoading: deleteLoading }] = useDeleteBadgeMutation();
    const [updateBatch, { isLoading: updateLoading }] = useUpdateBatchMutation();

    useEffect(() => {
        if (uploadedPhotos) {
            const badge = uploadedPhotos?.data[0]?.path;
            setUploadedBadgeForEdit(badge);
        }
    }, [uploadedPhotos]);

    const badgeUploadHandlerForEdit = e => {
        if (e.target.files) {
            const formData = new FormData();
            const badge = e.target.files[0];
            formData.append("image", badge);
            photosUploadOnServer(formData);
        }
        
    };

    const editBadgeSubmitHandler = async e => {
        e.preventDefault();
        const data = {
            name: badgeName || name,
            icon: uploadedBadgeForEdit || icon,
            description: badgeName,
        };
        await updateBatch({
            id: id,
            data: data,
        });
        modalControll();
    };

    const deleteBadgeHandler = async e => {
        e.preventDefault();
        await deleteBadge(id);
        modalControll();
    };
    return (
        <Modal modalControll={modalControll}>
            <div className="w-full flex justify-between items-center">
                <h3 className="font-semibold font-poppins text-[28px] text-[#32353B]">Edit Badge</h3>
                <ImCross className="w-[35px] h-[35px] cursor-pointer" onClick={modalControll} />
            </div>
            <form className="flex flex-col items-start gap-y-[30px]">
                <input
                    type="text"
                    name="name"
                    className="w-full h-[60px] shadow-[2px_2px_10px_2px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none px-[12px] text-[#707276] text-[14px] font-OpenSans"
                    placeholder="Badge Name"
                    onChange={e => setBadgeName(e.target.value)}
                />
                <div className="flex items-center gap-x-[22px]">
                    <span className="text-[28px] text-[#000000] font-poppins">Icon</span>
                    <label
                        htmlFor="badgeEditIcon"
                        className="flex items-center justify-center gap-x-[27px] bg-white w-[173px] h-[44px] shadow-[2px_2px_10px_2px_rgba(0,0,0,0.12)] rounded-[16px] cursor-pointer"
                    >
                        {photoUploadLoading ? (
                            <Oval
                                height={20}
                                width={20}
                                color="#CC1A7A"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="oval-loading"
                                secondaryColor="#A72CC7"
                                strokeWidth={4}
                                strokeWidthSecondary={4}
                            />
                        ) : (
                            <>
                                {uploadedBadgeForEdit ? (
                                    <>
                                        <span className="text-green-400 font-semibold font-Inter">Added</span>
                                        <img src={uploadedBadgeForEdit} alt="badge" className="w-[30px] h-[30px] rounded-full" />
                                    </>
                                ) : (
                                    <>
                                        Upload Photo
                                        <AiOutlinePlus />
                                    </>
                                )}
                            </>
                        )}
                    </label>
                    <input type="file" name="badgeEditIcon" id="badgeEditIcon" className="hidden" onChange={badgeUploadHandlerForEdit} />
                </div>
                <div className="w-full h-[60px] flex justify-between items-center">
                    <div>
                        <button
                            className="w-[102px] h-[32px] border-[1px] border-[#E41272] rounded-[37px] text-[#E41272] text-[14px] font-Montserrat font-bold uppercase flex justify-center items-center"
                            onClick={deleteBadgeHandler}
                        >
                            {deleteLoading ? (
                                <Oval
                                    height={20}
                                    width={20}
                                    color="#CC1A7A"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                    ariaLabel="oval-loading"
                                    secondaryColor="#A72CC7"
                                    strokeWidth={4}
                                    strokeWidthSecondary={4}
                                />
                            ) : (
                                "delete"
                            )}
                        </button>
                    </div>
                    <div className="flex items-center gap-x-[16px]">
                        <button
                            className="w-[102px] h-[32px] border-[1px] border-[#E41272] rounded-[37px] text-[#E41272] text-[14px] font-Montserrat font-bold uppercase"
                            onClick={modalControll}
                        >
                            Dismiss
                        </button>
                        <button
                            className={`w-[102px] h-[32px] rounded-[96px] text-white bg-[linear-gradient(90deg,_#CC1A7A_0%,_#A72CC7_95.57%)] flex justify-center items-center text-[14px] font-bold font-Inter cursor-pointer`}
                            onClick={editBadgeSubmitHandler}
                            disabled={updateLoading}
                        >
                            {updateLoading ? (
                                <Oval
                                    height={20}
                                    width={20}
                                    color="#FFFFFF"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                    ariaLabel="oval-loading"
                                    secondaryColor="#808080"
                                    strokeWidth={4}
                                    strokeWidthSecondary={4}
                                />
                            ) : (
                                "Confirm"
                            )}
                        </button>
                    </div>
                </div>
            </form>
        </Modal>
    );
};

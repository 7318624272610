// configuration
import React from "react";

// Third party packages
import { BiEdit } from "react-icons/bi";
import { CiShoppingCart } from "react-icons/ci";

export const TopMonthSellerCard = ({ picture }) => {
  return (
    <div className="card w-[390px] h-[427px] bg-white py-[17px] mx-auto">
      <div className="w-full flex justify-center">
        <button className="bg-[#E41272] w-[113px] h-[48px] text-white flex items-center text-[20px] font-bold font-Inter rounded-[100px] justify-center gap-x-[8px]">
          Edit
          <BiEdit />
        </button>
      </div>
      <div className="w-[322px] mx-auto mt-[16px]">
        <img
          src={picture}
          alt="photoOFUser"
          className="w-full h-[208px] rounded-[12px]"
        />
        <div className="content mt-[16px]">
          <div className="w-[228px] flex justify-between">
            <p className="text-[18px] font-normal text-[#272727]">৳500</p>
            <CiShoppingCart className="text-[24px] text-gray-400" />
          </div>
          <p className="text-[#272727] text-left w-[228px] text-[14px] font-semibold font-playFair mt-[16px]">
            Premium Beautiful Cloth for man and woman
          </p>
          <div className="flex items-center mt-[16px] gap-x-[16px]">
            <div className="flex items-center gap-x-[6px]">
              <div className="w-[16px] h-[16px] rounded-full bg-[#CFCFCF]"></div>
              <p className="text-[14px] text-[#CFCFCF] font-normal font-playFair">
                Jacket
              </p>
            </div>
            <div className="flex items-center gap-x-[6px]">
              <div className="w-[16px] h-[16px] rounded-full bg-[#CFCFCF]"></div>
              <p className="text-[14px] text-[#CFCFCF] font-normal font-playFair">
                Jacket
              </p>
            </div>
            <div className="flex items-center gap-x-[6px]">
              <div className="w-[16px] h-[16px] rounded-full bg-[#CFCFCF]"></div>
              <p className="text-[14px] text-[#CFCFCF] font-normal font-playFair">
                Jacket
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

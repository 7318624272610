import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";
import costCalculatorSlice from "../features/costCalculator/costCalculatorSlice";
// import shopPackageSlice from "../features/ShopPackage/shopPackageSlice";
import restrictSuspendUserSlice from "../features/RestrictSuspend/User/restrictSuspendUserSlice";
import priorityUserSlice from "../features/User/PriorityUser/priorityUserSlice";
import userFilter from "../features/User/userFilter/userFilter";
import userPlanSlice from "../features/usersPlan/userPlanSlice";
import shopPackageSlice from "../features/Packages/shopPackageSlice";
import coursePackageSlice from "../features/Packages/coursePackageSlice";
// import coursePackageSlice from "../features/CoursePackage/coursePackageSlice";

const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        restrictSuspend: restrictSuspendUserSlice,
        filter: userFilter,
        plans: userPlanSlice,
        markPriority: priorityUserSlice,
        costCalculator: costCalculatorSlice,
        shopPackage: shopPackageSlice,
        coursePackage:coursePackageSlice
    },
    middleware: getDefaultMiddlewares =>
        getDefaultMiddlewares({
            serializableCheck: false,
        }).concat(apiSlice.middleware),
});

export default store;

// configuration
import React, { useState } from "react";

// Third party packages
import { Pagination } from "@mui/material";
import { AiOutlineWarning } from "react-icons/ai";
import { MdOutlineRecommend } from "react-icons/md";

// components
import { ApprovedUserLoader } from "../../../Shared/Loader/User/ApprovedUserLoader";
import SearchManyUsers from "../../../Shared/components/SearchUsers/SearchManyUsers";
import { ModifyAgentProfileCard } from "../../../Shared/Cards/Agent/ModifyAgentProfileCard/ModifyAgentProfileCard";

const AgentProfile = () => {
  const [userObj, setUser] = useState({});
  const [page, setPage] = useState(1);

  const { isLoading, error } = userObj;

  let content;
  if (isLoading) {
    content = (
      <div className="w-full flex gap-y-[20px]">
        <ApprovedUserLoader />
        <ApprovedUserLoader />
      </div>
    );
  } else if (error) {
    content = (
      <div className="flex flex-col items-center justify-center mt-[100px]">
        <AiOutlineWarning className="text-[42px] text-gray-400" />
        <p className="mt-[10px] text-[16px] font-Inter font-medium text-gray-500">
          An Error occurred! <br /> Please login or try again later!
        </p>
      </div>
    );
  } else if (!error && userObj?.data?.users?.length === 0) {
    content = (
      <div className="flex flex-col items-center justify-center mt-[100px]">
        <MdOutlineRecommend className="text-[42px] text-gray-400" />
        <p className="mt-[10px] text-[16px] font-Inter font-medium text-gray-500">
          No User Found!
        </p>
      </div>
    );
  } else if (!error && userObj?.data?.users?.length > 0) {
    content = (
      <section className="grid grid-cols-2 gap-[30px] mt-16">
        {userObj?.data?.users?.map((agent) => (
          <ModifyAgentProfileCard key={agent._id} {...{ agent }} />
        ))}
      </section>
    );
  }

  return (
    <div>
      <SearchManyUsers setUser={setUser} page={page} role="agent" />
      <div>{content}</div>
      {/* Pagination */}
      <div className="flex justify-around items-center my-12">
        <p className="leading-6 font-medium">
          Showing {userObj?.data?.users?.length} from {userObj?.data?.total}{" "}
          data
        </p>
        <Pagination
          className="bg-[#E3E4EB] px-[1px] py-[3px] rounded-md text-white"
          onChange={(event, value) => {
            setPage(value);
          }}
          count={Math.ceil(userObj?.data?.total / 8) || 1}
          shape="rounded"
        />
      </div>
    </div>
  );
};
export default AgentProfile;

import React from 'react';

const Selector = ({ title, setTitle }) => {
  return (
    <div className="w-full flex items-center justify-center my-6 h-24">
      <div
        className={
          "flex items-center bg-gray-200 w-[400px] h-16 rounded-xl overflow-hidden"
        }
      >
        <button
          onClick={() => setTitle("edu")}
          className={`w-1/2 font-semibold h-full flex justify-center items-center ${
            title === "edu"
              ? "bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] text-white"
              : "bg-gray-200"
          } `}
        >
          Educational Certificates
        </button>
        <button
          onClick={() => setTitle("prof")}
          className={`w-1/2 font-semibold h-full flex justify-center items-center ${
            title === "prof"
              ? "bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] text-white"
              : "bg-gray-200"
          } `}
        >
          Professional Certificates
        </button>
      </div>
    </div>
  );
};

export default Selector;

// configuration, ex: react-router
import React, { useState, useEffect } from "react";

// Third Party Packages
import { toast, Toaster } from "react-hot-toast";
import Pagination from "@mui/material/Pagination";

// components
import { CircularProgress } from "@mui/material";
import TextArea from "antd/es/input/TextArea";
import useDocumentTitle from "../../../../helper/utilities/useDocumentTitle";
import { useSendNotificationToManyMutation } from "../../../../Redux/features/notification/notification";
import { useGetAllPlansQuery } from "../../../../Redux/features/usersPlan/userPlansApi";
import { SendForPaymentCard } from "../../../Shared/Cards/Send_For_Payment_Card/SendForPaymentCard";
import SearchManyUsers from "../../../Shared/components/SearchUsers/SearchManyUsers";
import RecentPaidUserTable from "./RecentPaidUserTable";

const SendForPayment = ({ notificationFor }) => {
  const [searchQuery, setSearchQuery] = useState([]);
  const [page, setPage] = useState(1);
  const [userObj, setUser] = useState({});
  const [message, setMessage] = useState("");
  const { data: plans } = useGetAllPlansQuery();
  const [sendNotification, { data, isLoading, error }] =
    useSendNotificationToManyMutation();
  const handlePlanSelection = (item) => {
    if (searchQuery.filter((i) => i?.planTitle === item.title)?.length) {
      setSearchQuery(searchQuery.filter((i) => i?.planTitle !== item.title));
    } else {
      setSearchQuery([...searchQuery, { planTitle: item.title }]);
    }
  };

  const handleSendNotification = () => {
    if (searchQuery.length === 0 || !message) {
      toast.error("Please Select user and Type a message");
    } else {
      sendNotification({ message, users: searchQuery });
    }
  };

  useEffect(() => {
    setPage(1);
    setMessage("");
    setSearchQuery([]);
  }, [notificationFor]);
  
  useEffect(() => {
    if (data) {
      toast.success(data?.message);
      setMessage("");
      setSearchQuery([]);
    }
    if (error) {
      toast.error(error?.data?.message);
    }
  }, [data, error]);

  // page title
  useDocumentTitle(`Admin | Send ${notificationFor} Notifications`);
  return (
    <div>
      <div className="mt-[20px]">
        <h1 className="text-[#333333] text-[32px] mb-5 leading-[44px] font-semibold font-Nunito">
          Send Notification For {notificationFor}
        </h1>
        <SearchManyUsers role="member" setUser={setUser} page={page} />
        {userObj?.data && (
          <div className="mt-[44px] grid grid-cols-2 gap-[29px] ml-[30px]">
            {!userObj?.isLoading &&
              !userObj?.error &&
              userObj?.data?.users?.map((item) => (
                <SendForPaymentCard
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  user={item}
                ></SendForPaymentCard>
              ))}
          </div>
        )}
        {userObj?.isLoading && (
          <div className="flex items-center justify-center my-24">
            <CircularProgress />
          </div>
        )}
      </div>

      <div className="flex justify-between items-center my-[62px] ">
        <p className="text-[#3E4954] leading-6 font-medium">
          Showing {userObj?.data?.users?.length} from {userObj?.data?.total}{" "}
          data
        </p>
        {userObj?.data && (
          <Pagination
            className="bg-[#E3E4EB] px-[1px] py-[3px] rounded-md text-white"
            page={page}
            onChange={(event, value) => {
              setPage(value);
              setUser(null);
            }}
            count={Math.ceil(userObj?.data?.total / 8)}
            shape="rounded"
          />
        )}
      </div>

      <div>
        <TextArea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          rows={16}
          placeholder="Write Your Message"
        />
      </div>
      <p className="text-[24px] send-notification-text mt-5 mb-5">
        Send Notification To:
      </p>
      <div className="grid grid-cols-4 gap-4">
        {plans &&
          plans?.data?.plans?.map((item) => {
            return (
              <p
                onClick={() => handlePlanSelection(item)}
                key={item._id}
                className={`pl-8 cursor-pointer rounded-lg shadow-lg pr-8 pt-2 pb-2 text-[24px] border-2 ${
                  searchQuery.filter((i) => i?.planTitle === item.title)?.length
                    ? "bg-[#E41272] text-white"
                    : ""
                }`}
              >
                {item.title} User
              </p>
            );
          })}
      </div>
      <div className=" flex items-center justify-center flex-col">
        <button
          onClick={handleSendNotification}
          className="cursor-pointer text-white px-8 w-[25%] mb-4 mt-12 py-2 text-[24px] bg-gradient-to-b from-[#DF298C] to-[#B02BBE]"
        >
          {`${isLoading ? "Loading" : "Send Now"}`}
        </button>
      </div>
      <RecentPaidUserTable></RecentPaidUserTable>
      <Toaster />
    </div>
  );
};
export default SendForPayment;

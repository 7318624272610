import React from "react";

const UserActivitiesCard = ({ user, selectedId, setSelectedId }) => {
  return (
    <div>
      <div>
        <div>
          <img className="w-64 h-72" src={user?.profilePhoto} alt="" />
        </div>
        <h3 className="my-6 text-xl leading-8 font-medium">
          {user?.firstName + " " + user?.lastName}
        </h3>
        {selectedId === user?._id ? (
          <button
            className="rounded-lg w-full text-center py-2 text-white"
            style={{
              backgroundImage:
                "linear-gradient(94.59deg, #E41272 26.33%, #942DD9 78.54%)",
            }}
          >
            Selected
          </button>
        ) : (
          <button
            onClick={() => setSelectedId(user?._id)}
            className="bg-[#D9D9D9] rounded-lg w-full text-center py-2"
          >
            Select
          </button>
        )}
      </div>
    </div>
  );
};

export default UserActivitiesCard;

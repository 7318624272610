import React, { useState, useEffect } from "react";
import VerificationButton from "../../../Components/Lawyer/Document_Verification/AllDocumentspage/DocumentsVerification/VerificationButton/VerificationButton";
import HeaderImg from "../../../Components/User/DocumentsVerification/HeaderImg/HeaderImg";
import ImgNumberIndicator from "../../../Components/User/MarraigeCertificateVerification/ImgNumberIndicator/ImgNumberIndicator";
import CertificateSelector from "./CertificateSelector";

const Certificates = ({ title, certificates }) => {
  const [selected, setSelected] = useState();
  const [selectedPhoto, setSelectedPhoto] = useState();
  useEffect(() => {
    if (certificates) {
      setSelected(certificates[0]);
    }
  }, [certificates]);

  useEffect(() => {
    if (selected) {
      setSelectedPhoto(selected?.certificates[0]);
    }
  }, [selected]);

  return (
    <>
      <div className="grid grid-cols-6 gap-1">
        {certificates?.map((item, index) => (
          <CertificateSelector
            {...{ index, item, selected, setSelected, title }}
          />
        ))}
      </div>

      <div className="font-poppins font-medium pt-[66px]">
        {/* Header Img */}
        {selectedPhoto && (
          <HeaderImg selectCertificate={selectedPhoto}></HeaderImg>
        )}

        {/* Img number indicator */}
        {selected?.certificates?.length !== 0 && (
          <ImgNumberIndicator
            {...{
              photos: selected?.certificates,
              selectedPhoto,
              setSelectedPhoto,
            }}
          />
        )}

        {/* Certificate banner images */}
        <div className="grid grid-cols-2 gap-x-[25px] gap-y-[80px] w-full pb-[65px] font-poppins font-medium text-[18px] text-[#000000]">
          {selected?.certificates?.map((certificate, index) => (
            <div
              onClick={() => setSelectedPhoto(certificate)}
              key={certificate._id}
              className="h-[237px] w-full mb-10"
            >
              <img
                className={`h-full w-full rounded-xl cursor-pointer  ${
                  certificate?._id === selectedPhoto?._id
                    ? "border-2 border-[#E41272]"
                    : "border-0"
                }`}
                src={certificate?.photo}
                alt="certificate"
              />
              <div className="cursor-pointer lawyer-certificate w-48 mx-auto mt-2 rounded-xl">
                <p className="pt-[10px] pb-[10px]">Certificate {index + 1}</p>
              </div>
            </div>
          ))}
        </div>

        {selected?.certificates?.length === 0 && (
          <div className="text-yellow-600 text-center flex items-center justify-center">
            <span>{/* <WarningIcon /> */}</span> No Certificates found
          </div>
        )}
      </div>
      {/* verification buttons */}
      {selectedPhoto && (
        <VerificationButton {...{selected, selectedPhoto}} />
      )}
    </>
  );
};

export default Certificates;

import getCookie from "../../../helper/cookies/getCookie";
import { apiSlice } from "../../api/apiSlice";

export const reviewsApi = apiSlice.injectEndpoints({
    endpoints: builder => ({
        updateReviewByAdmin: builder.mutation({
            query: ({ id, data }) => ({
                url: `/service/admin/review/update/${id}`,
                method: "PUT",
                headers: {
                    authorization: `Bearer ${getCookie("adminToken")}`,
                },
                body: data,
            }),
            invalidatesTags: ["AllReviews"],
        }),
    }),
});

export const { useUpdateReviewByAdminMutation } = reviewsApi;

// Configuration
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

// components
import {
  useApproveMemberMutation,
  useRejectMemberMutation,
  useSendToResubmitMemberMutation,
} from "../../../../../Redux/features/User/registrationRequestApi";
import { useGetProfileDetailsQuery } from "../../../../../Redux/features/User/userApi";
import ProfileAccordion from "../../../../Shared/ProfileAccordion/ProfileAccordion";

const ViewRegistrationForUserInfo = () => {
  const navigate = useNavigate();
  const [
    approveMember,
    { data: approveResponse, isLoading: approvedResponseLoading },
  ] = useApproveMemberMutation();
  const [rejectMember, { data: rejectResponse, isLoading: rejectLoading }] =
    useRejectMemberMutation();
  const [
    sendToResubmitMember,
    { data: resubmitResponse, isLoading: resubmitLoading },
  ] = useSendToResubmitMemberMutation();
  const { requestedId } = useParams();
  const { data: registration, isLoading } =
    useGetProfileDetailsQuery(requestedId);

  //   finding user from all data
  const { profilePhoto, role } = registration || {};

  //   check decision response
  useEffect(() => {
    if (approveResponse || rejectResponse || resubmitResponse) {
      if (approveResponse?.success) {
        navigate(-1);
      }
      if (rejectResponse?.success) {
        navigate(-1);
      }
      if (resubmitResponse?.success) {
        navigate(-1);
      }
    }
  }, [navigate, approveResponse, rejectResponse, resubmitResponse]);

  return (
    <div>
      {/* card section */}
      <div className="mx-auto w-[385px] mt-[96px]">
        <div className="rounded-lg font-inter font-medium text-2xl w-[385px] bg-[#301B6B]">
          <div className="flex items-center justify-center gap-5 pt-6 pb-7 text-[#FFFFFF]">
            <p className="font-bold text-[15px]">Current Status:</p>
            <button
              style={{ boxShadow: "4px 4px 8px 4px rgba(0, 0, 0, 0.1)" }}
              className="font-medium text-base py-[9px] px-[30px] bg-gradient-to-b from-[#B42BB8] to-[#E41272] rounded"
            >
              Pending
            </button>
          </div>
          <div className="flex gap-6 pl-[35px] text-white">
            <img
              src={
                profilePhoto
                  ? profilePhoto
                  : "https://i.postimg.cc/QtSSf7Cw/download.jpg"
              }
              className="h-[85px] w-[85px] rounded-full"
              alt="profile"
            />
            <div className="text-left">
              <h1 className="mb-4">
                {registration?.firstName + " " + registration?.lastName}
              </h1>
              <p className="font-normal text-xl">Age : {registration?.age}</p>
            </div>
          </div>
          <div className="text-center mt-11 pb-[39px]">
            <button
              onClick={() => navigate(-1)}
              className="py-[9px] px-[49px] rounded text-[16px] bg-gradient-to-b from-[#DF298C] to-[#B02BBE] text-white"
            >
              Back To List
            </button>
          </div>
        </div>
      </div>
      {/* Buttons */}
      <div className="flex items-center justify-center gap-[52px] font-Inter font-medium text-base text-[#FFFFFF] pt-11 pb-[113px]">
        <button
          className="py-[9px] px-[35px] bg-gradient-to-b from-[#E22989] to-[#A72CC7] rounded"
          onClick={() => approveMember(registration._id)}
        >
          {approvedResponseLoading ? "Loading..." : "Approve"}
        </button>
        <button
          className="py-[9px] px-5 bg-gradient-to-b from-[#E22989] to-[#A72CC7] rounded"
          onClick={() => rejectMember(registration._id)}
        >
          {rejectLoading ? "Loading..." : "Reject"}
        </button>
        <button
          className="py-[9px] px-4 bg-gradient-to-b from-[#E22989] to-[#A72CC7] rounded"
          onClick={() => sendToResubmitMember(registration._id)}
        >
          {resubmitLoading ? "Loading..." : "Send for Resubmit"}
        </button>
      </div>
      {/* Accordion */}
      <ProfileAccordion
        {...{ data: registration, isLoading, profileRole: role }}
      ></ProfileAccordion>
    </div>
  );
};

export default ViewRegistrationForUserInfo;

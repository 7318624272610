const restrictSuspendOption = [
    { id: 1, label: "1 Day", value: 1 },
    { id: 2, label: "3 Day", value: 3 },
    { id: 3, label: "7 Day", value: 7 },
    { id: 4, label: "10 Day", value: 10 },
    { id: 5, label: "12 Day", value: 12 },
    { id: 6, label: "15 Day", value: 15 },
    { id: 7, label: "17 Day", value: 17 },
    { id: 8, label: "20 Day", value: 20 },
    { id: 9, label: "25 Day", value: 25 },
    { id: 10, label: "45 Day", value: 45 },
    { id: 11, label: "50 Day", value: 50 },
    { id: 12, label: "2 Month", value: 60 },
    { id: 13, label: "3 Month", value: 90 },
    { id: 14, label: "6 Month", value: 180 },
    { id: 15, label: "1 Year", value: 365 },
    { id: 16, label: "Lifetime", value: 3650 },
];

export default restrictSuspendOption;

import React from "react";
import { useEffect } from "react";
import { useState } from "react";

/* Third party package */
import { useForm } from "react-hook-form";
import { toast, Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetSingleCaseByIdQuery,
  useUpdateCaseStudyMutation,
} from "../../../../Redux/features/Services/servicesApi";

/* components */
import { CaseDetailsFields } from "./InputFields/CaseDetailsFields";
import { CasePeriodFields } from "./InputFields/CasePeriodFields";
import { CaseServiceFields } from "./InputFields/CaseServiceFields";
import { CourtFields } from "./InputFields/CourtFields";
import { ProfessionProofFields } from "./InputFields/ProfessionProofFields";
import { ResultFields } from "./InputFields/ResultFields";
import { RoleFields } from "./InputFields/RoleFields";
import { SpecialAchievementsFields } from "./InputFields/SpecialAchievementsFields";

const ModifyCaseForm = ({ role }) => {
  const [editedPeriod, setPeriod] = useState();
  const [editedCourt, setCourt] = useState();
  const [editedCertificate, setCertificate] = useState();
  /* React hook form function */
  const { register, handleSubmit, reset } = useForm();

  const { id } = useParams();

  const navigate = useNavigate();

  // Redux api
  const [updateCaseStudy, { data: response, isLoading, error }] =
    useUpdateCaseStudyMutation();
  const { data: getCaseResponse } = useGetSingleCaseByIdQuery(id);
  const { name, details, achievement, certificate, court, period, result } =
    getCaseResponse?.serviceStudy || {};

  const onSubmit = async (data) => {
    data.name = data.name || name;
    data.details = data.details || details;
    data.achievement = data.achievement || achievement;
    data.result = data.result || result;
    data.period = editedPeriod || period;
    data.court = editedCourt || court;
    data.certificate = editedCertificate || certificate;

    await updateCaseStudy({ id, data });
  };

  useEffect(() => {
    if (response) {
      toast.success("Case Edited Successfully");
      reset();
      navigate(-1);
    }
    if (!response && error) {
      toast.error("Something went wrong. Please try again");
    }
  }, [response, reset, error, navigate]);

  return (
    <div>
      {/* Back to ModifyCaseStudy */}
      <div className="flex justify-start font-poppins font-semibold text-xl text-[#000000] mt-[77px] mb-[55px]">
        <button
          onClick={() => navigate(-1)}
          style={{ boxShadow: "2px 2px 10px 2px rgba(0, 0, 0, 0.12)" }}
          className="py-3 px-[45px] rounded-2xl bg-[#FFFFFF]"
        >
          Back To Service List
        </button>
      </div>
      <h1 className="font-poppins font-normal text-left text-[29px] text-[#32353B]">
        {role === "lawyer" && "Modify Case Study"}
        {role === "agent" && "Modify Deal Status"}
        {role === "kazi" && "Modify Deal Status"}
      </h1>
      <div className="mt-[19px] mb-[39px] w-full border border-[#E9ECF2]"></div>

      {/* Modify case form */}
      <div className="text-left font-sans font-bold text-xs text-[#707276]">
        <form onSubmit={handleSubmit(onSubmit)}>
          <CaseServiceFields {...{ register, name: "name" }} />
          <RoleFields {...{ role }} />
          <CaseDetailsFields {...{ register, name: "details" }} />
          <CasePeriodFields {...{ setPeriod }} />
          <CourtFields {...{ setCourt }} />
          <ResultFields {...{ register, name: "result" }} />
          <SpecialAchievementsFields {...{ register, name: "achievement" }} />
          <ProfessionProofFields {...{ setCertificate, certificate }} />
          <input
            className="px-[35px] py-[15px] text-white bg-[#E41272] border border-[#3D66D7] rounded-[3px] mb-10 cursor-pointer"
            type="submit"
            value={isLoading ? "Loading..." : "Submit"}
          />
        </form>
      </div>
      <Toaster />
    </div>
  );
};

export default ModifyCaseForm;

import Support from "../../Admin__Portion/Components/Support/Support";
import SingleSupportTicketDetails from "../../Admin__Portion/Components/Support/SupportTicket/SingleSupportTicketDetails/SingleSupportTicketDetails";
import SupportTicket from "../../Admin__Portion/Components/Support/SupportTicket/SupportTicket";

const supportRoutes = [
  {
    id: 1,
    path: "/support",
    element: <Support />,
    nestedRoutes: [
      {
        id: 1,
        path: "supportTicket",
        element: <SupportTicket />,
      },
      {
        id: 2,
        path: "supportTicket/:id",
        element: <SingleSupportTicketDetails />,
      },
    ],
  },
];

export default supportRoutes;

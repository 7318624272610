// Configuration
import React, { useState, useEffect } from "react";

// Third party package
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { Oval } from "react-loader-spinner";
import { AiOutlinePlus } from "react-icons/ai";

// Components
import {
  useCreateContentMutation,
  useUpdateContentMutation,
} from "../../../../../../Redux/features/Course/courseApi";
import Error from "../../../../../Shared/components/accordion/UI/Error/Error";
import { usePhotosUploadOnServerMutation } from "../../../../../../Redux/features/fileUpload/fileUploadApi";

const AddContentForm = ({
  id,
  setContentFormVisible,
  contentForEdit,
  setContentEditFormVisible,
  formType,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [uploadedVideo, setUploadedVideo] = useState("");
  // Redux api
  const [
    photosUploadOnServer,
    { data: videoUploadResponse, isLoading: videoUploadLoading },
  ] = usePhotosUploadOnServerMutation();
  const [createContent, { data: contentResponse, isLoading, error }] =
    useCreateContentMutation();

  const videoUploadHandler = (e) => {
    if (e.target.files) {
      const formData = new FormData();
      const video = e.target.files[0];
      formData.append("image", video);
      photosUploadOnServer(formData);
    }
  };

  useEffect(() => {
    if (videoUploadResponse) {
      const video = videoUploadResponse?.data[0]?.path;
      setUploadedVideo(video);
    }
  }, [videoUploadResponse]);

  const [
    updateContent,
    {
      data: contentEditResponse,
      isLoading: contentEditLoading,
      error: contentEditError,
    },
  ] = useUpdateContentMutation();

  const onSubmit = async (data) => {
    if (formType === "editForm") {
      data.name = data.name || contentForEdit?.name;
      data.video = uploadedVideo || contentForEdit?.video;
      await updateContent({ id: contentForEdit?._id, data: data });
    } else {
      data.module = id;
      data.video = uploadedVideo;
      await createContent(data);
    }
  };

  useEffect(() => {
    if (contentResponse) {
      toast.success("Content Created Successfully");
      reset();
      setUploadedVideo("");
      setContentFormVisible(false);
    }
    if (error) {
      toast.error(error?.data?.message);
      reset();
      setUploadedVideo("");
      setContentFormVisible(false);
    }
    if (contentEditResponse) {
      toast.success("Content Edited Successfully");
      reset();
      setUploadedVideo("");
      setContentEditFormVisible(false);
    }
    if (contentEditError) {
      toast.error("Something went wrong. Please login or try again later");
      reset();
      setUploadedVideo("");
      setContentEditFormVisible(false);
    }
  }, [
    contentResponse,
    error,
    reset,
    setContentFormVisible,
    contentEditResponse,
    contentEditError,
    setContentEditFormVisible,
  ]);

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col items-start w-full gap-y-5"
      >
        {/* Content Name */}
        <div className="w-full flex flex-col">
          <label className="text-left mb-1 text-[12px] font-bold text-[#707276] ml-[5px]">
            Content Name
          </label>
          <input
            {...register(
              "name",
              formType !== "editForm" && {
                required: "Content Name is required",
              }
            )}
            className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-4 px-[12px]"
            type="text"
            placeholder="Content Name"
          />
          {errors?.name && <Error message={errors?.name?.message} />}
        </div>

        {/* Video Duration */}
        <div className="w-full flex flex-col">
          <label className="text-left mb-1 text-[12px] font-bold text-[#707276] ml-[5px]">
            Video Duration (Minute)
          </label>
          <input
            {...register(
              "duration",
              formType !== "editForm"
                ? {
                    required: "Video Duration is required",
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Input must be a number",
                    },
                    min: 1,
                  }
                : {
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Input must be a number",
                    },
                    min: 1,
                  }
            )}
            className="h-[60px] w-full bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.1)] rounded-[3.6px] outline-none text-[#707276] text-[14px] font-OpenSans py-4 px-[12px]"
            type="number"
            placeholder="Video Duration"
          />
          {errors?.duration && <Error message={errors?.duration?.message} />}
        </div>

        {/* Video upload */}
        <div className="w-full flex flex-col">
          <label className="text-left mb-1 text-[12px] font-bold text-[#707276] ml-[5px]">
            Upload Video
          </label>
          <div className="flex items-center gap-x-[22px] w-full">
            <label
              htmlFor="videoUpload"
              className="flex items-center justify-center gap-x-[27px] bg-white w-full h-[44px] shadow-[2px_2px_10px_2px_rgba(0,0,0,0.1)] rounded-[4px] cursor-pointer"
            >
              {videoUploadLoading ? (
                <Oval
                  height={20}
                  width={20}
                  color="#CC1A7A"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#A72CC7"
                  strokeWidth={4}
                  strokeWidthSecondary={4}
                />
              ) : (
                <>
                  {uploadedVideo ? (
                    <>
                      <span className="text-green-400 font-semibold font-Inter">
                        Video Added
                      </span>
                    </>
                  ) : (
                    <p className="flex items-center gap-x-2">
                      Upload Video
                      <AiOutlinePlus />
                    </p>
                  )}
                </>
              )}
            </label>
          </div>
          <input
            type="file"
            name="videoUpload"
            accept="video/*"
            id="videoUpload"
            className="hidden"
            onChange={videoUploadHandler}
          />
        </div>

        <input
          type="submit"
          value={isLoading || contentEditLoading ? "Loading..." : "Submit"}
          className="py-2 px-8 rounded-md font-Inter bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] text-white font-medium text-lg cursor-pointer hover:bg-[linear-gradient(166deg,rgb(148,45,217)_0%,rgb(242,40,118)_100%)]"
        />
      </form>
    </div>
  );
};

export default AddContentForm;

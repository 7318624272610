import React from "react";
import { Outlet } from "react-router-dom";
import LawyerPackageNav from "./LawyerPackageNav/LawyerPackageNav";

const LawyerPackage = () => {
  return (
    <LawyerPackageNav>
      <Outlet />
    </LawyerPackageNav>
  );
};

export default LawyerPackage;

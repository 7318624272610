import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";

export const ProductRow = ({ product, setGrandProducts, grandProducts, selectedProducts, setSelectedProducts }) => {
    const [subTotal, setSubTotal] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const [delivery, setDelivery] = useState(0);
    const [disCount, setDisCount] = useState(0);
    const { photos, name, price, _id } = product || {};
    let productName = name?.length > 29 ? name?.slice(0, 29) + "..." : name;

    useEffect(() => {
        setSubTotal(price);
    }, [product, price]);

    const updateDeliveryCharge = e => {
        const value = e.target.value;
        setDelivery(value);
    };

    const updateQuantity = e => {
        const value = e.target.value;
        setQuantity(value);
    };

    const updateDisCount = e => {
        const value = e.target.value;
        setDisCount(value);
    };

    const calculate = () => {
        const sumWithQuantity = price * quantity;
        const sumWithDelivery = sumWithQuantity + Number(delivery);
        const percentage = (sumWithDelivery / 100) * disCount;
        setSubTotal(sumWithDelivery - percentage);

        const updatedGrandProducts = grandProducts?.map(product => {
            if (product._id === _id) {
                return { ...product, subTotal: sumWithDelivery - percentage };
            }
            return product;
        });

        setGrandProducts(updatedGrandProducts);
    };

    const reset = () => {
        setQuantity(1);
        setSubTotal(price);
        setDelivery(0);
        setDisCount(0);
        const updatedGrandProducts = grandProducts?.map(product => {
            if (product._id === _id) {
                return { ...product, subTotal: price };
            }
            return product;
        });
        setGrandProducts(updatedGrandProducts);
    };

    const handleRemoveProduct = () => {
        const filteredProduct = selectedProducts?.filter(item => {
            return item.value._id !== _id;
        });

        setSelectedProducts(filteredProduct);
    };

    return (
        <tr className="w-full flex justify-between px-[20px] py-[15px]">
            <td>
                <AiOutlineClose className="text-gray-500 hover:text-black cursor-pointer" onClick={handleRemoveProduct} />
            </td>
            <td className="text-center w-full flex justify-center">
                <img src={photos[0]?.url} alt="product" className="w-[40px] h-[40px]" />
            </td>
            <td className="text-center w-full">{productName}</td>
            <td className="text-center w-full">{price}</td>
            <td className="text-center w-full">
                <input type="number" value={delivery} onChange={updateDeliveryCharge} className="w-1/2 border border-solid text-center" />
            </td>
            <td className="text-center w-full">
                <input type="number" value={quantity} onChange={updateQuantity} className="w-1/2 border border-solid text-center" />
            </td>
            <td className="text-center w-full">
                <input type="number" value={disCount} onChange={updateDisCount} className="w-1/2 border border-solid text-center" />
            </td>
            <td className="text-center w-full">{subTotal}</td>
            <td className="text-center w-full">
                <Button
                    variant="text"
                    size="small"
                    onClick={calculate}
                    sx={{
                        fontSize: "12px",
                    }}
                >
                    Apply
                </Button>
            </td>
            <td className="text-center w-full">
                <Button
                    variant="text"
                    size="small"
                    onClick={reset}
                    sx={{
                        fontSize: "12px",
                        color: "red",
                    }}
                >
                    Reset
                </Button>
            </td>
        </tr>
    );
};

// configuration
import React from "react";
import { useNavigate } from "react-router-dom";

// Third party packages
import { Tooltip } from "antd";
import { BiEdit } from "react-icons/bi";

export const TrendyProductCard = ({ product }) => {
  const navigate = useNavigate();
  const name =
    product?.name?.length > 24
      ? product?.name?.substr(0, 24) + "..."
      : product?.name;
  return (
    <div className="h-auto shadow-xl pb-4">
      <img
        src={product?.photos[0]?.url}
        className="w-[243px] h-[252px] rounded-t-[40px] cursor-pointer"
        alt="product"
        onClick={() => navigate(`/shop/product/${product?._id}`)}
      />
      <div className="font-playFair text-[#272727] flex flex-col items-start p-3 pb-0">
        <Tooltip
          onClick={() => navigate(`/shop/product/${product?._id}`)}
          className="text-lg cursor-pointer hover:text-gray-500"
          title={product?.name}
        >
          <span>{name}</span>
        </Tooltip>
        <p className="text-sm">৳ {product?.price}</p>
      </div>
      <div className="w-full flex justify-center mt-[20px]">
        <button
          onClick={() => navigate(`/shop/productEdit/${product?._id}`)}
          className="bg-[#E41272] w-[113px] h-[48px] text-white flex items-center text-[20px] font-bold font-Inter rounded-[100px] justify-center gap-x-[8px]"
        >
          Edit
          <BiEdit />
        </button>
      </div>
    </div>
  );
};

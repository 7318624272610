// Configuration
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Third party package
import { Button, Rating } from "@mui/material";
import { pink } from "@mui/material/colors";
import { Modal } from "antd";
import { IoClose } from "react-icons/io5";

// Components
import SendReviewForm from "../SendReviewForm/SendReviewForm";
import { useGetAllReviewsByCourseIdQuery } from "../../../../../Redux/features/Course/courseApi";
import { OvalLoader } from "../../../../Shared/Loader/OvalLoader";
import ErrorMessage from "../../../../Shared/components/ErrorMessage/ErrorMessage";
import { MdOutlineRecommend } from "react-icons/md";
import { Toaster } from "react-hot-toast";
import useDocumentTitle from "../../../../../helper/utilities/useDocumentTitle";

const ViewCourseReview = () => {
  const [reviewEditFormVisible, setReviewEditFormVisible] = useState(false);
  const [reviewForEdit, setReviewForEdit] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: reviewResponse,
    isLoading,
    error,
  } = useGetAllReviewsByCourseIdQuery({
    id: id,
    page: 1,
    limit: 10,
  });

  const handleReviewFormCancel = () => {
    setReviewEditFormVisible(false);
  };

  const handleEditReview = (review) => {
    setReviewEditFormVisible(true);
    setReviewForEdit(review);
  };

  // Page Title
  useDocumentTitle("Admin | Course Review");

  let content;
  if (isLoading) {
    content = <OvalLoader title={"Loading..."} />;
  } else if (error) {
    content = <ErrorMessage />;
  } else if (!error && reviewResponse?.reviews?.length === 0) {
    content = (
      <>
        <div className="border-b-2 flex justify-between items-center">
          <p className="text-[32px] text-gray-700 font-semibold"></p>
          <div onClick={() => navigate(-1)}>
            <IoClose className="border border-gray-500 text-3xl rounded-full hover:bg-gray-500 hover:text-white cursor-pointer duration-300 mb-3" />
          </div>
        </div>
        <div className="flex flex-col items-center justify-center mt-[100px]">
          <MdOutlineRecommend className="text-[42px] text-gray-400" />
          <p className="mt-[10px] text-[16px] font-Inter font-medium text-gray-500">
            No Reviews Found!
          </p>
        </div>
      </>
    );
  } else if (!error && reviewResponse?.reviews?.length > 0) {
    content = (
      <>
        <div className="border-b-2 flex justify-between items-center mb-[20px]">
          <h2 className="text-[32px] text-gray-700 font-semibold">
            Reviews Of - {reviewResponse?.reviews[0]?.course?.name}
          </h2>
          <div onClick={() => navigate(-1)}>
            <IoClose className="border border-gray-500 text-3xl rounded-full hover:bg-gray-500 hover:text-white cursor-pointer duration-300" />
          </div>
        </div>
        <div className="w-full flex flex-col gap-y-[30px]">
          {reviewResponse?.reviews?.map((review) => {
            return (
              <div className="flex w-full" key={review?._id}>
                <img
                  src={review?.user?.profilePhoto}
                  className="w-[60px] h-[60px] rounded-full mr-5"
                  alt="profile"
                />
                <div className="w-full">
                  <div className="flex justify-between items-center">
                    <div>
                      <h3 className="text-2xl font-Inter font-semibold">
                        {review?.user?.firstName + " " + review?.user?.lastName}
                      </h3>
                      <Rating
                        name="read-only"
                        value={review?.rating}
                        readOnly
                      />
                    </div>
                    <div>
                      <Button
                        onClick={() => handleEditReview(review)}
                        sx={{
                          borderColor: pink[500],
                        }}
                        variant="outlined"
                        className="whitespace-nowrap"
                      >
                        Edit Review
                      </Button>
                    </div>
                  </div>
                  <p className="mt-[10px] text-base font-normal text-gray-600">
                    {review?.review}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }

  return (
    <div className="my-14 text-left px-6">
      <div>{content}</div>

      {/* Edit review form modal */}
      <Modal
        title={<p className="text-xl">Edit Review</p>}
        open={reviewEditFormVisible}
        onCancel={handleReviewFormCancel}
        footer={null}
      >
        <SendReviewForm {...{ reviewForEdit, setReviewEditFormVisible }} />
      </Modal>

      <Toaster />
    </div>
  );
};

export default ViewCourseReview;

import { Divider } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { ProductRow } from "./ProductRow";

export const CalculationTable = ({ selectedProducts, setSelectedProducts }) => {
    const [grandTotal, setGrandTotal] = useState(0);
    const [grandProducts, setGrandProducts] = useState([]);

    const setInitialGrandProducts = useCallback(() => {
        if (selectedProducts) {
            const a = selectedProducts?.map(item => {
                const { value } = item || {};
                return {
                    _id: value._id,
                    subTotal: value?.price,
                };
            });
            setGrandProducts(a);
        }
    }, [selectedProducts]);

    let content;
    if (selectedProducts?.length === 0) {
        content = "No Products Added";
    } else {
        content = selectedProducts?.map((item, index) => {
            const { value } = item || {};
            return (
                <ProductRow
                    key={index}
                    product={value}
                    setGrandProducts={setGrandProducts}
                    grandProducts={grandProducts}
                    setInitialGrandProducts={setInitialGrandProducts}
                    selectedProducts={selectedProducts}
                    setSelectedProducts={setSelectedProducts}
                />
            );
        });
    }

    useEffect(() => {
        setInitialGrandProducts();
    }, [selectedProducts, setInitialGrandProducts]);

    useEffect(() => {
        const total = grandProducts?.reduce((acc, curr) => {
            return acc + curr?.subTotal;
        }, 0);
        setGrandTotal(total);
    }, [grandProducts]);
    return (
        <div>
            <table className="relative w-full">
                <thead>
                    <tr className="border border-solid border-l-0 border-r-0 w-full flex justify-between px-[20px]">
                        <th></th>
                        <th className="text-center w-full flex justify-center">Image</th>
                        <th className="text-center w-full flex justify-center">Name</th>
                        <th className="text-center w-full flex justify-center">Price</th>
                        <th className="text-center w-full flex justify-center">Delivery</th>
                        <th className="text-center w-full flex justify-center">Quantity</th>
                        <th className="text-center w-full flex justify-center">Discount</th>
                        <th className="text-center w-full flex justify-center">SubTotal</th>
                        <th className="text-center w-full flex justify-center">Action</th>
                        <th className="text-center w-full flex justify-center"></th>
                    </tr>
                </thead>
                <tbody>{content}</tbody>
            </table>
            <Divider />
            <div className="flex justify-end text-[20px] font-Manrope gap-x-[10px] mr-[30px]">
                <span className="font-semibold">Grand Total: </span>
                <span>{grandTotal}</span>
            </div>
        </div>
    );
};

import React from "react";
import { Link } from "react-router-dom";

// Components
import { ageCalculator } from "../../../../../helper/utilities/ageCalculator";

export const ViewAllAgentsCard = ({ agent }) => {
  const age = ageCalculator(agent?.dateOfBirth);
  return (
    <div
      className="py-10 rounded-lg bg-[linear-gradient(167.47deg,#FB56B9_24.77%,#A57BFF_91.59%)]"
      style={{ boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.12)" }}
    >
      <div className="flex justify-start items-center gap-6 px-9 mb-9">
        <img
          className="w-[85px] h-[85px] rounded-full"
          src={agent?.profilePhoto}
          alt="profile"
        />
        <div className="text-left">
          <h3 className="text-2xl leading-[29px] font-medium mb-4">
            {(agent?.firstName ? agent?.firstName : "") +
              " " +
              (agent?.lastName ? agent?.lastName : "")}
          </h3>
          <p className="text-xl leading-6">
            Age : {agent?.age ? agent?.age : age ? age : ""}
          </p>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <Link
          to={`profile/${agent?._id}`}
          className="text-base text-white leading-5 font-medium w-[135px] py-[9px] rounded-sm mr-2"
          style={{
            backgroundImage:
              "linear-gradient(180deg, #E22989 0%, #A72CC7 100%)",
          }}
        >
          View Profile
        </Link>
      </div>
    </div>
  );
};

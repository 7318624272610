// Configuration
import { Select } from "antd";
import React from "react";
import ReactApexChart from "react-apexcharts";

const VisitorStatusChart = () => {
  const handleChange = (value) => {};
  return (
    <div>
      <div className="w-[900px] h-[419px] pl-3 pr-6 py-6 rounded-xl shadow-[0px_3px_4px_rgba(0,0,0,0.04)]">
        <div className="flex justify-between items-center">
          <h1 className="text-left text-lg leading-7 font-bold font-poppins pl-3">
            Visitor
          </h1>
          <Select
            defaultValue="month"
            style={{
              width: 100,
            }}
            onChange={handleChange}
            options={[
              {
                value: "week",
                label: "Week",
              },
              {
                value: "month",
                label: "Month",
              },
              {
                value: "year",
                label: "Year",
              },
            ]}
          />
        </div>
        <ReactApexChart
          options={{
            chart: {
              type: "bar",
              height: 350,
              toolbar: {
                show: false,
              },
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: "55%",
                endingShape: "rounded",
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              show: true,
              width: 2,
              colors: ["transparent"],
            },
            colors: ["#E41272", "#8559E4"],
            grid: {
              borderColor: "#DDDDDD",
              strokeDashArray: 7,
            },
            legend: {
              position: "top",
              horizontalAlign: "right",
              fontSize: "14px",
              labels: {
                colors: "#98A4B5",
              },
              markers: {
                width: 6,
                height: 6,
                radius: 6,
              },
            },
            xaxis: {
              labels: {
                style: {
                  colors: "#98A4B5",
                },
              },
              categories: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ],
            },
            yaxis: {
              labels: {
                style: {
                  colors: "#98A4B5",
                },
              },
            },
            fill: {
              opacity: 1,
            },
            tooltip: {
              y: {
                formatter: (val) => {
                  return val + " Person";
                },
              },
            },
          }}
          series={[
            {
              name: "Service Provider",
              data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 49, 77, 96],
            },
            {
              name: "User",
              data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 139, 213, 180],
            },
          ]}
          type="bar"
          height={350}
        />
      </div>
    </div>
  );
};

export default VisitorStatusChart;

// configuration
import React from "react";

// Third party packages
import { useDispatch, useSelector } from "react-redux";
import { setAction } from "../../../../../Redux/features/RestrictSuspend/User/restrictSuspendUserSlice";

export const RestrictSuspendHeaderButton = () => {
    // hook variable declaration
    const currentActionType = useSelector(state => state.restrictSuspend.action);
    const dispatch = useDispatch();

    //function declaration
    const restrictSuspendHandler = action => {
        dispatch(setAction(action));
    };

    return (
        <div className="w-full mt-[57px] flex items-center gap-x-[16px]">
            <button
                className={`w-[144px] h-[48px] text-[16px] font-semibold font-Inter rounded-[4px] flex justify-center items-center drop-shadow-[4px_4px_4px_rgba(0,0,0,0.12)] ${
                    currentActionType.includes("Suspend")
                        ? "bg-[linear-gradient(180deg,#DF2983_0%,#B02BBE_100%)] text-white"
                        : "bg-[#FFFFFF] text-[#000000]"
                }`}
                onClick={() => restrictSuspendHandler("Suspend")}
            >
                Suspend
            </button>
            <button
                className={`w-[144px] h-[48px] text-[16px] font-semibold font-Inter rounded-[4px] flex justify-center items-center drop-shadow-[4px_4px_4px_rgba(0,0,0,0.12)] ${
                    currentActionType.includes("Restrict")
                        ? "bg-[linear-gradient(180deg,#DF2983_0%,#B02BBE_100%)] text-white"
                        : "bg-[#FFFFFF] text-[#000000]"
                }`}
                onClick={() => restrictSuspendHandler("Restrict")}
            >
                Restrict
            </button>
        </div>
    );
};
